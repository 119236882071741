import type { LessonCheckpointTest } from '../../../definitions/checkpoint-definitions';

export const checkpoint: LessonCheckpointTest = {
  id: 'checkpoint_1',
  areaId: 'pitch_and_harmony',
  checkpoint: true,
  order: 10,
  title: 'Piano, clefs and accidentals',
  options: {
    name: 'Piano, clefs and accidentals',
    instructions: `<p>
        This checkpoint tests your mastery of the piano keyboard, the treble clef, the bass clef, and accidentals.
        </p>
        <p>
          <strong>All of your answers are final</strong>. You will not be able to go back to change answers on previous questions.
        </p>
        <p>
          You have up to {{totalTime}} to complete this checkpoint. Please be sure you have enough time before beginning the checkpoint.
        </p>
        <p>
          {{passingSentence}}
        </p>
      `,
  },
  sections: [
    {
      options: {
        instructions: `<p>
            Section {{sectionNumber}}: Piano
          </p>
          <p>
            In this section you will answer {{totalQuestions}} questions playing and identifying notes on the piano.
            You will have a maximum of {{sectionTime}} for this section, any unanswered questions will be counted wrong.
          </p>
          `,
        name: 'Piano',
        timeLimit: '0:60',
        sortOrder: 'type',
        weight: 33,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'PianoID',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {
                highestNote: 'D5',
                lowestNote: 'G3',
              },
            },
            {
              questionType: 'SingleNotePlay',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {
                highestNote: 'E5',
                lowestNote: 'C4',
                ignoreOctave: true,
              },
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: ['B#', 'E#'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Cb', 'Fb'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Bx', 'Ex'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Cbb', 'Fbb'],
            },
            {
              numberOfQuestions: 1,
              questions: ['C', 'D', 'E', 'F', 'G', 'A', 'B'],
            },
            {
              numberOfQuestions: 1,
              questions: ['C#', 'D#', 'F#', 'G#', 'A#', 'Db', 'Eb', 'Gb', 'Ab', 'Bb'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Dbb', 'Ebb', 'Gbb', 'Abb', 'Bbb', 'Cx', 'Dx', 'Fx', 'Gx', 'Ax'],
            },
          ],
        },
      ],
    },
    {
      options: {
        instructions: `<p>
            Section {{sectionNumber}}: {{sectionName}}
          </p>
          <p>
            In this section, you will have {{sectionTime}} to write and identify {{totalQuestions}} notes in different clefs.
            Any unanswered questions will be counted wrong.
          </p>
          <p>
            <strong>Be careful: Clefs change between questions.</strong>
          </p>
          `,
        name: 'Treble and Bass Clef',
        timeLimit: '1:30',
        sortOrder: 'type',
        weight: 33,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'NoteID',
              questionWeighting: 1,
              questionTypeFrequency: 2,
              options: {},
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: [
                'E4/treble',
                'F4/treble',
                'G4/treble',
                'A4/treble',
                'B4/treble',
                'C5/treble',
                'D5/treble',
                'E5/treble',
                'F5/treble',
                'D4/treble',
                'G5/treble',
              ],
            },
            {
              numberOfQuestions: 1,
              questions: ['A5/treble', 'B5/treble', 'C6/treble', 'D6/treble', 'E6/treble'],
            },
            {
              numberOfQuestions: 1,
              questions: ['A3/treble', 'B3/treble', 'C4/treble', 'G3/treble', 'F3/treble'],
            },
            {
              numberOfQuestions: 1,
              questions: [
                'G2/bass',
                'A2/bass',
                'B2/bass',
                'C3/bass',
                'D3/bass',
                'E3/bass',
                'F3/bass',
                'G3/bass',
                'A3/bass',
                'B3/bass',
              ],
            },
            {
              numberOfQuestions: 1,
              questions: ['C4/bass', 'D4/bass', 'E4/bass', 'F4/bass', 'G4/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['F2/bass', 'E2/bass', 'D2/bass', 'C2/bass', 'B1/bass'],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'NoteDraw',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {
                ignoreOctave: true,
              },
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: [
                'E4/treble',
                'F4/treble',
                'G4/treble',
                'A4/treble',
                'B4/treble',
                'C5/treble',
                'D5/treble',
              ],
            },
            {
              numberOfQuestions: 1,
              questions: [
                'G2/bass',
                'A2/bass',
                'B2/bass',
                'C3/bass',
                'D3/bass',
                'E3/bass',
                'F3/bass',
              ],
            },
          ],
        },
      ],
    },
    {
      options: {
        instructions: `<p>
            Section {{sectionNumber}}: {{sectionName}}
          </p>
          <p>
            In this section, you will play {{sectionQuestions}} written measures of music on a piano keyboard. You have {{sectionTime}}, or roughly {{secondsPerQuestion}} seconds per measure.
          </p>
          `,
        name: 'Piano Note Reading',
        timeLimit: '2:45',
        sortOrder: 'random',
        weight: 34,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'NoteReadingPiano',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {
                clef: 'treble',
                highestNote: 'E5',
                lowestNote: 'C4',
              },
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: [
                'E4/Bb4/A4/Bb4',
                'F#/A/D/F#',
                'Eb4/C4/Eb/E',
                'C#/F#/A#/C#',
                'Db5/B/Db5/D5',
                'Eb5/F#4/G4/C5',
                'G/Gb/Gb/G',
                'Eb5/Bb4/G4/Bb4',
                'D#/E/D#/C#',
                'Db/F/Ab/Db',
              ],
            },
            {
              numberOfQuestions: 1,
              questions: [
                'F#4/Fx4/G#4/Fx4',
                'Cx/D#/F#/B',
                'Bb/Bbb/Ab/G',
                'Fb/Abb/Db/Abb',
                'Cx/Bbb/D#5/A',
              ],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'NoteReadingPiano',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {
                clef: 'treble',
                highestNote: 'F6',
                lowestNote: 'E5',
              },
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: [
                'E6/C6/G5/Bb5',
                'F#5/C6/A5/F#5',
                'Bb5/Ab5/F#5/G5',
                'Eb6/F#5/A5/Eb6',
                'G5/G#5/B5/G#5',
                'D6/F#5/Fx5/G#5',
              ],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'NoteReadingPiano',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {
                clef: 'treble',
                highestNote: 'F4',
                lowestNote: 'E3',
              },
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: [
                'C/Ab3/F3/D4',
                'F#3/D4/G3/F#3',
                'Gb3/A3/G#3/G#3',
                'Cx4/D#4/Fx3/G#3',
                'Bb3/Bbb3/Ab3/Gb3',
              ],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'NoteReadingPiano',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {
                clef: 'bass',
                highestNote: 'B3',
                lowestNote: 'G2',
              },
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: [
                'F#3/G#3/A3/F#3',
                'C#3/Bb3/E3/G3',
                'G2/Db3/Eb3/Db3',
                'A#3/Ax3/B2/Ax3',
                'Bb2/Eb3/Ab3/Eb3',
                'A2/F#3/A#2/B2',
                'B#2/D#3/Fx3/Gx3',
                'E3/E#3/G#3/F#3',
                'Bb3/A3/F3/Eb3',
                'Ab2/G2/C#3/D3',
                'C#3/G2/E3/C#3',
              ],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'NoteReadingPiano',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {
                clef: 'bass',
                highestNote: 'G4',
                lowestNote: 'F3',
              },
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: [
                'G4/Eb4/Bb3/G3',
                'A3/Bb3/F4/E4',
                'Gb4/Bb3/C4/Gb4',
                'D4/F#4/A3/F#4',
                'Eb4/D4/G4/C4',
                'Cx4/D#4/Fx4/A#3',
                'Gb4/Ebb4/Cb4/Bb3',
              ],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'NoteReadingPiano',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {
                clef: 'bass',
                highestNote: 'D3',
                lowestNote: 'B1',
              },
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: [
                'B1/C2/F2/E2',
                'Bb2/F#2/G2/D2',
                'C2/F#2/G2/F#2',
                'Eb2/C2/Eb2/G2',
                'Ab2/Abb2/Gb2/Abb2',
                'E2/G#2/D2/G2',
                'B1/F2/Eb2/C2',
              ],
            },
          ],
        },
      ],
    },
  ],
};
