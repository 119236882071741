import type { TypeOf } from 'io-ts';
import {
  intersection,
  string,
  literal,
  union,
  type,
  nullType,
  boolean,
  number,
  partial,
} from 'io-ts';
import { date } from 'io-ts-types';
import type { CustomCheckpoint, SavedCustomCheckpoint } from './checkpoint-definitions';
import { LatePenaltySchema } from './late-penalty-definition';
import { SolfegeSystemNameSchema } from './musicTheory/solfegeAndScaleDegrees';

/**
 * The three main areas in uTheory
 */
export const AreaNameSchema = union([
  literal('rhythm'),
  literal('pitch_and_harmony'),
  literal('ear_training'),
]);
/**
 * The three main areas in uTheory
 */
export type AreaName = TypeOf<typeof AreaNameSchema>;

export const AssignmentStatusSchema = union([
  literal('required'),
  literal('optional'),
  literal('hidden'),
]);
export type AssignmentStatus = TypeOf<typeof AssignmentStatusSchema>;

export interface SectionRecordWithJoins extends SectionRecord {
  tests: SavedCustomCheckpoint[];
  lmsProviderId: null | string;
  /** Hidden from student */
  inviteUrl: string | null;
}

export interface ConnectedTestInformation {
  customCheckpointId: string;
  visibleFromSet: boolean;
  visibleFrom: Date | null;
  visibleUntilSet: boolean;
  visibleUntil: Date | null;
  dueDateSet: boolean;
  dueDate: Date | null;
}

export interface SectionRecord {
  id: string;
  /** Hidden from students */
  inviteLink?: string | null;
  ownerIds: string[];
  teacherIds: string[];
  teachingAssistantIds: string[];
  // testIds: string[];
  connectedTests: ConnectedTestInformation[];
  sectionName: string;
  /** Which lesson areas are enabled for this section? */
  areas: AreaName[];
  lessons: SectionLessonSettings[];
  /** Which skill areas are enabled for this section? */
  skillAreas: AreaName[];
  /** Hidden skills are removed from array on save */
  skills: SectionSkillSettings[];
  options: SectionOptions;
  /** institutionId */
  paidById?: null | string;
  institutionId?: null | string;
  isDefaultSection?: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface SectionRecordWithCount extends SectionRecord {
  /** Current enrollment */
  count: number;
}

export const SectionLessonSettingsSchema = type({
  id: string,
  status: AssignmentStatusSchema,
  date: union([date, nullType]),
});

export type SectionLessonSettings = TypeOf<typeof SectionLessonSettingsSchema>;

export const SectionSkillSettingsSchema = SectionLessonSettingsSchema;
export type SectionSkillSettings = TypeOf<typeof SectionSkillSettingsSchema>;

export interface SectionWithJoins extends SectionRecord {
  tests: CustomCheckpoint[];
  count?: number;
}

export const SectionSystemsOptionsSchema = type({
  rhythm: type({
    allowSelect: boolean,
    method: union([literal('american'), literal('british')]),
  }),
  solfege: type({
    allowSelect: boolean,
    method: SolfegeSystemNameSchema,
  }),
});

export type SectionSystemsOptions = TypeOf<typeof SectionSystemsOptionsSchema>;

export const defaultSectionSystemOptions: SectionSystemsOptions = {
  rhythm: { allowSelect: true, method: 'american' },
  solfege: { allowSelect: true, method: 'scaleDegrees' },
};

export const SectionOptionsDeadlinesSchema = type({
  /** End date for deadlines AS ISO STRING, or false */
  dateFinish: union([date, nullType]),
  /** Start date for deadlines AS ISO STRING, or false */
  dateStart: union([date, nullType]),
  /** Whether to show due dates or not */
  visible: boolean,
  /** The penalty to apply, if any, for late work */
  penalty: LatePenaltySchema,
});
export type SectionOptionsDeadlines = TypeOf<typeof SectionOptionsDeadlinesSchema>;

export const SectionOptionsGradingSchema = type({
  /** Number 0 - 100 */
  checkpoints: number,
  /** Number 0 - 100 */
  checkpointsPercent: number,
  /** Number 0 - 100 */
  lessons: number,
  /** Number 0 - 100 */
  lessonsPercent: number,
  /** Number 0 - 100 */
  skills: number,
  /** Number 0 - 100 */
  skillsPercent: number,
  /** Whether to show students the grade calculation */
  visibleToStudents: boolean,
});
export type SectionOptionsGrading = TypeOf<typeof SectionOptionsGradingSchema>;

export const CheckpointRequirementsSchema = type({
  /** 0 - 100 */
  passingPercent: number,
  password: intersection([
    type({
      require: boolean,
    }),
    partial({
      /** Hidden from students */
      value: union([string, nullType]),
    }),
  ]),
  repetition: type({ allow: boolean, count: number }),
});
export type CheckpointRequirements = TypeOf<typeof CheckpointRequirementsSchema>;

export const SectionOptionsSchema = type({
  systems: SectionSystemsOptionsSchema,
  deadlines: SectionOptionsDeadlinesSchema,
  grading: SectionOptionsGradingSchema,
  lessons: type({
    mustPassPriorCheckpoint: boolean,
    mustCompletePriorLesson: boolean,
    checkpoints: CheckpointRequirementsSchema,
  }),
  knowledge: type({
    hideSkillsNotInLessons: boolean,
    useLessonSkills: boolean,
  }),

  lms: type({
    /** Whether to show optional lessons & skills as assignments in the LMS */
    hideOptionalContent: boolean,
  }),
  performanceThresholds: type({
    rhythm: number,
  }),
});
export type SectionOptions = TypeOf<typeof SectionOptionsSchema>;
