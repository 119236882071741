import type { Lesson } from '../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'bass_clef_1',
  areaId: 'pitch_and_harmony',
  order: 7,
  title: 'The Bass Clef: Intro',
  skills: ['bass_lines', 'bass_spaces'],
  pages: [
    {
      title: 'Introduction',
      id: 'bass_clef_1_intro',
      order: 1,
      content: `
      <h3>The Bass Clef</h3>
      <p>In this lesson you'll learn about the <strong>bass
      clef</strong>, the clef we use for lower notes and
      instruments.</p>
      <p>Click the next button below to get started!</p>
      `,
    },
    /**
     *  Video Script: bassClef1-1
     *
     *  The Bass Clef is a fancy letter F, with the
     *  two dots surrounding the line that is F.  From there, we can go up the
     *  scale until we reach Middle C on the first Ledger Line above the staff.
     *  Let's practice reading these first five notes of the Bass Clef.
     */
    {
      title: 'Bass Clef F to C',
      id: 'bass_clef_1_video1',
      order: 2,
      video: {
        url: 'bassClef1-1',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Draw a note on the line or space indicated:
     */
    {
      title: 'Identify: F to C',
      order: 3,
      id: 'bass_clef_1_ID_f_to_g',
      interactive: {
        type: 'NoteID',
        questionPrompt: 'Click on the name of the note you see.',
        clef: 'bass',
        questions: [
          'F3',
          'G3',
          'A3',
          'B3',
          'C4',
          'F3',
          'C4',
          'A3',
          'B3',
          'G3',
          'C4',
          'G3',
          'B3',
          'A3',
          'F3',
        ],
      },
    },
    /**
     * Video Script: bassClef1-2
     *
     * If we count down from bass clef F, we get the rest of the notes of the
     * bass clef.  Take a look at these - G, A, B, C, D, E, and let's practice
     * these six notes:
     */
    {
      title: 'Bass Clef: G to E',
      order: 4,
      id: 'bass_clef_1_video2',
      video: {
        url: 'bassClef1-2',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      title: 'Play: G to E',
      order: 5,
      id: 'bass_clef_1_play_g_to_e',
      interactive: {
        type: 'NoteReadingPiano',
        clef: 'bass',
        lowestNote: 'F2',
        highestNote: 'C4',
        questions: ['G2/A2/B2/C3', 'E3/F3/G2/D3', 'B2/F3/A2/E3', 'A2/E3/F3/G2', 'E3/A2/D3/B2'],
      },
    },
    /**
     *  Video Script: bassClef1-3
     *
     *  A quick way of remembering these notes is to use a mnemonic device for
     *  the lines and spaces.  Let's starts with the spaces -- which are A C E
     *  G.  We could make a sentence where each word begins with the letter name
     *  of the note.  The really popular one is All Cows Eat Grass.  Take your
     *  hand (etc...)  Let's practice these.
     */
    {
      title: 'Bass Clef Spaces',
      order: 6,
      id: 'bass_clef_1_spaces',
      video: {
        url: 'bassClef1-3',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Draw a note on the line or space indicated:
     */
    {
      title: 'Name Spaces',
      order: 7,
      id: 'bass_clef_1_id_spaces',
      interactive: {
        type: 'NoteID',
        questionPrompt: 'Click on the name of the note you see.',
        clef: 'bass',
        questions: [
          'A2',
          'C3',
          'E3',
          'G3',
          'A2',
          'G3',
          'E3',
          'C3',
          'G3',
          'E3',
          'G3',
          'C3',
          'E3',
          'A2',
          'G3',
          'A2',
        ],
      },
    },
    /**
     *  Video Script: bassClef1-3
     *
     *  And the lines: GBDFA -- the classic mnemonic for this is "Good Boys Do
     *  Fine Always", which I think is too easily confused with the treble clef
     *  lines mnemonic: "Every good boy deserves fudge."  So, instead, let's
     *  use: "Great Bassists Don't Forget Anything."  Because it has the word
     *  "bass" in it, it will help you remember that it's the mnemonic for the
     *  bass clef lines!
     */
    {
      title: 'Bass Clef Lines',
      order: 8,
      id: 'bass_clef_1_lines',
      video: {
        url: 'bassClef1-4',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      title: 'Play Lines',
      order: 9,
      id: 'bass_clef_1_play_lines',
      interactive: {
        type: 'NoteReadingPiano',
        clef: 'bass',
        lowestNote: 'G2',
        highestNote: 'C4',
        questions: [
          'G2/B2/D3/F3/A3',
          'D3/G2/F3/B2',
          'A3/F3/A3/B2',
          'F3/G2/F3/B2',
          'G2/A3/B2/F3/D3',
        ],
      },
    },
    /**
     * Video Script: bass-clef1-4
     *
     * Let's mix and match the lines and spaces now.  In this next game, we'll
     * ask you to name the note on a particular line and space of the bass clef
     * -- without actually showing you the clef or note!  Remember we count
     * these from the bottom, lines 1 2 3 4 5; spaces 1 2 3 4.  So if you were
     * asked, "What's the note on the 3rd line of the bass clef?" You'd imagine
     * the bass clef, and think up to the third line, which is D.  Give it a
     * try!
     */
    {
      title: 'Bass Clef: Lines & Spaces',
      order: 10,
      id: 'bass_clef_1_video5',
      video: {
        url: 'bassClef1-5',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      title: 'Lines and Spaces',
      order: 11,
      id: 'bass_clef_1_name_note_on_line_space',
      interactive: {
        type: 'NameNoteOnLineOrSpace',
        questionPrompt: "What's the name of the note {question} of the bass clef?",
        clef: 'bass',
        questions: [1, 4, 2, 5, 3, 1.5, 4.5, 2.5, 3.5, 2, 4.5, 1.5, 5, 1, 2.5, 3, 3.5],
      },
    },
    {
      title: 'Draw: Bass Clef',
      order: 12,
      id: 'bass_clef_1_draw_notes',
      interactive: {
        type: 'NoteDraw',
        questionPrompt: 'Draw the note {question} on the staff.',
        ignoreOctave: true,
        clef: 'bass',
        questions: ['G3', 'A3', 'D3', 'E3', 'F3', 'B3', 'C3', 'G3', 'D3', 'F3', 'B3', 'A3', 'E3'],
      },
    },
    {
      title: 'Summary',
      order: 13,
      id: 'bass_clef_1_summary',
      content: `
      <h3>Nice Work</h3>
      <p>That's it for the bass clef.  You know the note names of the bass clef, how it is just fancy letter F, and you've read some music written in bass clef.  Great job!</p>
      `,
    },
  ],
};
