import { get } from './get';

interface SortOptions {
  direction?: 'ascending' | 'descending';
  ignoreCase?: boolean;
}

export interface KeyWithOptions extends SortOptions {
  key: string | number;
}

const defaultSortOptions: SortOptions = {
  direction: 'ascending',
  ignoreCase: true,
};

function isKeyWithOptions(el: any): el is KeyWithOptions {
  return typeof el === 'object' && 'key' in el;
}

/**
 * Sort an array of objects by a shared property name.
 * ```typescript
 *   [
 *     { name: 'Amy', age: 23 },
 *     { name: 'Denise', age: 22 },
 *     { name: 'Amy', age: 5 },
 *     { name: 'Cathy', age: 15 },
 *     { name: 'Betty', age: 80 },
 *   ]
 *   .slice(0)
 *   .sort(sortByFactory('name', { key: 'age', direction: 'descending' }));
 *   // Returns:
 *   [
 *     { name: 'Amy', age: 23 },
 *     { name: 'Amy', age: 5 },
 *     { name: 'Betty', age: 80 },
 *     { name: 'Cathy', age: 15 },
 *     { name: 'Denise', age: 22 }
 *   ]
 * ```
 */
export function sortByFactory(...keysAndKeysWithOptions: (string | number | KeyWithOptions)[]) {
  return function sortFunction<T>(a: T, b: T) {
    return sort(a, b, keysAndKeysWithOptions);
  };
}

function sort<T>(
  a: T,
  b: T,
  keysAndKeysWithOptions: (string | number | KeyWithOptions)[]
): -1 | 0 | 1 {
  if (!keysAndKeysWithOptions.length) return 0;
  keysAndKeysWithOptions = [...keysAndKeysWithOptions];
  const keyOrKeyWithOptions = keysAndKeysWithOptions.shift();
  const key = isKeyWithOptions(keyOrKeyWithOptions)
    ? (keyOrKeyWithOptions.key as keyof T)
    : (keyOrKeyWithOptions as keyof T);

  const options: SortOptions = isKeyWithOptions(keyOrKeyWithOptions)
    ? { ...defaultSortOptions, ...keyOrKeyWithOptions }
    : defaultSortOptions;

  const sortDirection = comparisonFunction(a, b, key, options);
  if (sortDirection || !keysAndKeysWithOptions.length) return sortDirection;
  return sort(a, b, keysAndKeysWithOptions);
}

function comparisonFunction<T>(a: T, b: T, key: keyof T, options: SortOptions): -1 | 0 | 1 {
  const { direction, ignoreCase } = options;
  const aProp = get(a as any, key as string | number);
  const bProp = get(b as any, key as string | number);
  if (aProp === bProp) return 0;
  let sortDirection = 0;

  if (typeof aProp === 'string' && typeof bProp === 'string' && ignoreCase) {
    const aLowerCase = aProp.toLowerCase();
    const bLowerCase = bProp.toLowerCase();
    if (aLowerCase === bLowerCase) return 0;
    sortDirection = aLowerCase < bLowerCase ? -1 : 1;
  } else {
    sortDirection = aProp < bProp ? -1 : 1;
  }
  return (direction === 'ascending' ? sortDirection : sortDirection * -1) as -1 | 0 | 1;
}
