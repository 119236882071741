import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Tricky Intervals and Imaginary Keys
 */
export const lesson: Lesson = {
  id: 'tricky_intervals',
  areaId: 'pitch_and_harmony',
  order: 28,
  title: 'Tricky Intervals',
  skills: [
    'imaginary_keys',
    'ascending_major_perfect',
    'ascending_others',
    'descending_minor_perfect',
    'descending_others',
  ],
  pages: [
    {
      id: 'tricky_intervals_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Tricky Intervals</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>How to write & recognize intervals from notes that don't have
        their own key signature,</li>
        <li>About "theoretical" keys, and</li>
        <li>Ways to simplify tricky interval questions.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: Cancellation Method
     *
     * 1) Cancellation Method
     *  - E#, +M3
     *  - Bbb, -m3
     *  Let's practice writing some tricky intervals using the cancellation method.
     */
    {
      id: 'tricky_intervals_video_1',
      order: 2,
      title: 'Cancellation Method',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'trickyIntervals1',
      },
    },
    /**
     * Exercise: Write Tricky Intervals (cancellation method)
     */
    {
      title: 'Write Tricky Intervals',
      order: 3,
      id: 'tricky_intervals_cancellation_method',
      interactive: {
        type: 'WriteIntervals',
        questions: [
          'Fb3/M3',
          'G#4/d7',
          'D#5/M3',
          'Dbb3/-m6',
          'Fx3/m3',
          'Bbb4/-d5',
          'E#5/-M3',
          'B#4/-A4',
        ],
      },
    },
    /**
     * Video: Theoretical Keys
     *
     * Video 2: Theoretical Keys
     *  - Key of C Major, sharp everything, you get 7#'s, C# major
     *  - Key of C major, flat everything, you get 7b's, Cb major
     *  - F Major --> Fb Major
     *  Practice some theoretical keys.
     */
    {
      id: 'tricky_intervals_video_2',
      title: 'Theoretical Keys',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'trickyIntervals2',
      },
    },
    /**
     * Exercise: Name double accidentals in theoretical keys
     */
    {
      title: 'Practice Theoretical Keys',
      order: 5,
      id: 'tricky_intervals_theoretical_keys',
      interactive: {
        type: 'ImaginaryKeys',
        questions: ['G#/M', 'Bbb/M', 'E#/M', 'B#/m', 'Gb/m', 'D#/M', 'Gbb/M', 'Fx/M'],
      },
    },
    /**
     * Video: Theoretical Keys Method
     *
     *  - D#, +m6
     *  - Ebb, -m3
     *  Let's practice writing some tricky intervals using the "theoretical keys" method.
     */
    {
      id: 'tricky_intervals_video_3',
      order: 6,
      title: 'Theoretical Keys Method',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'trickyIntervals3',
      },
    },
    /**
     * Exercise: Write tricky intervals (theoretical keys method)
     */
    {
      title: 'Write Tricky Intervals (2)',
      order: 7,
      id: 'tricky_intervals_id_speed',
      interactive: {
        type: 'WriteIntervals',
        questions: ['A#3/M3', 'Fb4/-d5', 'Gx2/d7', 'Cx3/M6', 'Bbb2/A6', 'Abb4/-m3', 'Fx3/M2'],
      },
    },
    /**
     * Video: Tricky Interval ID
     */
    {
      id: 'tricky_intervals_video_4',
      order: 8,
      title: 'Identification',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'trickyIntervals4',
      },
    },
    /**
     * Exercise: Tricky Interval ID
     */
    {
      title: 'Practice: ID Tricky Intervals',
      order: 9,
      id: 'tricky_intervals_id',
      interactive: {
        type: 'IntervalsID',
        questions: ['Fx/m3', 'Cbb4/A4', 'Gb4/A6', 'Dx5/m3', 'Db3/d5', 'E#3/P5', 'Ebb2/M3'],
        autoadvance: true,
      },
    },
    /**
     * Video: Conclusion
     *
     * Conclusion!
     *
     * If you can do that, you can do just about anything with intervals! Great work!
     */
    {
      id: 'tricky_intervals_video_5',
      title: 'Conclusion',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'trickyIntervals5',
      },
    },
  ],
};
