import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Descending Intervals
 */
export const lesson: Lesson = {
  id: 'descending_intervals',
  areaId: 'pitch_and_harmony',
  order: 27,
  title: 'Descending Intervals',
  skills: ['descending_minor_perfect', 'descending_others'],
  pages: [
    {
      id: 'descending_intervals_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Descending Intervals</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>About the Perfect group and the Major/minor group of intervals,</li>
        <li>How to write intervals above a note, and</li>
        <li>How to identify intervals you see.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video #1:
     *
     * So far we've worked with the rule:
     *   - If the top note of an interval is in the Major key of the bottom
     *     note, it is a Perfect or Major interval.
     *
     * There is an inverse corolary of that rule, which will make it easy for us
     * to write descending intervals: If the bottom note of an interval is in
     * the Major key of the top note, it is a minor or Perfect interval.
     *
     * Notice that in both cases, we have Perfect intervals (this is at the
     * heart of why they're considered "perfect"). But when we go down from the
     * top note, we get minor intervals instead of Major.
     *
     * Let's use this to write some descending minor and Perfect intervals.
     *
     * EX: G:-m2, Ab:-P4, Bb:-m3
     */
    {
      id: 'descending_intervals_video_1',
      order: 2,
      title: 'Rule for Descending Intervals',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'descendingIntervals1',
      },
    },
    {
      title: 'Write Perfect and minors',
      order: 3,
      id: 'descending_intervals_perfect_and_min',
      interactive: {
        type: 'WriteIntervals',
        questions: [
          'F3/-P4',
          'A3/-m7',
          'Eb4/-P5',
          'F#3/-m2',
          'Gb3/-m6',
          'Bb4/-P5',
          'E3/-m3',
          'Db3/-P8',
          'F2/-P4',
        ],
      },
    },
    /**
     * descendingIntervals2 Video Script:
     *
     * So if we wanted to write descending Major intervals, we'd need to
     * increase the size of the interval. Let's look at doing that:
     *
     * If we start with A, and want to write a descending Major 3rd, we'll first
     * think of a descending minor 3rd, F#. Now, to make it larger, what
     * accidental should F have?
     *
     * We'll make it F-natural, to move the bottom note down, and farther away
     * from the top note.
     *
     * Let's write some descending Major intervals:
     */
    {
      id: 'descending_intervals_video_2',
      title: 'Descending Major Intervals',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'descendingIntervals2',
      },
    },
    {
      title: 'Write Major Intervals',
      order: 5,
      id: 'descending_intervals_major',
      interactive: {
        type: 'WriteIntervals',
        questions: [
          'D3/-M3',
          'F#2/-M2',
          'E3/-M3',
          'Ab3/-M6',
          'G4/-M7',
          'Eb5/-M6',
          'C#4/-M3',
          'Cb5/-M2',
        ],
      },
    },
    /**
     * descendingIntervals video 3 Script
     *
     * With that, and remembering our chart for accidental qualities, we can now
     * also write diminished and augmented intervals. Let's give some a try.
     *
     * What if we wanted to write a descending A4 from C#?
     * - A descending d7 from F?
     *
     * Let's give some a try
     */
    {
      id: 'descending_intervals_video_3',
      order: 6,
      title: 'Augmented Intervals',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'descendingIntervals3',
      },
    },
    {
      title: 'Write Aug Intervals',
      order: 7,
      id: 'descending_intervals_write_augmented_intervals',
      interactive: {
        type: 'WriteIntervals',
        questions: ['C4/-A4', 'D5/-A2', 'F#3/-A6', 'C#3/-A4', 'G5/-A4'],
      },
    },
    /**
     * Video: Descending diminished intervals
     */
    {
      id: 'descending_intervals_video_4',
      order: 8,
      title: 'Diminished Intervals',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'descendingIntervals4',
      },
    },
    {
      title: 'Write dim Intervals',
      order: 9,
      id: 'descending_intervals_write_diminished_intervals',
      interactive: {
        type: 'WriteIntervals',
        questions: ['F3/-d7', 'Gb4/-d5', 'Bb2/-d4', 'Eb5/-d7', 'Eb3/-d5'],
      },
    },
    /**
     * Identifying Intervals
     *
     * When you know the rule for both ascending & descending
     * intervals, then when you see an interval, you can feel
     * free to figure it out from the key of the top note,
     * or from the key of the bottom note.
     *
     * Let's try one both ways:
     *    Eb - Db: Let's figure it out as an ascending interval.
     *       "An interval is perfect or major if the top note
     *        is in the Major key of the bottom note."
     *      Is Db in Eb Major? No -- it's a half-step too low,
     *      so this is a minor interval.
     *
     *      Let's figure it out as a descending interval. "An
     *      interval is minor or perfect when the bottom note
     *      is in the key of the top note."
     *      Is Eb in the key of Db Major? Yes!
     *
     *      It doesn't matter whether the top or bottom note is
     *      written first, or if they're written to be played
     *      at the same time, you can figure it out from either
     *      note all the time.
     *
     * When identifying intervals, I suggest picking whichever
     * note has the "easier" key signature for you and going
     * from there to figure it out. In fact, you may encounter
     * some notes that don't have their own Major key signature --
     * like G# -- in which case it will definitely be easier to
     * think about it from the other note.
     *
     * Give that a try with these next intervals to identify.
     */
    {
      id: 'descending_intervals_video_5',
      title: 'Identifying Intervals',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'descendingIntervals5',
      },
    },
    {
      title: 'Practice Interval ID',
      order: 11,
      id: 'descending_intervals_id',
      interactive: {
        type: 'IntervalsID',
        questions: [
          'C#2/m7',
          'B4/-A4',
          'Cb4/M7',
          'F5/-M2',
          'Gb3/M3',
          'A#4/-M3',
          'G#3/-A6',
          'G#3/d5',
          'D#4/-A4',
          'F#4/-A2',
          'F2/m6',
          'D4/m6',
          'E3/-m3',
          'D3/-A4',
          'C4/d7',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'descending_intervals_video_6',
      title: 'Conclusion',
      order: 12,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'descendingIntervals6',
      },
    },
  ],
};
