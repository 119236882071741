import type { Lesson } from '../../../definitions/content-definitions';

/**
 * Scale Degrees
 */
export const lesson: Lesson = {
  id: 'scale_degrees',
  areaId: 'pitch_and_harmony',
  order: 34,
  title: 'Scale Degrees',
  skills: ['scale_degree_names', 'scale_degree_conversion_major', 'scale_degree_conversion_minor'],
  pages: [
    {
      id: 'scale_degrees_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Scale Degrees</h3>
        <p>In this lesson we'll learn:
        <ul>
          <li>The names of the scale degrees</li>
          <li>How to spell and identify scale degrees in major and minor, and</li>
          <li>How to write scales from notes other than tonic</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: Scale Degree Names
     */
    {
      id: 'scale_degrees_video_1',
      order: 2,
      title: 'Scale Degree Names',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'scaleDegrees1',
      },
    },
    /**
     * Exercise: Sort Scale Degrees:
     */
    {
      id: 'scale_degrees_sort',
      title: 'Sort Scale Degrees',
      order: 3,
      interactive: {
        type: 'ScaleDegreeNamesSort',
      },
    },
    /**
     * Video: Scale Degrees to Notes
     */
    {
      id: 'scale_degrees_video_2',
      title: 'Finding Scale Degrees',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'scaleDegrees2',
      },
    },
    /**
     * Exercise: Number to Note
     */
    {
      id: 'scale_degrees_number_to_note',
      title: 'Write Notes from SD Numbers',
      order: 5,
      interactive: {
        type: 'ScaleDegreeToNoteID',
        presentationSystem: 'scaleDegrees',
        questions: ['G: 3', '6', '4', 'Bb min: 5', '2', '#7'],
      },
    },
    /**
     * Exercise: Fancy Degree Names to Note
     */
    {
      id: 'scale_degrees_names_to_note',
      title: 'Write Notes from SD Names',
      order: 6,
      interactive: {
        type: 'ScaleDegreeToNoteID',
        presentationSystem: 'names',
        questions: ['Eb: 2', '7', '5', 'F# min: 5', '7', '#7'],
      },
    },
    /**
     * Video: Notes to Degrees
     */
    {
      id: 'scale_degrees_video_3',
      title: 'Scale Degree ID',
      order: 7,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'scaleDegrees3',
      },
    },
    /**
     * Exercise: Find Note from Key: Degree
     */
    {
      id: 'scale_degrees_note_from_number',
      title: 'Identify the Degree Number',
      order: 8,
      interactive: {
        type: 'NoteToScaleDegreeID',
        presentationSystem: 'scaleDegrees',
        questions: ['D: 4/Major', '3', '7', 'G min: 2/harmonicMinor', '#7', '4'],
      },
    },
    /**
     * Video: Naming Keys from Note + Degrees
     */
    {
      id: 'scale_degrees_video_4',
      order: 9,
      title: 'Finding the Key',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'scaleDegrees4',
      },
    },
    /**
     * Exercise: Naming Keys from Note + Degrees
     */
    {
      id: 'scale_degrees_identify_key_from_note_and_degree',
      order: 10,
      title: 'ID the Key from the SD',
      interactive: {
        type: 'ScaleDegreeToNoteID',
        keyIdentification: true,
        degreeSystem: 'names',
        questions: ['E: 3', 'F min: 4', 'Gb: 7', 'D min: 7', 'B: 5'],
      },
    },
    /**
     * Video: Writing scales fron notes other than tonic
     */
    {
      id: 'scale_degrees_video_5',
      order: 11,
      title: 'Scales from SDs',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'scaleDegrees5',
      },
    },
    /**
     * Exercise: Writing scales fron notes other than tonic
     *
     * "Write the Major scale with G as its mediant."
     */
    {
      id: 'scale_degrees_write_scales_from_note_and_degree_name',
      order: 12,
      title: 'Write Scales from SDs',
      interactive: {
        type: 'ScaleWrite',
        degreeName: 'text',
        notFromTonic: true,
        questions: [
          'G/Major/asc/treble/7',
          'C/harmonic minor/asc/bass/4',
          'A/Major/dsc/treble/3',
          'Eb/natural minor/dsc/bass/7',
          'F#/melodic minor/dsc/treble/6',
        ],
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'scale_degrees_video_6',
      title: 'Conclusion',
      order: 13,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'scaleDegrees6',
      },
    },
  ],
};
