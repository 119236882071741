import type { Lesson } from '../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'bass_clef_2',
  areaId: 'pitch_and_harmony',
  order: 8,
  title: 'Bass Clef Ledger Lines',
  skills: ['bass_high_legers', 'bass_low_legers'],
  pages: [
    /**
     * We've already talked about Middle C, the first leger line above the bass
     * clef.  But often we need to write notes that are even higher than that.
     * Let's get some practice with the five notes above the bass clef, starting
     * with Middle C.
     */
    {
      title: 'High Ledger Lines',
      id: 'bass_clef_2_high_leger_video',
      order: 1,
      video: {
        url: 'bassClef2-1',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Identify high legers in bass clef:
     */
    {
      title: 'Identify: High Ledgers',
      order: 2,
      id: 'bass_clef_2_high_leger_id',
      interactive: {
        type: 'NoteID',
        questionPrompt: 'Click on the name of the note you see.',
        clef: 'bass',
        questions: ['C', 'D', 'E', 'F', 'G', 'D', 'F', 'G', 'C', 'E', 'D', 'F'],
      },
    },
    /**
     * Video Script: bassClef2-2
     *
     * And now the leger lines below the clef -- from first-line G down to low C:
     */
    {
      title: 'Bass Clef: Low Ledgers',
      order: 3,
      id: 'bass_clef_2_low_legers_video',
      video: {
        url: 'bassClef2-2',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      title: 'Play: Low Ledgers',
      order: 4,
      id: 'bass_clef_2_play_low_ledgers',
      interactive: {
        type: 'NoteReadingPiano',
        lowestNote: 'C2',
        highestNote: 'C3',
        clef: 'bass',
        questions: ['G2/C2/D2/E2', 'F2/E2/G2/C2', 'D2/F2/G2/E2', 'E2/C2/D2/G2', 'D2/G2/E2/C2'],
      },
    },
    {
      title: 'Identify: Bass Ledgers',
      order: 5,
      id: 'bass_clef_2_id_low_legers',
      interactive: {
        type: 'NoteID',
        questionPrompt: 'Click on the name of the note you see.',
        clef: 'bass',
        drill: true,
        maxQuestions: 15,
        skills: ['bass_low_legers', 'bass_high_legers'],
      },
    },
    {
      id: 'bass_clef_2_conclusion',
      title: 'Summary',
      order: 6,
      content:
        "<h3>Nice Work</h3>\n<p>Those are the bass leger lines, you'll get more practice with them as we begin to learn other skills!</p>\n",
    },
  ],
};
