import knowledgeGraphNodes from './knowledgeGraphNodes';
import type {
  KnowledgeGraphAreaNode,
  KnowledgeGraphNode,
  KnowledgeGraphSkillNode,
  KnowledgeGraphTopicNode,
} from './knowledgeGraphDefinitions';

export const skills = Object.freeze(
  knowledgeGraphNodes.filter(
    (node: KnowledgeGraphNode): node is KnowledgeGraphSkillNode => node.type === 'skill'
  )
);

export const topics = Object.freeze(
  knowledgeGraphNodes.filter(
    (node: KnowledgeGraphNode): node is KnowledgeGraphTopicNode => node.type === 'topic'
  )
);
export const areas = Object.freeze(
  knowledgeGraphNodes.filter(
    (node: KnowledgeGraphNode): node is KnowledgeGraphAreaNode => node.type === 'area'
  )
);

export const areasById = Object.freeze(
  areas.reduce(
    (dictionary: { [id: string]: KnowledgeGraphAreaNode }, area: KnowledgeGraphAreaNode) => {
      dictionary[area.id] = area;
      dictionary[area.textId] = area;
      return dictionary;
    },
    {}
  )
);
export const topicsById = Object.freeze(
  topics.reduce(
    (dictionary: { [id: string]: KnowledgeGraphTopicNode }, topic: KnowledgeGraphTopicNode) => {
      dictionary[topic.id] = topic;
      dictionary[topic.textId] = topic;
      return dictionary;
    },
    {}
  )
);
export const skillsById = Object.freeze(
  skills.reduce(
    (dictionary: { [id: string]: KnowledgeGraphSkillNode }, skill: KnowledgeGraphSkillNode) => {
      dictionary[skill.id] = skill;
      dictionary[skill.textId] = skill;
      return dictionary;
    },
    {}
  )
);

/**
 * Get the string ID for any skill ID or object with obj.id.
 */
export function makeString(id: string | number): string | undefined {
  return skillsById[id]?.textId;
}

/**
 * Get the numeric ID for any skill ID or object with obj.id.
 */
export function makeInt(id: string | number): number | undefined {
  return skillsById[id]?.id;
}
