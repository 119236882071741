import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Half and Whole Notes
 */
export const lesson: Lesson = {
  id: 'halvesWholes',
  areaId: 'rhythm',
  order: 2,
  title: 'Half and Whole Notes',
  skills: ['S:h', 'S:hr', 'S:w', 'S:wr'],
  pages: [
    {
      id: 'halvesWholes_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Half and Whole Notes and Rests</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>About notes that are longer than one beat,</li>
        <li>How to count and play half notes, and</li>
        <li>How to count and play whole notes.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * VIDEO 1:
     *
     * When we combine two quarter notes, we get a half note. When a quarter
     * note occupies one beat, a half note will occupy two.
     *
     * Let's look at a rhythm:
     *
     *  // RHYTHM: "4/4: tempo=80 q q q q | h h ||"
     *
     * In the second measure, we have only two half notes, the first one
     * occupies beats 1 & 2 (remember, half notes take up two beats). What beats
     * does the second one occupy? (3 & 4).
     *
     * Let's count the meter and clap the rhythm together.
     *
     * Great, now let's get some practice playing half notes!
     */
    {
      id: 'halvesWholes_meterAndBeat',
      order: 2,
      title: 'Half Notes',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'halvesWholes1',
      },
    },
    {
      id: 'halvesWholes_tapMeter',
      title: 'Play Half Notes',
      order: 3,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=70 q q h | h q q | q q q q | h h',
          '4/4: tempo=105 h qr q | h q qr | q h q | h h',
          '3/4: tempo=80 q q e e | h q | q h | e e h',
          '2/4: tempo=60 q qr | h | q qr | e e q',
          '3/4: tempo=100 h q | e e h | qr h | h e e',
        ],
        autoadvance: true,
      },
    },
    /**
     * halvesWholes2 Video Script:
     *
     * We can also have a half note rest, which is written like this: _-_
     *
     * Like the half note, this will last two beats. Let's play some
     * half note rests together:
     *
     * 4/4: tempo=70 q q h | h hr | q q hr | h hr
     *
     * Now give it a try on  your own:
     */
    {
      id: 'halvesWholes_quarterRests',
      order: 4,
      title: 'Half Note Rests',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'halvesWholes2',
      },
    },
    {
      id: 'halvesWholes_playQuarterRests',
      order: 5,
      title: 'Play Half Note Rests',
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: q q hr | e e e e hr | q h q | q hr q',
          '4/4: tempo=100 h q e e | h hr | q h q | h hr',
          '3/4: h e e | q hr | q qr q | q hr',
          '4/4: q qr e e qr | h hr | h q e e | hr h',
          '3/4: tempo=100 qr q q | hr q | q q qr | q hr',
        ],
      },
    },
    /**
     * Video: Whole Notes:
     *
     * When we combine 4 quarter notes, we get a whole note, which is drawn as
     * an open oval. In 4/4, 5/4, and so on, any time with 4 as the lower value,
     * a whole note lasts for four beats.
     *
     * Because of this, we cannot fit a whole note in a 2/4 or 3/4 measure.
     *
     * Let's clap a rhythm with whole notes:
     *
     *  // RHYTHM: 4/4: tempo=70 q q q q | w | h h | w
     *
     * Great, now try some on your own:
     */
    {
      id: 'halvesWholes_video_3',
      title: 'Whole Notes',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'halvesWholes3',
      },
    },
    /**
     * Exercise: Play whole notes
     */
    {
      id: 'halvesWholes_order_on_staff',
      title: 'Play Whole Notes',
      order: 7,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=120 q q e e q | h qr e e | q h q | w',
          '4/4: tempo=80 h q qr | w | q hr q | w',
          '4/4: tempo=140 q q h | w | w | q q h',
        ],
      },
    },
    /**
     * Video: Whole Note Rests
     *
     * And, of course, we can also have a whole rest: -_-, which also takes up 4
     * beats.
     *
     * Let's play a rhythms with a whole rests:
     *
     *   // Rhythm: 4/4: tempo=80 hr h | q q h | wr | w
     *
     * Try some on your own
     */
    {
      id: 'halvesWholes_video_4',
      title: 'Whole Note Rests',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'halvesWholes4',
      },
    },
    {
      id: 'halvesWholes_playQuartersEighths',
      title: 'Play Whole Note Rests',
      order: 9,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=80 q h q | wr | h qr e e | wr',
          '4/4: tempo=120 h qr e e | w | h hr | w',
          '4/4: tempo=100 h h | wr | wr | hr qr q',
        ],
      },
    },
    /**
     * Video: Conclusion
     *
     * Congratulations! you've got a good start on rhythm, and
     * on how to read and play half and whole notes and rests!
     */
    {
      id: 'halvesWholes_video_5',
      title: 'Conclusion',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'halvesWholes5',
      },
    },
  ],
};
