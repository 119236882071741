import type { Lesson } from '../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'layout_of_the_piano',
  areaId: 'pitch_and_harmony',
  order: 1,
  title: 'Layout of the Piano',
  skills: ['piano_layout', 'piano_c_g', 'piano_a_b'],
  pages: [
    {
      content:
        "<h3>The Layout of the Piano</h3>\n<p>In this lesson\n- you'll learn about the arrangement of white and black\nnotes on the piano\n- as well as the names of the white notes on the piano.</p>\n<p>Click the next button below to get started!</p>\n",
      order: 1,
      id: 'piano_basics',
      title: 'Piano basics',
    },
    {
      id: 'video_introduction_to_piano',
      title: 'Layout of the Piano',
      order: 2,
      video: {
        url: 'pianoWhiteNotes1',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      id: 'piano_creation',
      interactive: {
        autoadvance: true,
        questionPrompt: 'Click to add black notes to the piano.',
        type: 'PianoCreation',
      },
      order: 3,
      title: 'Practice: Build a piano',
    },
    {
      id: 'more_about_piano',
      order: 4,
      title: 'More on about piano',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'pianoWhiteNotes2',
      },
    },
    {
      id: 'get_familiar_with_octaves',
      interactive: {
        autoadvance: true,
        highestNote: 'C5',
        lowestNote: 'C3',
        questionPrompt: 'Play the note an octave <strong>{question}</strong> the highlighted note.',
        directionOnly: true,
        forceSkill: 1,
        questions: ['B3/P8', 'C3/P8', 'Ab3/P8', 'Bb4/-P8', 'G4/-P8', 'Db4/-P8'],
        type: 'PianoIntervals',
      },
      order: 5,
      title: 'Practice: Get familiar with octaves',
    },
    {
      id: 'piano_note_names',
      title: 'Piano note names',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'pianoWhiteNotes3',
      },
    },
    {
      id: 'interactive_play_a_note',
      title: 'Play C to G',
      order: 7,
      interactive: {
        type: 'SingleNotePlay',
        questionPrompt: 'Play {question} on this keyboard.',
        ignoreOctave: true,
        lowestNote: 'C4',
        highestNote: 'C5',
        questions: ['C4', 'D4', 'E4', 'F4', 'G4'],
      },
    },
    {
      id: 'interactive_ID_c_to_g',
      title: 'Identify C to G',
      order: 8,
      interactive: {
        type: 'PianoID',
        questionPrompt: 'Click the name of the highlighted note.',
        lowestNote: 'C4',
        highestNote: 'C5',
        questions: ['E4', 'C4', 'G4', 'D4', 'F4', 'C4', 'G4', 'E4', 'F4', 'E4', 'D4', 'G4', 'C4'],
      },
    },
    {
      id: 'guess_a_note_video',
      title: 'Note Names',
      order: 9,
      video: {
        url: 'pianoWhiteNotes4',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      id: 'play_note_on_a_piano',
      title: 'Play notes on a piano',
      order: 10,
      interactive: {
        type: 'SingleNotePlay',
        lowestNote: 'C4',
        highestNote: 'C5',
        drill: true,
        skills: ['piano_c_g'],
        ignoreOctave: true,
        maxQuestions: 15,
        autoadvance: true,
      },
    },
    {
      id: 'the_last_two_note_names',
      order: 11,
      title: 'The last two note names',
      video: {
        url: 'pianoWhiteNotes5',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      id: 'the_last_two_note_names_exercise',
      order: 12,
      title: 'The last two note names. Practice',
      interactive: {
        type: 'SingleNotePlay',
        questionPrompt: 'Play the note {question} on this keyboard.',
        lowestNote: 'G3',
        highestNote: 'G5',
        ignoreOctave: true,
        questions: ['A4', 'B4', 'B3', 'A3'],
      },
    },
    {
      id: 'drill_pianoid_exercise_1',
      order: 13,
      title: 'Final exercise',
      interactive: {
        type: 'PianoID',
        drill: true,
        lowestNote: 'C4',
        highestNote: 'G5',
        skills: ['piano_c_g', 'piano_a_b'],
        maxQuestions: 15,
      },
    },
    {
      id: 'final_video_lesson_1',
      order: 14,
      title: 'Final video for first lesson',
      video: {
        url: 'pianoWhiteNotes6',
        autostart: true,
        autoadvance: false,
      },
    },
  ],
};
