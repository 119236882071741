import { action, observable, computed, makeObservable } from 'mobx';
import type {
  LicenseUsageWithSentence,
  LicenseUsage,
  Institution,
  GroupLicense,
  TestingLicense,
} from '../../definitions/license-definitions';
import type { ClientUserStore } from '..';

export interface GroupLicenseStats extends GroupLicense {
  starts: Date;
  ends: Date;
  status: 'future' | 'current' | 'expired';
  quantity: number;
  isCurrent: boolean;
  isFuture: boolean;
  isExpired: boolean;
}

export interface TestingLicenseStats extends TestingLicense {
  starts: Date;
  ends: Date;
  status: 'future' | 'current' | 'expired' | 'full' | 'nearly full';
  quantity: number;
  isCurrent: boolean;
  isFuture: boolean;
  isExpired: boolean;
  isFull: boolean;
  isNearlyFull: boolean;
}

export default class InstitutionStore {
  @observable schoolName!: string;

  @observable firstName!: string;

  @observable lastName!: string;

  @observable id!: string;

  @observable licenses?: GroupLicenseStats[];

  @action addOrUpdateLicense(groupLicense: GroupLicense) {
    if (!this.licenses) this.licenses = [];
    const licenseIndex = this.licenses.findIndex((l) => l.id === groupLicense.id);
    const newLicense = makeLicenseStats(groupLicense);
    if (licenseIndex === -1) {
      this.licenses.push(newLicense);
    } else this.licenses.splice(licenseIndex, 1, newLicense);
  }

  @observable testingLicenses?: TestingLicenseStats[];

  @action addOrUpdateTestingLicense(testingLicense: TestingLicense) {
    if (!this.testingLicenses) this.testingLicenses = [];
    const licenseIndex = this.testingLicenses.findIndex((l) => l.id === testingLicense.id);
    const newLicense = makeTestingLicenseStats(testingLicense);
    if (licenseIndex === -1) {
      this.testingLicenses.push(newLicense);
    } else this.testingLicenses.splice(licenseIndex, 1, newLicense);
  }

  @observable teachers!: {
    firstName: string;
    lastName: string;
    id: string;
  }[];

  @computed get isMe(): boolean {
    return this.id === this.root.id;
  }

  @computed get institutionName(): string {
    return this.schoolName || `${this.firstName} ${this.lastName}`;
  }

  @computed get hasValidGroupLicense(): boolean {
    return !!this.licenses && this.licenses.some((license) => license.isCurrent);
  }

  @computed get groupLicenseIsExpired(): boolean {
    return !!this.licenses && !!this.licenses.length && !this.hasValidGroupLicense;
  }

  root!: ClientUserStore;

  @observable usage?: LicenseUsageWithSentence;

  constructor(institution: Institution, root: ClientUserStore) {
    makeObservable(this);
    this.init(institution, root);
  }

  @action init(institution: Institution, root: ClientUserStore) {
    this.root = root;
    this.update(institution);
    this.usage = undefined;
  }

  @action update(institution: Institution) {
    this.schoolName = institution.schoolName;
    this.firstName = institution.firstName;
    this.lastName = institution.lastName;
    this.id = institution.id;
    // The dates will have turned into strings over the wire, convert them back to dates.
    this.licenses = institution.licenses.map(makeLicenseStats);
    // The dates will have turned into strings over the wire, convert them back to dates.
    this.testingLicenses = institution.testingLicenses.map(makeTestingLicenseStats);
    this.teachers = institution.teachers;
  }

  @action updateUsage(usage: LicenseUsage): void {
    this.usage = getUsageWithSentence(usage, this);
  }
}

interface UsageWords {
  institutionName: string;
  toBe: string;
  toHave: string;
  possessive: string;
  pronoun: string;
}

function getUsageWithSentence(
  usage: LicenseUsage,
  institution: InstitutionStore
): LicenseUsageWithSentence {
  const { institutionName, pronoun, toBe, toHave, possessive } = getUsageWords(usage, institution);
  const { seats, students, extraStudents, exceeded } = usage;
  const sentence = exceeded
    ? `${pronoun} ${toHave} exceeded ${possessive} group license for ${seats} students by ${extraStudents} extra students.`
    : `${pronoun} ${toBe} using ${students} of ${possessive} ${
        seats === -1 ? 'unlimited' : seats
      } group license spots.`;
  return {
    ...usage,
    institutionName,
    sentence,
  };
}

function getUsageWords(usage: LicenseUsage, institution: InstitutionStore): UsageWords {
  const institutionName: string =
    institution.schoolName || `${institution.firstName} ${institution.lastName}`;
  const pronoun = usage.userId === institution.root.id ? 'You' : institutionName;
  const toBe = usage.userId === institution.root.id ? 'are' : 'is';
  const toHave = usage.userId === institution.root.id ? 'have' : 'has';
  const possessive = usage.userId === institution.root.id ? 'your' : 'its';
  return { institutionName, toBe, toHave, possessive, pronoun };
}

function makeTestingLicenseStats(testingLicense: TestingLicense): TestingLicenseStats {
  const now = new Date();
  const starts = new Date(testingLicense.starts);
  const ends = new Date(testingLicense.ends);
  const createdAt = new Date(testingLicense.createdAt);
  const updatedAt = new Date(testingLicense.updatedAt);
  const isCurrent = now >= starts && now <= ends;
  const isFuture = now < starts;
  const isExpired = now > ends;
  const isFull =
    testingLicense.quantity !== -1 && testingLicense.quantity - testingLicense.studentCount <= 0;
  const isNearlyFull =
    testingLicense.quantity !== -1 && testingLicense.quantity - testingLicense.studentCount <= 10;
  let status: TestingLicenseStats['status'];
  if (isExpired) status = 'expired';
  else if (isFuture) status = 'future';
  else if (isFull) status = 'full';
  else if (isNearlyFull) status = 'nearly full';
  else status = 'current';

  return {
    ...testingLicense,
    starts,
    ends,
    isCurrent,
    isFuture,
    isExpired,
    isFull,
    isNearlyFull,
    status,
    createdAt,
    updatedAt,
  };
}

/**
 * Get needed stats for display from a license object
 */
function makeLicenseStats(license: GroupLicense): GroupLicenseStats {
  const starts = new Date(license.starts);
  const ends = new Date(license.ends);
  const future = starts.valueOf() > Date.now();
  const expired = ends.valueOf() < Date.now();
  let status: GroupLicenseStats['status'];
  if (future) status = 'future';
  else if (expired) status = 'expired';
  else status = 'current';

  return {
    ...license,
    status,
    starts: new Date(license.starts),
    ends: new Date(license.ends),
    quantity: license.quantity,
    isCurrent: status === 'current',
    isFuture: status === 'future',
    isExpired: status === 'expired',
  };
}
