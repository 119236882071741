import { action, computed, makeObservable } from 'mobx';
import type SectionKnowledgeTopicStore from './section.knowledge.topic.store';
import type SectionKnowledgeSkillStore from './section.knowledge.skill.store';
import type {
  KnowledgeGraphAreaNode,
  KnowledgeGraphEdge,
} from '../../knowledge/knowledgeGraphDefinitions';
import type { SectionStore } from '../..';

export default class SectionKnowledgeAreaStore {
  // eslint-disable-next-line class-methods-use-this
  @computed get parents(): null[] {
    return [];
  }

  @computed get children(): (SectionKnowledgeSkillStore | SectionKnowledgeTopicStore)[] {
    const isEdgeFromMe = (e: KnowledgeGraphEdge): boolean => e.parentId === this.definition.textId;
    const childIds = this.root.knowledge.knowledgeGraphEdges
      .filter(isEdgeFromMe)
      .map((e: KnowledgeGraphEdge) => e.childId);

    const children = ([] as (SectionKnowledgeSkillStore | SectionKnowledgeTopicStore)[])
      .concat(this.root.knowledge.skills)
      .concat(this.root.knowledge.topics)
      .filter((nodeStore: SectionKnowledgeSkillStore | SectionKnowledgeTopicStore) =>
        childIds.includes(nodeStore.textId)
      );

    return children;
  }

  @computed get topics(): SectionKnowledgeTopicStore[] {
    return this.children.filter(
      (store): store is SectionKnowledgeTopicStore => store.type === 'topic'
    );
  }

  @computed get skills(): SectionKnowledgeSkillStore[] {
    return this.children.filter(
      (store): store is SectionKnowledgeSkillStore => store.type === 'skill'
    );
  }

  @computed get enabled(): boolean {
    return this.root.knowledge.enabledAreaIds.includes(this.textId);
  }

  @computed
  get isVisible() {
    return this.topics.some((topic) => topic.isVisible);
  }

  @computed
  get isRequired() {
    return this.topics.some((topic) => topic.isRequired);
  }

  @computed
  get visibleTopics(): SectionKnowledgeTopicStore[] {
    return this.topics.filter((t) => t.isVisible);
  }

  @computed
  get requiredTopics(): SectionKnowledgeTopicStore[] {
    return this.topics.filter((t) => t.isRequired);
  }

  @computed
  get requiredSkills(): SectionKnowledgeSkillStore[] {
    return this.skills.filter((t) => t.isRequired);
  }

  definition!: KnowledgeGraphAreaNode;

  id!: number;

  textId!: 'rhythm' | 'pitch_and_harmony' | 'ear_training';

  title!: string;

  root!: SectionStore;

  type!: 'area';

  constructor(definition: KnowledgeGraphAreaNode, root: SectionStore) {
    makeObservable(this);
    this.root = root;
    this.init(definition);
  }

  @action init(definition: KnowledgeGraphAreaNode) {
    this.id = definition.id;
    this.type = 'area';
    this.textId = definition.textId;
    this.definition = definition;
    this.title = definition.title;
  }

  @action setEnabled(enabled: boolean) {
    const knowledge = this.root.knowledge;
    const withoutThisArea = knowledge.enabledAreaIds.filter((id) => id !== this.textId);
    if (enabled) {
      knowledge.enabledAreaIds = [...withoutThisArea, this.textId];
    } else {
      knowledge.enabledAreaIds = [...withoutThisArea];
    }
  }
}
