/* eslint-disable class-methods-use-this */
import { observable, computed, runInAction, action, makeObservable } from 'mobx';

const queuedMessages = observable.array<QueuedMessage<any> | ApiErrorMessage>([], { deep: false });

let messageId = 0;

interface ApiErrorMessage {
  statusText: string;
  status: 'pending';
  triesBeforeWarning: 0;
  retries: 1;
  id: number;
}

export interface QueuedMessageOptions {
  /** Delay before beginning first attempt -- useful for debounce */
  delayS: number;
  /** Longest delay in seconds allowed between attempts */
  maxDelayS: number;
  /** Greatest number of retries allowed */
  maxRetries: number;
  /**  gerund, e.g. "saving data", which becomes "error saving data" or "finished saving data" */
  actionText: string;
  /**  if true, the 'retry' option is not presented on failure. */
  noRetry: boolean;
  /**  test function on error message -- if true, rejects w/out retrying */
  rejectWithoutRetryIf: (error: any) => boolean;
  /**  number of tries before this should be included in warnings list */
  triesBeforeWarning: number;
}

/**
 * A message queued in the ApiQueue
 */
class QueuedMessage<T> {
  @observable status: 'pending' | 'resolved' | 'rejected' = 'pending';

  @action setStatus(status: 'pending' | 'resolved' | 'rejected') {
    this.status = status;
  }

  @observable statusText?: string;

  @action setStatusText(statusText: string) {
    this.statusText = statusText;
  }

  public promise!: Promise<T>;

  private delayS = 0.5; // s

  @observable public retries = 0;

  public noRetry: boolean = false;

  private maxDelayS = 120; // s

  private maxRetries = 10;

  private queueStartTime = new Date().valueOf();

  private actionText = 'saving data';

  private upperCaseActionText: string;

  public triesBeforeWarning = 2;

  public canceled = false;

  private rejectWithoutRetryIf?: (error: any) => boolean;

  private latestError: any;

  public id = messageId++;

  private secondsToRetry?: number;

  private statusIntervalIdentifier?: number | NodeJS.Timeout;

  private statusTextCountdown?: number | NodeJS.Timeout;

  private resolve!: (value: T) => void;

  private reject!: (reason?: any) => void;

  // Set by ApiQueue on creation, and used as an index for QueuedMessages in the queue.
  public apiQueuePromise?: Promise<T>;

  private initialOptions: Partial<QueuedMessageOptions> = {};

  /**
   * Class to queue requests and retry them
   */
  constructor(
    private callback: () => Promise<T>,
    options: Partial<QueuedMessageOptions> = {},
    private apiQueue?: ApiQueue
  ) {
    this.initialOptions = options;
    makeObservable(this);
    this.setOptions(options);
    this.makePromise();
    this.upperCaseActionText = this.actionText.slice(0, 1).toUpperCase() + this.actionText.slice(1);
    this.startQueue();
  }

  private makePromise() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  private setOptions(options: Partial<QueuedMessageOptions>) {
    /** Delay before beginning first attempt -- useful for debounce */
    if (options.delayS !== undefined) this.delayS = options.delayS;
    /** Longest delay in seconds allowed between attempts */
    if (options.maxDelayS !== undefined) this.maxDelayS = options.maxDelayS;
    /** Greatest number of retries allowed */
    if (options.maxRetries !== undefined) this.maxRetries = options.maxRetries;
    /**  gerund, e.g. "saving data", which becomes "error saving data" or "finished saving data" */
    if (options.actionText !== undefined) this.actionText = options.actionText;
    /**  if true, the 'retry' option is not presented on failure. */
    if (options.noRetry !== undefined) this.noRetry = options.noRetry;
    /**  test function on error message -- if true, rejects w/out retrying */
    if (options.rejectWithoutRetryIf !== undefined)
      this.rejectWithoutRetryIf = options.rejectWithoutRetryIf;
    /**  number of tries before this should be included in warnings list */
    if (options.triesBeforeWarning !== undefined)
      this.triesBeforeWarning = options.triesBeforeWarning;
    if (this.triesBeforeWarning >= this.maxRetries) {
      this.triesBeforeWarning = this.maxRetries - 1;
    }
  }

  startStatusTextCountdown() {
    this.statusTextCountdown = setInterval(() => {
      const delayMS = this.delayS * 1000;
      const nextRetry = this.queueStartTime + delayMS;
      const timeToRetry = nextRetry - new Date().valueOf();
      const secondsToRetry = ~~(timeToRetry / 1000) + 1;
      if (this.secondsToRetry === secondsToRetry) return;
      this.secondsToRetry = secondsToRetry;
      const statusText =
        timeToRetry >= 0
          ? `Warning: error ${this.actionText}. Retrying in ${secondsToRetry} second(s)`
          : `${this.upperCaseActionText} now...`;
      this.setStatusText(statusText);
    }, 200);
  }

  /**
   * Cancel status updater
   */
  private cancelStatusUpdater() {
    if (this.statusIntervalIdentifier !== undefined) {
      clearInterval(this.statusIntervalIdentifier as any);
      this.statusIntervalIdentifier = undefined;
    }
    if (this.statusTextCountdown !== undefined) {
      clearInterval(this.statusTextCountdown as any);
      this.statusTextCountdown = undefined;
    }
  }

  startQueue() {
    if (this.retries >= this.maxRetries) {
      this.reject(this.latestError);
      this.cancelStatusUpdater();
      this.setStatusText(`Error: ${this.actionText} failed.`);
      this.setStatus('rejected');
      return;
    }
    this.startStatusTextCountdown();
    this.retries++;
    const delay = this.delayS;
    this.statusIntervalIdentifier = setTimeout(() => {
      if (this.canceled) return;
      this.cancelStatusUpdater();
      this.setStatusText(`${this.upperCaseActionText} now (attempt #${this.retries + 1})`);
      this.callback()
        .then((result) => {
          if (this.canceled) return;
          this.setStatus('resolved');
          this.setStatusText(`Success: finished ${this.actionText}`);
          this.resolve(result);
        })
        .catch((err) => {
          if (this.canceled) return;
          if (this.rejectWithoutRetryIf && this.rejectWithoutRetryIf(err)) {
            this.setStatus('resolved');
            const index = queuedMessages.indexOf(this);
            runInAction(() => {
              if (index !== -1) queuedMessages.splice(index, 1);
            });
            this.reject(err);
            return;
          }
          this.queueStartTime = new Date().valueOf();
          this.delayS = Math.min(this.maxDelayS, this.delayS * 2);
          this.latestError = err;
          this.startQueue();
        });
    }, delay * 1000);
  }

  /**
   * Cancel the entire request -- abort any future attempts
   */
  cancel() {
    this.canceled = true;
  }

  /**
   * Force a retry of the message after failure.
   */
  retry() {
    if (this.noRetry) return;
    if (this.retries < this.maxRetries) return;
    if (this.apiQueue) {
      this.apiQueue.remove(this);
      this.apiQueue.queue(this.callback, { ...this.initialOptions, triesBeforeWarning: 0 });
      return;
    }
    this.setStatus('pending');
    this.makePromise();
    this.canceled = false;
    this.retries = 0;
    this.secondsToRetry = 0;
    this.startQueue();
  }
}

/**
 * Queue of requests to the api, with built in retrying and
 * notifications
 */
export default class ApiQueue {
  constructor() {
    makeObservable(this);
    this.initializeOfflineListener();
  }

  initializeOfflineListener() {
    if (typeof window === 'undefined') return;
    const offlineError: ApiErrorMessage = {
      statusText:
        'You are offline. Your work will not be saved until you return online. Some features of uTheory may not function.',
      status: 'pending',
      triesBeforeWarning: 0,
      retries: 1,
      id: messageId++,
    };

    window.addEventListener(
      'offline',
      () => {
        console.error('we are offline!');
        const index = queuedMessages.indexOf(offlineError);
        // console.log('index', index);
        if (index !== -1) return;
        runInAction(() => {
          queuedMessages.unshift(offlineError);
        });
      },
      false
    );

    window.addEventListener(
      'online',
      () => {
        console.warn('we are online!');
        const index = queuedMessages.indexOf(offlineError);
        if (index === -1) return;
        runInAction(() => {
          queuedMessages.splice(index, 1);
        });
      },
      false
    );
  }

  /**
   * Queue a request to the api
   */
  queue<T>(callback: () => Promise<T>, options: Partial<QueuedMessageOptions>): Promise<T> {
    const queuedRequest = new QueuedMessage(callback, options, this);
    runInAction(() => {
      queuedMessages.push(queuedRequest);
    });
    queuedRequest.apiQueuePromise = queuedRequest.promise
      .then((data) => {
        // When it resolves, give 5 seconds in case it needs to display a success message
        // then remove it from the queue:
        setTimeout(() => {
          runInAction(() => {
            queuedMessages.splice(queuedMessages.indexOf(queuedRequest), 1);
          });
        }, 5000);
        // Resolve the data.
        return data;
      })
      .catch((err) => Promise.reject(err));
    return queuedRequest.apiQueuePromise;
  }

  remove(queuedMessage: QueuedMessage<any>) {
    runInAction(() => {
      const index = queuedMessages.indexOf(queuedMessage);
      if (index !== -1) {
        queuedMessages.splice(index, 1);
      }
    });
  }

  cancel<T>(apiQueuePromise: Promise<T>): boolean {
    // console.log('cancelling...', apiQueuePromise);
    // console.log(queue.concat([]));
    const index = queuedMessages.findIndex(
      (candidateQueuedRequest) =>
        'apiQueuePromise' in candidateQueuedRequest &&
        candidateQueuedRequest.apiQueuePromise === apiQueuePromise
    );
    if (index === -1) return false;
    const queuedRequest = queuedMessages[index];
    runInAction(() => {
      queuedMessages.splice(index, 1);
    });
    if (queuedRequest && 'canceled' in queuedRequest) queuedRequest.canceled = true;
    return true;
  }

  getAllMessages() {
    return queuedMessages;
  }

  @computed get messages() {
    const messages = queuedMessages
      .filter((queuedMessage) => queuedMessage.retries > queuedMessage.triesBeforeWarning)
      .map((queuedMessage) => {
        const message = {
          text: queuedMessage.statusText,
          status: queuedMessage.status,
          id: queuedMessage.id,
        };

        if (queuedMessage.status !== 'rejected') return message;

        return {
          ...message,
          retry: () => queuedMessage.retry(),
          cancel() {
            queuedMessages.splice(queuedMessages.indexOf(queuedMessage), 1);
          },
        };
      });
    return messages;
  }
}
