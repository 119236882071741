import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Melodic Minor Scales
 */
export const lesson: Lesson = {
  id: 'melodic_minor_scales',
  areaId: 'pitch_and_harmony',
  order: 22,
  title: 'Melodic Minor Scales',
  skills: ['melodic_minor_piano', 'melodic_minor'],
  pages: [
    {
      id: 'melodic_minor_scales_intro',
      order: 1,
      title: 'Introduction',
      content:
        "<h3>Melodic Minor Scales</h3>\n<p>In this lesson we'll learn:\n<ul>\n<li>The interval pattern of ascending and descending melodic \nminor scales and</li>\n<li>How to write melodic minor scales.</li></ul>\n</p>\n<p>Click next to get started with the lesson!</p>\n",
    },
    /**
     * Video: About Melodic Minor Scales
     *
     * Melodic minor scales are the only scales that are different going up and
     * coming down.
     *
     * Take a look at the ascending C melodic minor scale: the first half is
     * like minor, and the top half is like major. This lets us have a half step
     * from 7-1 without the odd-sounding Augmented 2nd from 6-7. So, we have
     * half steps from 2-3 and 7-1. This gives a really strong pull up to tonic.
     *
     * Let's get some practice with the pattern of half and whole steps in the
     * ascending melodic minor scale:
     */
    {
      id: 'melodic_minor_scales_video_1',
      order: 2,
      title: 'Ascending Melodic Minor',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'melodicMinorScales1',
      },
    },
    /**
     * Exercise: What's the interval between ___ and ___ in a melodic minor scale?
     */
    {
      title: 'Asc. Melodic HS/WS Pattern',
      order: 3,
      id: 'melodic_minor_scales_ascending_hs_ws_pattern',
      interactive: {
        type: 'ScalePatterns',
        questionPrompt: 'What is the interval between the {question} notes of {question} scale?',
        hideAugSecond: false,
        skills: ['melodic_minor_ascending'],
        ordinalNumbers: true,
      },
    },
    /**
     * Video: Let's play and spell some ascending melodic minor scales.
     */
    {
      id: 'melodic_minor_scales_video_2',
      title: 'Spelling Ascending Melodic Minor',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'melodicMinorScales2',
      },
    },
    /**
     * Exercise: Play & Spell Asc Melodic Minor Scales::
     */
    {
      id: 'melodic_minor_scales_play_and_spell_ascending',
      title: 'Play & Spell Ascending Melodic Minor',
      order: 5,
      interactive: {
        type: 'ScalePlayAndSpell',
        scaleDegreeHints: true,
        questions: ['Eb/melodic', 'B/melodic', 'G/melodic', 'F#/melodic'],
        autoadvance: true,
      },
    },
    /**
     * Video: Write Melodic Minor Scales
     */
    {
      id: 'melodic_minor_scales_writing_ascending',
      title: 'Write: Melodic Minor Scales',
      order: 5,
      interactive: {
        type: 'ScaleWrite',
        questionPrompt: 'Write {question} scale.',
        questions: ['E/melodic/asc', 'Bb/melodic/asc', 'F#/melodic/asc'],
      },
    },
    /**
     * Descending Melodic Minor Scales:
     *
     * When we come down a melodic minor scale, we no longer want the strong
     * pull back up to tonic that having the raised 6 and 7 give us.
     *
     * So, instead, we simply write a natural minor scale descending.
     *
     * The descending natural minor & descending melodic minor scales are
     * identical.
     *
     *
     * Let's get some practice with the pattern of half and whole steps in the
     * descending melodic minor scale. Remember: it's the same as natural minor.
     */
    {
      id: 'melodic_minor_scales_video_3',
      order: 6,
      title: 'Descending Melodic Minor',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'melodicMinorScales3',
      },
    },
    /**
     * Exercise: What's the interval between ___ and ___ in a melodic minor scale?
     */
    {
      title: 'Dsc. Melodic HS/WS Pattern',
      order: 7,
      id: 'melodic_minor_scales_descending_hs_ws_pattern',
      interactive: {
        type: 'ScalePatterns',
        hideAugSecond: false,
        skills: ['melodic_minor_descending'],
        ordinalNumbers: false,
        useSolfegeSystem: false,
      },
    },
    /**
     * Video: Let's play and spell some descending melodic minor scales.
     */
    {
      id: 'melodic_minor_scales_video_4',
      title: 'Spelling Descending Melodic Minor',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'melodicMinorScales4',
      },
    },
    /**
     * Exercise: Play & Spell Descending Melodic Minor Scales
     */
    {
      id: 'melodic_minor_scales_play_and_spell_descending',
      title: 'Play & Spell Descending Melodic Minor',
      order: 9,
      interactive: {
        type: 'ScalePlayAndSpell',
        scaleDegreeHints: true,
        questions: ['C#/melodic/dsc', 'B/melodic/dsc', 'F/melodic/dsc', 'Ab/melodic/dsc'],
        autoadvance: true,
      },
    },
    /**
     * Exercise: Write all minor scales
     */
    {
      id: 'melodic_minor_scales_writing_all_minor_scales',
      title: 'Write: All Minor Scales',
      order: 10,
      interactive: {
        type: 'ScaleWrite',
        questionPrompt: 'Write {question} scale.',
        questions: [
          'D/melodic',
          'C/harmonic/dsc',
          'G#/melodic/asc',
          'A#2/melodic/dsc',
          'F/minor',
          'B4/melodic',
          'A/harmonic',
        ],
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'melodic_minor_scales_video_5',
      title: 'Conclusion',
      order: 11,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'melodicMinorScales5',
      },
    },
  ],
};
