import { isObject } from './isObject';

interface JSONDate {
  /** As ISO Date String */
  value: string;
  type: 'uTheoryJSONDate';
  format: 'ISO';
}

function isJSONDate(jsonDate: any): jsonDate is JSONDate {
  if (!isObject(jsonDate)) return false;
  if ((jsonDate as JSONDate).type !== 'uTheoryJSONDate') return false;
  if ((jsonDate as JSONDate).format !== 'ISO') return false;
  if (!(jsonDate as JSONDate).value) return false;
  const date = new Date((jsonDate as JSONDate).value);
  if (Number.isNaN(date.valueOf())) return false;
  return true;
}

export function convertFromDatesToJSONDates<T extends any>(value: T): T {
  return JSONParse(JSONStringify(value));
}

export function convertToDatesFromJSONDates<T extends any>(value: T): T {
  return JSONParse(JSON.stringify(value));
}

/**
 * A replacement for Javascript's built in JSON.stringify function. It preserves
 * Date types when stringifying. And the uTheory JSONParse function returns them
 * to Date types when parsing.
 *
 * Usage:
 * ```typescript
 *   const json = JSONStringify({
 *     expiry: new Date('2023-12-03')
 *   });
 *
 *   const obj = JSONParse(json);
 *
 *   obj.expiry instanceof Date; // true
 * ```
 */
export function JSONStringify(value: any): string {
  return JSON.stringify(value, JSONReplacer);
}

/**
 * A replacement for Javascript's built in JSON.parse function. It restores Date
 * types as Date when the JSON was stringified using the uTheory JSONStringify function.
 *
 * Usage:
 * ```typescript
 *   const json = JSONStringify({
 *     expiry: new Date('2023-12-03')
 *   });
 *
 *   const obj = JSONParse(json);
 *
 *   obj.expiry instanceof Date; // true
 * ```
 */
export function JSONParse<T>(json: string): T {
  return JSON.parse(json, JSONReviver);
}

/**
 * A replacer function that can be passed to the second argument of
 * JSON.stringify to preserve Date tyeps as JSONDate format in the stringified
 * JSON. Use the matching JSONReviver as the second argument to JSON.parse to
 * restore these to Date objects.
 */
export function JSONReplacer(this: any, key: string, stringValue: string): any {
  const value = this[key];
  // If it's a date, we replace it:
  if (value instanceof Date) {
    return {
      value: value.toISOString(),
      type: 'uTheoryJSONDate',
      format: 'ISO',
    };
  }
  return stringValue;
}

/**
 * A reviver function that can be passed to the second argument of
 * JSON.parse to restore Date types saved in JSONDate format in the stringified
 * JSON. Use the matching JSONReplacer as the second argument to JSON.parse to
 * restore these to Date objects.
 */
export function JSONReviver(key: string | number, value: any): any {
  if (isJSONDate(value)) {
    return new Date(value.value);
  }
  return value;
}
