import { action, computed, observable, toJS, makeObservable } from 'mobx';
import { sortByFactory } from '../../utils/sortByFactory';
import type {
  SectionSkillSettings,
  SectionRecord,
  AreaName,
} from '../../../definitions/section-definitions';
import type SectionStore from '../section.store';
import SectionKnowledgeTopicStore from './section.knowledge.topic.store';
import SectionKnowledgeAreaStore from './section.knowledge.area.store';
import SectionKnowledgeSkillStore from './section.knowledge.skill.store';
import type { GroupedCollection } from '../../utils/groupByReducers';
import { groupByAsDictionaryReducer } from '../../utils/groupByReducers';
import type {
  KnowledgeGraphEdge,
  KnowledgeGraphNode,
} from '../../knowledge/knowledgeGraphDefinitions';

export default class SectionKnowledgeStore {
  @observable.shallow areas!: SectionKnowledgeAreaStore[];

  @observable rhythm!: SectionKnowledgeAreaStore;

  @observable pitch_and_harmony!: SectionKnowledgeAreaStore;

  @observable ear_training!: SectionKnowledgeAreaStore;

  @observable.shallow topics!: SectionKnowledgeTopicStore[];

  @observable.shallow skills!: SectionKnowledgeSkillStore[];

  @observable knowledgeGraphNodes!: KnowledgeGraphNode[];

  @observable knowledgeGraphEdges!: KnowledgeGraphEdge[];

  @observable enabledAreaIds!: AreaName[];

  skillsSettingsById!: GroupedCollection<SectionSkillSettings>;

  root!: SectionStore;

  /** Add node definitions, edge definitions as optional. Only construted in section.store */
  constructor({
    skillsSettings,
    skillAreas,
    root,
    nodes,
    edges,
  }: {
    skillsSettings: SectionSkillSettings[];
    skillAreas: SectionRecord['skillAreas'];
    root: SectionStore;
    nodes?: KnowledgeGraphNode[];
    edges?: KnowledgeGraphEdge[];
  }) {
    makeObservable(this);
    this.root = root;
    this.init({ skillsSettings, skillAreas, nodes, edges });
  }

  @action
  init({
    skillsSettings,
    skillAreas,
    nodes,
    edges,
  }: {
    skillsSettings: SectionSkillSettings[];
    skillAreas: SectionRecord['skillAreas'];
    nodes?: KnowledgeGraphNode[];
    edges?: KnowledgeGraphEdge[];
  }) {
    const skillsSettingsById = skillsSettings.reduce(
      groupByAsDictionaryReducer('id'),
      {} as GroupedCollection<SectionSkillSettings>
    );
    this.skillsSettingsById = skillsSettingsById;
    this.enabledAreaIds = skillAreas;
    this.knowledgeGraphNodes = [];
    this.knowledgeGraphEdges = [];

    if (!nodes) nodes = [];
    if (!edges) edges = [];

    const skills = nodes?.filter((n) => n.type === 'skill');
    const topics = nodes?.filter((n) => n.type === 'topic');
    const areas = nodes?.filter((n) => n.type === 'area');

    this.skills = skills.map(
      (skillDefinition) =>
        this.addOrUpdateKnowledgeGraphNodeDefinition(skillDefinition) as SectionKnowledgeSkillStore
    );
    this.topics = topics.map(
      (topicDefinition) =>
        this.addOrUpdateKnowledgeGraphNodeDefinition(topicDefinition) as SectionKnowledgeTopicStore
    );
    this.areas = areas
      .map(
        (areaDefinition) =>
          this.addOrUpdateKnowledgeGraphNodeDefinition(areaDefinition) as SectionKnowledgeAreaStore
      )
      .sort(sortByFactory('textId'))
      .reverse();

    edges.forEach((e) => this.addKnowledgeGraphEdgeDefinition(e));

    this.rhythm = this.areas.find((area) => area.textId === 'rhythm') as SectionKnowledgeAreaStore;
    this.ear_training = this.areas.find(
      (area) => area.textId === 'ear_training'
    ) as SectionKnowledgeAreaStore;
    this.pitch_and_harmony = this.areas.find(
      (area) => area.textId === 'pitch_and_harmony'
    ) as SectionKnowledgeAreaStore;
  }

  /** Returns true if the edge did not already exist. */
  @action addKnowledgeGraphEdgeDefinition(definition: KnowledgeGraphEdge): boolean {
    // if (this.knowledgeGraphEdges.some((e) => isEqual(e, definition))) {
    //   return false;
    // }
    if (
      this.knowledgeGraphEdges.some(
        (e) => e.parentId === definition.parentId && e.childId === definition.childId
      )
    ) {
      return false;
    }

    this.knowledgeGraphEdges.push(definition);
    return true;
  }

  @action private addKnowledgeGraphNodeDefinition(
    definition: KnowledgeGraphNode
  ): SectionKnowledgeSkillStore | SectionKnowledgeTopicStore | SectionKnowledgeAreaStore {
    this.knowledgeGraphNodes.push(definition);

    switch (definition.type) {
      case 'skill': {
        return new SectionKnowledgeSkillStore(definition, this.root);
      }
      case 'topic': {
        return new SectionKnowledgeTopicStore(definition, this.root);
      }
      default:
      case 'area': {
        return new SectionKnowledgeAreaStore(definition, this.root);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  @action private updateKnowledgeGraphNodeDefinition(
    definition: KnowledgeGraphNode,
    existingNode:
      | SectionKnowledgeSkillStore
      | SectionKnowledgeTopicStore
      | SectionKnowledgeAreaStore
  ): SectionKnowledgeSkillStore | SectionKnowledgeTopicStore | SectionKnowledgeAreaStore {
    switch (definition.type) {
      case 'skill': {
        const skill = existingNode as SectionKnowledgeSkillStore;
        skill.definition = definition;
        return skill;
      }
      case 'topic': {
        const topic = existingNode as SectionKnowledgeTopicStore;
        topic.definition = definition;
        return topic;
      }
      default:
      case 'area': {
        const area = existingNode as SectionKnowledgeAreaStore;
        area.definition = definition;
        return area;
      }
    }
  }

  /**
   * Add or update a node definition in the SectionKnowledgeStore and create a new KnowledgeGraphNode store
   * if one doesn't already exist.
   * @param definition - Node definition.
   * @returns A new or existing KnowledgeGraphNode store.
   */
  @action addOrUpdateKnowledgeGraphNodeDefinition(
    definition: KnowledgeGraphNode
  ): SectionKnowledgeSkillStore | SectionKnowledgeTopicStore | SectionKnowledgeAreaStore {
    let nodeExists:
      | SectionKnowledgeSkillStore
      | SectionKnowledgeTopicStore
      | SectionKnowledgeAreaStore
      | undefined;
    if (definition.type === 'skill') {
      nodeExists = this.skills?.find((s) => s.textId === definition.textId);
    } else if (definition.type === 'topic') {
      nodeExists = this.topics?.find((t) => t.textId === definition.textId);
    } else {
      nodeExists = this.areas?.find((a) => a.textId === definition.textId);
    }
    if (nodeExists) {
      return this.updateKnowledgeGraphNodeDefinition(definition, nodeExists);
    }
    return this.addKnowledgeGraphNodeDefinition(definition);
  }

  @action removeKnowledgeGraphNodeDefinition(textId: string): boolean {
    if (!this.knowledgeGraphNodes.some((node: KnowledgeGraphNode) => node.textId === textId)) {
      return false;
    }
    this.knowledgeGraphNodes = this.knowledgeGraphNodes.filter(
      (node: KnowledgeGraphNode) => node.textId !== textId
    );
    return true;
  }

  @action removeKnowledgeGraphEdgeDefinition(parentId: string, childId: string): boolean {
    if (
      !this.knowledgeGraphEdges.find(
        (e: KnowledgeGraphEdge) => e.parentId === parentId && e.childId === childId
      )
    ) {
      return false;
    }
    this.knowledgeGraphEdges = this.knowledgeGraphEdges.filter(
      (e: KnowledgeGraphEdge) => e.parentId !== parentId || e.childId !== childId
    );
    return true;
  }

  @action dehydrate(): SectionSkillSettings[] {
    return this.skills.map((skill) => ({
      id: skill.textId,
      status: toJS(skill.status),
      date: null,
    }));
  }

  @action update(skillsSettings: SectionSkillSettings[]) {
    skillsSettings.forEach((skillDefinition) => {
      this.updateSkill(skillDefinition.id, skillDefinition.status);
    });
  }

  @computed get topicsById(): { [id: string]: SectionKnowledgeTopicStore } {
    return this.topics.reduce((dictionary: { [id: string]: SectionKnowledgeTopicStore }, topic) => {
      dictionary[topic.id] = topic;
      dictionary[topic.textId] = topic;
      return dictionary;
    }, {});
  }

  @computed get skillsById(): { [id: string]: SectionKnowledgeSkillStore } {
    return this.skills.reduce((dictionary: { [id: string]: SectionKnowledgeSkillStore }, skill) => {
      dictionary[skill.id] = skill;
      dictionary[skill.textId] = skill;
      return dictionary;
    }, {});
  }

  @action updateSkill(id: string | number, status: 'required' | 'optional' | 'hidden') {
    const skill = this.skillsById[id];
    if (!skill) throw Error(`Skill id ${id} not found in section.knowledge.store. Cannot update.`);
    skill.setStatus(status);
  }
}

export function getParentNodes<
  NodeType extends { textId: string },
  EdgeType extends { childId: string; parentId: string }
>(node: NodeType, nodes: NodeType[], edges: EdgeType[]) {
  const isEdgeToMe = (e: EdgeType): boolean => e.childId === node.textId;
  const parentIds = edges.filter(isEdgeToMe).map((e) => e.parentId);
  return nodes.filter((n) => parentIds.includes(n.textId));
}

export function getChildNodes<
  NodeType extends { textId: string },
  EdgeType extends { childId: string; parentId: string }
>(node: NodeType, nodes: NodeType[], edges: EdgeType[]) {
  const isEdgeFromMe = (e: EdgeType): boolean => e.parentId === node.textId;
  const childIds = edges.filter(isEdgeFromMe).map((e) => e.childId);
  return nodes.filter((n) => childIds.includes(n.textId));
}
