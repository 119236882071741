import { computed, makeObservable } from 'mobx';
import type { SectionLessonOrCheckpointStore } from '../../section/sectionLessons/section.lessonOrCheckpoint.store';
import { isCheckpoint } from '../../../definitions/checkpoint-definitions';
import type { UserLessonAreaStore } from './user.lesson.area.store';
import type { UserLessonStore } from './user.lesson.store';
import type { UserCheckpointStore } from './user.checkpoint.store';
import type { AbstractUserStore } from '../abstract.user.store';
import { getGradePenalty } from './user.utils';

export type UserLessonOrCheckpointStore = UserLessonStore | UserCheckpointStore;

export abstract class UserContentStore {
  id!: string;

  type!: 'lesson' | 'checkpoint';

  area!: UserLessonAreaStore;

  root!: AbstractUserStore;

  abstract score: number;

  abstract progress: number;

  constructor() {
    makeObservable(this);
  }

  @computed get index(): number {
    return this.area.lessons.findIndex((lesson) => lesson.id === this.id);
  }

  @computed get isVisible(): boolean {
    return this.model.isVisible;
  }

  @computed get isDueDateVisible(): boolean {
    return this.model.isDueDateVisible;
  }

  @computed get isDue(): boolean {
    return this.model.isDue;
  }

  @computed get dueDate(): Date | undefined {
    return this.model.dueDate;
  }

  @computed get includeInGradeToDate(): boolean {
    return this.model.includeInGradeToDate;
  }

  @computed get isRequired(): boolean {
    return this.model.isRequired;
  }

  @computed get isOptional(): boolean {
    return this.model.isOptional;
  }

  @computed get isAvailable(): boolean {
    return this.isVisible && !this.mustCompletePriorLesson && !this.mustCompletePriorCheckpoint;
  }

  @computed get model(): SectionLessonOrCheckpointStore {
    const model = this.root.currentSection.lessons.lessonsById[this.id];
    if (!model)
      throw Error(
        `Cannot find section lesson/checkpoint model from id ${this.id} @UserLessonOrCheckpointStore`
      );
    return model;
  }

  abstract isComplete: boolean;

  abstract isInProgress: boolean;

  abstract isLate: boolean;

  abstract dateCompleted: Date | undefined;

  abstract gradePenalty: number;

  abstract scoreWithoutGradePenalty: number;

  @computed get futureGradePenalty(): number {
    if (!this.isDueDateVisible) return 0;
    if (!this.dueDate) return 0;
    return getGradePenalty(
      this.dueDate,
      this.root.currentSection.now,
      this.root.currentSection.options.deadlines.penalty
    );
  }

  @computed get priorLesson(): UserLessonOrCheckpointStore | undefined {
    return this.area.getPriorLesson(this.id);
  }

  @computed get nextLesson(): UserLessonOrCheckpointStore | undefined {
    return this.area.getNextLesson(this.id);
  }

  /**
   * Gets the next available lesson that's incomplete, or, on failing to find
   * any that are incomplete, returns the next available lesson instead.
   */
  @computed get nextAvailableIncompleteLessonOrNextLesson() {
    return (
      this.nextAvailableIncompleteLesson ||
      (this.nextLesson?.isAvailable ? this.nextLesson : undefined)
    );
  }

  /**
   * Gets the next available incomplete lesson or checkpoint -- but you probably
   * don't want this, if all of them have been completed, you'll want
   * nextAvailableIncompleteLessonorNextLesson instead!
   */
  @computed get nextAvailableIncompleteLesson(): UserLessonOrCheckpointStore | undefined {
    const requiredIndex = this.area.requiredLessons.findIndex((lesson) => lesson.id === this.id);

    return (
      // try finding the next required lesson that's not complete:
      this.area.requiredLessons.find(
        (lesson, index) => index > requiredIndex && !lesson.isComplete && lesson.isAvailable
      ) ||
      // then try for the next optional lesson that's not complete:
      this.area.lessons.find(
        (lesson, index) => index > this.index && !lesson.isComplete && lesson.isAvailable
      ) ||
      // then try for any required lesson that's not complete:
      this.area.requiredLessons.find(
        (lesson, index) => !lesson.isComplete && lesson.isAvailable && index !== requiredIndex
      ) ||
      // then try for any optional lesson that's not complete:
      this.area.lessons.find(
        (lesson, index) => !lesson.isComplete && lesson.isAvailable && index !== this.index
      ) ||
      // finally, abandon all hope
      undefined
    );
  }

  @computed get title(): string {
    if (isCheckpoint(this.model.definition)) {
      return this.model.definition.options.name || this.model.definition.title || 'Checkpoint';
    }

    const { solfegeMethod, rhythmMethod } = this.root.preferences;

    if (typeof this.model.definition.title === 'object') {
      if (this.model.definition.title[solfegeMethod]) {
        return this.model.definition.title[solfegeMethod] as string;
      }
      if (this.model.definition.title[rhythmMethod]) {
        return this.model.definition.title[rhythmMethod] as string;
      }
    } else if ('title' in this.model.definition) {
      return this.model.definition.title as string;
    }
    return 'Lesson';
  }

  @computed get canAccessNextLesson(): boolean {
    if (this.isComplete) return true;
    const nextLesson = this.nextLesson;
    if (!nextLesson) return true;
    return nextLesson.mustCompletePriorLesson || nextLesson.mustCompletePriorCheckpoint;
  }

  @computed get mustCompletePriorLesson(): boolean {
    if (!this.root.currentSection.options.lessons.mustCompletePriorLesson) return false;
    const incompleteIndex = this.area.requiredLessons.findIndex((lesson) => !lesson.isComplete);
    if (incompleteIndex === -1) return false;
    const thisIndex = this.area.requiredLessons.findIndex((lesson) => lesson.id === this.id);
    if (thisIndex === -1) return false;
    return thisIndex > incompleteIndex;
  }

  @computed get mustCompletePriorCheckpoint(): boolean {
    if (!this.root.currentSection.options.lessons.mustPassPriorCheckpoint) return false;

    const incompleteIndex = this.area.requiredLessons.findIndex(
      (lesson) => lesson.type === 'checkpoint' && !lesson.isComplete
    );
    if (incompleteIndex === -1) return false;
    const thisIndex = this.area.requiredLessons.findIndex((lesson) => lesson.id === this.id);
    return thisIndex > incompleteIndex;
  }
}
