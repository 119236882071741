import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Flat Major Key Signatures
 */
export const lesson: Lesson = {
  id: 'flat_major_keys',
  areaId: 'pitch_and_harmony',
  order: 18,
  title: 'Flat Major Keys',
  skills: ['flat_majors', 'treble_order_flats', 'bass_order_flats'],
  pages: [
    {
      id: 'flat_major_keys_intro',
      order: 1,
      title: 'Introduction',
      content:
        "<h3>Flat Major Keys</h3>\n<p>In this lesson we'll learn:\n<ul>\n<li>How to recognize major keys with flats</li>\n<li>The order of flats, and</li>\n<li>How to write major keys with flats.</li></ul>\n</p>\n<p>Click next to get started with the lesson!</p>\n",
    },
    /**
     * Video: What is a key sig?
     */
    {
      id: 'flat_major_keys_video_1',
      order: 2,
      title: 'Rule for Flat Majors',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'flatMajorKeys1',
      },
    },
    /**
     * Interactive: Name this key
     */
    {
      id: 'flat_major_keys_id',
      title: 'Flat Keys ID',
      order: 3,
      interactive: {
        type: 'KeySigID',
        questions: [
          'Gb/M/treble',
          'F/M/bass',
          'Ab/M/bass',
          'Bb/M/treble',
          'Db/M/treble',
          'Cb/M/bass',
          'Eb/M/bass',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video: Order of flats
     */
    {
      id: 'flat_major_keys',
      order: 4,
      title: 'Order of Flats',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'flatMajorKeys2',
      },
    },
    /**
     * Exercise: Type the order of flats
     */
    {
      id: 'flat_major_keys_order_flats',
      order: 5,
      title: 'Order of Flats',
      interactive: {
        type: 'OrderOfSharpsOrFlats',
        staff: false,
        questions: ['flats', 'flats', 'flats', 'flats', 'flats'],
      },
    },
    /**
     * Video 3: Writing order of flats on staff
     */
    {
      id: 'flat_major_keys_video_3',
      title: 'Order of Flats on the Staff',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'flatMajorKeys3',
      },
    },
    /**
     * Exercise: Write the order of flats on the staff
     */
    {
      id: 'flat_major_keys_order_on_staff',
      title: 'Draw the Order of Flats',
      order: 7,
      interactive: {
        type: 'OrderOfSharpsOrFlats',
        staff: true,
        questions: [
          'flats/treble',
          'flats/bass',
          'sharps/treble',
          'flats/treble',
          'sharps/bass',
          'flats/bass',
        ],
      },
    },
    /**
     * Video: How many flats are in...? Name the last flat in...
     */
    {
      id: 'flat_major_keys_video_4',
      title: 'Figuring out Last Flat',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'flatMajorKeys4',
      },
    },
    /**
     * Exercise: How many flats are in...?
     */
    {
      id: 'flat_major_keys_how_many_flats',
      title: 'How many flats?',
      order: 9,
      interactive: {
        type: 'NumberOfSharpsOrFlats',
        questions: ['Ab/M', 'Db/M', 'C/M', 'Bb/M', 'F/M'],
      },
    },
    /**
     * Exercise: Name the last flat in...?
     */
    {
      id: 'flat_major_keys_find_last_flat',
      title: 'Name the Last Flat',
      order: 10,
      interactive: {
        type: 'LastSharpOrFlatInKey',
        questions: ['Bb/M', 'Cb/M', 'Gb/M', 'Eb/M'],
      },
    },
    /**
     * Video: Writing Flat Keys
     */
    {
      id: 'flat_major_keys_video_5',
      title: 'Writing Flat Keys',
      order: 11,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'flatMajorKeys5',
      },
    },
    /**
     * Exercise: Write flat keys
     */
    {
      id: 'flat_major_keys_writing',
      title: 'Write Flat Keys',
      order: 12,
      interactive: {
        type: 'KeySigConstruction',
        questions: [
          'Ab/M/bass',
          'C/M/treble',
          'Bb/M/bass',
          'F/M/bass',
          'Gb/M/treble',
          'Eb/M/treble',
        ],
      },
    },
    /**
     * Video: Reviewing all major keys
     */
    {
      id: 'flat_major_keys_video_7',
      title: 'Identifying all Major Keys',
      order: 13,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'flatMajorKeys7',
      },
    },
    /**
     * Exercise: Identify all major keys
     */
    {
      id: 'flat_major_keys_ID',
      title: 'Name the Key',
      order: 14,
      interactive: {
        type: 'KeySigID',
        questions: [
          'D/M',
          'C/M',
          'Eb/M',
          'A/M',
          'F/M',
          'Ab/M',
          'F#/M',
          'Db/M',
          'C#/M',
          'Gb/M',
          'Cb/M',
          'G/M',
          'Bb/M',
          'E/M',
          'B/M',
        ],
      },
    },
    /**
     * Video: The Circle of Fifths
     */
    {
      id: 'flat_major_keys_video_8',
      title: 'Circle of Fifths',
      order: 15,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'flatMajorKeys8',
      },
    },
  ],
};
