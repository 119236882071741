import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Simple Time Sixteenth Notes
 *
 * See: Sixteenth Note Patterns in Simple Time.md for lesson draft details
 */
export const lesson: Lesson = {
  id: 'simpleTimeSixteenthNotes',
  areaId: 'rhythm',
  order: 8,
  title: 'Sixteenth Notes in Simple Time',
  skills: ['S:ssss', 'S:sse', 'S:ess', 'S:sser', 'S:erss', 'S:ssssr', 'S:srsss'],
  pages: [
    {
      id: 'simpleTimeSixteenthNotes_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Sixteenth Notes in Simple Time</h3>
        <p>In this lesson we'll learn how to count and play:
        <ul>
          <li>Sixteenth notes in simple time,</li>
          <li>Basic patterns using sixteenth notes, and</li>
          <li>Pickup paterns using sixteenths</li>
        </ul>
          in simple meters.
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: Sixteenth notes
     */
    {
      id: 'simpleTimeSixteenthNotes_ssss',
      order: 2,
      title: 'Sixteenth Notes',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'simple-sixteenth-patterns-video-1',
        rhythm: false,
      },
    },
    /**
     * Exercise: Count rhythms w/ ssss in them
     */
    {
      id: 'simpleTimeSixteenthNotes_playSSSS',
      title: 'Count Sixteenths',
      order: 3,
      interactive: {
        type: 'LabelCounts',
        manualQuestions: true,
        questions: [
          '4/4: tempo=60 s s s s s s s s e e q',
          '3/4: tempo=80 er e s s s s e er',
          '4/4: tempo=60 h. s s s s',
        ],
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play rhythms w/ ssss in them
     */
    {
      id: 'simpleTimeSixteenthNotes_playSkips',
      title: 'Play Sixteenths',
      order: 4,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=60 q e e s s s s q | s s s s s s s s s s s s q',
          '3/4: tempo=70 q hr | e e s s s s s s s s | q hr | s s s s h',
          '4/4: tempo=60 h. s s s s | wr | e e qr e e qr | s s s s qr hr | w |',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video: Anapests & Dactyls
     */
    {
      id: 'simpleTimeSixteenthNotes_AnapestDactyl',
      order: 5,
      title: 'Anapests & Dactyls',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'simple-sixteenth-patterns-video-2',
        rhythm: false,
      },
    },
    /**
     * Exercise: Count anapests & dactyls
     */
    {
      id: 'simpleTimeSixteenthNotes_countAnapestDactyl',
      title: 'Count Anapests & Dactyls',
      order: 6,
      interactive: {
        type: 'LabelCounts',
        manualQuestions: true,
        questions: [
          '3/4: s s s s s s e e s s',
          '4/4: tempo=80 e s s qr er e s s e',
          '5/4: tempo=60 e e hr s s e e s s',
        ],
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play anapests & dactyls
     */
    {
      id: 'simpleTimeSixteenthNotes_playAnapestsDactyls',
      title: 'Play Anapests & Dactyls',
      order: 7,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '2/4: tempo=65 s s e s s e | e e q | er e e s s | s s e e er',
          '3/4: tempo=55 h e s s | h. | h er e | s s e h',
          '5/4: tempo=72 s s s s q h. | s s e q h e s s | h e s s h | s s s s e s s s s e h',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video: Eighth NoteRests (ss er) and (er ss)
     */
    {
      id: 'simpleTimeSixteenthNotes_eighthRests',
      order: 8,
      title: 'Eighth Rests',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'simple-sixteenth-patterns-video-3',
        rhythm: false,
      },
    },
    /**
     * Exercise: Count rhythms with (ss er) and (er ss)
     */
    {
      id: 'simpleTimeSixteenthNotes_countEighthRests',
      title: 'Count Eighth Rests',
      order: 9,
      interactive: {
        type: 'LabelCounts',
        manualQuestions: true,
        questions: ['4/4: tempo=60 q er e s s er q', '3/4: tempo=80 er s s q s s er'],
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play rhythms with (ss er) and (er ss)
     */
    {
      id: 'simpleTimeSixteenthNotes_playEighthRests',
      title: 'Play Eighth Rests',
      order: 10,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=60 s:p s:p | e s s er s s q er s s | s s er s s er h',
          '3/4: tempo=80 s:p s:p | h er s s | q er e e s s | s s er qr er s s | h qr',
          '5/4: tempo=72 s:p s:p | w er s s | q wr | qr s s s s e s s q er s s | s s er w',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video: Sixteenth rests
     */
    {
      id: 'simpleTimeSixteenthNotes_sixteenthRests',
      order: 11,
      title: 'Sixteenth Rests',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'simple-sixteenth-patterns-video-4',
        rhythm: false,
      },
    },
    /**
     * Exercise: Count rhythms w/ (sr s s s) and (s s s sr) in them.
     */
    {
      id: 'simpleTimeSixteenthNotes_countSixteenthRests',
      title: 'Count Sixteenth Rests',
      order: 12,
      interactive: {
        type: 'LabelCounts',
        manualQuestions: true,
        questions: ['2/4: tempo=60 sr s s s s s s sr', '4/4: tempo=80 er s s sr s s s q s s s sr'],
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play rhythms w/ (sr s s s) and (s s s sr) in them.
     */
    {
      id: 'simpleTimeSixteenthNotes_playSixteenthRests',
      title: 'Play Sixteenth Rests',
      order: 13,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=52 h qr sr s s s | w | h.r s s s sr | h. qr',
          '2/4: tempo=70 s s s sr s s s sr | s s s sr q | sr s s s s s s sr | er e q',
          '3/4: tempo=65 s:p s:p s:p | h sr s s s | s s s sr qr sr s s s | h er s s | qr er e er e',
          '5/4: tempo=80 q | s s s sr er e er e er e sr s s s | s s s sr er e sr s s s s s s s e s s | q sr s s s q sr s s s s s e | s s s sr e s s sr s s s h',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'simpleTimeSixteenthNotes_summary',
      order: 14,
      title: 'Summary',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'simple-sixteenth-patterns-video-5',
        rhythm: false,
      },
    },
  ],
};
