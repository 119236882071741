import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Compound Meters
 */
export const lesson: Lesson = {
  id: 'compoundMeters',
  areaId: 'rhythm',
  order: 5,
  title: 'Compound Meters',
  skills: ['C:q.', 'C:q.r', 'C:eee', 'C:h.', 'C:h.r', 'C:w.', 'C:w.r'],
  pages: [
    {
      id: 'compoundMeters_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Compound Meters</h3>
        <p>In this lesson we'll learn:</p>
        <ul>
        <li>What a compound meter is,</li>
        <li>How to count beats and subdivisions in compound meters, and</li>
        <li>Why the time signature of a compound meter refers to the number of subdivisions,
        not the number of beats, in a bar.</li>
        </ul>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     *  VIDEO 1: Compound Time So far we've had time signatures where the
     *  quarter note was the beat. These are time signatures with a four on
     *  bottom. Our quarter note beat could be subdivided into two eighth notes.
     *
     *  There are also time signatures meters where the beat can be subdivided
     *  into three eighth note parts. Instead of counting these "1& 2&" we count
     *  them as "1 la li 2 la li."
     *
     *  Let's count & clap one of these together, with 2 beats, which we'll
     *  count as "1 la li 2 la li":
     *
     *    || 6/8: tempo=60 e e e e e e | e e e e e e ||
     *
     *  We call  time signatures whose beats subdivide into three parts compound
     *  meters. Lots of nursery rhymes in English are in compound meters:
     *    - "There was an old lady who lived in a shoe..."
     *    - "Humpty dumpty sat on a wall..."
     *    - "Jack & Jill..."
     *
     *  And if our beat divides into three eighth note parts, what note value
     *  will the beat itself be? (In other words -- what note value is 3 eighth
     *  notes long?)
     *
     *  A dotted quarter note. Compound meters all have a dotted note as the
     *  beat.
     *
     *  In our time signature we can represent this one of two ways, we can say
     *  we have 2 dotted quarter notes per bar. Or, and this is the more
     *  traditional way, we can say there are 6 eighth notes in the bar. It's
     *  important to know that when we see 6/8, we don't mean there are six
     *  eighth-note beats to the bar. We mean there are six eighth notes in the
     *  bar, which are grouped into two dotted quarter beats.
     *
     *  Let's clap some beats together in 6/8 time, counting "1 la li 2 la li"
     *  as we do:
     *    - "6/8: tempo=60 q. q. | q. q. | q. q. | q. q. ||"
     *
     *  Now let's try a rhythm mixing beats & eighth notes:
     *    - 6/8: tempo=60 e e e q. | e e e q. | q. q. | e e e q. ||
     *
     *  Great, now give some a try on your own!
     *
     */
    {
      id: 'compoundMeters_subdivision',
      order: 2,
      title: 'Beat & Subdivision',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'compoundMeters1',
        rhythm: true,
      },
    },
    /**
     * Interactive 1: eee q.
     */
    {
      id: 'compoundMeters_playDottedHalf',
      title: 'Play Beats & Subdivisions',
      order: 3,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '6/8: tempo=68 q. q. | q. q. | e e e e e e | q. q.',
          '6/8: tempo=52 e e e e e e | e e e q. | e e e e e e | e e e q.',
          '6/8: tempo=60 q. q. | e e e e e e | q. q. | e e e e e e',
          '6/8: tempo=70 q. e e e | e e e q. | e e e q. | q. q.',
        ],
        autoadvance: true,
      },
    },
    /**
     * VIDEO 2: Dotted quarter rests
     *
     * Just as we can have a dotted quarter note, we can also have a dotted
     * quarter rest, which in 6/8 will last for one beat. Let's count and clap
     * this rhythm together:
     *    - "6/8: tempo=60 q. e e e | q. q.r | q. q. | q.r q.",
     */
    {
      id: 'compoundMeters_dottedQuarterRests',
      order: 4,
      title: 'Dotted Quarter Rests',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'compoundMeters2',
        rhythm: true,
      },
    },
    /**
     * INTERACTIVE: 6/8 rhythm reading with q. q.r eee
     */
    {
      id: 'compoundMeters_playDottedHalfRests',
      order: 5,
      title: 'Play Quarter Rests',
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '6/8: tempo=64 q. q.r | q. q.r | e e e q.r | q. q.r',
          '6/8: tempo=72 q. q. | q. q.r | e e e q. | q.r q.',
          '6/8: tempo=52 e e e q.r | q. q.r | q. e e e | q.r q.',
        ],
      },
    },
    /**
     * VIDEO 3: 9/8 and 12/8
     *
     * 6/8 time means we have two dotted quarter beats per bar.
     *
     * If we have three dotted quarter beats per bar, how many eighth notes
     * total would we have in the measure? 9. 9/8 is the compound meter with
     * three dotted quarter beats per bar. We count it "1 la li 2 la li 3 la li"
     *
     * Let's read a rhythm in 9/8 together:
     *   - "9/8: tempo=58 q. q. e e e | q. e e e e e e | e e e q. q.",
     *
     * If we have four dotted quarter beats per bar, how many eighth notes total
     * will be in the measure? (12) 12/8 is the compound meter with four dotted
     * quarter beats per bar.
     *
     * Let's read a rhythm in 12/8 together:
     *   - 12/8: tempo=58 q. e e e q. e e e | e e e e e e q. q.r ||
     */
    {
      id: 'compoundMeters_video_3',
      title: '9/8 & 12/8',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'compoundMeters3',
        rhythm: true,
      },
    },
    /**
     * INTERACTIVE: 9/8 & 12/8 rhythm reading with q. q.r eee
     */
    {
      id: 'compoundMeters_playNineEight_TwelveEight',
      title: 'Play in 9/8 & 12/8',
      order: 7,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '9/8: q. q. q. | e e e e e e e e e | q. q. q.r | e e e q. q. | q. q.r q.r',
          '12/8: q. q.r q. e e e | q. q. q.r q. | q. q.r q. e e e | q. q. e e e q.',
          '9/8: tempo=52 q. q.r e e e | q. q. q.r | q. e e e q.r | q.r e e e q.r',
          '12/8: tempo=72 q.r q. q. q.r | q.r e e e q. q.r | q.r e e e e e e q.r | q.r q. q. q.r',
        ],
      },
    },
    /**
     *
     * VIDEO 4: Dotted half notes & rests
     *
     * In 6/8, 9/8 and 12/8 the dotted quarter note is the beat.
     *
     * But imagine that we had two dotted quarter notes tied together. Is there
     * a way we could represent this as one longer note value? That is, what
     * rhythmic value is as long as two dotted quarter notes? A dotted half.
     *
     * In 6/8, 9/8, and 12/8, a dotted half note gets two beats.
     *
     * Let's read a rhythm together:
     *   - 12/8: tempo=62 q. q. h. | h. e e e e e e | h. h.r ||
     *
     * INTERACTIVE: Compound w/ h., h.r, q., q.r, eee
     */
    {
      id: 'compoundMeters_video_4',
      title: 'Dotted Half Notes',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'compoundMeters4',
        rhythm: true,
      },
    },
    /**
     * INTERACTIVE 4: Offbeats
     */
    {
      id: 'compoundMeters_playDottedHalfs',
      title: 'Play Dotted Half Notes',
      order: 9,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '6/8: q. e e e | h. | e e e e e e | h.',
          '9/8: q. q. q. | h. q. | e e e q. q. | h. q.r',
          '12/8: e e e q. h. | e e e e e e q. q.r | h. q. q. | h.r q. q.',
          '9/8: q. e e e q. | h.r q. | e e e q. q. | h.r e e e | h.r q.',
        ],
      },
    },
    /**
     * VIDEO 5: Dotted whole notes
     *
     * Imagine in 12/8 time that we tied two dotted half notes together. Is
     * there a way we could represent this as one note value? Yes -- a dotted
     * whole note, which will last four beats.
     *
     * Let's get some practice reading dotted whole notes and rests in 12/8
     * time.
     */
    {
      id: 'compoundMeters_video_5',
      title: 'Dotted Whole Notes',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'compoundMeters5',
        rhythm: true,
      },
    },
    /**
     * INTERACTIVE: 12/8 w/ dotted wholes.
     */
    {
      id: 'compoundMeters_playDottedWhole',
      title: 'Play Dotted Whole Notes',
      order: 11,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '12/8: tempo=85 q. q. q. q. | w. | q. q. q. q. | h. q.r q.',
          '12/8: tempo=60 e e e q. h.r | e e e h. e e e | w. | e e e h. e e e | h. h. | w.',
          '12/8: tempo=100 q. q. q.r q. | w.r | q. q. q.r q. | w.',
          '12/8: tempo=52 e e e h. q. | w. | e e e h. q. | w.r | e e e h. e e e | w.',
        ],
      },
    },
    /**
     * VIDEO 6: Summary
     *
     * Nice work -- you have a good start in understanding compound meters: time
     * signatures where the beat subdivides into three parts instead of two. We
     * learned that in compound meters, the beat will always be a dotted note.
     * That the time signature of compound meters tells us how many subdivisions
     * there are in the meter, instead of how many beats there are. And we've
     * practiced reading rhythms in the three most common compound meters: 6/8,
     * 9/8 and 12/8. Great work!
     */
    {
      id: 'compoundMeters_video_6',
      title: 'Summary',
      order: 12,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'compoundMeters6',
        rhythm: true,
      },
    },
  ],
};
