import type { Lesson } from '../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'accidentals_1',
  areaId: 'pitch_and_harmony',
  order: 4,
  title: 'Accidentals: Sharps & Flats',
  skills: ['sharps', 'flats'],
  pages: [
    {
      id: 'accidentals_1_about_accidentals_and_sharps',
      title: 'About Accidentals & Sharps',
      order: 1,
      video: {
        autoadvance: true,
        autostart: true,
        url: 'accidentals1-1',
      },
    },
    {
      id: 'accidentals_1_play_sharps',
      title: 'Play: Sharps',
      order: 2,
      interactive: {
        type: 'SingleNotePlay',
        highestNote: 'G5',
        ignoreOctave: true,
        lowestNote: 'C4',
        questionPrompt: 'Play the note {question} on the piano.',
        questions: [
          'F4',
          'F#4',
          'A4',
          'A#4',
          'C4',
          'C#4',
          'E4',
          'E#4',
          'D4',
          'D#4',
          'B4',
          'B#4',
          'E4',
          'E#4',
          'G4',
          'G#4',
        ],
      },
    },
    {
      id: 'accidentals_1_about_flats',
      title: 'About Flats',
      order: 3,
      video: {
        autoadvance: true,
        autostart: true,
        url: 'accidentals1-2',
      },
    },
    {
      id: 'accidentals_1_play_flats',
      title: 'Play: Flats',
      order: 4,
      interactive: {
        type: 'SingleNotePlay',
        highestNote: 'G5',
        ignoreOctave: true,
        lowestNote: 'C4',
        questionPrompt: 'Play the note {question} on the piano.',
        questions: ['G4', 'Gb4', 'E4', 'Eb4', 'F4', 'Fb4', 'A4', 'Ab4', 'C5', 'Cb5'],
      },
    },
    {
      id: 'accidentals_1_one_note_two_names',
      title: 'One Note, Two Names',
      order: 5,
      video: {
        autoadvance: true,
        autostart: true,
        url: 'accidentals1-3a',
      },
    },
    {
      id: 'accidentals_1_labeling_the_piano',
      title: 'Labeling the Piano',
      order: 6,
      interactive: {
        type: 'PianoLabelNoteNames',
      },
    },
    {
      id: 'accidentals_1_writing_accidentals_on_the_staff',
      title: 'Writing Accidentals on the Staff',
      order: 7,
      video: {
        autoadvance: true,
        autostart: true,
        url: 'accidentals1-3',
      },
    },
    {
      id: 'accidentals_1_reading_and_playing_accidentals',
      title: 'Reading & Playing Accidentals',
      order: 8,
      interactive: {
        type: 'NoteReadingPiano',
        clef: 'treble',
        highestNote: 'G5',
        lowestNote: 'C4',
        questions: [
          'Ab4/Eb4/Cb5/Bb4',
          'F#4/C#5/A#4',
          'Ab4/F4/Fb4/Eb4/Db4/C',
          'D4/D#4/E/G#4/B4/C#5',
          'Db5/Bb4/Db4/Gb5',
        ],
      },
    },
    {
      id: 'accidentals_1_about_naturals',
      title: 'Naturals',
      order: 9,
      video: {
        autoadvance: true,
        autostart: true,
        url: 'accidentals1-4',
      },
    },
    {
      id: 'accidentals_1_play_the_notes_you_see',
      title: 'Play the notes you see',
      order: 10,
      interactive: {
        type: 'NoteReadingPiano',
        clef: 'treble',
        highestNote: 'G5',
        lowestNote: 'C4',
        questions: [
          'Eb4/C4/Eb4/E4',
          'F#4/G#4/A#4/F#4',
          'G/Gb/F/Gb',
          'D4/Db4/D#4/D#4',
          'Bb/B4/C5/B4',
          'A#4/D4/F#4/A#4',
        ],
      },
    },
    {
      id: 'accidentals_1_conclusion',
      order: 11,
      content: `<h3>Summary</h3>
        <p>That was a big lesson -- nice work getting through it.</p>
        <p>We learned about <strong>sharps</strong>, <strong>flats</strong> and <strong>naturals</strong>, their symbols, and how they affect notes.<p>
        <p>We learned that accidentals last through a measure -- unless another accidental cancels them out.</p>
        <p>And we learned that accidentals come first on the staff, and second when we are writing out a letter name.</p>
        `,
      title: 'Summary',
    },
  ],
};
