import { observable, action, computed, transaction, makeObservable, runInAction } from 'mobx';
import { isInIFrame } from '../utils/isInIFrame';
import type {
  UserWithJoins,
  UserRecord,
  PagesRemaining,
  StudentUserRecord,
} from '../../definitions/user-object-definitions';
import type { SavedCustomCheckpoint } from '../../definitions/checkpoint-definitions';
import type { Subscription } from '../../definitions/subscription-definitions';
import type { JoinedSkillRecord } from '../../definitions/skill-record';
import type { LessonRecord } from '../../definitions/lesson-record';
import type { Institution } from '../../definitions/license-definitions';
import { RestAPIServiceClass } from '../apiClient/restApi';
import { CustomTestStore } from './tests/custom.test.store';
import { AbstractUserStore } from './abstract.user.store';
import SectionStore from '../section/section.store';
import type { Results } from './tests/checkpoint.attempt.store';
import { TestAttemptStore } from './tests/checkpoint.attempt.store';
import type { GroupLicenseStats, TestingLicenseStats } from '../licenses/institution.store';
import InstitutionStore from '../licenses/institution.store';
import type { OAuthLoginCredential } from '../../definitions/login-credential-definitions';
import type { SectionWithJoins } from '../../definitions/section-definitions';
import SubscriptionStore from './subscriptions/subscription.store';
import { uniqueByFilter } from '../utils/uniqueByFilter';
import { sortByFactory } from '../utils/sortByFactory';
import type { UserIPBasedAccess } from 'definitions/ip-based-access';

/**
 * The root user store for the frontend client. It extends the user store,
 * and adds functionality to connect to the rest database.
 */
export default class ClientUserStore extends AbstractUserStore {
  @observable currentTime: Date = new Date();

  api: RestAPIServiceClass;

  loadingPromise: Promise<ClientUserStore>;

  @observable loading!: boolean;

  @observable saving!: boolean;

  @observable error!: false | string;

  @observable errorObject?: any;

  @observable emailVerified!: boolean;

  @observable teacherStatusVerified!: boolean;

  @observable schoolName?: string;

  @action setSchoolName(schoolName: string) {
    this.schoolName = schoolName;
  }

  @action private setError(error: string, err?: any) {
    this.error = error;
    if (err) this.errorObject = err;
  }

  @action private setLoadingStatus(bool: boolean) {
    this.loading = bool;
  }

  @action private setSavingStatus(bool: boolean) {
    this.saving = bool;
  }

  @computed get status(): 'loading' | 'loaded' | 'saving' | 'errored' {
    if (this.error) return 'errored';
    if (this.saving) return 'saving';
    if (this.loading) return 'loading';
    return 'loaded';
  }

  @computed get isSavingToAnonymousUser() {
    if (this.type !== 'anonymous') return false;
    return this.api._progressSent;
  }

  @action toggleHighContrast() {
    const theme = this.theme === 'normal' ? 'high-contrast' : 'normal';
    console.log({ theme });
    this.setPersonalPreferences({ theme });
    this.save({ preferences: { theme } });
  }

  @action setTheme(theme: ClientUserStore['theme']) {
    this.setPersonalPreferences({ theme });
    this.save({ preferences: { theme } });
  }

  @observable isSubscribed!: boolean;

  @action setIsSubscribed(isSubscribed: boolean) {
    this.isSubscribed = isSubscribed;
  }

  @observable isCanceling!: boolean;

  @action setIsCanceling(isCanceling: boolean) {
    this.isCanceling = isCanceling;
  }

  @computed get allSectionsPaid(): boolean {
    return this.allSections
      .filter((s) => s.teacherIds.includes(this.id) || s.ownerIds.includes(this.id))
      .every((s) => s.paidById);
  }

  @computed get noSectionsPaid(): boolean {
    return this.allSections
      .filter((s) => s.teacherIds.includes(this.id) || s.ownerIds.includes(this.id))
      .every((s) => !s.paidById);
  }

  @computed get someSectionsPaid(): boolean {
    return (
      this.allSections
        .filter((s) => s.teacherIds.includes(this.id) || s.ownerIds.includes(this.id))
        .some((s) => s.paidById) && !this.allSectionsPaid
    );
  }

  getStudentUserRecord(id: string): StudentUserRecord | undefined {
    return this.taughtSections
      .map((section) => section.getStudentUserRecord(id))
      .filter((truthy): truthy is StudentUserRecord => !!truthy)[0];
  }

  @computed get hasGoogleLogin(): boolean {
    return !!this.credentials.find((c) => c.type === 'oAuth' && c.oAuthProviderId === 'google');
  }

  @computed get googleOAuthUserId(): string | false {
    const credential = this.credentials.find(
      (c) => c.type === 'oAuth' && c.oAuthProviderId === 'google'
    );
    if (!credential) return false;
    return (credential as OAuthLoginCredential).oAuthUserId;
  }

  @computed get isClassroomConnected(): boolean {
    return !!this.credentials.find(
      (c) => c.type === 'oAuth' && c.oAuthProviderId === 'google' && c.isClassroomConnected === true
    );
  }

  @computed get hasCleverLogin(): boolean {
    return !!this.credentials.find((c) => c.type === 'oAuth' && c.oAuthProviderId === 'clever');
  }

  @computed get cleverOAuthUserId(): string | false {
    const credential = this.credentials.find(
      (c) => c.type === 'oAuth' && c.oAuthProviderId === 'clever'
    );
    if (!credential) return false;
    return (credential as OAuthLoginCredential).oAuthUserId;
  }

  @computed get groupLicenses(): GroupLicenseStats[] {
    return this.teachesForInstitutions.flatMap((i) => i.licenses || []);
  }

  @computed get hasGroupLicense(): boolean {
    return !!this.groupLicenses.length;
  }

  @computed get validGroupLicenses(): GroupLicenseStats[] {
    return this.groupLicenses.filter((l) => l.isCurrent);
  }

  @computed get hasValidGroupLicense(): boolean {
    return this.groupLicenses.some((l) => l.isCurrent);
  }

  @computed get testingLicenses(): TestingLicenseStats[] {
    return this.teachesForInstitutions.flatMap((i) => i.testingLicenses || []);
  }

  @computed get validTestingLicenses(): TestingLicenseStats[] {
    return this.testingLicenses.filter((l) => l.isCurrent);
  }

  @computed get hasTestingLicense(): boolean {
    return !!this.testingLicenses.length;
  }

  @computed get hasValidTestingLicense(): boolean {
    return this.testingLicenses.some((l) => l.isCurrent);
  }

  /**
   * Is the user's usage paid, either by themselves, by a license,
   * or because they're a teacher?
   */
  @computed get isPaid(): boolean {
    return (
      this.roles.includes('crawler') ||
      this.roles.includes('teacher') ||
      this.roles.includes('institution') ||
      this.isSubscribed ||
      this.isPaidByGroupLicense ||
      this.ipBasedAccess.hasIPBasedAccess
    );
  }

  @observable hasEverSubscribed!: boolean;

  @observable.shallow subscriptions!: SubscriptionStore[];

  @action addOrUpdateSubscription(subscription: Subscription) {
    const subscriptionStore = this.subscriptions.find((s) => s.id === subscription.id);
    if (subscriptionStore) {
      subscriptionStore.update(subscription);
    } else {
      this.subscriptions.push(new SubscriptionStore(subscription));
    }
    this.hasEverSubscribed = true;
  }

  @observable isPaidByGroupLicense!: boolean;

  @observable pagesRemaining!: PagesRemaining;

  @action updatePagesRemaining(pagesRemaining: PagesRemaining) {
    this.pagesRemaining = {
      ...pagesRemaining,
      date: new Date(pagesRemaining.date),
    };
  }

  /**
   * Is the user allowed to view another lesson page or
   * excercise today without registering (if anonymous)
   * or subscribing?
   */
  @computed get hasPagesRemainingToday(): boolean {
    if (this.isPaid) return true;
    // This should never happen
    if (!this.pagesRemaining) return true;

    return this.type === 'anonymous'
      ? this.pagesRemaining.anonymousPages > 0
      : this.pagesRemaining.pages > 0;
  }

  @observable teachesForInstitutions!: InstitutionStore[];

  @computed get institutionsWithActiveLicense(): InstitutionStore[] {
    if (!this.teachesForInstitutions.length) return [];
    return this.teachesForInstitutions.filter((i) => i.hasValidGroupLicense);
  }

  @action changeSection(sectionStoreOrId: SectionStore | string) {
    super.changeSection(sectionStoreOrId);
    // If they've moved into one of their current sections, save it.
    // Otherwise, don't -- creates problems on database side to have a sectionId
    // be of an owned or taught class, plus, what if they are removed as
    // a teacher from that class? We could watch for it, I suppose... but
    // let's just trust the local store to treat this as a preference.
    this.api.putUser(this.id, { sectionId: this.sectionId }).catch((err) => console.error(err));
  }

  @computed get defaultSection(): SectionStore | undefined {
    return this.allSections.find((s) => s.isDefaultSection);
  }

  @computed get sectionTests(): CustomTestStore[] {
    return this.allSections.flatMap((s) => s.tests).filter(uniqueByFilter('id'));
  }

  /**
   * Every test on user object: from user.tests & user.ownedTests.
   * user.ownedTests. This assures no duplication of test stores.
   */
  @observable private _tests!: { [id: string]: CustomTestStore };

  /**
   * All testIds associated with this user.
   */
  @observable testIds!: string[];

  @action addOrUpdateTest(customTest: SavedCustomCheckpoint) {
    const existingTest = this._tests[customTest.id];
    if (!existingTest) {
      this._tests[customTest.id] = new CustomTestStore(customTest, this);
      return;
    }
    existingTest.update(customTest);
  }

  @action removeTest(testOrId: SavedCustomCheckpoint | CustomTestStore | string): boolean {
    const customTestId = typeof testOrId === 'string' ? testOrId : testOrId.id;
    return delete this._tests[customTestId];
  }

  public hasBeenRedirectedToLandingPageTest = false;

  /**
   * If a custom test needs to be the landing page, this will show it.
   */
  @computed get landingPageTest(): CustomTestStore | undefined {
    return this.sectionAndUserConnectedTests
      .filter((test) => test.isLandingPage)
      .sort((a, b) => a.landingPagePriority - b.landingPagePriority)[0];
  }

  /**
   * All section.tests, user.tests, and user.ownedTests
   * including those that should be hidden to students.
   */
  @computed get allTests(): CustomTestStore[] {
    return Object.values(this._tests).sort(sortByFactory({ key: 'title', ignoreCase: true }));
  }

  /**
   * Tests on the user.testIds property only.
   */
  @computed get tests(): CustomTestStore[] {
    return Object.values(this._tests).filter((test) => this.testIds.includes(test.id));
  }

  @computed get testsById(): { [id: string]: CustomTestStore } {
    return this._tests;
  }

  /**
   * All section & user.tests tests that should be visible to a student,
   * and in the case of a teacher all tests that they have any access to.
   */
  @computed get visibleTests(): CustomTestStore[] {
    const testTitleSort = (a: CustomTestStore, b: CustomTestStore) => {
      if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;
      if (b.title.toUpperCase() > a.title.toUpperCase()) return -1;
      return 0;
    };

    if (this.roles.includes('teacher') && this.currentSection.isDefaultSection) {
      return this.allTests.slice(0).sort(testTitleSort);
    }

    return this.sectionAndUserConnectedTests.slice(0).sort(testTitleSort);
  }

  /**
   * All section & user.tests tests that should be visible to a student,
   * excludes tests taught but not in current section/on user object.
   */
  @computed get sectionAndUserConnectedTests(): CustomTestStore[] {
    return [...this.tests, ...this.currentSection.tests]
      .filter((test) => test.isVisible)
      .filter(uniqueByFilter('id'));
  }

  /**
   * Tests owned by this user. (Does not include tests the user is a teacher
   * or TA for. Use UserStore.taughtTests instead.)
   */
  @computed get ownedTests(): CustomTestStore[] {
    return this.allTests.filter((test) => test.isOwner);
  }

  /**
   * Tests the user is owner, teacher or TA for.
   */
  @computed get taughtTests(): CustomTestStore[] {
    return this.allTests.filter(
      (test) => test.isOwner || test.isTeacher || test.isTeachingAssistant
    );
  }

  @computed get userDataForInteractive() {
    return {
      skills: this.knowledgeProgress.skills,
      preferences: this.preferences,
      progress: this.knowledgeProgress.skills,
      accommodation: this.activeAccommodation,
      userStore: this as ClientUserStore,
    };
  }

  @observable flashes!: UserWithJoins['flash'];

  @action setFlashes(flashes: UserWithJoins['flash']) {
    this.flashes = flashes;
  }

  @action removeFlash(flashId: string) {
    const index = this.flashes.findIndex((f) => f.id === flashId);
    if (index === -1) return false;
    this.flashes.splice(index, 1);
    return true;
  }

  @observable isInIFrame = isInIFrame();

  constructor(
    private urls: { apiUrl: string; websocketUrl: string },
    userWithJoins?: UserWithJoins,
    cookiedFetch?: typeof fetch
  ) {
    super();
    makeObservable(this);

    this.api = new RestAPIServiceClass(
      {
        baseUrl: this.urls.apiUrl,
        webSocketUrl: this.urls.websocketUrl,
      },
      this,
      cookiedFetch
    );

    if (userWithJoins) {
      this.init(userWithJoins);
      this.loadingPromise = Promise.resolve(this);
    } else {
      this.loadingPromise = this.load(userWithJoins);
    }

    if (typeof window !== 'undefined') {
      this.api.checkAndUpdateIPBasedAccess()
    }
  }

  @observable ipBasedAccess: UserIPBasedAccess = { hasIPBasedAccess: false };

  @action init(userRecord: UserWithJoins) {
    this._tests = {};
    if (userRecord.schoolName) this.schoolName = userRecord.schoolName;
    this.isSubscribed = userRecord.isSubscribed;
    this.isPaidByGroupLicense = userRecord.isPaidByGroupLicense;
    this.isCanceling = userRecord.isCanceling;
    this.testIds = userRecord.testIds || [];
    this.subscriptions = [];
    this.emailVerified = !!userRecord.emailVerified;
    this.teacherStatusVerified = !!userRecord.teacherStatusVerified;
    if (userRecord.pagesRemaining) this.updatePagesRemaining(userRecord.pagesRemaining);
    if (userRecord.subscriptions) {
      userRecord.subscriptions.forEach((subscription) =>
        this.addOrUpdateSubscription(subscription)
      );
    }
    this.hasEverSubscribed = userRecord.hasEverSubscribed;
    if (userRecord.tests) {
      userRecord.tests.forEach((test) => this.addOrUpdateTest(test));
    }

    if (userRecord.ownedTests) {
      userRecord.ownedTests.forEach((test) => this.addOrUpdateTest(test));
    }

    if (userRecord.sections) {
      const sectionTests = userRecord.sections.flatMap((s) => s.tests || []);
      sectionTests.forEach((test) => this.addOrUpdateTest(test));
    }

    this.updateTeachesForInstitutions(userRecord.teachesForInstitutions);

    this.setFlashes(userRecord.flash || []);

    super.init(userRecord);
  }

  /**
   * Should only be called with a complete UserWithJoins record, as any
   * missing sections, tests, skills, etc... will be deleted. This effectively
   * resets the client user store to match the state of the database.
   */
  @action update(userRecord: UserWithJoins) {
    // Because pages may be watching a child observable, multiple digest cycles
    // could be fired. We run as a transaction to prevent those digests.
    transaction(() => {
      this.initializeUPoints(userRecord.uPoints.dataPoints);

      if (userRecord.allSections) {
        this.updateSections(userRecord.allSections);
      }
      // Unless we've set a preferedClassView, update the current section:
      if (userRecord.sectionId) this.sectionId = userRecord.sectionId;
      if (userRecord.roles) this.setRoles(userRecord.roles);
      if (typeof userRecord.demoUser !== 'undefined') this.demoUser = !!userRecord.demoUser;

      if (typeof userRecord.id !== 'undefined') {
        this.id = userRecord.id;
      }
      if (typeof userRecord.firstName !== 'undefined') {
        this.firstName = userRecord.firstName;
      }
      if (typeof userRecord.lastName !== 'undefined') {
        this.lastName = userRecord.lastName;
      }
      if (typeof userRecord.email !== 'undefined') {
        this.email = userRecord.email;
      }
      if (typeof userRecord.accommodations !== 'undefined') {
        this.accommodations = userRecord.accommodations;
      }
      if (typeof userRecord.avatarUrl !== 'undefined') {
        this.avatarUrl = userRecord.avatarUrl || null;
      }
      if (typeof userRecord.credentials !== 'undefined') {
        this.credentials = userRecord.credentials;
      }
      if (typeof userRecord.isSubscribed !== 'undefined') {
        this.isSubscribed = userRecord.isSubscribed;
      }
      if (typeof userRecord.isPaidByGroupLicense !== 'undefined') {
        this.isPaidByGroupLicense = userRecord.isPaidByGroupLicense;
      }
      if (typeof userRecord.hasEverSubscribed !== 'undefined') {
        this.hasEverSubscribed = userRecord.hasEverSubscribed;
      }
      if (typeof userRecord.isCanceling !== 'undefined') {
        this.isCanceling = userRecord.isCanceling;
      }
      if (typeof userRecord.testIds !== 'undefined') {
        this.testIds = userRecord.testIds || [];
      }
      if (typeof userRecord.subscriptions !== 'undefined') {
        this.updateSubscriptions(userRecord.subscriptions);
      }
      if (typeof userRecord.skills !== 'undefined') {
        this.updateSkills(userRecord.skills);
      }
      if (typeof userRecord.checkpoints !== 'undefined') {
        this.updateTestAttempts(userRecord.checkpoints);
      }
      if (typeof userRecord.lessons !== 'undefined') {
        this.updateLessons(userRecord.lessons);
      }
      if (typeof userRecord.pagesRemaining !== 'undefined') {
        this.updatePagesRemaining(userRecord.pagesRemaining);
      }
      if (typeof userRecord.tests !== 'undefined' && typeof userRecord.testIds !== 'undefined') {
        this.updateTests(userRecord.tests, userRecord.testIds);
      }
      if (typeof userRecord.ownedTests !== 'undefined') {
        this.updateOwnedTests(userRecord.ownedTests);
      }

      if (userRecord.teachesForInstitutions) {
        this.updateTeachesForInstitutions(userRecord.teachesForInstitutions);
      }

      if (userRecord.flash) {
        this.setFlashes(userRecord.flash);
      }
      if (userRecord.lastActiveAt) this.lastActiveAt = new Date(userRecord.lastActiveAt);
      if (userRecord.lastLoginAt) this.lastLoginAt = new Date(userRecord.lastLoginAt);
    });
  }

  @action private updateTeachesForInstitutions(institutions: Institution[]) {
    this.teachesForInstitutions = this.teachesForInstitutions || [];
    this.teachesForInstitutions = institutions
      ? institutions.map((institution) => {
          const existingInstitution = this.teachesForInstitutions.find(
            (i) => i.id === institution.id
          );
          if (existingInstitution) {
            existingInstitution.update(institution);
            return existingInstitution;
          }
          return new InstitutionStore(institution, this);
        })
      : [];
  }

  @action private updateLessons(lessonRecords: Omit<LessonRecord, 'progressData'>[]) {
    const lessonRecordsById = lessonRecords.reduce(
      (dictionary: { [id: string]: Omit<LessonRecord, 'progressData'> }, lessonRecord) => {
        dictionary[lessonRecord.lessonId] = lessonRecord;
        return dictionary;
      },
      {}
    );
    this.lessonProgress.lessons.forEach((lesson) => {
      if (lesson.type === 'checkpoint') return;
      const lessonRecord = lessonRecordsById[lesson.id];
      // If it's a checkpoint, return -- that's updated by test attempts
      lesson.update(lessonRecord);
    });
  }

  @action private updateTests(userTests: SavedCustomCheckpoint[] = [], testIds: string[]) {
    userTests.forEach((t) => this.addOrUpdateTest(t));
    // Now delete any that aren't owned, on a section or on the user:
    const sectionTestIds = this.allSections.flatMap((s) =>
      s.connectedTests.map((t) => t.customCheckpointId)
    );
    const allIds = [...testIds, ...sectionTestIds];
    this.allTests.forEach((test) => {
      if (!allIds.includes(test.id) && !test.isOwner) {
        this.removeTest(test.id);
      }
    });
  }

  @action private updateOwnedTests(ownedTests: SavedCustomCheckpoint[] = []) {
    ownedTests.forEach((t) => this.addOrUpdateTest(t));
    this.ownedTests.forEach((t) => {
      if (!ownedTests.find((test) => test.id === t.id)) {
        this.removeTest(t.id);
      }
    });
  }

  @action private updateSubscriptions(subscriptions: Subscription[] = []) {
    this.subscriptions.length = 0;
    subscriptions.forEach((s) => this.addOrUpdateSubscription(s));
  }

  /**
   * Completely updates/refreshes user knowledge from a fully joined user record.
   * @param skillRecords - Fully joined! any missing will be marked as 0 progress.
   */
  @action private updateSkills(skillRecords: JoinedSkillRecord[]) {
    const skillRecordsById = skillRecords.reduce(
      (dictionary: { [id: string]: JoinedSkillRecord }, skillRecord) => {
        dictionary[skillRecord.skillId] = skillRecord;
        return dictionary;
      },
      {}
    );
    this.knowledgeProgress.skills.forEach((skill) => {
      const skillRecord = skillRecordsById[skill.textId];
      skill.update(skillRecord);
    });
  }

  @action private updateTestAttempts(attempts: Results[]) {
    // Add or update any new ones:
    attempts.forEach((attempt) => {
      const existingAttempt = this.testAttempts.find((candidate) => candidate.id === attempt.id);
      if (existingAttempt) existingAttempt.update(attempt);
      else {
        this.testAttempts.push(new TestAttemptStore(attempt, this));
      }
    });

    // And get rid of deleted ones:
    this.testAttempts = this.testAttempts.filter((attempt) =>
      attempts.some((newAttempt) => newAttempt.id === attempt.id)
    );
  }

  @action protected updateSections(sections: SectionWithJoins[]) {
    const before = this.allSections.map((s) => s.id);
    sections.forEach((sectionRecord) => {
      const existingSection = this.allSections.find((s) => s.id === sectionRecord.id);
      if (existingSection) {
        existingSection.update(sectionRecord);
      } else {
        this.addSection(new SectionStore(sectionRecord, this));
      }
    });
    this.allSections.forEach((oldSection) => {
      if (!sections.find((s) => s.id === oldSection.id)) {
        this.removeSection(oldSection.id);
      }
    });
    console.log('updating sections', {
      toUpdate: sections.map((s) => s.id),
      before,
      after: this.allSections.map((s) => s.id),
    });
  }

  @action protected initSectionsAndRoles(userRecord: UserWithJoins) {
    this.allSections = [];
    // Must be before currentSections, so we get the most complete version of each.
    if (userRecord.allSections) {
      userRecord.allSections.forEach((section) => this.addSection(new SectionStore(section, this)));
    }

    this.sectionId = userRecord.sectionId;

    this.roles = userRecord.roles;
    this.demoUser = !!userRecord.demoUser;
    if (userRecord.preferences) this.setPersonalPreferences(userRecord.preferences);
  }

  private async load(userWithJoins?: UserWithJoins): Promise<ClientUserStore> {
    this.setLoadingStatus(true);
    try {
      userWithJoins =
        userWithJoins || (await this.api.$get<UserWithJoins>(`/api/users`, { joinAll: true }));
      this.init(userWithJoins);

      this.setLoadingStatus(false);
    } catch (err) {
      this.setError('Error loading user.', err);
      throw err;
    } finally {
      this.setLoadingStatus(false);
    }
    return this;
  }

  save(
    fields: Partial<
      Pick<
        UserRecord,
        'firstName' | 'lastName' | 'id' | 'avatarUrl' | 'preferences' | 'email' | 'schoolName'
      > & {
        password: string;
        password2: string;
      }
    >
  ) {
    this.setSavingStatus(true);
    return this.api
      .putUser(this.id, fields)
      .then((response) => {
        if (response.firstName) {
          this.setFirstName(response.firstName);
        }
        if (response.lastName) {
          this.setLastName(response.lastName);
        }
        if (response.email) {
          this.setEmail(response.email);
        }
        if (response.avatarUrl) {
          this.setAvatarUrl(response.avatarUrl);
        }
        if (response.preferences) {
          this.setPersonalPreferences(response.preferences);
        }
        if (response.schoolName) {
          this.setSchoolName(response.schoolName);
        }
        this.setSavingStatus(false);
      })
      .catch((err) => {
        this.setSavingStatus(false);
        this.setError('Error saving user.', err);
        return Promise.reject(err);
      });
  }
}
