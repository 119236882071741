import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Generic Intervals
 */
export const lesson: Lesson = {
  id: 'generic_intervals',
  areaId: 'pitch_and_harmony',
  order: 25,
  title: 'Generic Intervals',
  skills: ['generic_intervals'],
  pages: [
    {
      id: 'generic_intervals_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Generic Intervals</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>What generic intervals are,</li>
        <li>How to identify and write generic intervals, and</li>
        <li>How to convert between simple and compound interval names.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video #1:
     *
     * Let's learn about generic intervals. A generic interval is a measure of
     * how many letter names, or lines and spaces, notes are from each other --
     * without worrying about how many half steps are between them.
     *
     * To figure out a generic interval, we count the letters between notes,
     * including both the starting and ending note. So:
     *
     * From F to C, we count 1 2 3 4 5, and call it a 5th.  From A up to F, 1 2
     * 3 4 5 6, and call it a 6th.
     *
     * Two intervals have special names: instead of saying a 1st, we say a
     * "Unison" and abbreviate it, "U." Instead of saying an 8th, we say an
     * "Octave," though we still abbreviate it 8.
     *
     * What interval is this? (4th), (7th), (unison), (8ve)
     *
     * When the notes of an interval are sounded at the same time, we call them
     * a "harmonic" interval. When they're sounded one after another, we call
     * them a "melodic" interval. Whether an interval is melodic or harmonic, we
     * still count it the same way.
     *
     * Let's get some practice writing and identifying generic intervals.
     */
    {
      id: 'generic_intervals_video_1',
      order: 2,
      title: 'Generic Intervals',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'genericIntervals1',
      },
    },
    {
      title: 'ID Generic Intervals',
      order: 3,
      id: 'generic_intervals_id',
      interactive: {
        type: 'GenericIntervalID',
        questionPrompt: 'Name the generic interval below',
        drill: true,
        skills: ['generic_intervals'],
        maxQuestions: 10,
      },
    },
    {
      title: 'Write Generic Intervals',
      order: 4,
      id: 'generic_intervals_writing',
      interactive: {
        type: 'GenericIntervalConstruction',
        drill: true,
        skills: ['generic_intervals'],
        maxQuestions: 10,
      },
    },
    /**
     * Video 2: Speed Tips
     *
     * Here's a trick to increase your speed at identifying generic intervals:
     * take a look at these intervals (1, 3, 5, 7) what do they have in common?
     * (All on lines, all odd numbered.)
     *
     * Now try these: (1, 3,5,7; on spaces) what do you notice? (All on spaces,
     * all odd numbered.) If the notes are both on lines, or both on spaces,
     * you'll have an odd numbered interval. This can let us count more quickly
     * -- just count up the lines or spaces in odd numbers to get to your
     * interval.
     *
     * See if you can name these generic intervals before I do!: (7, 3, 5, 1)
     *
     * If one's on a line and one's on a space, it'll be an even numbered
     * interval, but we can still count up in odds until we get close to it.
     *
     * See if you can name these generic intervals before I do! (4, 7, 2, 6, 5,
     * 8)
     *
     * Let's get some more practice with writing & identifying generic intervals
     * -- but see how quickly you can do it!
     */
    {
      id: 'generic_intervals_video_2',
      title: 'Speed Tips',
      order: 5,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'genericIntervals2',
      },
    },
    {
      title: 'Speed ID',
      order: 6,
      id: 'generic_intervals_id_speed',
      interactive: {
        type: 'GenericIntervalID',
        questionPrompt: 'Name the generic interval below',
        drill: true,
        skills: ['generic_intervals'],
        maxQuestions: 10,
      },
    },
    {
      title: 'Speed Writing',
      order: 7,
      id: 'generic_intervals_writing_speed',
      interactive: {
        type: 'GenericIntervalConstruction',
        drill: true,
        skills: ['generic_intervals'],
        maxQuestions: 10,
      },
    },
    /**
     * Video: Simple and Compound Intervals
     *
     * What if we had an interval that was larger than an octave? For instance,
     * this one: (9)
     *
     * We can name it two ways: we can count up as usual and call it a 9th. Or:
     * we can recognize that it is just the same as a 2nd with an extra octave
     * in between it and call it a 2nd.
     *
     * If we call it a 9th, we're giving the interval its compound name.
     * Compound names are larger than an octave. If we call it a 2nd we're
     * giving it its simple name.
     *
     * What about this interval (10th). What is its compound name? What is its
     * simple name?
     *
     * These names will always be seven apart from each other.
     *
     * What's the simple version of a 12th? (5th). What's the compound version
     * of a 6th? (13th)
     *
     * Let's get some practice going between simple and compound names for
     * intervals.
     */
    {
      id: 'generic_intervals_video_3',
      order: 8,
      title: 'Simple and Compound Intervals',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'genericIntervals3',
      },
    },
    {
      title: 'Convert Simple and Compound',
      order: 9,
      id: 'generic_intervals_simple_and_compound',
      interactive: {
        type: 'CompoundIntervals',
        drill: true,
        skills: ['simple_and_compound_intervals'],
        maxQuestions: 15,
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'generic_intervals_video_4',
      title: 'Conclusion',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'genericIntervals4',
      },
    },
  ],
};
