import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Sharp Major Key Signatures
 */
export const lesson: Lesson = {
  id: 'sharp_major_keys',
  areaId: 'pitch_and_harmony',
  order: 17,
  title: 'Sharp Major Keys',
  skills: ['order_sharps', 'treble_order_sharps', 'bass_order_sharps', 'sharp_majors', 'c_major'],
  pages: [
    {
      id: 'sharp_major_keys_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Sharp Major Keys</h3>
        <p>In this lesson we'll learn:
        <ul><li>What key signatures are</li>
        <li>How to recognize major keys with sharps</li>
        <li>The order of sharps, and</li>
        <li>How to write major keys with sharps.</li></ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: Key Signatures Intro
     */
    {
      id: 'sharp_major_keys_video_1',
      order: 2,
      title: 'Key Signatures Intro',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'sharpMajorKeys1',
      },
    },
    /**
     * Exercise: Play notes within a key signature
     */
    {
      id: 'sharp_major_keys_play_in_key',
      title: 'Playing in Key Signatures',
      order: 3,
      interactive: {
        type: 'NoteReadingPiano',
        clef: 'treble',
        questions: ['D:F#4/G4/C#4/D', 'Eb:Eb/G/Bb/Ab/G', 'B:F#/E/D#/G#/B3', 'Cb:Cb5/Bb/Gb/Fb/Eb'],
        autoadvance: true,
      },
    },
    /**
     * Video: Accidentals in Keys
     *
     * What if we're in G Major and we see a # in front of F in a measure???
     */
    {
      id: 'sharp_major_keys',
      order: 4,
      title: 'Accidentals in Keys',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'sharpMajorKeys2',
      },
    },
    /**
     * Exercise: Play music w/ accidentals and keys
     */
    {
      id: 'sharp_major_keys_play_and_write',
      order: 5,
      title: 'Play Accidentals in Keys',
      interactive: {
        type: 'NoteReadingPiano',
        clef: 'bass',
        autoadvance: true,
        questions: [
          'Ab:C3/Eb3/D3/Bb2/Eb3',
          'E:E3/G#3/C#/C/B3/D#3/E3',
          'Bb:Bb3/C/D4/F#3/G3/E3/F3',
          'F#:F#2/Gx2/A#2/C#3/D3/E#3/F#3',
        ],
      },
    },
    /**
     * Video: Rule for Sharp Major keys
     *
     * If the last sharp is __ what key are we in?
     */
    {
      id: 'sharp_major_keys_video_3',
      title: 'Rule for Sharp Keys',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'sharpMajorKeys3',
      },
    },
    /**
     * Exercise: If the last sharp is __, the key is?
     */
    {
      id: 'sharp_major_keys_from_last_sharp',
      title: 'Name key from last sharp',
      order: 7,
      interactive: {
        type: 'KeyByLastSharpOrFlat',
        questions: ['E/M', 'A/M', 'C#/M', 'G/M'],
      },
    },
    /**
     * Video: ID Sharp major Keys by looking at key sig
     */
    {
      id: 'sharp_major_keys_video_4',
      title: 'Identifying Sharp Keys',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'sharpMajorKeys4',
      },
    },
    /**
     * Exercise: ID Sharp major Keys by looking at key sig
     */
    {
      id: 'sharp_major_keys_from_signature',
      title: 'Sharp Majors ID',
      order: 9,
      interactive: {
        type: 'KeySigID',
        questions: ['B/M', 'F#/M', 'D/M', 'C#/M'],
      },
    },
    /**
     * Video: Finding the Last Sharp from the key name
     */
    {
      id: 'sharp_major_keys_video_5',
      title: 'Finding the Last Sharp',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'sharpMajorKeys5',
      },
    },
    /**
     * Exercise: Name the Last Sharp from the key name
     */
    {
      id: 'sharp_major_keys_find_last_sharp',
      title: 'Name the Last Sharp',
      order: 11,
      interactive: {
        type: 'LastSharpOrFlatInKey',
        questions: ['A/M', 'F#/M', 'E/M', 'D/M'],
      },
    },
    /**
     * Video: Order of Sharps in Letters
     */
    {
      id: 'sharp_major_keys_video_6',
      title: 'Order of Sharps',
      order: 12,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'sharpMajorKeys6',
      },
    },
    /**
     * Exercise: Spell Order of Sharps in Letters
     */
    {
      id: 'sharp_major_keys_order_sharps',
      title: 'Write the Order of Sharps',
      order: 13,
      interactive: {
        type: 'OrderOfSharpsOrFlats',
        questions: ['sharps', 'sharps', 'sharps', 'sharps', 'sharps'],
        staff: false,
      },
    },
    /**
     * Video: Order of Sharps on the Staff
     */
    {
      id: 'sharp_major_keys_video_7',
      title: 'Order of Sharps on the Staff',
      order: 14,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'sharpMajorKeys7',
      },
    },
    /**
     * Exercise: Write Order of Sharps on the Staff
     */
    {
      id: 'sharp_major_keys_order_sharps_staff',
      title: 'Draw the Order of Sharps',
      order: 15,
      interactive: {
        type: 'OrderOfSharpsOrFlats',
        questions: ['sharps', 'sharps', 'sharps', 'sharps'],
        staff: true,
      },
    },
    /**
     * Video: Writing Sharp Major Key Signatures
     */
    {
      id: 'sharp_major_keys_video_8',
      title: 'Writing Sharp Key Signatures',
      order: 16,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'sharpMajorKeys8',
      },
    },
    /**
     * Exercise: Write Sharp Major Key Signatures
     */
    {
      id: 'sharp_major_keys_writing',
      title: 'Draw Sharp Keys',
      order: 17,
      interactive: {
        type: 'KeySigConstruction',
        questions: [
          'B/M/treble',
          'G/M/treble',
          'D/M/bass',
          'C/M/bass',
          'F#/M/treble',
          'E/M/bass',
          'C#/M/bass',
          'A/M/treble',
        ],
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'sharp_major_keys_video_10',
      title: 'Conclusion',
      order: 18,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'sharpMajorKeys10',
      },
    },
  ],
};
