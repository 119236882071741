import { observable, computed, action, makeObservable } from 'mobx';
import type {
  CheckpointResults,
  ScoreText,
  SectionStats,
} from '../../../definitions/checkpoint-definitions';
import { isCheckpoint } from '../../../definitions/checkpoint-definitions';
import type { CheckpointRequirements } from '../../../definitions/section-definitions';
import type { CheckpointSectionDatum } from '../../../definitions/user-object-definitions';
import { isCheckpointPassed, resultsAreFromCustomCheckpoint } from './tests.utils';
import type { AbstractUserStore } from '../abstract.user.store';
import { CustomTestStore } from './custom.test.store';
import type { UserSkillStore } from '../userKnowledge/user.skill.store';
import type { UserLessonOrCheckpointStore } from '../userLessons/user.lessonOrCheckpoint.store';
import { getGradePenalty } from '../userLessons/user.utils';
import { dateString, dateTimeString } from '../../utils/date.utils';

export type Results =
  | CheckpointSectionDatum
  | CheckpointResults
  | Omit<CheckpointResults, 'questions'>;

const defaultSkillsTargetPercent = 80;

export type TestAttemptSectionStats = Omit<SectionStats, 'completesSkills' | 'completesLessons'> & {
  scoreText: string | false;
  areSkillsPassed: boolean;
  requiredPassingPercent: number | false;
  targetSkillsPercent: number;
  title: string;
  disabled: boolean;
  completesSkills: UserSkillStore[];
  completesLessons: UserLessonOrCheckpointStore[];
  isPassed: boolean;
};

export class TestAttemptStore {
  id: string;

  checkpointId: string;

  @observable record: CheckpointSectionDatum | CheckpointResults;

  customCheckpoint: boolean;

  @observable questionsLoaded!: false | true | 'loading' | 'errored';

  @observable root!: AbstractUserStore;

  @computed get score() {
    return Math.max(0, this.record.overall.percentage - this.gradePenalty);
  }

  @computed get scoreWithoutGradePenalty() {
    return this.record.overall.percentage;
  }

  @computed get sectionCheckpointSettings(): CheckpointRequirements {
    return this.root.currentSection.options.lessons.checkpoints;
  }

  /**
   * Section checkpoint settings apply if, and only if, this is a checkpoint
   * in the list of lessons.
   */
  @computed get doSectionCheckpointSettingsApply(): boolean {
    return this.isInLessonsList;
  }

  @computed get dateCompleted(): Date {
    return new Date(this.record.updatedAt);
  }

  @computed get dueDate(): Date | undefined {
    if (!this.isInLessonsList) return this.model?.dueDate || undefined;

    const sectionLesson = this.root.currentSection.lessons.lessons.find(
      (l) => l.id === this.checkpointId
    );
    if (!sectionLesson) return undefined;
    if (!sectionLesson.isRequired) return undefined;
    if (!this.root.currentSection.options.deadlines.visible) return undefined;
    if (!sectionLesson.dueDate) return undefined;
    return sectionLesson.dueDate;
  }

  @computed get gradesRow(): (number | string | Date | boolean | null)[] {
    const root = this.root;
    if (!root) {
      throw Error('Can only get gradesRow for a custom test store attached to a user.');
    }
    if (!this.model) {
      throw Error('Can only get gradesRow for a test attempt store with a model.');
    }
    const attempts = root.testAttemptsByTestId[this.model.id]?.length || 0;

    const columns = this.model?.gradeColumnInformation;
    return columns.map((column) => {
      switch (column.type) {
        case 'userInfo':
          return root.gradesRowUserInfo[column.id];
        case 'overall':
          if (column.id === 'date')
            return this.record.updatedAt ? new Date(this.record.updatedAt) : null;
          if (column.id === 'overall') {
            return typeof this.score === 'number' ? this.score : null;
          }
          if (column.id === 'passed') {
            return this.isPassed;
          }
          if (column.id === 'attempts') {
            return attempts;
          }
          if (column.id === 'attemptId') {
            return this.id;
          }
          break;
        case 'section':
          return this.sections[column.id as number]?.percentage ?? null;
      }
      return null;
    });
  }

  /** Currently only applies to checkpoints, not custom tests */
  @computed get isLate(): boolean {
    if (!this.isInLessonsList || !this.dueDate) return false;
    return this.dueDate.valueOf() <= this.dateCompleted.valueOf();
  }

  @computed get gradePenalty(): number {
    // If it's not late, no penalty.
    if (!this.isLate) return 0;

    return getGradePenalty(
      this.dueDate,
      this.dateCompleted,
      this.root.currentSection.options.deadlines.penalty
    );
  }

  /**
   * Does this checkpoint appear in the lessons list?
   */
  @computed get isInLessonsList(): boolean {
    return this.root.currentSection.lessons.lessons.some((l) => l.id === this.checkpointId);
  }

  @computed private get checkpointTest() {
    const model = this.model;
    if (model instanceof CustomTestStore) {
      return model.checkpointTest;
    }

    if (isCheckpoint(model?.definition)) {
      return model?.definition;
    }

    return undefined;
  }

  @computed get model() {
    const lessonCpModel = this.root.currentSection.lessons.lessons.find(
      (l) => l.id === this.checkpointId
    );

    if (lessonCpModel) return lessonCpModel;

    const sectionCpModel = this.root.allSections.find((s) => s.testsById[this.checkpointId])
      ?.testsById[this.checkpointId];

    if (sectionCpModel) return sectionCpModel;

    const userCpModel = this.root.testsById[this.checkpointId];

    if (userCpModel) return userCpModel;
    return undefined;
  }

  @computed get passingPercent(): number | false {
    // If this is a custom checkpoint, the passing requirements are stored on the
    // results -- so that even if the test definition changes, whether a student
    // has passed or not will not change.
    if (this.record.passing) {
      return this.record.passing.type ? this.record.passing.passingPercent : false;
    }

    const passingPercent = this.root.currentSection.options.requiredCheckpointPassingScore;
    return passingPercent ? passingPercent * (this.root.activeAccommodation.accuracy || 1) : false;
  }

  @computed get isPassed(): boolean {
    return isCheckpointPassed(
      this.record,
      this.doSectionCheckpointSettingsApply ? this.passingPercent : undefined,
      this.gradePenalty
    );
  }

  /**
   * A checkpoint is complete when a passing score, ignoring late penalties,
   * has been obtained.
   */
  @computed get isComplete(): boolean {
    return isCheckpointPassed(
      this.record,
      this.doSectionCheckpointSettingsApply ? this.passingPercent : undefined
    );
  }

  @computed get overallScoreText(): string {
    const model = this.model;
    if (!(model instanceof CustomTestStore)) return '';

    if (this.record.attemptStatus !== 'complete') return 'The test has not yet been completed.';
    const scoreLevels = model.checkpointTest.overallScoreText;
    if (scoreLevels && scoreLevels.length) {
      return getDiagnosticSpecificScoreText(
        this.record.overall.percentage,
        scoreLevels,
        model.title,
        this.passingPercent
      );
    }
    return getGenericScoreText(this);
  }

  @computed get retakeText(): string {
    const model = this.model;
    if (!(model instanceof CustomTestStore)) return '';

    if (!model.hasAttemptsRemaining)
      return `Although you cannot retake the test,
        you may work on improving your skills on each section below.`;

    if (model.isPastDueDate) {
      return `
        The due date for this test has passed. Although you cannot retake the test,
        you may work on improving your skills on each section below.`;
    }

    const text: string[] = [];
    if (!this.isPassed && this.isRetakeReady) {
      text.push('You are ready to retake the test.');
    }
    if (!this.isRetakeReady) {
      text.push(`Complete the sections below that need more study or practice. Then,
        when you are ready, you may retake the test.`);
    }

    const dueDateText = this.dueDate
      ? ` before the ${dateString(this.dueDate)}, ${dateTimeString(this.dueDate, {
          hour: 'numeric',
          minute: '2-digit',
        })} deadline`
      : '';

    text.push(`You have ${model.remainingAttemptsString} remaining${dueDateText}. Your 
      ${model.definition.attemptToUse === 'mostRecent' ? 'most recent' : 'highest'} score
      will replace any previous attempts.`);

    return text.join(' ');
  }

  @computed get sections(): TestAttemptSectionStats[] {
    return this.record.sections.map((s, i) => {
      const requiredPassingPercent = this.getSectionPassingPercent(i);
      return {
        ...s,
        scoreText: this.getSectionScoreText(i, requiredPassingPercent),
        areSkillsPassed: this.areTestSectionSkillsPassed(i),
        requiredPassingPercent,
        targetSkillsPercent: this.getSectionTargetSkillsPercent(i),
        title: this.getSectionName(i),
        disabled: this.getSectionIsDisabled(i),
        completesSkills: this.getSectionSkills(i),
        completesLessons: this.getSectionLessons(i),
        isPassed: requiredPassingPercent
          ? Math.round(s.percentage) >= Math.round(requiredPassingPercent)
          : true,
      };
    });
  }

  private getSectionIsDisabled(index: number): boolean {
    const model = this.model;
    if (!(model instanceof CustomTestStore)) return false;

    return !!model.checkpointTest.sections[index]?.options.disabled;
  }

  private getSectionName(index: number) {
    const model = this.model;
    if (model instanceof CustomTestStore) {
      return model.checkpointTest.sections[index]?.options.name || `Section ${index}`;
    }

    if (isCheckpoint(model?.definition)) {
      return model?.definition.sections[index]?.options.name || `Section ${index}`;
    }

    // This should never happen.
    return 'Section';
  }

  @computed get isRetakeReady(): boolean {
    return this.sections.every((s) => s.areSkillsPassed);
  }

  private getSectionPassingPercent(index: number): number | false {
    const sectionPassingRequired =
      this.record.passing?.type === 'both' || this.record.passing?.type === 'sections';
    if (!sectionPassingRequired) return false;
    return (
      this.record.sections[index]?.passingPercent || this.record.passing?.passingPercent || false
    );
  }

  private getSectionSkills(index: number): UserSkillStore[] {
    const cpModel = this.checkpointTest;
    const skillIds =
      cpModel?.sections[index]?.options.completesSkills ||
      this.record.sections[index]?.completesSkills ||
      [];

    return skillIds
      .map((id) => this.root.knowledgeProgress.skillsById[id])
      .filter((truthy): truthy is UserSkillStore => !!truthy);
  }

  private getSectionLessons(index: number): UserLessonOrCheckpointStore[] {
    const cpModel = this.checkpointTest;
    const skillIds =
      cpModel?.sections[index]?.options.completesSkills ||
      this.record.sections[index]?.completesSkills ||
      [];
    return this.root.lessonProgress.getLessonsBySkills(skillIds);
  }

  private areTestSectionSkillsPassed(index: number): boolean {
    if (this.record.attemptStatus !== 'complete') return false;
    const model = this.model;
    if (!(model instanceof CustomTestStore)) return true;

    const targetPercent = this.getSectionTargetSkillsPercent(index);

    return this.getSectionSkills(index).every((skill) => skill.score >= targetPercent);
  }

  private getSectionScoreText(
    index: number,
    requiredPassingPercent: number | false
  ): string | false {
    if (this.record.attemptStatus !== 'complete') return false;
    const model = this.model;
    if (!(model instanceof CustomTestStore)) return false;

    if (model.definition.sectionScoreText) {
      const section = this.record.sections[index];

      if (!section) return '';
      return getSectionScoreText(
        section.percentage,
        model.definition.sectionScoreText as ScoreText[],
        model.checkpointTest.sections[index]?.options.name || `Section ${index}`,
        requiredPassingPercent
      );
    }
    return false;
  }

  private getSectionTargetSkillsPercent(index: number): number {
    const model = this.model;
    if (!(model instanceof CustomTestStore)) return defaultSkillsTargetPercent;

    return (
      this.getSectionPassingPercent(index) ||
      this.record.sections[index]?.passingPercent ||
      this.record.passing?.passingPercent ||
      model.definition.sections[index]?.options.passingPercent ||
      model.definition.passing?.passingPercent ||
      defaultSkillsTargetPercent
    );
  }

  constructor(attempt: Results, root: AbstractUserStore) {
    makeObservable(this);
    this.id = attempt.id;
    this.checkpointId = attempt.checkpointId;
    this.record = attempt;
    this.customCheckpoint = resultsAreFromCustomCheckpoint(attempt);
    this.init(attempt, root);
  }

  @action init(attempt: Results, root: AbstractUserStore) {
    this.root = root;
    this.update(attempt);
  }

  @action update(attempt: Results) {
    this.record = attempt;
    this.questionsLoaded = attempt.hasOwnProperty('questions');
  }
}

function getGenericScoreText(attempt: TestAttemptStore) {
  if (!attempt) return '';
  const overallScore = Math.round(attempt.score);

  // If it's not graded on passing, give rather generic text:
  if (!attempt.record.passing || !attempt.record.passing.type) {
    return `
      You've completed the ${attempt.model?.title} test with a
      score of <strong>${overallScore}%</strong>. You may wish
      to improve on individual sections with more practice, below.
    `;
  }

  if (attempt.isPassed) {
    return `
      <span class="text-green">Congratulations!</span>
      
      You've passed the ${attempt.model?.title} test with a
      score of ${overallScore}%. You may wish
      to improve on individual sections with more practice, below.
    `;
  }

  const sorry = '<span class="text-red">Sorry.</span>';
  const passingInfo =
    attempt.record.passing?.type === 'overall'
      ? `You scored ${overallScore}% on this test, but a score of
      ${attempt.record.passing?.passingPercent}% is required
      to pass.`
      : `You did not pass some of the required sections (see below).
      Your overall score was ${overallScore}%.`;

  return `${sorry} ${passingInfo}`;
}

/**
 * If a test has .overallScoreText, we need test-specific feedback:
 * @param results - Test results
 * @param  scoreLevels - array of \{ lowerPercent: number, text: string \}[]
 * Handlebars variables available:
 * \{score\} \{passingPercent\} \{title\} \{red\}\{/red\} \{green\}\{/green\}
 */
function getDiagnosticSpecificScoreText(
  score: number,
  scoreLevels: ScoreText[],
  title: string,
  passingPercent: number | false
): string {
  if (!scoreLevels) throw Error('ScoreLevels required to get diagnostic specific score text.');
  const text = getSpecificText(scoreLevels, score, title, passingPercent);
  return text;
}

function getSectionScoreText(
  score: number,
  scoreLevels: ScoreText[],
  title: string,
  passingPercent: number | false
) {
  return getSpecificText(scoreLevels, score, title, passingPercent);
}

/**
 * Get specific score feedback text for a complete test or section of a test.
 * @param  scoreLevels - array of { lowerPercent: number, text: string }[]
 * Handlebars variables available:
 * \{score\} \{passingPercent\} \{title\} \{red\}\{/red\} \{green\}\{/green\}
 * @param percent - number 0-100
 */
function getSpecificText(
  scoreLevels: ScoreText[],
  percent: number,
  title: string,
  passingPercent: number | false
) {
  const score = Math.round(percent);
  const text: string | undefined = scoreLevels
    .concat([])
    .sort((a, b) => b.lowerPercent - a.lowerPercent)
    .find(({ lowerPercent }) => score >= lowerPercent)?.text;
  if (!text) {
    console.error('Invalid lower bound of ScoreText @custom.test.store getSpecificText');
    return '';
  }
  return replaceHandlebars(text, score, title, passingPercent);
}

function replaceHandlebars(
  text: string,
  score: number,
  title: string,
  passingPercent: number | false
) {
  // {score} {passingPercent} {title} {red}{/red} {green}{/green}
  const variables = {
    score,
    passingPercent: passingPercent || 0,
    title,
    red: '<span class="uRed">',
    '/red': '</span>',
    green: '<span class="uGreen">',
    '/green': '</span>',
  };

  return Object.keys(variables).reduce((accum, key) => {
    const value: string | number = (variables as any)[key];
    const re = new RegExp(`{${key}}`, 'g');
    return accum.replace(re, `${value}`);
  }, text);
}
