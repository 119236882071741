import type { LessonCheckpointTest } from '../../../definitions/checkpoint-definitions';

export const checkpoint: LessonCheckpointTest = {
  id: 'checkpoint_2',
  areaId: 'pitch_and_harmony',
  checkpoint: true,
  order: 19,
  title: 'Major scales and keys',
  options: {
    name: 'Major scales and keys',
    instructions:
      '<p>This checkpoint tests your mastery of major scales and keys.</p>\n<p>\n  <strong>All of your answers are final</strong>. You will not be able to go back to change answers\n  on previous questions.</p><p>You have up to {{totalTime}} to complete this checkpoint. Please be sure you\n  have enough time before beginning the checkpoint.\n</p>\n<p>\n  {{passingSentence}}\n</p>\n',
  },
  sections: [
    {
      options: {
        instructions:
          '<p>Section {{sectionNumber}}: {{sectionName}}<p>\n<p>In this section you will play and write {{sectionQuestions}} Major scales. You will have a maximum of {{sectionTime}} for this section,\nor roughly {{secondsPerQuestion}} seconds per scale, and any unanswered questions will be counted wrong.</p>\n',
        name: 'Major scales',
        timeLimit: '9:00',
        sortOrder: 'type',
        weight: 33,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'ScalePlay',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
            {
              questionType: 'ScaleWrite',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 3,
              questions: [
                'G/Major/asc/bass',
                'D/Major/asc/treble',
                'A/Major/dsc/bass',
                'E/Major/dsc/treble',
              ],
            },
            {
              numberOfQuestions: 3,
              questions: [
                'C/Major/dsc/treble',
                'F/Major/asc/treble',
                'Bb/Major/dsc/bass',
                'Eb/Major/asc/bass',
                'Ab/Major/dsc/treble',
              ],
            },
            {
              numberOfQuestions: 2,
              questions: ['B/Major/asc/bass', 'F#/Major/dsc/bass', 'C#/Major/dsc/treble'],
            },
            {
              numberOfQuestions: 2,
              questions: ['Db/Major/asc/treble', 'Gb/Major/asc/bass', 'Cb/Major/asc/bass'],
            },
          ],
        },
      ],
    },
    {
      options: {
        instructions:
          '<p>Section {{sectionNumber}}: {{sectionName}}<p>\n<p>\n  You will have {{sectionTime}}, roughly {{secondsPerQuestion}}-seconds per question,\n  to write and identify {{sectionQuestions}} Major keys.\n  Any unanswered questions will be counted wrong.\n</p>\n',
        name: 'Write & ID Major Keys',
        timeLimit: '6:00',
        sortOrder: 'type',
        weight: 33,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'KeySigConstruction',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {},
            },
            {
              questionType: 'KeySigID',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {},
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 5,
              questions: [
                'Cb/Major/bass',
                'Gb/Major/treble',
                'Db/Major/bass',
                'Ab/Major/treble',
                'Eb/Major/bass',
                'Bb/Major/treble',
                'F/Major/bass',
              ],
            },
            {
              numberOfQuestions: 5,
              questions: [
                'C#/Major/treble',
                'F#/Major/bass',
                'B/Major/treble',
                'E/Major/bass',
                'A/Major/treble',
                'D/Major/bass',
                'G/Major/treble',
                'C/Major/bass',
              ],
            },
          ],
        },
      ],
    },
    {
      options: {
        instructions:
          '<p>Section {{sectionNumber}}: {{sectionName}}<p>\n<p>In this speed section, you will answer {{sectionQuestions}} questions about Major key signatures.\nYou will have {{sectionTime}}, or {{secondsPerQuestion}} seconds per question.</p>\n',
        name: 'Key Signature Facts',
        timeLimit: '2:30',
        sortOrder: 'random',
        weight: 34,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'LastSharpOrFlatInKey',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
            {
              questionType: 'KeyByLastSharpOrFlat',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
            {
              questionType: 'NumberOfSharpsOrFlats',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 14,
              questions: [
                'Cb/Major',
                'Gb/Major',
                'Db/Major',
                'Ab/Major',
                'Eb/Major',
                'Bb/Major',
                'F/Major',
                'C#/Major',
                'F#/Major',
                'B/Major',
                'E/Major',
                'A/Major',
                'D/Major',
                'G/Major',
              ],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'NumberOfSharpsOrFlats',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: ['C/Major'],
            },
          ],
        },
      ],
    },
  ],
};
