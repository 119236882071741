import type { Lesson } from '../../../../definitions/content-definitions';

/**
 * # Octave Numbers
 *
 * ## Outcomes
 *
 * Students will be able to:
 *
 * - Identify Middle C on the keyboard
 * - Write Middle C on the treble & bass clef staffs
 * - Play notes in specific octaves on the piano, given Middle C.
 * - Write notes in specific octaves on the treble & bass clef staves.
 *
 */
export const lesson: Lesson = {
  id: 'octave_numbers',
  areaId: 'pitch_and_harmony',
  order: 9,
  title: 'Octave Numbers',
  skills: ['treble_clef_octave_numbers', 'bass_clef_octave_numbers'],
  pages: [
    /**
     * The C closest to the middle of the piano is Middle C.
     *
     * Middle C is the fourth C on the piano, counting from the left, and so we
     * can also call it C4.
     *
     * Middle C is right in the middle of the human singable range, comfortably
     * low for treble voices, and comfortably high for bass voices.
     *
     * In treble clef, Middle C is written one leger line below the staff.
     *
     * In bass clef, Middle C is written one leger line above the staff.
     *
     * Let's get some practice writing Middle C, or C4.
     */
    {
      id: 'octave_numbers_intro',
      order: 1,
      title: 'Introduction',
      content: `<h3>Octave Numbers</h3>
        <p>In this lesson we'll learn:
          <ul>
            <li>About Middle C,</li>
            <li>Where it's written on the treble and bass clefs, and</li>
            <li>How we use numbers to specify the octave of a note.</li>
          </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * The C closest to the middle of the piano is Middle C.
     *
     * Middle C is the fourth C on the piano, counting from the left, and so we
     * can also call it C4.
     *
     * Middle C is right in the middle of the human singable range, comfortably
     * low for treble voices, and comfortably high for bass voices.
     *
     * In treble clef, Middle C is written one leger line below the staff.
     *
     * In bass clef, Middle C is written one leger line above the staff.
     *
     * Let's get some practice writing Middle C, or C4.
     */
    {
      id: 'octave_numbers_middle_c',
      order: 2,
      title: 'Middle C',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'octave_numbers_middle_c',
      },
    },
    {
      id: 'octave_numbers_write_middle_c',
      title: 'Write Middle C',
      order: 3,
      interactive: {
        type: 'NoteDraw',
        questions: ['C4/treble', 'C4/bass', 'C4/treble', 'C4/bass'],
        ignoreOctave: false,
        questionPrompt: 'Write Middle C',
        autoadvance: true,
      },
    },
    /**
     * Middle C is also known as C4, because it is the 4th C on a full piano
     * keyboard.
     *
     * The C an octave above middle C is C5, and an octave above that is C6.
     *
     * The C an octave below middle C is C3, and an octave below that is C2.
     *
     * Let's get some practice writing different octaves of C on the treble and
     * bass clef staves.
     */
    {
      id: 'octave_numbers_c_octaves',
      order: 4,
      title: 'C Octave Numbers',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'octave_numbers_c_octaves',
      },
    },
    {
      id: 'octave_numbers_write_various_octaves_of_c',
      title: 'Write Cs',
      order: 5,
      interactive: {
        type: 'NoteDraw',
        ignoreOctave: false,
        questions: [
          'C4/treble',
          'C5/treble',
          'C6/treble',
          'C4/bass',
          'C3/bass',
          'C2/bass',
          'C5/treble',
          'C3/bass',
          'C6/treble',
          'C2/bass',
        ],
        autoadvance: true,
      },
    },
    /**
     *  We can use the octave number system for all notes, not just C, to
     *  specify which octave a note is in.
     *  All of the notes in the octave above a C have the same number as that C. Our octaves begin from C.
     *
     *  For instance:
     *
     *  This is C4 -- all the notes above it, until we get to the next C -- also have 4 as their number.
     *  So C4, a step above that is D4, E4, F4, G4, A4 -- notice, still 4, not 5! -- and B4.
     *
     *  When we get back to C, we begin a new octave, so this is C5.
     *
     *  What is one note below C4? It's B3!
     *
     *  Let's get some practice playing and writing notes in specific octaves.
     */
    {
      id: 'octave_numbers_all_notes',
      order: 6,
      title: 'Numbers for All Notes',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'octave_numbers_all_notes',
      },
    },
    {
      id: 'octave_numbers_play_notes_in_octaves',
      title: 'Play Notes in Octaves',
      order: 7,
      interactive: {
        type: 'SingleNotePlay',
        autoadvance: true,
        questions: ['F4', 'D4', 'Bb3', 'D4', 'F4', 'Bb4', 'D5', 'C5', 'Bb4', 'D4', 'E4', 'F4'],
        ignoreOctave: false,
        lowestNote: 'A3',
        highestNote: 'E5',
      },
    },
    {
      id: 'octave_numbers_write_notes_in_octaves',
      title: 'Write Notes in Octaves',
      order: 8,
      interactive: {
        type: 'NoteDraw',
        ignoreOctave: false,
        questions: [
          'E2/bass',
          'A2/bass',
          'C3/bass',
          'E3/bass',
          'A3/bass',
          'C4/bass',
          'B3/bass',
          'C4/treble',
          'E4/treble',
          'G4/treble',
          'G4/treble',
          'G5/treble',
          'G5/treble',
          'E5/treble',
          'E5/treble',
        ],
        autoadvance: true,
      },
    },
    /**
     *  This is C4, middle C. Where do you think Cb4 would be?
     *
     *  It's just below it -- even though that note appears to be in the 3
     *  octave on the piano. When we number octaves, we ignore the accidental
     *  attached to a note.
     *
     *  So, here's C4, middle C, again. Where would Bx3 be?
     *
     *  Start with B3 -- ignore the accidental -- then count up two half steps
     *  for the double sharp, and we see that Bx3 is actually a semi-tone higher
     *  than C4 on the piano.
     *
     *  Let's practice playing notes with accidentals in various octaves on the
     *  piano.
     */
    {
      id: 'octave_numbers_accidentals',
      order: 9,
      title: 'Accidentals & Octave Numbers',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'octave_numbers_accidentals',
      },
    },
    {
      id: 'octave_numbers_accidentals_play',
      title: 'Play Accidentals',
      order: 10,
      interactive: {
        type: 'SingleNotePlay',
        autoadvance: true,
        questions: ['B#4', 'Cbb4', 'Bx3', 'Dbb5'],
        ignoreOctave: false,
        lowestNote: 'A3',
        highestNote: 'E5',
      },
    },
    {
      id: 'octave_numbers_summary',
      order: 11,
      title: 'Conclusion',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'octave_numbers_summary',
      },
    },
  ],
};
