import { action, computed, observable, makeObservable, runInAction } from 'mobx';
import type {
  Accommodation,
  StudentUserRecord,
  UserPreferences,
  UserWithJoins,
  UserRole,
} from '../../definitions/user-object-definitions';
import { isUserWithJoins } from '../../definitions/user-object-definitions';
import type SectionStore from '../section/section.store';
import UserKnowledgeStore from './userKnowledge/user.knowledge.store';
import { UserLessonsStore } from './userLessons/user.lessons.store';
import { TestAttemptStore } from './tests/checkpoint.attempt.store';
import { getNoon, dateString } from '../utils/date.utils';
import type { CustomTestStore } from './tests/custom.test.store';
import { getAttemptToUse } from './tests/tests.utils';
import { reduceAccommodations } from './reduceAddommodation';
import type { GroupedCollection } from '../utils/groupByReducers';
import { groupByAsDictionaryReducer } from '../utils/groupByReducers';
import type { OAuthLoginCredential } from '../../definitions/login-credential-definitions';

export interface GradesRowUserInfo {
  firstName: string | null;
  lastName: string | null;
  fullName: string;
  userId: string;
  userIdAndLastActiveAt: string;
}

export abstract class AbstractUserStore {
  @observable id!: string;

  @observable.shallow roles!: UserRole[];

  constructor() {
    makeObservable(this);
  }

  @action setRoles(roles: UserRole[]) {
    this.roles = roles;
  }

  @observable credentials!: UserWithJoins['credentials'];

  @computed get username(): string | undefined {
    const localCredential = this.credentials.find((c) => c.type === 'local');
    return localCredential?.type === 'local' ? localCredential.username : undefined;
  }

  @computed get displayUsername(): string | undefined {
    const localCredential = this.credentials.find((c) => c.type === 'local');
    return localCredential?.type === 'local'
      ? localCredential.displayUsername || localCredential.username
      : undefined;
  }

  @computed get isTeacher(): boolean {
    return this.roles.includes('teacher');
  }

  @computed get isInstitution(): boolean {
    return this.roles.includes('institution');
  }

  @observable firstName!: string | null;

  @action setFirstName(firstName: string) {
    this.firstName = firstName;
  }

  @observable lastName!: string | null;

  @action setLastName(lastName: string) {
    this.lastName = lastName;
  }

  /**
   * Full name with first name first
   */
  @computed get fullName(): string {
    return [this.firstName, this.lastName].join(' ').trim();
  }

  /**
   * Full name with last name first
   */
  @computed get fullNameLastNameFirst(): string {
    return [this.lastName, this.firstName]
      .filter((n) => n)
      .join(', ')
      .trim();
  }

  @computed get initials(): string {
    const firstInitial = this.firstName?.[0]?.toUpperCase() || '';
    const lastInitial = this.lastName?.[0]?.toUpperCase() || '';
    return `${firstInitial}${lastInitial}` || '?';
  }

  @observable email?: string | null;

  @action setEmail(email: string) {
    this.email = email;
  }

  @observable avatarUrl?: string | null;

  @action setAvatarUrl(url: string) {
    this.avatarUrl = url;
  }

  @computed get type(): string | 'local' | 'anonymous' {
    if (!this.credentials || !this.credentials.length) return 'anonymous';
    const isLocal = this.credentials.find((credential) => credential.type === 'local');
    if (isLocal) return 'local';
    return this.credentials[0]!.type === 'oAuth'
      ? (this.credentials[0] as Pick<
          OAuthLoginCredential,
          'type' | 'oAuthProviderId' | 'isClassroomConnected'
        >)!.oAuthProviderId
      : 'local';
  }

  @observable demoUser?: boolean;

  @observable lastLoginAt!: Date | null;

  @observable lastActiveAt!: Date | null;

  /**
   * The sections that a user is enrolled in.
   */
  @computed get currentSections(): SectionStore[] {
    return this.allSections.filter((s) => this.sectionIds.includes(s.id));
  }

  /**
   * The sections that a user owns.
   */
  @computed get ownedSections(): SectionStore[] {
    return this.allSections.filter((s) => s.ownerIds.includes(this.id));
  }

  /**
   * Sections that a user owns, teachers, or TAs for.
   */
  @computed get taughtSections(): SectionStore[] {
    return this.allSections
      .filter(
        (s) =>
          s.teacherIds.includes(this.id) ||
          s.ownerIds.includes(this.id) ||
          s.teachingAssistantIds.includes(this.id)
      )
      .sort((a, b) => a.sectionName.toLowerCase().localeCompare(b.sectionName.toLowerCase()));
  }

  /**
   * Sections that a user is either enrolled in or owns
   */
  @observable.shallow allSections!: SectionStore[];

  @action addSection(section: SectionStore) {
    if (this.allSections.some((s) => s.id === section.id)) return false;
    this.allSections.push(section);
    return true;
  }

  @action removeSection(sectionOrId: SectionStore | string) {
    const id = typeof sectionOrId === 'string' ? sectionOrId : sectionOrId.id;
    const index = this.allSections.findIndex((s) => s.id === id);
    if (index === -1) return false;
    this.allSections.splice(index, 1);
    return true;
  }

  /**
   * The sections that a user is enrolled in as a map { [id: string]: SectionStore }
   */
  @computed get currentSectionsById(): { [id: string]: SectionStore } {
    return this.currentSections.reduce(
      (dictionary: { [id: string]: SectionStore }, section: SectionStore) => {
        dictionary[section.id] = section;
        return dictionary;
      },
      {}
    );
  }

  /**
   * The single section a user is currently viewing/enrolled in
   */
  @observable sectionId!: string;

  /**
   * All the section ids a user is currently enrolled in.
   */
  @computed get sectionIds(): string[] {
    return this.allSections
      .filter((s) => !s.isOwner && !s.isTeacher && !s.isTeachingAssistant)
      .map((s) => s.id);
  }

  /**
   * The single section a user is currently viewing/enrolled in
   */
  @computed get currentSection(): SectionStore {
    const matchedSection = this.allSections.find((s) => s.id === this.sectionId);
    if (matchedSection) return matchedSection;
    // this should never happen
    action(() => {
      this.sectionId = this.currentSections[0]!.id;
    });
    return this.currentSections[0]!;
  }

  changeSection(sectionStoreOrId: SectionStore | string) {
    runInAction(() => {
      if (typeof sectionStoreOrId === 'string') {
        this.sectionId = sectionStoreOrId;
      } else {
        this.sectionId = sectionStoreOrId.id;
      }
    });
  }

  abstract tests: CustomTestStore[];

  abstract ownedTests: CustomTestStore[];

  abstract testIds: string[];

  abstract testsById: { [id: string]: CustomTestStore };

  @observable uPointsRecords!: {
    dailyPoints: number;
    date: string | Date;
    points: number;
    totalTime: number;
    dateString: string;
  }[];

  @computed get uPoints(): number {
    return this.uPointsRecords.slice(-1)[0]?.points || 0;
  }

  @computed get totalActiveLearningTimeSeconds(): number {
    return this.uPointsRecords.reduce((accum, point) => accum + (point.totalTime / 1000 || 0), 0);
  }

  @computed get totalActiveLearningTimeValueAndDescriptor(): { value: number; descriptor: string } {
    const totalSecondsSpent = this.totalActiveLearningTimeSeconds;
    const thresholds = [
      [0, 'second', 1],
      [60, 'minute', 60],
      [60 * 60, 'hour', 60 * 60],
      [60 * 60 * 1000, 'day', 60 * 60 * 24],
      [60 * 60 * 24 * 1000, 'week', 60 * 60 * 24 * 7],
    ] as const;

    const matchedThreshold = [...thresholds]
      .reverse()
      .find(([minSec]) => minSec <= totalSecondsSpent);

    if (!matchedThreshold) return { value: totalSecondsSpent, descriptor: 'seconds' };

    const [, descriptor, divisor] = matchedThreshold;

    const roundedTime = Math.round((100 * totalSecondsSpent) / divisor) / 100;
    const s = roundedTime === 1 ? '' : 's';
    return { value: roundedTime, descriptor: `${descriptor}${s}` };
  }

  @observable accommodations!: Accommodation[];

  /**
   * All accommodations that apply to the currentSection, to the institutionId
   * of the currentSection, or to the user in general
   */
  @computed get sectionAccommodations(): Accommodation[] {
    return this.accommodations.filter(
      (a) =>
        (a.sectionId && a.sectionId === this.currentSection.id) ||
        (a.institutionId && a.institutionId === this.currentSection.paidById) ||
        true
    );
  }

  /**
   * The reduction of all active accommodations that should be applied for
   * calculation of scores. Yields the most permissive time and accuracy
   * requirements.
   */
  @computed get activeAccommodation(): Pick<Accommodation, 'accuracy' | 'time'> {
    return reduceAccommodations(this.sectionAccommodations);
  }

  @observable.shallow testAttempts!: TestAttemptStore[];

  @computed get testAttemptsByTestId(): { [testId: string]: TestAttemptStore[] } {
    return this.testAttempts.reduce(
      groupByAsDictionaryReducer('checkpointId'),
      {} as GroupedCollection<TestAttemptStore>
    );
  }

  @computed get testAttemptsToUseByTestId(): { [testId: string]: TestAttemptStore } {
    const attempts = this.testAttemptsByTestId;
    return Object.keys(attempts).reduce((accum: { [id: string]: TestAttemptStore }, id: string) => {
      accum[id] = getAttemptToUse(attempts[id]!) as TestAttemptStore;
      return accum;
    }, {});
  }

  @action addOrUpdateTestAttempt(attempt: TestAttemptStore) {
    const existingAttempt = this.testAttempts.find((ta) => ta.id === attempt.id);
    if (!existingAttempt) this.addTestAttempt(attempt);
    else existingAttempt.update(attempt.record);
  }

  @action addTestAttempt(attempt: TestAttemptStore) {
    this.testAttempts.push(attempt);
  }

  @action removeTestAttempt(attemptId: string): boolean {
    const index = this.testAttempts.findIndex((t) => t.id === attemptId);
    if (index === -1) return false;
    this.testAttempts.splice(index, 1);
    return true;
  }

  lessonProgress!: UserLessonsStore;

  knowledgeProgress!: UserKnowledgeStore;

  @computed get latesRow(): boolean[] {
    return this.currentSection.gradeColumnInformation.map((column): boolean => {
      if (column.id === 'overall') return false;

      if (column.type === 'lesson') {
        return this.lessonProgress.lessonsById[column.id]?.isLate || false;
      }
      if (column.type === 'checkpoint') {
        const cpStore = this.lessonProgress.lessonsById[column.id];
        if (cpStore?.type !== 'checkpoint') {
          throw Error(
            'Expected section.checkpointStore and instead got lessonStore in user get gradesRow'
          );
        }
        return cpStore.isLate;
      }
      return false;
    });
  }

  @computed get gradesRowUserInfo(): GradesRowUserInfo {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      fullName: this.fullNameLastNameFirst,
      userId: this.id,
      userIdAndLastActiveAt: `${this.id}${this.lastActiveAt?.valueOf()}`,
    };
  }

  @computed get gradesRow(): (number | string | null)[] {
    return this.currentSection.gradeColumnInformation.map((column): number | string | null => {
      if (column.type === 'userInfo') {
        return this.gradesRowUserInfo[column.id] || null;
      }

      if (column.type === 'overall') {
        // If it's not valid, it'll show 100%, which is fine.
        return this.gradeToDate;
      }
      if (column.type === 'upoints') {
        return this.uPoints;
      }
      if (column.type === 'lesson') {
        if (column.id === 'overall') {
          return this.lessonProgress.lessonsGradeToDate;
        }
        return this.lessonProgress.lessonsById[column.id]?.score || null;
      }
      if (column.type === 'checkpoint') {
        if (column.id === 'overall') {
          return this.lessonProgress.checkpointsGradeToDate;
        }
        const cpStore = this.lessonProgress.lessonsById[column.id];
        if (cpStore?.type !== 'checkpoint')
          throw Error(
            'Expected section.checkpointStore and instead got lessonStore in user get gradesRow'
          );
        return cpStore.attempts.length ? cpStore.score : null;
      }
      if (column.type === 'topic') {
        if (column.id === 'overall') {
          return this.knowledgeProgress.gradeToDate;
        }
        return this.knowledgeProgress.topicsById[column.id]?.score || null;
      }
      if (column.type === 'test') {
        const attemptToUse = this.testAttemptsToUseByTestId[column.id];
        if (!attemptToUse) return null;
        return attemptToUse.score;
      }
      throw Error(`Got column with unexpected type "${column.type}" in get user.gradesRow`);
    });
  }

  @computed get theme(): UserPreferences['theme'] {
    return this.personalPreferences?.theme || 'normal';
  }

  @observable personalPreferences?: UserPreferences;

  @computed get preferences(): Required<UserPreferences> {
    // const sectionSystems = this.currentSection.options.systems;
    const sectionSolfegeMethod =
      this.currentSection.options.systems.solfege.method || 'scaleDegrees';
    const sectionRhythmMethod = this.currentSection.options.systems.rhythm.method || 'american';

    const solfegeMethod = this.currentSection.options.systems.solfege.allowSelect
      ? this.personalPreferences?.solfegeMethod || sectionSolfegeMethod
      : sectionSolfegeMethod;
    const rhythmMethod = this.currentSection.options.systems.rhythm.allowSelect
      ? this.personalPreferences?.rhythmMethod || sectionRhythmMethod
      : sectionRhythmMethod;

    const theme = this.personalPreferences?.theme || 'normal';

    return {
      solfegeMethod,
      rhythmMethod,
      theme,
    };
  }

  @action setPersonalPreferences(prefs: Partial<UserPreferences>) {
    runInAction(() => {
      this.personalPreferences = {
        ...(this.personalPreferences || {}),
        ...prefs,
      };
    });
  }

  @computed get grade(): number {
    const { lessonsPercent, checkpointsPercent, skillsPercent } =
      this.currentSection.options.grading;
    return (
      (this.lessonProgress.lessonsScore * lessonsPercent) / 100 +
      (this.lessonProgress.checkpointsScore * checkpointsPercent) / 100 +
      (this.knowledgeProgress.score * skillsPercent) / 100
    );
  }

  /**
   * If nothing is due yet, the grade to date isn't valid. Let's not display it.
   */
  @computed get gradeToDateIsValid(): boolean {
    return (
      this.knowledgeProgress.includeInGradeToDate ||
      this.lessonProgress.includeLessonsInGradeToDate ||
      this.lessonProgress.includeCheckpointsInGradeToDate
    );
  }

  @computed get gradeToDate(): number {
    let { lessonsPercent, checkpointsPercent, skillsPercent } = this.currentSection.options.grading;
    if (!this.knowledgeProgress.includeInGradeToDate) skillsPercent = 0;
    if (!this.lessonProgress.includeLessonsInGradeToDate) lessonsPercent = 0;
    if (!this.lessonProgress.includeCheckpointsInGradeToDate) checkpointsPercent = 0;
    const totalPercent = lessonsPercent + checkpointsPercent + skillsPercent;
    // It's possible nothing is due yet...
    if (totalPercent === 0) return 100;

    return (
      (this.lessonProgress.lessonsGradeToDate * lessonsPercent) / totalPercent +
      (this.lessonProgress.checkpointsGradeToDate * checkpointsPercent) / totalPercent +
      (this.knowledgeProgress.gradeToDate * skillsPercent) / totalPercent
    );
  }

  /**
   * Used for the grade-to-date chart, for instance, on the user dashboard.
   */
  @computed get gradeToDateComponents(): {
    label: string;
    value: number;
    weightedValue: number;
    weight: number;
  }[] {
    let { lessonsPercent, checkpointsPercent, skillsPercent } = this.currentSection.options.grading;
    if (!this.knowledgeProgress.includeInGradeToDate) skillsPercent = 0;
    if (!this.lessonProgress.includeLessonsInGradeToDate) lessonsPercent = 0;
    if (!this.lessonProgress.includeCheckpointsInGradeToDate) checkpointsPercent = 0;
    // It's possible nothing is due yet...

    return [
      {
        label: 'Skills',
        value: this.knowledgeProgress.gradeToDate,
        weightedValue: (this.knowledgeProgress.gradeToDate * skillsPercent) / 100,
        weight: skillsPercent,
      },
      {
        label: 'Lessons',
        value: this.lessonProgress.lessonsGradeToDate,
        weightedValue: (this.lessonProgress.lessonsGradeToDate * lessonsPercent) / 100,
        weight: lessonsPercent,
      },
      {
        label: 'Checkpoints',
        value: this.lessonProgress.checkpointsGradeToDate,
        weightedValue: (this.lessonProgress.checkpointsGradeToDate * checkpointsPercent) / 100,
        weight: checkpointsPercent,
      },
    ];
  }

  init(userRecord: StudentUserRecord | UserWithJoins, currentSection?: SectionStore) {
    const rawUPointRecords = isUserWithJoins(userRecord)
      ? userRecord.uPoints.dataPoints
      : userRecord.uPoints;

    this.initializeUPoints(rawUPointRecords);

    this.initSectionsAndRoles(userRecord, currentSection);

    this.id = userRecord.id;
    this.firstName = userRecord.firstName;
    this.lastName = userRecord.lastName;
    this.email = userRecord.email;
    this.accommodations = userRecord.accommodations;
    this.lastActiveAt = userRecord.lastActiveAt ? new Date(userRecord.lastActiveAt) : null;
    this.lastLoginAt = userRecord.lastLoginAt ? new Date(userRecord.lastLoginAt) : null;
    this.avatarUrl = userRecord.avatarUrl || null;
    this.credentials = userRecord.credentials;
    this.knowledgeProgress = new UserKnowledgeStore(this, userRecord.skills);
    // Important: this must be done before the user lessons store is initialized
    this.testAttempts = userRecord.checkpoints
      .filter((attempt) => attempt.attemptStatus === 'complete')
      .map((attempt) => new TestAttemptStore(attempt, this));
    this.lessonProgress = new UserLessonsStore(this, userRecord.lessons);
  }

  protected abstract initSectionsAndRoles(
    userRecord: StudentUserRecord | UserWithJoins,
    sectionStore?: SectionStore
  ): void;

  @action protected initializeUPoints(
    uPointRecords: {
      dailyPoints: number;
      date: string | Date;
      points: number;
      totalTime: number;
    }[]
  ) {
    this.uPointsRecords = uPointRecords.map((uPoint) => {
      const noon = getNoon(new Date(uPoint.date));
      return {
        ...uPoint,
        date: noon,
        dateString: dateString(noon, true),
        totalTime: uPoint.totalTime || 0,
      };
    });
  }

  @action setUPoints(uPoints: number) {
    this.uPointsRecords.slice(-1)[0]!.points = uPoints;
  }
}
