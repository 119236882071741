import { observable, action, runInAction, makeObservable } from 'mobx';
import type { WebSocket as WSWebSocket } from 'ws';
import { JSONParse, JSONStringify } from '../utils/JSONUtils';

let requestId = 0;

export type USocketEventType = string;
export type USocketData = any;
export interface USocketMetadata {
  requestId: number;
  respondingToRequestId?: number;
}

export type USocketMessage = [USocketEventType, USocketData, USocketMetadata?];

export class SocketDispatcher {
  callbacks: { [messageName: string]: Function } = {};

  anyCallbacks: Function[] = [];

  resolvers: {
    [key: number]: (value?: any) => void;
  } = {};

  rejectors: {
    [key: number]: (reason: any) => void;
  } = {};

  url?: string;

  @observable.ref socket?: WebSocket | WSWebSocket;

  @observable status: 'open' | 'closed' = 'closed';

  @action private setStatus(status: 'open' | 'closed') {
    this.status = status;
  }

  @observable error?: Event | false;

  @action private setError(err: Event | false) {
    this.error = err;
  }

  constructor(ws: WebSocket);
  constructor(url: string);
  constructor(urlOrWs: string | WebSocket) {
    makeObservable(this);
    if (typeof urlOrWs === 'string') {
      this.url = urlOrWs;
    } else if (urlOrWs) {
      this.attachSocket(urlOrWs);
    }
  }

  on<T = any>(messageName: string, callback: (data: T, metadata?: USocketMetadata) => void): void {
    this.callbacks[messageName] = callback;
  }

  onAny<T = any>(callback: (eventType: string, data: T, metadata?: USocketMetadata) => void): void {
    this.anyCallbacks.push(callback);
  }

  attachSocket(ws: WebSocket | WSWebSocket) {
    this.setError(false);
    this.socket = ws;

    this.socket.onerror = (error: Event) => {
      this.setStatus('closed');
      this.error = error;
      console.error('socket error', error);
      this.trigger('error', error);
    };

    this.socket.onclose = () => {
      delete this.openingPromise;
    };

    this.socket.onmessage = (message: Event & { data: string }) => {
      let eventType: USocketEventType;
      let data: USocketData;
      let metadata: USocketMetadata | undefined;

      try {
        [eventType, data, metadata] = JSONParse(message.data) as USocketMessage;
        this.trigger(eventType, data, metadata);
      } catch (err) {
        this.trigger('error', err);
      }
    };
  }

  private openingPromise?: Promise<void>;

  async open(url = this.url) {
    if (this.openingPromise) {
      this.openingPromise = this.openingPromise.then(() => {});
      return this.openingPromise;
    }
    if (!url)
      throw Error('cannot open websocket without url specified at construction or on open.');

    // The webpack mode comment is needed to make sure that /exercises includes this import
    // in the build. That won't hit production, but without it, the testing build of exercises
    // fails.
    // See: https://medium.com/front-end-weekly/webpack-and-dynamic-imports-doing-it-right-72549ff49234
    const WS =
      typeof WebSocket === 'undefined'
        ? (await import(/* webpackMode: "eager" */ 'isomorphic-ws')).WebSocket
        : WebSocket;

    const socket = new WS(url);
    this.attachSocket(socket);

    this.openingPromise = new Promise<void>((resolve) => {
      socket.onopen = () => {
        this.setStatus('open');
        // This is here because the server sometimes needs time between opening the connection (NGINX)
        // and setting up listeners (Express). Therefore, we give it some milliseconds.
        setTimeout(resolve, 100);
      };
    });
    return this.openingPromise;
  }

  close() {
    this.socket?.close();
    this.setStatus('closed');
  }

  send<T, U = any>(eventType: string, data: U, respondingToRequestId?: number): Promise<T> {
    const metadata =
      typeof respondingToRequestId === 'number'
        ? {
            respondingToRequestId,
            requestId: ++requestId,
          }
        : {
            requestId: ++requestId,
          };
    const message = JSONStringify([eventType, data, metadata]);

    const promise = new Promise<T>((resolve, reject) => {
      this.resolvers[requestId] = resolve;
      this.rejectors[requestId] = reject;
    });

    this.socket?.send(message);
    return promise;
  }

  private trigger(eventType: USocketEventType, data: USocketData, metadata?: USocketMetadata) {
    runInAction(() => {
      // Resolve or reject any related promises:
      if (metadata?.respondingToRequestId && this.resolvers[metadata?.respondingToRequestId]) {
        if (eventType !== 'error') {
          this.resolvers[metadata.respondingToRequestId](data);
        } else if (this.rejectors[metadata.respondingToRequestId]) {
          this.rejectors[metadata.respondingToRequestId](data);
        }
        delete this.resolvers[metadata.respondingToRequestId];
        delete this.rejectors[metadata.respondingToRequestId];
      }

      this.anyCallbacks.forEach((anyCb) => anyCb(eventType, data, metadata));
      const cb = this.callbacks[eventType];
      if (!cb) return;
      cb(data, metadata);
    });
  }
}
