import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Major Scales on the Piano
 */
export const lesson: Lesson = {
  id: 'piano_major_scales',
  areaId: 'pitch_and_harmony',
  order: 12,
  title: 'Major Scales on the Piano',
  skills: ['major_scales_piano'],
  pages: [
    {
      title: 'Intro',
      id: 'piano_major_scales_1',
      order: 1,
      content: `
        <h3>Major Scales on the Piano</h3>
        <p>In this lesson we'll learn the pattern of half- and whole-steps in Major scales, and use our knowledge of half- and whole-steps to play any Major scale on the piano.</p>
        <p>Let's get started!</p>
        `,
    },
    /**
     * Video: Major Scale HS & WS Pattern
     *
     * Most of our scales in western music have eight notes, the top note is
     * just the bottom note up an octave.  The most common one in classical and
     * popular music is the Major scale.  Every major scale has the same pattern
     * of whole and half steps.  Let's look at the C Major scale -- which is
     * played on all of the white notes -- to figure this out:
     *  - Where are the half steps in this scale?
     *    Between 3-4 and 7-1.  All of the other steps are whole steps.
     *
     * Let's look at another scale.  Here's the E Major Scale:
     * Where are the half-steps?  Again, between 3-4 and 7-1.
     *
     * All of the other steps are whole steps.
     *
     * Every major scale will have half steps between notes 3 and 4, as well as
     * 7 and 1, and all of the others will be whole steps.
     *
     * Let's say that again: In Major scales, 3-4 and 7-8 are half steps.
     * Everything else is a whole step.
     */
    {
      title: 'Half and Whole Steps in Major Scales',
      id: 'piano_major_scales_video_1',
      order: 2,
      video: {
        url: 'majorScalesPiano1',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Exercise: What is the interval between __ & __ in a Major scale?
     */
    {
      title: 'HS & WS Pattern',
      order: 3,
      id: 'piano_major_scales_pattern_quiz',
      interactive: {
        type: 'ScalePatterns',
        questionPrompt: 'What is the interval between the {question} notes of {question} scale?',
        hideAugSecond: true,
        skills: ['major_scales_piano'],
        ordinalNumbers: true,
      },
    },
    /**
     * Video: Playing Major Scales
     *
     * When we know this pattern of half and whole steps in the major scale, we
     * can use it to play any major scale on the piano.
     *
     * So: If we were to play a major scale from D: the first note is D, so we
     * need a whole step...
     *
     * Or: If we were to build one from Ab: ...
     *
     * On the next page, play major scales from the highlighted note.
     */
    {
      title: 'Half and Whole Steps in Major Scales',
      id: 'piano_major_scales_video_2',
      order: 4,
      video: {
        url: 'majorScalesPiano2',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play Major Scales
     */
    {
      title: 'Play: Major Scales',
      order: 5,
      id: 'piano_major_scales_play_scales_ascending',
      interactive: {
        type: 'ScalePlay',
        questionPrompt: 'Play {question} scale.',
        scaleDegreeHints: true,
        questions: ['A3/M', 'F4/M', 'Eb3/M', 'B4/M'],
      },
    },
    /**
     * Video: Playing Descending Major Scales
     *
     * We can also play major scales going down from a highlighted note.  To
     * play a descending scale, we just have to count down from 8 -- er... 1 --
     * to keep track of when to play the half steps.
     *
     * So: If we were to play a major scale down from Db: the first note is Db,
     * then we go down to 7, so we need a half step...
     *
     * On the next page, play descending major scales from the highlighted note.
     */
    {
      title: 'Descending Major Scales',
      id: 'piano_major_scales_video_3',
      order: 6,
      video: {
        url: 'majorScalesPiano3',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play descending major scales
     */
    {
      title: 'Play: Descending Major Scales',
      order: 7,
      id: 'piano_major_scales_play_scales_descending',
      interactive: {
        type: 'ScalePlay',
        questionPrompt: 'Play {question} scale.',
        scaleDegreeHints: true,
        questions: ['Bb/M/dsc', 'E4/M/dsc', 'G/M/dsc', 'Db/M/dsc'],
      },
    },
    /**
     * Video: Playing w/out labeled scale degrees
     *
     * Nice work: now we're going to take the training wheels off a little bit.
     * We'll ask you to play ascending and descending major scales from the
     * highlighted note, but we're going to stop labeling the numbers on the
     * keys -- you'll have to also keep track of which scale degree you're on.
     * Good luck!
     */
    {
      title: 'Training Wheels Off',
      id: 'piano_major_scales_video_4',
      order: 8,
      video: {
        url: 'majorScalesPiano4',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play Major Scales (w/out scale degrees labeled)
     */
    {
      title: 'Play: Major Scales',
      order: 9,
      id: 'piano_major_scales_play_scales_all',
      interactive: {
        type: 'ScalePlay',
        questionPrompt: 'Play {question} scale.',
        scaleDegreeHints: false,
        hideLabels: true,
        questions: ['D/M', 'F#/M/dsc', 'B/M', 'Ab/M/dsc'],
      },
    },
    /**
     * Video: Conclusion
     *
     * Congratulations -- you can now play any major scale on the piano.  Scales
     * are at the heart of all music theory and ear training, and you're off to
     * a good start with them.  Eventually, you'll become so familiar with the
     * scales that you can call to mind all the notes of any scale in mere
     * moments.  Don't worry -- there will be plenty of chances for you to
     * practice these to move towards that goal!!
     */
    {
      title: 'Conclusion',
      order: 10,
      id: 'piano_major_scales_video5',
      video: {
        url: 'majorScalesPiano5',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      title: 'Summary',
      id: 'piano_major_scales_summary',
      order: 11,
      content:
        '<h3>Major Scales on the Piano Summary</h3>\n<p>In this lesson we learned the Major scale is made up of eight notes, with all whole steps except between the 3rd and 4th notes and the 7th and 8th notes of the scale.  We used this to play scales on the keyboard, both ascending and descending.</p>\n<p>Nice Work!</p>\n',
    },
  ],
};
