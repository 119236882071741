/* eslint-disable max-classes-per-file */

import { computed, makeObservable } from 'mobx';
// Note: must be * as _. Lodash isn't es6 compat.
import { areasById } from '../../knowledge/knowledge.store';
import type { AbstractUserStore } from '../abstract.user.store';
import type { UserTopicStore } from './user.topic.store';
import type { UserSkillStore } from './user.skill.store';
import { meanReducerFactory } from '../../utils/meanReducer';
import { sum } from '../../utils/sum';
import type {
  KnowledgeGraphAreaNode,
  KnowledgeGraphEdge,
} from '../../knowledge/knowledgeGraphDefinitions';

export class UserAreaKnowledgeStore {
  id: number;

  textId: string;

  title: string;

  root: AbstractUserStore;

  type: 'area';

  @computed get skills(): UserSkillStore[] {
    return this.children.filter((store): store is UserSkillStore => store.type === 'skill');
  }

  @computed get topics(): UserTopicStore[] {
    return this.children.filter((store): store is UserTopicStore => store.type === 'topic');
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get parents(): null[] {
    return [];
  }

  @computed get children(): (UserSkillStore | UserTopicStore)[] {
    const isEdgeFromMe = (e: KnowledgeGraphEdge): boolean => e.parentId === this.definition.textId;
    const childIds = this.root.knowledgeProgress.knowledgeGraphEdges
      .filter(isEdgeFromMe)
      .map((e: KnowledgeGraphEdge) => e.childId);

    const children = ([] as (UserSkillStore | UserTopicStore)[])
      .concat(this.root.knowledgeProgress.skills)
      .concat(this.root.knowledgeProgress.topics)
      .filter((nodeStore: UserSkillStore | UserTopicStore | UserAreaKnowledgeStore) =>
        childIds.includes(nodeStore.textId)
      );

    return children;
  }

  @computed get requiredTopics(): UserTopicStore[] {
    return this.topics.filter((topic) => topic.isRequired);
  }

  @computed get requiredSkills(): UserSkillStore[] {
    return this.skills.filter((skill) => skill.isRequired);
  }

  @computed get optionalSkills(): UserSkillStore[] {
    return this.skills.filter((skill) => !skill.isRequired && skill.isVisible);
  }

  @computed get visibleSkills(): UserSkillStore[] {
    return this.skills.filter((skill) => skill.isVisible);
  }

  /**
   * If this area isn't required, we should use all topics to calculate
   * the score. Otherwise, we should just use the required topics.
   */
  @computed get topicsForScoreCalculation(): UserTopicStore[] {
    if (this.isRequired) return this.requiredTopics;
    return this.topics;
  }

  @computed get score(): number {
    return this.topicsForScoreCalculation
      .map((topic) => topic.score)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get accuracyScore(): number {
    return (
      this.topicsForScoreCalculation
        .map((topic) => topic.accuracyScore)
        .reduce(meanReducerFactory(), 0) || 0
    );
  }

  @computed get accuracy(): number {
    return (
      this.topicsForScoreCalculation
        .map((topic) => topic.accuracy)
        .reduce(meanReducerFactory(), 0) || 0
    );
  }

  @computed get questionsScore(): number {
    return this.topicsForScoreCalculation
      .map((topic) => topic.questionsScore)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get numQuestions(): number {
    return this.topicsForScoreCalculation.map((topic) => topic.numQuestions).reduce(sum, 0);
  }

  @computed get speedScore(): number | null {
    if (!this.isSpeedRequired) return null;
    return this.topicsForScoreCalculation
      .filter((topic) => topic.isSpeedRequired)
      .map((topic) => topic.speedScore || 0)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get isSpeedRequired(): boolean {
    return !!this.topicsForScoreCalculation.find((topic) => topic.isSpeedRequired);
  }

  @computed get speed(): number | null {
    if (!this.topicsForScoreCalculation) return null;
    return this.topics
      .filter((topic) => topic.speed !== null)
      .map((topic) => topic.speed || 0)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get isVisible(): boolean {
    return !!this.topics.find((skill) => skill.isVisible);
  }

  @computed get isRequired(): boolean {
    return this.requiredTopics.length !== 0;
  }

  @computed get definition(): KnowledgeGraphAreaNode {
    const definition = areasById[this.id];
    if (!definition)
      throw Error(`Unable to get area definition for ${this.id} @UserAreaKnowledgeStore`);
    return definition;
  }

  constructor(textId: string, root: AbstractUserStore) {
    makeObservable(this);
    this.root = root;
    this.type = 'area';
    this.textId = textId;
    const area = this.root.knowledgeProgress.knowledgeGraphNodes.find(
      (n) => n.textId === this.textId
    ) as KnowledgeGraphAreaNode | undefined;
    if (!area) {
      throw Error(`Unable to get topic definition for ${this.textId} @UserAreaKnowledgeStore`);
    }
    this.id = area.id;
    this.title = area.title;
  }
}
