import type { Lesson } from '../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'accidentals_2',
  areaId: 'pitch_and_harmony',
  order: 5,
  title: 'Double Accidentals',
  skills: ['double_sharps', 'double_flats'],
  pages: [
    {
      id: 'accidentals_2_double_sharps_and_flats',
      title: 'Double Sharps and Flats',
      order: 1,
      video: {
        url: 'accidentals2-1',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      id: 'accidentals_2_play_double_flats',
      title: 'Play: Double Flats',
      order: 2,
      interactive: {
        type: 'SingleNotePlay',
        lowestNote: 'C4',
        highestNote: 'G5',
        questionPrompt: 'Play the note {question} on the piano.',
        ignoreOctave: true,
        questions: [
          'F4',
          'Fb4',
          'Fbb4',
          'Bb4',
          'Bbb4',
          'Dbb4',
          'Abb4',
          'Cbb4',
          'Gbb4',
          'Ebb4',
          'Fbb4',
        ],
      },
    },
    {
      id: 'accidentals_2_double_sharps',
      title: 'Double Sharps',
      order: 3,
      video: {
        url: 'accidentals2-2',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      id: 'accidentals_2_play_double_sharps',
      title: 'Play: Double Sharps',
      order: 4,
      interactive: {
        type: 'SingleNotePlay',
        lowestNote: 'C4',
        highestNote: 'G5',
        questionPrompt: 'Play the note {question} on the piano.',
        ignoreOctave: true,
        questions: ['G4', 'G#4', 'Gx4', 'E4', 'Ex4', 'Bx3', 'Fx4', 'Dx4', 'Cx4', 'Ax4'],
      },
    },
    {
      id: 'accidentals_2_play_double_accidentals',
      title: 'Play Double Accidentals',
      order: 5,
      interactive: {
        type: 'NoteReadingPiano',
        lowestNote: 'G3',
        highestNote: 'E5',
        clef: 'treble',
        questions: ['C4/Eb4/Gb4/Bbb4', 'D#4/Fx4/A#4/Cx4', 'Cbb4/Db4/Fb/Abb4', 'Ex4/Bbb3/Ax4/Dx4'],
      },
    },
    {
      id: 'accidentals_2_enharmonic_equivalence_video',
      title: 'Enharmonic Equivalence',
      order: 6,
      video: {
        url: 'accidentals2-3',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      id: 'accidentals_2_enharmonic_equivalence_exercise',
      title: 'Enharmonic Spellings',
      order: 7,
      interactive: {
        type: 'EnharmonicEquivalence',
        questionPrompt:
          'What are three enharmonically equivalent spellings of the highlighted note?',
        lowestNote: 'G3',
        highestNote: 'C5',
        clef: 'treble',
        questions: ['G4', 'E4', 'B4', 'Gb4', 'Eb4', 'A4'],
      },
    },
    {
      id: 'accidentals_2_accidentals_review',
      title: 'Accidentals Review',
      order: 8,
      video: {
        url: 'accidentals2-4',
        autostart: true,
        autoadvance: true,
      },
    },
    {
      id: 'accidentals_2_accidentals_sort',
      title: 'Sort the Accidentals',
      order: 9,
      interactive: {
        type: 'AccidentalSorter',
      },
    },
    {
      id: 'accidentals_2_conclusion',
      title: 'Summary',
      order: 10,
      content:
        "<h3>Summary</h3>\n<p>That's everything there is to know about accidentals.  Great work hanging in there.  You'll see tons of single & double accidentals throughout this course and in all your musical endeavors, and you'll be ready for them!</p>\n",
    },
  ],
};
