import { number, union, literal, type } from 'io-ts';
import type { TypeOf } from 'io-ts';

export const LatePenaltySchema = type({
  /**
   * The percentage to deduct from a student's score for work submitted late
   */
  deductionPercent: number,
  /** The interval by which to deduct it, if 'never' no penalty for submitting late work */
  deductionInterval: union([literal('never'), literal('once'), literal('day'), literal('week')]),
});

export type LatePenalty = TypeOf<typeof LatePenaltySchema>;
