import { get } from './get';
import { isObject } from './isObject';

/**
 * Deeply merge any number of objects, using a customizer function to alter or
 * choose the value assigned to destination properties.
 * @param destination - the object to be modified, merged on to. This will alter
 * the original object, so clone first if you need to preserve it.
 * @param source - the object with the properties to overwrite on the
 * destination.
 * @param customizer - a comparator function. If it returns a value, that value
 * is assigned to the destination prop. If it returns undefined, the normal
 * merge logic (apply source prop, unless undefined) applies.
 * ```typescript
 *   mergeWith(
 *     { price: 3.99 },
 *     { price: null, quantity: 3 },
 *     (destValue, sourceValue) => sourceValue === null ? destValue : undefined
 *   ); // { price: 3.99, quantity: 3 }
 * ```
 */
export function mergeWith<Destination extends object, Source extends object>(
  destination: Destination,
  source: Source,
  customizer?: (destinationValue: any, sourceValue: any) => any
): Destination & Source;

/**
 * Deeply merge any number of objects, using a customizer function to alter or
 * choose the value assigned to destination properties.
 * @param destination - the object to be modified, merged on to. This will alter
 * the original object, so clone first if you need to preserve it.
 * @param source - the object with the properties to overwrite on the
 * destination.
 * @param customizer - a comparator function. If it returns a value, that value
 * is assigned to the destination prop. If it returns undefined, the normal
 * merge logic (apply source prop, unless undefined) applies.
 * ```typescript
 *   mergeWith(
 *     { price: 3.99 },
 *     { price: null, quantity: 3 },
 *     (destValue, sourceValue) => sourceValue === null ? destValue : undefined)
 *   ); // { price: 3.99, quantity: 3 }
 * ```
 */
export function mergeWith<Destination, Source>(
  destination: Destination[],
  source: Source[],
  customizer?: (destinationValue: any, sourceValue: any) => any
): (Destination & Source)[];
/**
 * Deeply merge any number of objects, using a customizer function to alter or
 * choose the value assigned to destination properties.
 * @param destination - the object to be modified, merged on to. This will alter
 * the original object, so clone first if you need to preserve it.
 * @param source - the object with the properties to overwrite on the
 * destination.
 * @param customizer - a comparator function. If it returns a value, that value
 * is assigned to the destination prop. If it returns undefined, the normal
 * merge logic (apply source prop, unless undefined) applies.
 * ```typescript
 *   mergeWith(
 *     { price: 3.99 },
 *     { price: null, quantity: 3 },
 *     (destValue, sourceValue) => sourceValue === null ? destValue : undefined)
 *   ); // { price: 3.99, quantity: 3 }
 * ```
 */
export function mergeWith(
  destination: any[] | object,
  source: any[] | object,
  customizer?: (destinationValue: any, sourceValue: any) => any
) {
  // If the destination is not assignable (i.e., a primitive), then
  // we cannot merge onto it. Instead, we clobber it with source.
  if (!isObject(destination) && !Array.isArray(destination) && typeof destination !== 'function')
    return source;

  Object.keys(source).forEach((property) => {
    if (
      // The destination prop is an object or array:
      (isObject(get(destination, property)) || Array.isArray(get(destination, property))) &&
      // The source prop is an object or array:
      (isObject(get(source, property)) || Array.isArray(get(source, property)))
    ) {
      (destination as any)[property] = mergeWith(
        (destination as any)[property],
        (source as any)[property],
        customizer
      );
    } else {
      // If we have a customizer and the customizer returns something other than undefined,
      const customizerValue =
        typeof customizer === 'undefined'
          ? undefined
          : customizer((destination as any)[property], (source as any)[property]);

      // then use what the customizer returned:
      if (typeof customizerValue !== 'undefined') {
        (destination as any)[property] = customizerValue;
        // Otherwise, if the source is defined, use that value:
      } else if (typeof (source as any)[property] !== 'undefined') {
        (destination as any)[property] = (source as any)[property];
      }
      // And if the source is not defined, do nothing.
    }
  });
  return destination;
}
