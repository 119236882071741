import type { Lesson } from '../../../definitions/content-definitions';
/**
 * # Ascending Intervals
 */
export const lesson: Lesson = {
  id: 'ascending_intervals',
  areaId: 'pitch_and_harmony',
  order: 26,
  title: 'Ascending Intervals',
  skills: ['interval_groups', 'ascending_major_perfect', 'ascending_others'],
  pages: [
    {
      id: 'ascending_intervals_intro',
      order: 1,
      title: 'Introduction',
      content:
        "<h3>Ascending Intervals</h3>\n<p>In this lesson we'll learn:\n<ul>\n<li>About the Perfect group and the Major/minor group of intervals,</li>\n<li>How to write intervals above a note, and</li>\n<li>How to identify intervals you see.</li>\n</ul>\n</p>\n<p>Click next to get started with the lesson!</p>\n",
    },
    /**
     * ## Video #1:
     *
     * In addition to our generic interval names -- we use a specific name to
     * distinguish how many half steps are within an interval.
     *
     * We are first going to look at the Major/minor group of intervals. This
     * includes the intervals of a 2nd, 3rd, 6th and 7th, as well as the
     * compound versions of these. Each of these intervals can be described as
     * being either Major or minor. None of the other intervals can.
     *
     * The other group of intervals is known as the Perfect group. This includes
     * the unison, the fourth, the fifth and the octave. These intervals, and
     * their compound versions, can be described as Perfect, but cannot be
     * described as being Major or minor.
     *
     * Let's get some practice distinguishing which group an interval is in.
     *
     */
    {
      id: 'ascending_intervals_video_1',
      order: 2,
      title: 'Interval Groups',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'ascendingIntervals1',
      },
    },
    {
      title: 'Perfect or Maj/min',
      order: 3,
      id: 'ascending_intervals_perfect_vs_maj_min',
      interactive: {
        type: 'IntervalGroups',
        drill: true,
        skills: ['interval_groups'],
        maxQuestions: 12,
      },
    },
    /**
     * ascendingIntervals2 Video Script: Video 2: Major & Perfect Intervals
     *
     * An interval is Major or perfect when the top note is in the major scale
     * of the bottom note. So, let's imagine we wanted to write a Maj 6th above
     * Eb. What this means is we want the 6th note of the Eb Major scale. So, we
     * imagine writing the Eb major scale to get our answer. (We could also have
     * imagined going down to 6 from the Eb an octave higher to save time.)
     *
     * Let's do another: Write a P4 above B.
     *
     * Now here's the real time saver: if you know the key signature of the
     * bottom note, you don't have to count up every half & whole step, you can
     * use the key signature to figure out what accidental the top note should
     * have.
     *
     * Let's say we wanted a Maj7 above A. We go up a generic 7th, to G. Now we
     * think of our key signature for A Major -- 3 sharps, and note that G is
     * sharped, so our answer will be G#.
     *
     * All this can be expressed as one rule:
     *
     * If the top note of an interval is in the Major key of the bottom note,
     * the interval is Major or Perfect.
     *
     * You can think of this as our rule for ascending intervals -- we'll come
     * back to it every time we write one.
     *
     * Let's get some practice writing Major intervals.
     */
    {
      id: 'ascending_intervals_video_2',
      title: 'Perfect & Major Intervals',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'ascendingIntervals2',
      },
    },
    {
      title: 'Write Perfect & Major Intervals',
      order: 5,
      id: 'ascending_intervals_id_speed',
      interactive: {
        type: 'WriteIntervals',
        questions: [
          'Eb4/P5',
          'F3/P4',
          'E3/M3',
          'A3/M7',
          'Db2/P8',
          'F#3/M2',
          'Gb3/M6',
          'Bb4/P5',
          'F2/P4',
        ],
      },
    },
    /**
     *
     * ## ascendingIntervals video 3 Script
     *
     * If an interval is a half-step smaller than Major, we say it is minor. In
     * latin, Major means big and minor means small.
     *
     * So, if we started with a Major 6th above G, we'd get E. To make that a
     * half-step smaller, we lower it to Eb.
     *
     * What about a minor 2nd above B? Start with the Major 2nd -- C#, and make
     * it a half-step smaller, and we get C-natural.
     *
     */
    {
      id: 'ascending_intervals_video_3',
      order: 6,
      title: 'Minor Intervals',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'ascendingIntervals3',
      },
    },
    {
      title: 'Write minor Intervals',
      order: 7,
      id: 'ascending_intervals_minor',
      interactive: {
        type: 'WriteIntervals',
        questions: ['D3/m3', 'Ab2/m7', 'G4/m7', 'F#2/m2', 'Eb5/m6', 'Db3/m3', 'C#4/m3', 'Cb5/m2'],
      },
    },
    /**
     * ascendingIntervals4 Video Script:
     *
     * Augmented!
     *
     * 4 When an interval is a half-step larger than Major, or than Perfect, we
     * say it is Augmented. Let's write some augmented intervals. Both intervals
     * in the Major/minor group and in the Perfect group can be augmented.
     *
     * D: A4, Eb: A6
     *
     */
    {
      id: 'ascending_intervals_video_4',
      title: 'Augmented Intervals',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'ascendingIntervals4',
      },
    },
    {
      title: 'Write Augmented Intervals',
      order: 9,
      id: 'ascending_intervals_augmented',
      interactive: {
        type: 'WriteIntervals',
        questions: ['C2/A5', 'E2/A4', 'Gb4/A5', 'B3/A3', 'Bb2/A6', 'A4/A2', 'F3/A4', 'Cb/A6'],
      },
    },
    /**
     * Video 5 If an interval is a half-step smaller than Perfect, or a
     * half-step smaller than minor, we say it is diminished. Let's write some
     * diminished intervals:
     *
     * C#: d7, B: D5
     *
     * When you're writing a diminished interval, pay attention to whether
     * you're in the Major/minor group, or in the Perfect group. A diminished
     * interval is 2 half steps smaller than Major, but only one half step
     * smaller than perfect!
     */
    {
      id: 'ascending_intervals_video_5',
      title: 'Diminished Intervals',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'ascendingIntervals5',
      },
    },
    {
      title: 'Write diminished Intervals',
      order: 11,
      id: 'ascending_intervals_diminished',
      interactive: {
        type: 'WriteIntervals',
        questions: ['G4/d5', 'D2/d7', 'F#/d7', 'C#4/d3', 'E5/d5', 'Bb2/d5', 'B3/d7'],
      },
    },
    /**
     * Video 6
     *
     * Good, now you can write all of the ascending intervals. To identify an
     * interval, we just think through the process of writing it, and determine
     * what it would have been. Let's do a couple together:
     *
     * F# - C#
     *
     * Db - Fb
     *
     * Gb - C
     *
     */
    {
      id: 'ascending_intervals_video_6',
      title: 'Identifying Intervals',
      order: 12,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'ascendingIntervals6',
      },
    },
    {
      title: 'Identify Intervals',
      order: 13,
      id: 'ascending_intervals_id',
      interactive: {
        type: 'IntervalsID',
        questions: [
          'F2/m7',
          'C4/A4',
          'Gb4/M7',
          'D5/M2',
          'Db3/M3',
          'F#3/M3',
          'Bb2/A6',
          'C#3/d5',
          'B3/d5',
          'G5/A2',
          'Eb2/m6',
          'A4/m6',
          'E4/m3',
          'Ab2/A4',
          'G4/d7',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video 7: Conclusion
     */
    {
      id: 'ascending_intervals_video_7',
      title: 'Conclusion',
      order: 13,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'ascendingIntervals7',
      },
    },
  ],
};
