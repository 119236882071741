import type { LessonCheckpointTest } from '../../../definitions/checkpoint-definitions';

export const checkpoint: LessonCheckpointTest = {
  id: 'checkpoint_3',
  areaId: 'pitch_and_harmony',
  checkpoint: true,
  order: 24,
  title: 'Minor scales and keys',
  options: {
    name: 'Minor scales and keys',
    instructions: `<p>
        This checkpoint tests your mastery of minor scales and keys.
      </p>
      <p>
        <strong>All of your answers are final</strong>. You will not be able to go back to change
        answers on previous questions.
      </p>
      <p>
        You have up to {{totalTime}} to complete this checkpoint. Please be sure you have enough time before beginning the checkpoint.
      </p>
      <p>
        {{passingSentence}}
      </p>
      `,
  },
  sections: [
    {
      options: {
        instructions: `<p>
            Section {{sectionNumber}}: {{sectionName}}
          <p>
          <p>
            In this section you will play and write {{sectionQuestions}} minor scales.
            You will have a maximum of {{sectionTime}} for this section, or roughly
            {{secondsPerQuestion}} seconds per scale, and any unanswered questions
            will be counted wrong.
          </p>
          `,
        name: 'Minor scales',
        timeLimit: '9:00',
        sortOrder: 'type',
        weight: 33,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'ScalePlay',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
            {
              questionType: 'ScaleWrite',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 3,
              questions: [
                'E/minor/asc/bass',
                'B/melodic/asc/treble',
                'F#/harmonic/dsc/bass',
                'C#/melodic/dsc/treble',
              ],
            },
            {
              numberOfQuestions: 3,
              questions: [
                'A/harmonic/dsc/treble',
                'D/melodic/asc/treble',
                'G/minor/dsc/bass',
                'C/harmonic/asc/bass',
                'F/melodic/dsc/treble',
              ],
            },
            {
              numberOfQuestions: 2,
              questions: ['G#/melodic/asc/bass', 'D#/harmonic/dsc/bass', 'A#/minor/dsc/treble'],
            },
            {
              numberOfQuestions: 2,
              questions: ['Bb/minor/asc/treble', 'Eb/harmonic/asc/bass', 'Ab/minor/asc/bass'],
            },
          ],
        },
      ],
    },
    {
      options: {
        instructions: `<p>
            Section {{sectionNumber}}: Minor Keys
          </p>
          <p>
            You will have {{sectionTime}}, or roughly {{secondsPerQuestion}} seconds per question,
            to write and identify {{sectionQuestions}} minor keys. Any unanswered questions will be counted wrong.
          </p>
          `,
        name: 'Write & ID Minor Keys',
        timeLimit: '6:00',
        sortOrder: 'type',
        weight: 33,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'KeySigConstruction',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {},
            },
            {
              questionType: 'KeySigID',
              questionWeighting: 1,
              questionTypeFrequency: 1,
              options: {},
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 6,
              questions: [
                'Ab/minor/bass',
                'Eb/minor/treble',
                'Bb/minor/bass',
                'F/minor/treble',
                'C/minor/bass',
                'G/minor/treble',
                'D/minor/bass',
              ],
            },
            {
              numberOfQuestions: 6,
              questions: [
                'A#/minor/treble',
                'D#/minor/bass',
                'G#/minor/treble',
                'C#/minor/bass',
                'F#/minor/treble',
                'B/minor/bass',
                'E/minor/treble',
                'A/minor/bass',
              ],
            },
          ],
        },
      ],
    },
    {
      options: {
        instructions: `<p>
            Section {{sectionNumber}}: Key Sig Facts
          </p>
          <p>
            In this speed section, you will answer {{sectionQuestions}} questions
            about key minor key signatures. You will have {{secondsPerQuestion}} seconds
            per question, or {{sectionTime}} total.</p>
          `,
        name: 'Key Signature Facts',
        timeLimit: '3:00',
        sortOrder: 'random',
        weight: 34,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'LastSharpOrFlatInKey',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
            {
              questionType: 'KeyByLastSharpOrFlat',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
            {
              questionType: 'NumberOfSharpsOrFlats',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 6,
              questions: [
                'Ab/minor',
                'Eb/minor',
                'Bb/minor',
                'F/minor',
                'C/minor',
                'G/minor',
                'D/minor',
              ],
            },
            {
              numberOfQuestions: 6,
              questions: [
                'A#/minor',
                'D#/minor',
                'G#/minor',
                'C#/minor',
                'F#/minor',
                'B/minor',
                'E/minor',
              ],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'RelativeAndParallelKeys',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 3,
              questions: [
                'G/Major/parallel',
                'A/Major/relative',
                'B/Major/parallel',
                'C#/Major/relative',
                'F/Major/parallel',
                'Eb/Major/relative',
                'E/Major/parallel',
                'Cb/Major/relative',
              ],
            },
            {
              numberOfQuestions: 3,
              questions: [
                'A/minor/parallel',
                'B/minor/relative',
                'C#/minor/parallel',
                'D#/minor/relative',
                'G/minor/parallel',
                'F/minor/relative',
                'Eb/minor/parallel',
              ],
            },
          ],
        },
      ],
    },
  ],
};
