export type EqualityCheck<T> = (a: T, b: T) => boolean;
export type FilterFunction<T> = (el: T, index: number, arr: T[]) => boolean;

/**
 * Create a filter function for arrays that filters to a list of unique elements,
 * using the supplied equals function for comparison.
 * ```typescript
 *  const looselyEquals = (a, b) => a == b;
 *  [1, '1', 2, 2, '3', 3].filter(uniqueWithFilter(looselyEquals); // [1, 2, '3']
 * ```
 */
export function uniqueWithFilter<T>(equals: EqualityCheck<T>): FilterFunction<T> {
  return function uwf(el: T, index: number, arr: T[]) {
    const elIndex = arr.findIndex((priorEl) => equals(priorEl, el));
    return elIndex === index;
  };
}
