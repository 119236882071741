import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Roman numeral triads in minor
 */
export const lesson: Lesson = {
  id: 'roman_numeral_triads_in_minor',
  areaId: 'pitch_and_harmony',
  order: 37,
  title: 'Roman Numeral Triads in Minor',
  skills: ['triad_rn_qualities_minor', 'triad_rn_minor', 'rn_inversion', 'rn_open_spacing'],
  pages: [
    {
      id: 'roman_numeral_triads_in_minor_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Roman Numerals</h3>
        <p>In this lesson we'll learn:
        <ul>
          <li>The qualities of roman numerals in minor keys, and</li>
          <li>How to write and identify roman numerals in minor keys.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: RN Qualities in Minor
     */
    {
      id: 'roman_numeral_triads_in_minor_video_1',
      title: 'RN Qualities',
      order: 2,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMinor1',
      },
    },
    /**
     * Exercise: RN Quality ID: "What's the quality of the chord built on..."
     */
    {
      id: 'roman_numeral_triads_in_minor_number_to_note',
      title: 'ID RN Qualities',
      order: 3,
      interactive: {
        type: 'RomanQualityLabel',
        chordTypes: 'triads',
        questions: [
          'iv/minor',
          'iio/minor',
          'V/minor',
          'VI/minor',
          'III/minor',
          'viio/minor',
          'i/minor',
        ],
      },
    },
    /**
     * Video: Writing RN's in Minor
     */
    {
      id: 'roman_numeral_triads_in_minor_video_2',
      title: "Writing RN's",
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMinor2',
      },
    },
    /**
     * Exercise: Write RN's with keys
     */
    {
      id: 'roman_numeral_triads_in_minor_write_rns_with_key',
      order: 5,
      title: "Write RN's with Keys",
      interactive: {
        type: 'RomanNumeralDraw',
        drawKey: true,
        questions: [
          'G min: iv/treble',
          'G min: iio/treble',
          'G min: i/treble',
          'F# min: i/bass',
          'F# min: III/bass',
          'F# min: VI/bass',
        ],
      },
    },
    /**
     * Exercise: Write RN's without keys given
     */
    {
      id: 'roman_numeral_triads_in_minor_write_rns',
      title: "Write RN's",
      order: 6,
      interactive: {
        type: 'RomanNumeralDraw',
        spacing: 'close',
        questions: ['E min: iio', 'C min: III', 'Bb min: VI', 'A min: i'],
      },
    },
    /**
     * Video: Writing V and viio in minor
     */
    {
      id: 'roman_numeral_triads_in_minor_video_3',
      order: 7,
      title: 'Writing V and viio in minor',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'romanNumeralsInMinor3',
      },
    },
    /**
     * Exercise: Write key, then V or viio in minor
     */
    {
      id: 'roman_numeral_triads_in_minor_write_dominant_rns_with_key',
      order: 8,
      title: 'Write V and viio with keys',
      interactive: {
        type: 'RomanNumeralDraw',
        drawKey: true,
        questions: [
          'E min: V/treble',
          'E min: viio/treble',
          'E min: i/treble',
          'F min: iv/bass',
          'F min: V/bass',
          'F min: i/bass',
        ],
      },
    },
    /**
     * Exercise: Write V or viio in minor, key sig given
     */
    {
      id: 'roman_numeral_triads_in_minor_write_dominant_rns_key_given',
      order: 9,
      title: 'Write V and viio',
      interactive: {
        type: 'RomanNumeralDraw',
        questions: [
          'B min: V/treble',
          'B min: i/treble',
          'Eb min: iio/bass',
          'Eb min: viio/bass',
          'Eb min: i/bass',
          'G# min: viio/treble',
          'G# min: i/treble',
        ],
      },
    },
    /**
     * Video: Identify RN's
     */
    {
      id: 'roman_numeral_triads_in_minor_video_4',
      order: 10,
      title: "Identifying RN's",
      video: {
        autoadvance: true,
        autostart: true,
        url: 'romanNumeralsInMinor4',
      },
    },
    /**
     * Exercise: ID Rn's in minor
     */
    {
      id: 'roman_numeral_triads_in_minor_id_rns',
      order: 11,
      title: "ID RN's in minor",
      interactive: {
        type: 'RomanNumeralID',
        spacing: 'close',
        inversion: false,
        chordTypes: 'triads',
        questions: [
          'D min: VI',
          'A min: V',
          'C min: iio',
          'D# min: viio',
          'Eb min: iv',
          'C# min: III',
        ],
      },
    },
    /**
     * Video: Write inverted RN in minor
     */
    {
      id: 'roman_numeral_triads_in_minor_video_5',
      title: "Inverting RN's in minor",
      order: 12,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMinor5',
      },
    },
    /**
     * Exercise: Write inverted RN in minor
     */
    {
      id: 'roman_numeral_triads_in_minor_write_rns_in_inversion',
      order: 13,
      title: "Write inverted RN's",
      interactive: {
        type: 'RomanNumeralDraw',
        drawKey: true,
        questions: [
          'Bb min: i6/treble',
          'Bb min: iio6/treble',
          'Bb min: i64/treble',
          'Bb min: V6/treble',
          'Bb min: viio6/treble',
          'Bb min: iv64/treble',
          'Bb min: i/treble',
        ],
      },
    },
    /**
     * Video: ID Inverted RNs in minor
     */
    {
      id: 'roman_numeral_triads_in_minor_video_6',
      title: "Identifying Inverted RN's",
      order: 14,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMinor6',
      },
    },
    /**
     * Exercise: ID Inverted RNs in minor, close spacing
     */
    {
      id: 'roman_numeral_triads_in_minor_id_inverted_rns_close_spacing',
      order: 15,
      title: "ID Inverted RN's",
      interactive: {
        type: 'RomanNumeralID',
        spacing: 'close',
        inversion: true,
        chordTypes: 'triads',
        questions: ['Ab min: iio6/bass', 'Ab min: i64/bass', 'Ab min: V/bass', 'Ab min: i6/bass'],
      },
    },
    /**
     * Exercise: ID Inverted RNs in minor, open spacing
     */
    {
      id: 'roman_numeral_triads_in_minor_id_inverted_rns_open_spacing',
      order: 16,
      title: "ID Inverted RN's",
      interactive: {
        type: 'RomanNumeralID',
        spacing: 'open',
        inversion: true,
        chordTypes: 'triads',
        questions: [
          'F# min: iv6/treble',
          'F# min: i64/treble',
          'F# min: V/treble',
          'F# min: i6/treble',
        ],
      },
    },
    /**
     * Video: RN Review
     */
    {
      id: 'roman_numeral_triads_in_minor_video_7',
      title: 'RN Review',
      order: 17,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMinor7',
      },
    },
    /**
     * Exercise: ID RN's in Major & minor, close & open spacings
     */
    {
      id: 'roman_numeral_triads_in_minor_id_open_spacings',
      order: 18,
      title: 'ID RNs in Major & minor',
      interactive: {
        type: 'RomanNumeralID',
        spacing: 'open',
        inversion: true,
        chordTypes: 'triads',
        questions: ['Db Maj: I6', 'E min: iv64', 'C Maj: V6', 'Bb min: viio6', 'F Maj: IV6'],
      },
    },
    /**
     * Exercise: Write RN's in Major & minor, open spacings
     */
    {
      id: 'roman_numeral_triads_in_minor_write_open_spacings_major_or_minor_keys',
      order: 19,
      title: "Write Open Spacing's",
      interactive: {
        type: 'RomanNumeralDraw',
        spacing: 'open',
        chordTypes: 'triads',
        questions: [
          'A min: i6',
          'Db Maj: ii6',
          'G min: V6',
          'Eb Maj: IV6',
          'Ab min: V6',
          'D Maj: viio6',
          'Bb min: V',
        ],
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'roman_numeral_triads_in_minor_video_8',
      title: 'Conclusion',
      order: 20,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMinor8',
      },
    },
  ],
};
