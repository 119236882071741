import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Understanding Meters
 * - Simple vs. Compound
 * - Binary/Duple/Triple/Quad
 */
export const lesson: Lesson = {
  id: 'understandingMeters',
  areaId: 'rhythm',
  order: 5,
  title: 'Understanding Meters',
  skills: ['simple_vs_compound', 'dup_trip_quad', 'meter_beat_value'],
  pages: [
    {
      id: 'understandingMeters_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Understanding Meters</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>The difference between simple & compound meters,</li>
        <li>How to recognize simple & compound time signatures,</li>
        <li>How to figure out what rhythmic value is the beat in a time signature, and</li>
        <li>How to figure out how many beats there are in a measure from the time signature.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: Describing simple meters:
     *
     *   Simple meters have beats that divide into two even parts. We've seen
     *   this in 2/4, 3/4, 4/4 and 5/4, all of which have a quarter note beat
     *   that divides into two even eighth notes.
     *
     *   But not all simple meters have a quarter note beat. In simple meters,
     *   the bottom number of the time signature tells us what note value the
     *   beat is.
     *
     *   For instance: /4 is a quarter note beat, /2 is a half-note beat. What
     *   would x/8 have as a beat? /1? /16?
     *
     *   In simple meters, the top number of the time signature tells us how
     *   many beats we have per bar:
     *   - When we have 2 beats, we call it a duple meter. 2/4, 2/2, 2/8, and so
     *     on are all simple duple meters. We say duple instead of double
     *     because it's closer to the latin "duplus" than "double" is.
     *   - When we have 3 beats, we call it a triple meter. 3/4, 3/8, 3/1, and
     *     so on are all simple triple meters.
     *   - When we have 4 beats, we call it a quadruple meter. 4/4, 4/2, 4/8 are
     *     all simple quadruple meters.
     *   - And, when we have 5 beats, we call it a quintuple meter. 5/4, 5/8/
     *     5/16 are all simple quintuple meters.
     *
     *   In fact, when you see 2, 3, 4 or 5 on top of a meter, it is a simple
     *   meter.
     *
     *   - How would you describe 3/2? Simple or compound? Duple, triple, quad,
     *     or quint? And what note value is the beat?
     *   - How about 5/4? Simple or compound? Duple, triple, quad or quint? And
     *     what note value is the beat?
     *
     *   4/4? Simple or compound? Dup...? Note value gets the beat?
     *
     *   In fact, 4/4 is so frequent it's almost the default, and so we can also
     *   call 4/4 "common time" and write it as a c. (You can think of that c as
     *   standing for common time, though it actually comes from the some of the
     *   earliest signs for time signatures from the medieval period, in which
     *   it represented the 'imperfectum minor' -- the least perfect of the time
     *   signatures in a time when perfection in music theory was related to the
     *   holy trinity because it had neither three-beats per bar, nor
     *   three-subdivisions per beat)
     *
     *   How would you describe 2/2? Duple compound. What note value gets the
     *   beat? Because 2/2 in some ways is like cutting 4/4 in half, it's known
     *   as cut-time, and can be written as C|.
     *
     *   Let's get some practice describing simple meters, and labeling the note
     *   value that gets the beat.
     */
    {
      id: 'understandingMeters_subdivision',
      order: 2,
      title: 'Simple Meters',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'understandingTimeSignatures1',
        rhythm: true,
      },
    },
    /**
     * Exercise: Interactive 1: simple meters dup/trip/quad, beat value
     */
    {
      id: 'understandingMeters_describeSimpleMeters',
      title: 'Describe Simple Meters',
      order: 3,
      interactive: {
        beatValue: true,
        type: 'MeterDescription',
        questions: ['3/4', '4/8', 'C', '3/16', 'C|', '4/2', '3/1', '2/8'],
        autoadvance: true,
      },
    },
    /**
     *  Video 2: Describing compound meters:
     *
     *  Compound meters have dotted beats that divide into three even parts. For
     *  instance, 6/8 has two dotted quarter note beats, each of which divides
     *  into three eighth notes.
     *
     *  When we look at the time signature for a compound meter, it describes
     *  the number and kind of subdivisions per bar. To figure out how many
     *  beats there are, we have to divide the subdivision by three -- because
     *  our beats are made up of three even subdivisions.
     *
     *  So: How many beats are there in 6/8 time? Two of them: six eighth-note
     *  subdivisions [IMAGE], grouped in groups of three [IMAGE] makes two
     *  beats. And how long are those beats? What note is three eighth-notes
     *  long? A dotted quarter note.
     *
     *  So we would call 6/8 a compound duple meter -- compound because its
     *  beats are made up of three subdivisions, and duple because there are two
     *  beats total.
     *
     *  Because compound meters time signatures describe subdivisions, and each
     *  beat in compound meters has three subdivisions, the top number of a
     *  compound time signatures is always divisible by 3. Compound time
     *  signatures have 6, 9, 12 or 15 on top of them.
     *
     *  What if we had 9/16 time. This means we have six sixteenth-note
     *  subdivision per bar. How many beats would we have? We group them in
     *  three, and see we have two beats. How long are the beats? What note is
     *  three sixteenth-notes long? A dotted eighth note.
     *
     *  So how would we describe 9/16 time? Is it simple or compound? Duple,
     *  triple, quad, or quint?
     *
     *  What about 12/4 time? First, how many beats?  ... and what note value
     *  gets the beat?
     *
     *  Let's get some practice describing simple meters, and labeling the note
     *  value that gets the beat.
     */
    {
      id: 'understandingMeters_compoundMeters',
      order: 4,
      title: 'Compound Meters',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'understandingTimeSignatures2',
        rhythm: true,
      },
    },
    /**
     * Exercise: describe compound meters (dup/trip/quad, beat value)
     */
    {
      id: 'understandingMeters_describeCompoundMeters',
      order: 5,
      title: 'Describe Compound Meters',
      interactive: {
        type: 'MeterDescription',
        beatValue: true,
        questions: ['9/8', '6/4', '12/16', '6/8', '9/16', '12/4'],
      },
    },
    /**
     *  Video: Simple or Compound Meters
     *
     *  Great -- you've succesfully described compound and simple meters
     *  separately. Now, let's mix them together.
     *
     *  Looking at a key signature, how can you quickly tell a simple meter from
     *  a compound meter? Simple meters have 2, 3, 4 or 5 in their time
     *  signature. Compound meters have bigger numbers that are divisible by
     *  three: 6, 9, 12 or 15 in their time signature.
     *
     *  It's worth mentioning that if a time signature on 3 is at a fast enough
     *  tempo, we can end up feeling it as a compound single meter. For
     *  instance, the Blue Danube waltz is written as 3/4 time -- but it goes so
     *  fast, it feels more like it's in 1/h. time.
     *
     *  It's also worth mentioning that the value of the beat doesn't
     *  necessarily tell you about the tempo -- many 3/8's are slower than many
     *  3/4's. For instance, you probably know the first movement of Beethoven's
     *  5th symphony -- which is in 2/4. BUT, the second movement is written in
     *  3/8, and is definitely slower than the 3/4 of the Blue Danube waltz.
     *
     *  Let's get some practice describing & identifying the beat of both simple
     *  and compound meters. Good luck!
     */
    {
      id: 'understandingMeters_simpleVsCompound',
      title: 'Simple or Compound?',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'understandingTimeSignatures3',
        rhythm: true,
      },
    },
    /**
     * Exercise: describe simple & compound time
     */
    {
      id: 'understandingMeters_describeAllMeters',
      title: 'Describe Meters',
      order: 7,
      interactive: {
        type: 'MeterDescription',
        beatValue: true,
        questions: ['6/16', 'C|', '3/8', '9/4', '4/4', '6/8', '3/4', '12/8', '2/1', '9/16', 'C'],
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'understandingMeters_conclusion',
      title: 'Summary',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'understandingTimeSignatures4',
        rhythm: true,
      },
    },
  ],
};
