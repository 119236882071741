import type { LessonCheckpointTest } from '../../../definitions/checkpoint-definitions';

export const checkpoint: LessonCheckpointTest = {
  id: 'checkpoint_4_intervals',
  areaId: 'pitch_and_harmony',
  checkpoint: true,
  order: 30,
  title: 'Intervals',
  options: {
    name: 'Intervals',
    instructions: `<p>
        This checkpoint tests your mastery of intervals.
      </p>
      <p>
        <strong>All of your answers are final</strong>. You will not be able to go back to change answers on previous questions.
      </p>
      <p>
        You have up to {{totalTime}} to complete this checkpoint. Please be sure you have enough time before beginning the checkpoint.
      </p>
      <p>
        {{passingSentence}}
      </p>
      `,
  },
  sections: [
    /**
     * ## Section 1: Interval construction & ID
     */
    {
      options: {
        instructions: `<p>
            Section {{sectionNumber}}: Interval Construction & ID
          </p>
          <p>
            In this section you will play, write and identify {{totalQuestions}} intervals. You will have
            a maximum of {{secondsPerQuestion}} seconds per question, or {{sectionTime}} total.
          </p>
          `,
        name: 'Interval Construction & ID',
        timeLimit: '8:20',
        sortOrder: 'type',
        weight: 75,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'PianoIntervals',
              questionWeighting: 1,
              questionTypeFrequency: 0.75,
            },
            {
              questionType: 'IntervalsID',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
            {
              questionType: 'WriteIntervals',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: ['F3/P4/bass', 'F#5/P4/treble', 'B3/P4/treble'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Db2/P5/bass', 'Ab3/P5/bass', 'C#3/P5/bass', 'Gb4/P8/treble'],
            },
            {
              numberOfQuestions: 1,
              questions: ['E4/M3/treble', 'Db4/M3/treble', 'G3/M3/bass', 'A3/M3/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Eb3/M6/bass', 'Cb4/M6/treble', 'B2/M6/bass', 'D5/M6/treble'],
            },
            {
              numberOfQuestions: 1,
              questions: ['C#4/M7/treble', 'Bb2/M7/bass', 'G4/M7/treble', 'Ab2/M7/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['F#4/m3/treble', 'Eb5/m3/treble', 'G#3/m3/bass', 'C2/m3/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Eb3/m6/bass', 'Ab3/m6/treble', 'A2/m6/bass', 'F#4/m6/treble'],
            },
            {
              numberOfQuestions: 1,
              questions: ['D4/m7/treble', 'G#2/m7/bass', 'Bb3/m7/treble', 'Db3/m7/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Eb3/A2/bass', 'Ab3/A6/treble', 'Bb2/A5/bass', 'F4/A6/treble'],
            },
            {
              numberOfQuestions: 1,
              questions: ['B4/d5/treble', 'D3/d5/bass', 'G#2/d7/bass', 'F4/d7/treble'],
            },
            {
              numberOfQuestions: 1,
              questions: ['F#5/-P4/treble', 'Bb3/-P4/bass', 'Db5/-P4/treble', 'G3/-P4/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: [
                'F3/-P5/bass',
                'F#5/-P5/treble',
                'Eb4/-P5/treble',
                'A3/-P5/bass',
                'Fx5/-P8/treble',
              ],
            },
            {
              numberOfQuestions: 1,
              questions: ['C#6/-m3/treble', 'Bb4/-m3/treble', 'B3/-m3/bass', 'Db4/-m3/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['A5/-m6/treble', 'Ab5/-m6/treble', 'F#3/-m6/bass', 'Gb4/-m6/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['E5/-m7/treble', 'B4/-m7/treble', 'Eb4/-m7/bass', 'C4/-m7/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Bb4/-M3/treble', 'G4/-M3/treble', 'Db4/-M3/bass', 'D3/-M3/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['A5/-M6/treble', 'F4/-M6/treble', 'G#3/-M6/bass', 'C#3/-M6/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['C6/-M7/treble', 'E5/-M7/treble', 'G3/-M7/bass', 'Bb3/-M7/bass'],
            },
            {
              numberOfQuestions: 1,
              questions: ['C#4/-A2/bass', 'G#5/-A6/treble', 'B3/-A5/bass', 'C#5/-A6/treble'],
            },
            {
              numberOfQuestions: 1,
              questions: ['Db5/-d5/treble', 'Bb3/-d5/bass', 'Ab3/-d7/bass', 'E4/-d7/treble'],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'WriteIntervals',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 2,
              questions: [
                'Bb3/A11/treble',
                'A2/M10/bass',
                'C#4/d12/treble',
                'G3/m13/treble',
                'E2/m9/bass',
              ],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'WriteIntervals',
              questionWeighting: 1,
              questionTypeFrequency: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 3,
              questions: [
                'Fx4/-M3/treble',
                'Bbb3/A6/treble',
                'E#3/d5/bass',
                'Ebb3/M6/bass',
                'Cx4/-P5/bass',
                'B#5/-M6/treble',
              ],
            },
          ],
        },
      ],
    },
    /**
     * ## Section 2: Inversion, Compound & Enharmonic Intervals
     */
    {
      options: {
        instructions: `<p>
            Section {{sectionNumber}}: Inversion, Compound & Enharmonic Intervals
          </p>
          <p>
            In this section, you will have {{sectionTime}} ({{secondsPerQuestion}} sec/question)
            to answer {{sectionQuestions}} questions on interval inversion, compound intervals
            and enharmonic intervals. Any unanswered questions will be counted wrong.
          </p>
          <p>
            {{passingSentence}}
          </p>
          `,
        name: 'Inversion, Compound & Enharmonic Intervals',
        timeLimit: '2:00',
        sortOrder: 'type',
        weight: 25,
      },
      questionGroups: [
        {
          questionTypes: [
            {
              questionType: 'IntervalInversion',
              questionWeighting: 1,
              questionTypeFrequency: 2,
              options: {},
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: ['M3', 'M6'],
            },
            {
              numberOfQuestions: 1,
              questions: ['m2', 'm7'],
            },
            {
              numberOfQuestions: 1,
              questions: ['P4', 'P5'],
            },
            {
              numberOfQuestions: 1,
              questions: ['A2', 'd6', 'A4'],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'CompoundIntervals',
              questionWeighting: 1,
              questionTypeFrequency: 2,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 2,
              questions: ['m2', 'P4', 'M6', 'M7'],
            },
            {
              numberOfQuestions: 2,
              questions: ['M10', 'P12', 'P15'],
            },
          ],
        },
        {
          questionTypes: [
            {
              questionType: 'EnharmonicIntervals',
              questionWeighting: 1,
              questionTypeFrequency: 2,
              options: {
                genericIntervals: true,
              },
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 4,
              questions: ['m3', 'A4', 'm6', 'd7', 'A6', 'A1'],
            },
          ],
        },
      ],
    },
  ],
};
