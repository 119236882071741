import { action, computed, observable, makeObservable } from 'mobx';
import type { SectionLessonAssignment } from '@prisma/client';
import type {
  SectionLessonSettings,
  SectionRecord,
} from '../../../definitions/section-definitions';
import type { Lesson, LessonPage } from '../../../definitions/content-definitions';
import type { LessonCheckpointTest } from '../../../definitions/checkpoint-definitions';
import { lessonsStore } from '../../lessons/lessons.store';
import type { SectionLessonAreaStore } from './section.lessonArea.store';
import type SectionLessonsStore from './section.lessons.store';
import type SectionStore from '../section.store';
import { Saveable } from '../../user/saveable';
import type { UGradeColumnUserInfoHeader } from '../section.store';

type LessonOrCheckpointDefinition = Readonly<Lesson | LessonCheckpointTest>;

interface ULessonOrCheckpointGradeDataColumnHeader {
  title: string;
  type: 'overall' | 'section' | 'page';
  id: string | number;
  isPercent?: boolean;
}

export type ULessonOrCheckpointGradeColumnHeader =
  | UGradeColumnUserInfoHeader
  | ULessonOrCheckpointGradeDataColumnHeader;

export function isLessonCheckpointDefinition(
  lessonOrCheckpointDefinition: LessonOrCheckpointDefinition
): lessonOrCheckpointDefinition is LessonCheckpointTest {
  return (
    'checkpoint' in lessonOrCheckpointDefinition &&
    lessonOrCheckpointDefinition.checkpoint! &&
    !('pages' in lessonOrCheckpointDefinition)
  );
}

/**
 * The availability of a specific lesson or checkpoint and
 * its due date, along with the lesson or checkpoint object.
 */
export class SectionLessonOrCheckpointStore<
  T extends LessonOrCheckpointDefinition = LessonOrCheckpointDefinition
> extends Saveable {
  definition!: LessonOrCheckpointDefinition;

  id!: string;

  parent: SectionLessonsStore;

  root: SectionStore;

  area!: SectionLessonAreaStore;

  @computed get lmsTitle(): string {
    return isLessonCheckpointDefinition(this.definition)
      ? `${this.title} (Checkpoint)`
      : `${this.title} (Lesson)`;
  }

  /**
   * Caution: Status may not show whether a lesson/checkpoint is really required.
   * If its area is disabled, it will not be required. Use isVisible & isRequired
   * instead.
   */
  @observable status!: SectionLessonSettings['status'];

  @observable dueDate: Date | undefined;

  @computed
  get lmsStatus(): 'required' | 'optional' | 'hidden' {
    if (!this.isVisibleOnLMS) return 'hidden';
    if (this.isRequired) return 'required';
    return 'optional';
  }

  /**
   * Due Date for LMS -- note: since Google can only accept an due date in the future
   * this will return undefined for any due date in the past so that Classroom is not
   * updated. If DueDates are not set, this will return false to prompt its deletion.
   */
  @computed
  get lmsDueDate(): Date | false | undefined {
    if (this.root.options.deadlines.visible && this.dueDate) {
      // LMS Due Dates can ONLY be set in the future, so if the date is in the past, we send it as undefined
      // so that we don't overwrite any prior correctly set past due date.
      return this.dueDate.valueOf() > Date.now() + 30 * 1000 ? this.dueDate : undefined;
    }
    return false;
  }

  // @computed
  // get useDueDate(): boolean {
  //   // If due dates aren't enabled, nothing is ever due:
  //   if (!this.root.options.deadlines.visible) return false;

  //   // If this is not required, it's never due:
  //   if (this.status !== 'required') return false;

  //   // If it has a due date, use it!
  //   return !!this.dueDate;
  // }

  /**
   * If visible deadlines are off, all required lessons are included in grade to date.
   * If visible deadlines are on, if a deadline is set we test on that.
   * If no deadline is set, we include it in the grade to date. (However,
   * isDue will be false.)
   */
  @computed
  get includeInGradeToDate(): boolean {
    if (!this.isRequired) return false;
    if (!this.root.options.deadlines.visible) return true;
    if (!this.dueDate) return true;
    return this.isDue;
  }

  /**
   * If the section uses lesson skills and the lesson have due dates, then
   * we use those due dates. If no due date is set on a required lesson,
   * we do not mark it as due -- though we do mark it as includeInGradeToDate.
   */
  @computed
  get isDue(): boolean {
    // If this is not required, it's never due:
    if (this.status !== 'required') return false;

    // If due dates aren't enabled, nothing is ever due:
    if (!this.root.options.deadlines.visible) return false;

    return !!this.dueDate && this.dueDate < this.root.now;
  }

  @computed get isDueDateVisible(): boolean {
    return !!(this.isRequired && this.root.options.deadlines.visible && this.dueDate);
  }

  @computed get isVisible(): boolean {
    return !!this.area?.enabled && this.status !== 'hidden';
  }

  /**
   * Whether a lesson is visible on a connected LMS for the section. Optional lessons may
   * be set to be visible on uTheory but hidden in the LMS.
   */
  @computed get isVisibleOnLMS(): boolean {
    return this.isRequired || (this.isOptional && !this.root.options.lms.hideOptionalContent);
  }

  @computed get isRequired(): boolean {
    return !!this.area?.enabled && this.status === 'required';
  }

  @computed get isOptional(): boolean {
    return this.isVisible && !this.isRequired;
  }

  @computed get type(): T extends Lesson ? 'lesson' : 'checkpoint' {
    if (isLessonCheckpointDefinition(this.definition)) {
      return 'checkpoint' as T extends Lesson ? 'lesson' : 'checkpoint';
    }
    return 'lesson' as T extends Lesson ? 'lesson' : 'checkpoint';
  }

  @computed get nextLesson(): SectionLessonOrCheckpointStore | undefined {
    return this.parent.getNextLesson(this.id);
  }

  @computed get priorLesson(): SectionLessonOrCheckpointStore | undefined {
    return this.parent.getPriorLesson(this.id);
  }

  @computed get title(): string {
    if (isLessonCheckpointDefinition(this.definition)) {
      return this.definition.options.name || this.definition.title || 'Checkpoint';
    }

    const systems = this.root.options.systems;
    const solfegeMethod = systems.solfege.method;
    const rhythmMethod = systems.rhythm.method;

    if (typeof this.definition.title === 'object') {
      if (this.definition.title[solfegeMethod]) {
        return this.definition.title[solfegeMethod] as string;
      }
      if (this.definition.title[rhythmMethod]) {
        return this.definition.title[rhythmMethod] as string;
      }
    } else if ('title' in this.definition) {
      return this.definition.title as string;
    }
    return 'Page';
  }

  /**
   * Lesson pages with title determined based on section prefs
   */
  @computed get pages(): (LessonPage & { title: string })[] {
    if (isLessonCheckpointDefinition(this.definition))
      throw new Error('Cannot get pages for a checkpoint.');
    return this.definition.pages.map((page) => ({
      ...page,
      title: this.getPageTitle(page),
    }));
  }

  @computed get pagesById(): { [pageId: string]: LessonPage & { title: string } } {
    return this.pages.reduce(
      (accum: { [pageId: string]: LessonPage & { title: string } }, page) => ({
        ...accum,
        [page.id]: page,
      }),
      {}
    );
  }

  getPageTitle(page: LessonPage): string {
    const systems = this.root.options.systems;
    const solfegeMethod = systems.solfege.method;
    const rhythmMethod = systems.rhythm.method;

    if (typeof page.title === 'object') {
      if (page.title[solfegeMethod]) {
        return page.title[solfegeMethod] as string;
      }
      if (page.title[rhythmMethod]) {
        return page.title[rhythmMethod] as string;
      }
    } else if ('title' in page) {
      return page.title;
    }
    return 'Page';
  }

  @computed get gradeColumnInformation(): ULessonOrCheckpointGradeColumnHeader[] {
    const gradeColumns: ULessonOrCheckpointGradeColumnHeader[] = [];
    gradeColumns.push({
      title: 'ID',
      type: 'userInfo',
      id: 'userId',
    });

    gradeColumns.push({
      type: 'userInfo',
      id: 'userIdAndLastActiveAt',
      title: 'Refresh Key',
    });

    gradeColumns.push({
      title: 'Last name',
      type: 'userInfo',
      id: 'lastName',
    });

    // First name:
    gradeColumns.push({
      title: 'First name',
      type: 'userInfo',
      id: 'firstName',
    });

    gradeColumns.push({
      title: 'Name',
      type: 'userInfo',
      id: 'fullName',
    });

    gradeColumns.push({
      title: 'Overall',
      type: 'overall',
      id: 'overall',
      isPercent: true,
    });

    if (this.type === 'checkpoint' && this.root.options.requiredCheckpointPassingScore) {
      gradeColumns.push({
        title: 'Passed',
        type: 'overall',
        id: 'passed',
        isPercent: true,
      });
    }

    gradeColumns.push({
      title: 'Date',
      type: 'overall',
      id: 'date',
    });

    const pages: ULessonOrCheckpointGradeColumnHeader[] =
      this.type === 'lesson'
        ? this.pages.map((page) => ({
            title: page.title,
            type: 'page',
            id: page.id,
          }))
        : [];

    const sections: ULessonOrCheckpointGradeColumnHeader[] =
      this.type === 'checkpoint'
        ? (this.definition as LessonCheckpointTest).sections
            .map((s, i): ULessonOrCheckpointGradeColumnHeader | null => {
              if (s.options.disabled) return null;
              return {
                title: s.options.name,
                type: 'section',
                id: i,
                isPercent: true,
              };
            })
            .filter((s): s is ULessonOrCheckpointGradeColumnHeader => !!s)
        : [];

    return gradeColumns.concat(pages).concat(sections);
  }

  constructor(
    settings: SectionLessonSettings,
    lesson: T,
    parent: SectionLessonsStore,
    root: SectionStore
  ) {
    super();
    makeObservable(this);
    this.parent = parent;
    this.root = root;
    this.init(settings, lesson);
  }

  @action init(settings: SectionLessonSettings, lesson?: LessonOrCheckpointDefinition) {
    this.id = settings.id;
    this.status = settings.status;
    this.dueDate = settings.date ? new Date(settings.date) : undefined;
    const definition = lesson || lessonsStore.lessonsById[settings.id];
    if (!definition) throw Error(`Lesson definition not found for ${settings.id}`);
    this.definition = definition;
  }

  @action update(settings: SectionLessonSettings) {
    this.setStatus(settings.status);
    this.setDueDate(settings.date);
  }

  @action setStatus(status: this['status']) {
    this.status = status;
  }

  @action toggleStatus() {
    this.status =
      this.status === 'required' ? 'optional' : this.status === 'optional' ? 'hidden' : 'required';
  }

  @action setDueDate(date: Date | string | undefined | null) {
    if (date) {
      this.dueDate = new Date(date);
    } else {
      this.dueDate = undefined;
    }
  }

  @action save() {
    console.log('save!');
    const clientUserStore = this.root.root;
    if (!clientUserStore) {
      return Promise.reject('Save only available when clientUserStore is at root.');
    }

    return this.saveOrQueue<SectionLessonAssignment>(() =>
      clientUserStore?.api.ApiQueue.queue(
        () =>
          clientUserStore.api.$put(`api/sections/${this.root.id}/lessons/${this.id}`, {
            status: this.status,
            dueDate: this.dueDate || null,
          }),
        { maxRetries: 3 }
      )
    ).then((lessonAssignment) => {
      this.setStatus(lessonAssignment.status);
      this.setDueDate(lessonAssignment.dueDate);
    });
  }

  dehydrate(): SectionRecord['lessons'][number] {
    return {
      id: this.id,
      status: this.status,
      date: this.dueDate || null,
    };
  }
}
