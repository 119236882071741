import { observable, computed, action, makeObservable } from 'mobx';
import type { CheckpointResults } from '../../../definitions/checkpoint-definitions';
import type { CustomTestStore } from './custom.test.store';
import { isCheckpointPassed, getAttemptToUse } from './tests.utils';

export type CustomTestResultsWithNames = CheckpointResults & {
  firstName: string | null;
  lastName: string | null;
  email?: string | null;
  id: string;
};

export class RootlessTestAttemptStore {
  id: string;

  checkpointId: string;

  title: string;

  /**
   * The number of times this test has been attempted. Managed in rootles.test.results.store
   */
  @observable attempts: number = 1;

  @observable record!: CustomTestResultsWithNames;

  @computed get lastName() {
    return this.record.lastName;
  }

  @computed get firstName() {
    return this.record.firstName;
  }

  @computed get email() {
    return this.record.email;
  }

  @computed get userId() {
    return this.record.userId;
  }

  @computed get score() {
    return this.record.overall.percentage;
  }

  @computed get createdAt() {
    return new Date(this.record.createdAt);
  }

  @computed get updatedAt() {
    return new Date(this.record.updatedAt);
  }

  @computed get isPassed(): boolean {
    return isCheckpointPassed(this.record);
  }

  @computed get sections() {
    return this.record.sections.map((section) => {
      if (section.totalQuestions === 0) return undefined;
      return section.percentage;
    });
  }

  constructor(attempt: CustomTestResultsWithNames, private test?: CustomTestStore) {
    makeObservable(this);
    this.id = attempt.id;
    this.checkpointId = attempt.checkpointId;
    this.title = attempt.checkpointName;
    this.init(attempt);
  }

  @action init(attempt: CustomTestResultsWithNames) {
    this.record = attempt;
  }

  static getAttemptToUse(attempts: RootlessTestAttemptStore[], use: 'highest' | 'mostRecent') {
    return getAttemptToUse(attempts, use);
  }

  @computed get gradesRow(): (number | string | Date | boolean | null)[] {
    const model = this.test;
    if (!model) {
      throw Error('Can only get gradesRow for a rootless test store with a CustomTestStore.');
    }

    const columns = model.gradeColumnInformation;
    return columns.map((column) => {
      switch (column.type) {
        case 'userInfo':
          if (column.id === 'userId') return this.record.userId;
          if (column.id === 'firstName') return this.record.firstName;
          if (column.id === 'lastName') return this.record.lastName;
          if (column.id === 'fullName') return `${this.record.lastName}, ${this.record.firstName}`;

          return null;
        case 'overall':
          if (column.id === 'date')
            return this.record.updatedAt ? new Date(this.record.updatedAt) : null;
          if (column.id === 'overall') {
            return typeof this.score === 'number' ? this.score : null;
          }
          if (column.id === 'passed') {
            return this.isPassed;
          }
          if (column.id === 'attempts') {
            return this.attempts;
          }
          if (column.id === 'attemptId') {
            return this.record.id;
          }
          break;
        case 'section':
          return this.sections[column.id as number] ?? 0;
      }
      return null;
    });
  }
}
