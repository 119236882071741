/* eslint-disable max-classes-per-file */

import { action, computed, observable, _allowStateChanges, toJS, makeObservable } from 'mobx';
import type SectionKnowledgeSkillStore from '../../section/sectionKnowledge/section.knowledge.skill.store';
import type { JoinedSkillRecord } from '../../../definitions/skill-record';
import type { Accommodation } from '../../../definitions/user-object-definitions';
import { skillsById } from '../../knowledge/knowledge.store';
import type { UserTopicStore } from './user.topic.store';
import type { AbstractUserStore } from '../abstract.user.store';
import type {
  KnowledgeGraphEdge,
  KnowledgeGraphSkillNode,
} from '../../knowledge/knowledgeGraphDefinitions';
import type { UserAreaKnowledgeStore } from './user.area.knowledge.store';
import type UserKnowledgeStore from './user.knowledge.store';
import { merge } from '../../utils/merge';

export class UserSkillStore {
  id!: number;

  textId: string;

  questionData?: { date: Date; numQuestions: number; percent: number }[];

  root: AbstractUserStore;

  isSpeedRequired!: boolean;

  type: 'skill';

  @observable private userSkillsProgressById!: UserKnowledgeStore['userSkillsProgressById'];

  @computed get parents(): (UserSkillStore | UserTopicStore | UserAreaKnowledgeStore)[] {
    const isEdgeToMe = (e: KnowledgeGraphEdge): boolean => e.childId === this.definition.textId;
    const parentIds = this.root.knowledgeProgress.knowledgeGraphEdges
      .filter(isEdgeToMe)
      .map((e: KnowledgeGraphEdge) => e.parentId);

    const parents = ([] as (UserSkillStore | UserTopicStore | UserAreaKnowledgeStore)[])
      .concat(this.root.knowledgeProgress.skills)
      .concat(this.root.knowledgeProgress.topics)
      .concat(this.root.knowledgeProgress.areas)
      .filter((nodeStore: UserSkillStore | UserTopicStore | UserAreaKnowledgeStore) =>
        parentIds.includes(nodeStore.textId)
      );

    return parents;
  }

  @computed get children(): UserSkillStore[] {
    const isEdgeFromMe = (e: KnowledgeGraphEdge): boolean => e.parentId === this.definition.textId;
    const childIds = this.root.knowledgeProgress.knowledgeGraphEdges
      .filter(isEdgeFromMe)
      .map((e: KnowledgeGraphEdge) => e.childId);

    return this.root.knowledgeProgress.skills.filter((skill) => childIds.includes(skill.textId));
  }

  @computed get topic(): UserTopicStore | undefined {
    if (!this.parents.find((parent): parent is UserTopicStore => parent.type === 'topic')) {
      return {} as any;
    }

    return this.parents.find((parent): parent is UserTopicStore => parent.type === 'topic');
  }

  @computed get area(): UserAreaKnowledgeStore | undefined {
    return this.parents.find((parent): parent is UserAreaKnowledgeStore => parent.type === 'area');
  }

  @computed get title(): string {
    if (typeof this.definition.title === 'string') return this.definition.title;

    const solfegeMethod = this.root.preferences.solfegeMethod;
    if (this.definition.title[solfegeMethod]) {
      return this.definition.title[solfegeMethod] as string;
    }

    // This should never happen
    return this.definition.title.scaleDegrees;
  }

  @computed get definition(): KnowledgeGraphSkillNode {
    return skillsById[this.id]!;
  }

  @computed get model(): SectionKnowledgeSkillStore {
    const model = this.root.currentSection.knowledge.skillsById[this.id];
    if (!model) throw Error(`Unable to find section skill model from ${this.id} @UserSkillStore`);
    return model;
  }

  /**
   * This is the term used in the database -- it's confusing on the front end because
   * we truly only care about recentPercent. But we need this for calculations, so
   * it's marked as private here.
   */
  @computed get percent(): number {
    return this.userSkillsProgressById[this.textId]?.percent ?? 0;
  }

  /**
   * This is the term used in the database -- it's confusing on the front end because
   * we truly only care about recentPercent. But we need this for calculations, so
   * it's marked as private here.
   */
  @computed get recentPercent(): number {
    return this.userSkillsProgressById[this.textId]?.recentPercent ?? 0;
  }

  @computed get accuracy(): number {
    return this.recentPercent;
  }

  /**
   * In milliseconds.
   * This is the term used in the database -- it's confusing on the front end because
   * we truly only care about recentAverageTime. But we need this for calculations, so
   * it's marked as private here.
   */
  @computed get averageTime(): number | undefined {
    return this.userSkillsProgressById[this.textId]!.averageTime ?? undefined;
  }

  /**
   * In milliseconds.
   * This is the term used in the database -- it's confusing on the front end because
   * we truly only care about recentAverageTime. But we need this for calculations, so
   * it's marked as private here. Use property speed to get this.
   */
  @computed get recentAverageTime(): number | undefined {
    return this.userSkillsProgressById[this.textId]!.recentAverageTime ?? undefined;
  }

  /**
   * In seconds
   */
  @computed get speed(): number | null {
    const speed = this.recentAverageTime;
    if (speed === null || speed === undefined) return null;
    return speed / 1000;
  }

  @computed get numQuestions(): number {
    return this.userSkillsProgressById[this.textId]!.numQuestions;
  }

  @computed get questionsScore(): number {
    return this._scoreData.questionsScore;
  }

  @computed get score(): number {
    return this._scoreData.score;
  }

  @computed get accuracyScore(): number {
    return this._scoreData.accuracyScore;
  }

  @computed get speedScore(): number | null {
    return this._scoreData.speedScore;
  }

  @computed get includeInGradeToDate(): boolean {
    return this.model.includeInGradeToDate;
  }

  @computed get dueDate(): Date | undefined {
    return this.model.dueDate;
  }

  @computed get isDue(): boolean {
    return this.model.isDue;
  }

  @computed get isVisible(): boolean {
    return this.model.isVisible;
  }

  @computed get isRequired(): boolean {
    return this.model.isRequired;
  }

  @computed private get _scoreData() {
    const {
      textId: skillId,
      percent,
      recentPercent,
      averageTime,
      recentAverageTime,
      numQuestions,
    } = this;
    return getSkillProgress(
      this.id,
      {
        percent,
        recentPercent,
        averageTime: averageTime || undefined,
        recentAverageTime: recentAverageTime || undefined,
        numQuestions,
        skillId,
      },
      this.root.activeAccommodation
    );
  }

  // TODO(CP);
  constructor(
    textId: string,
    root: AbstractUserStore,
    userSkillsProgressById: UserKnowledgeStore['userSkillsProgressById']
  ) {
    makeObservable(this);
    this.type = 'skill';
    this.root = root;
    this.textId = textId;
    this.init(userSkillsProgressById);
  }

  @action init(userSkillsProgressById: { [textId: string]: JoinedSkillRecord }) {
    const skill = this.root.knowledgeProgress.knowledgeGraphNodes.find(
      (n) => n.textId === this.textId
    ) as KnowledgeGraphSkillNode | undefined;
    if (!skill) throw new Error(`Cannot initialize user skill ${this.textId} @UserSkillStore`);
    this.id = skill.id;
    this.isSpeedRequired = !!skill.master.speed;
    this.userSkillsProgressById = userSkillsProgressById;
    this.update(userSkillsProgressById[this.textId]);
  }

  @action update(
    skillRecord: Partial<JoinedSkillRecord> = {
      percent: 0,
      recentPercent: 0,
      numQuestions: 0,
    }
  ) {
    _allowStateChanges(true, () => {
      const skillProgress = this.userSkillsProgressById[this.textId] || skillRecord;
      const result = merge(skillProgress, skillRecord);
      (['percent', 'recentPercent', 'numQuestions'] as const).forEach((prop) => {
        if (result[prop] === undefined) {
          result[prop] = 0;
        }
      });
      if (!result.skillId) {
        result.skillId = this.textId;
      }

      this.userSkillsProgressById[this.textId] = result as Partial<JoinedSkillRecord> & {
        percent: number;
        recentPercent: number;
        numQuestions: number;
        skillId: number;
      };
    });
  }

  @action dehydrate(): JoinedSkillRecord {
    const record: JoinedSkillRecord = {
      numQuestions: this.numQuestions,
      percent: this.percent,
      recentPercent: this.recentPercent,
      skillId: this.textId,
    };
    if (this.averageTime) record.averageTime = this.averageTime;
    if (this.recentAverageTime) record.recentAverageTime = this.recentAverageTime;
    return toJS(record);
  }
}

/**
 * getSkillProgress: takes a userSkill object, examines the
 * mastery expectations for that skill from the skillsTree,
 * and returns scores for overall progress ("score"), and
 * progress in speed, accuracy, and the number of questions
 * answered.
 *
 * @param userSkill - as on user object, returned from API
 *  {
 *    numQuestions: 3,
 *    percent: 78,
 *    averageTime: 3000, // MS!!!
 *    recentPercent: 60,
 *    recentAverageTime: 3000 // MS
 *  }
 * @param accommodation - Only those that are valid in this
 * context -- i.e., valid for current section/institution
 * @returns
 * {
 *    score: 100 //calculated progress as a percent for skill
 *    speedScore: 100 //calculated score for speed as a percent
 *    speed: 10 // seconds
 *    questionsScore: 100 //calculated for num of questions answered
 *    questions: 3 // number of questions answered
 *    accuracyScore: 100 //calculated score for accuracy
 *    accuracy: 100 // percent
 * }
 */
function getSkillProgress(
  skillId: number,
  userSkill?: JoinedSkillRecord,
  accommodation?: Pick<Accommodation, 'accuracy' | 'time'>
): {
  score: number;
  accuracyScore: number;
  questionsScore: number;
  speedScore: number | null;
  isSpeedRequired: boolean;
} {
  const skillDefinition = skillsById[skillId]!;
  const isSpeedRequired = !!skillDefinition.master.speed;
  // In case of no data, return blank:
  if (!userSkill || userSkill.numQuestions === 0) return getEmptySkillProgress(isSpeedRequired);

  const recentPercent = userSkill.recentPercent || 0;
  const numQuestions = userSkill.numQuestions || 0;

  if (typeof skillDefinition === 'undefined') {
    throw new Error(`skill with id ${userSkill.skillId} cannot be found.`);
  }

  const accuracyAccommodationCoefficient = accommodation ? accommodation.accuracy || 1 : 1;

  // Calculate statistics:
  const accuracyScore = Math.min(
    recentPercent / (skillDefinition.master.accuracy * accuracyAccommodationCoefficient),
    1
  );
  const questionsScore = Math.min(numQuestions / skillDefinition.minQuestions, 1);

  let userSpeed: number | null;
  let requiredSpeed: number;
  let speedScore: number | null = null;
  let score: number;
  // Not all skills have speed:
  if (isSpeedRequired) {
    const speedAccommodationCoefficient = accommodation ? accommodation.time || 1 : 1;

    userSpeed = (userSkill.recentAverageTime || 0) / 1000 || null;
    requiredSpeed = speedAccommodationCoefficient * skillDefinition.master.speed!;
    speedScore = userSpeed ? Math.min(requiredSpeed / userSpeed, 1) : 0;
    score = (questionsScore * (speedScore + accuracyScore)) / 2;
  } else {
    score = questionsScore * accuracyScore;
  }

  return {
    score: score * 100,
    accuracyScore: accuracyScore * 100,
    questionsScore: questionsScore * 100,
    speedScore: isSpeedRequired ? speedScore! * 100 : null,
    isSpeedRequired,
  };
}

function getEmptySkillProgress(isSpeedRequired: boolean) {
  return {
    score: 0,
    accuracyScore: 0,
    questionsScore: 0,
    speedScore: isSpeedRequired ? 0 : null,
    isSpeedRequired,
  };
}
