/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
/* eslint { "no-use-before-define": ["error", { "functions": false, "classes": false }] } */
/* eslint { "@typescript-eslint/no-use-before-define": ["error", { "functions": false, "classes": false }] } */
import { action, computed, makeObservable } from 'mobx';
import type SectionKnowledgeSkillStore from './section.knowledge.skill.store';
import type SectionKnowledgeAreaStore from './section.knowledge.area.store';
import type SectionStore from '../section.store';
import type {
  KnowledgeGraphEdge,
  KnowledgeGraphTopicNode,
} from '../../knowledge/knowledgeGraphDefinitions';
import type { UGradeColumnUserInfoHeader } from '../section.store';

interface UKnowledgeTopicGradeDataColumnHeader {
  title: string;
  type: 'overall' | 'skill';
  id: string;
  rhythmSkillString?: string;
  isPercent?: boolean;
}

export type UKnowledgeTopicGradeColumnHeader =
  | UGradeColumnUserInfoHeader
  | UKnowledgeTopicGradeDataColumnHeader;

export default class SectionKnowledgeTopicStore {
  id!: number;

  textId!: string;

  definition!: KnowledgeGraphTopicNode;

  root: SectionStore;

  type!: 'topic';

  @computed get parents(): SectionKnowledgeAreaStore[] {
    const isEdgeToMe = (e: KnowledgeGraphEdge): boolean => e.childId === this.definition.textId;
    const parentIds = this.root.knowledge.knowledgeGraphEdges
      .filter(isEdgeToMe)
      .map((e: KnowledgeGraphEdge) => e.parentId);

    const parents = ([] as SectionKnowledgeAreaStore[])
      .concat(this.root.knowledge.areas)
      .filter((nodeStore: SectionKnowledgeAreaStore) => parentIds.includes(nodeStore.textId));

    return parents;
  }

  @computed get children(): SectionKnowledgeSkillStore[] {
    const isEdgeFromMe = (e: KnowledgeGraphEdge): boolean => e.parentId === this.definition.textId;
    const childIds = this.root.knowledge.knowledgeGraphEdges
      .filter(isEdgeFromMe)
      .map((e: KnowledgeGraphEdge) => e.childId);

    return this.root.knowledge.skills.filter((skill) => childIds.includes(skill.textId));
  }

  @computed get gradeColumnInformation(): UKnowledgeTopicGradeColumnHeader[] {
    const gradeColumns: UKnowledgeTopicGradeColumnHeader[] = [];
    gradeColumns.push({
      title: 'ID',
      type: 'userInfo',
      id: 'userId',
    });

    gradeColumns.push({
      title: 'Refresh Key',
      type: 'userInfo',
      id: 'userIdAndLastActiveAt',
    });

    gradeColumns.push({
      title: 'Last name',
      type: 'userInfo',
      id: 'lastName',
    });

    // First name:
    gradeColumns.push({
      title: 'First name',
      type: 'userInfo',
      id: 'firstName',
    });

    gradeColumns.push({
      title: 'Name',
      type: 'userInfo',
      id: 'fullName',
    });

    gradeColumns.push({
      title: 'Overall',
      type: 'overall',
      id: 'overall',
      isPercent: true,
    });

    const skillColumns: UKnowledgeTopicGradeColumnHeader[] = this.skills
      .filter((s) => s.isVisible)
      .map((s) => ({
        title: s.title,
        type: 'skill',
        id: s.textId,
        rhythmSkillString: s.definition.rhythm ? s.textId : undefined,
        isPercent: true,
      }));

    return gradeColumns.concat(skillColumns);
  }

  @computed
  get status(): 'required' | 'optional' | 'hidden' {
    if (this.isRequired) return 'required';
    if (this.isVisible) return 'optional';
    return 'hidden';
  }

  @computed get title(): string {
    if (typeof this.definition.title === 'string') return this.definition.title;
    const systems = this.root.options.systems;
    const solfegeMethod = systems.solfege.method;

    if (this.definition.title[solfegeMethod]) {
      return this.definition.title[solfegeMethod] as string;
    }

    // This should never happen
    return this.definition.title.scaleDegrees;
  }

  @computed get lmsTitle(): string {
    return `${this.title} (Skill Practice)`;
  }

  @computed get area(): SectionKnowledgeAreaStore | undefined {
    return this.parents.find((store): store is SectionKnowledgeAreaStore => store.type === 'area');
  }

  @computed get skills(): SectionKnowledgeSkillStore[] {
    return this.root.knowledge.skills.filter((s) => s.topic?.textId === this.textId);
  }

  @computed
  get isVisible() {
    return this.skills.some((skill) => skill.isVisible);
  }

  /**
   * Whether a lesson is visible on a connected LMS for the section. Optional lessons may
   * be set to be visible on uTheory but hidden in the LMS.
   */
  @computed get isVisibleOnLMS(): boolean {
    return this.isRequired || (this.isOptional && !this.root.options.lms.hideOptionalContent);
  }

  @computed
  get lmsStatus(): 'required' | 'optional' | 'hidden' {
    if (!this.isVisibleOnLMS) return 'hidden';
    if (this.isRequired) return 'required';
    return 'optional';
  }

  @computed
  get isRequired() {
    return this.skills.some((skill) => skill.isRequired);
  }

  @computed
  get isOptional() {
    return this.isVisible && !this.isRequired;
  }

  @computed
  get isDue(): boolean {
    return this.skills.some((skill) => skill.isDue);
  }

  @computed
  get includeInGradeToDate(): boolean {
    return this.skills.some((skill) => skill.includeInGradeToDate);
  }

  constructor(definition: KnowledgeGraphTopicNode, root: SectionStore) {
    makeObservable(this);
    this.root = root;
    this.init(definition);
  }

  @action init(definition: KnowledgeGraphTopicNode) {
    this.definition = definition;
    this.type = 'topic';
    this.id = definition.id;
    this.textId = definition.textId;
  }

  @action setStatus(status: 'required' | 'optional' | 'hidden') {
    this.skills.forEach((skill) => skill.setStatus(status));
  }
}
