/* eslint-disable max-classes-per-file */
import { observable, action, computed, makeObservable } from 'mobx';
import type { SectionLessonAreaStore } from '../../section/sectionLessons/section.lessonArea.store';
import type { UserLessonOrCheckpointStore } from './user.lessonOrCheckpoint.store';
import type { AbstractUserStore } from '../abstract.user.store';
import { meanReducerFactory } from '../../utils/meanReducer';

export class UserLessonAreaStore {
  id!: number;

  textId!: 'pitch_and_harmony' | 'rhythm' | 'ear_training';

  title!: string;

  @observable lessons!: UserLessonOrCheckpointStore[];

  @computed get model(): SectionLessonAreaStore {
    return this.root.currentSection.lessons[this.textId];
  }

  @computed get enabled(): boolean {
    return this.model.enabled;
  }

  @computed get isRequired(): boolean {
    return this.model.isRequired;
  }

  @computed get isVisible(): boolean {
    return this.model.isVisible;
  }

  @computed get isOptional(): boolean {
    return this.isVisible && !this.isRequired;
  }

  /**
   * A lesson area is complete when every visible lesson or checkpoint has
   * been completed (or passed, per required settings)
   */
  @computed get isComplete(): boolean {
    return this.lessons.every((lesson) => lesson.isComplete);
  }

  @computed get visibleLessons(): UserLessonOrCheckpointStore[] {
    return this.lessons.filter((lesson) => lesson.isVisible);
  }

  @computed get requiredLessons(): UserLessonOrCheckpointStore[] {
    return this.lessons.filter((lesson) => lesson.isRequired);
  }

  @computed get progress(): number {
    return this.isRequired
      ? this.requiredLessons.map((lesson) => lesson.progress).reduce(meanReducerFactory(), 0)
      : this.lessons.map((lesson) => lesson.progress).reduce(meanReducerFactory(), 0);
  }

  getNextIncompleteLesson(): UserLessonOrCheckpointStore {
    return (
      this.requiredLessons.find((lesson) => !lesson.isComplete) ||
      this.lessons.find((lesson) => !lesson.isComplete) ||
      this.lessons[0]
    );
  }

  getNextLesson(lessonId: string): UserLessonOrCheckpointStore | undefined {
    const index = this.lessons.findIndex((lesson) => lesson.id === lessonId);
    if (index === -1)
      throw Error(`Index not found for lesson ${lessonId} @user.lessons.area.store getNextLesson`);
    return this.lessons[index + 1];
  }

  getPriorLesson(lessonId: string): UserLessonOrCheckpointStore | undefined {
    const index = this.lessons.findIndex((lesson) => lesson.id === lessonId);
    if (index === -1)
      throw Error(`Index not found for lesson ${lessonId} @user.lessons.area.store getPriorLesson`);
    return this.lessons[index - 1];
  }

  constructor(lessons: UserLessonOrCheckpointStore[], public root: AbstractUserStore) {
    makeObservable(this);
    this.init(lessons);
  }

  @action init(lessons: UserLessonOrCheckpointStore[]) {
    this.lessons = lessons;
    this.id = this.lessons[0].model.area.id;
    this.textId = this.lessons[0].model.area.textId;
    this.title = this.lessons[0].model.area.title;
  }
}
