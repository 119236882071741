import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Understanding Rhythm Values
 */
export const lesson: Lesson = {
  id: 'understandingNoteValues',
  areaId: 'rhythm',
  order: 3,
  title: 'Understanding Rhythm Values',
  skills: ['basic_rhythm_math', 'dotted_rhythm_math'],
  pages: [
    {
      id: 'understandingNoteValues_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Rhythmic Values</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>The symbols for notes and rests of different durations,</li>
        <li>The relationship between faster and slower notes,</li>
        <li>About dots and ties, and</li>
        <li>How to figure out how many notes of one rhythmic value fit within another rhythmic value</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * VIDEO: Rhythm Tree
     *
     * Our system of rhythmic notation is based on the concept of note values
     * that are twice as fast or twice as slow as each other.
     *
     * The fraction words we use to describe rhythmic values help make this
     * obvious: A whole note can be divided into two half notes. Each of those
     * half notes can be divided into two quarter notes. And each of those can
     * be divided into two eighth notes.
     *
     * As we've learned, an eighth note has one flag on its stem, and when
     * multiple eighth notes are within the same beat, a beam connecting them
     * replaces the flags.
     *
     * As we divide notes further, we'll add a flag or beam each time.
     *
     * Eighth notes can be divided into two sixteenth notes, which have two
     * flags, or two beams when they occur in the same beat.
     *
     * Sixteenths can be divided into thirty-second notes, which have three
     * flags or beams.
     *
     * We could continue this indefinitely!
     *
     * Let's make the same chart for our rests:
     *
     * Starting with a whole rest -- which you might remember because it looks a
     * bit like a hole in the ground.
     *
     * A whole rest can be divided into two half rests -- you might remember
     * this because half sort of rhymes with hat and it kind of looks like a
     * hat.
     *
     * A half rest can be divided into two quarter rests. These are drawn as
     * three zigs (right left right) and a little letter c.
     *
     * A quarter rest divides into two eighth note rests. These have one flag on
     * them -- and note we never beam rests together, we always use flags.
     *
     * An eighth rest divides into two sixteenth rests, which just as sixteenth
     * notes have two flags.
     *
     * And sixteenth rests divide into thirty-second rests, which have three
     * flags.
     *
     * We could also continue this indefinitely!
     *
     * Let's get some practice matching note names, symbols and rest symbols!
     */
    {
      id: 'understandingNoteValues_rhythmicValues',
      order: 2,
      title: 'Note & Rest Duration',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'understandingNoteValues1',
        rhythm: true,
      },
    },
    /**
     * Exercise: Match rhythm symbols
     */
    {
      id: 'understandingNoteValues_tapMeter',
      title: 'Match Rhythmic Symbols',
      order: 3,
      interactive: {
        type: 'RhythmSorter',
        compound: false,
        listLength: 4,
        autoadvance: true,
      },
    },
    /**
     * VIDEO: Simple Rhythm Math
     *
     * As we perform rhythms, when we have longer notes, it's helpful to # be
     * aware of the smaller note values that make up the longer note, # and to
     * hear these subdivisions in our mind as we perform. Doing # this helps us
     * keep the relationship between different rhythmic # values more exact.
     *
     * So, if we had this rhythm: eeee h | h eeee, to be sure the half # note is
     * exactly the right length, we could imagine dividing it up # into eighth
     * notes and hear those eighth notes as we perform it.
     *
     * How many eighth notes are in a half note?  We know a half note has # two
     * quarters, and each of those has two eighths, so we get a total # of 4
     * eighths in a half note.
     *
     * You can think of this sort of calculating as being a kind of # rhythm
     * math: figuring out how many of one rhythmic value could fit # within
     * another. Imagining the rhythm tree is often helpful for # figuring these
     * out.
     *
     * How many eighth notes fit within a whole note?
     *
     * How many 16ths in a quarter?
     *
     * Give some a try on your own!
     */
    {
      id: 'understandingNoteValues_basicRhythmMath',
      order: 4,
      title: 'Rhythm Math',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'understandingNoteValues2',
        rhythm: true,
      },
    },
    /**
     * Exercise: How many eighths in a ___? (no dots)
     */
    {
      id: 'understandingNoteValues_basicRhythmMathPractice',
      order: 5,
      title: 'Rhythm Math Practice',
      interactive: {
        type: 'RhythmMath',
        questions: ['e/h', 's/h', 'q/w', 's/w', 'e/w', 'q/h', 's/q'],
      },
    },
    /**
     * VIDEO: Ties
     *
     * A tie is a curved line connecting two notes of the same pitch, # that
     * tells us to perform the two notes as one longer note lasting # the
     * duration of the two notes combined. A tie is like an addition # symbol in
     * rhythmic notation: it adds the two rhythmic values # together.
     *
     * So if we had this rhythm: h_q q | w, how many quarter notes would # the
     * half tied to the quarter last? (3!)
     *
     * Let's perform this rhythm together.
     *
     * We can also have ties across a barline, imagine this rhythm: q q q q_|_q
     * q q q ||
     *
     * Although two quarters together make up a half note, because we can # only
     * fit 4 quarter notes in a 4/4 measure, we've had to split up # the half
     * note into two tied quarter notes and put a barline # between them. Let's
     * perform this rhythm together.
     *
     * Give some rhythms with ties a try on your own!
     */
    {
      id: 'understandingNoteValues_video_3',
      title: 'Ties',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'understandingNoteValues3',
        rhythm: true,
      },
    },
    /**
     * Exercise: Play rhythms with ties
     */
    {
      id: 'understandingNoteValues_play_ties',
      title: 'Play Ties',
      order: 7,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=80 h h | ht q q | h qt q | w',
          '3/4: q q q | qt q e e | e e q q | qt h',
          '2/4: e e e e | ht | e e q | qt q',
        ],
      },
    },
    /**
     * VIDEO: Dots
     *
     * When a note is tied to the next smallest note value, like a half # tied
     * to a quarter, we frequently use a notational shortcut: a dot.
     *
     * A half tied to a quarter, can be represented as a dotted half. # (You can
     * think of the dot as being that extra quarter note.)
     *
     * How could we represent a quarter note tied to an eighth note?
     *
     * If you saw a dotted eighth note, that would be the same as an # eighth
     * note tied to what?
     *
     * Let's get some practice understanding dotted note values.
     */
    {
      id: 'understandingNoteValues_video_4',
      title: 'Dots',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'understandingNoteValues4',
        rhythm: true,
      },
    },
    /**
     * Exercise: Dotted X = X + ____ (H. = H + ___) (rhythmMath w/
     * understandingDots option)
     */
    {
      id: 'understandingNoteValues_practiceDots',
      title: 'Dots Practice',
      order: 9,
      interactive: {
        type: 'RhythmMath',
        understandingDots: true,
        questions: ['h', 'e', 'q', 'w', 'qr', 'hr', 'er', 'wr'],
      },
    },
    /**
     * VIDEO: Dotted Rhythm Math
     *
     * When we perform dotted rhythms, it's also important for us to be ## able
     * to hear the internal subdivisions of these notes. Let's try ## some
     * rhythm math with dotted rhythms.
     *
     * How many eighth notes are in a dotted quarter?
     *
     * How many eighth notes are in a dotted half?
     *
     * How many dotted quarter notes are in a dotted half note? First, how many
     *   eighth notes are in a dotted quarter? (3)
     *
     *   Then, how many eighths are within a dotted half? 4 for the half, ## 2
     *   for the tied quarter, so 6 total.
     *
     *   Therefore, there are 2 dotted quarter notes in a dotted half.
     *
     * But, here's a shortcut: if both notes are dotted, they are both ## one
     * and a half times their original length, so you can just think ## of the
     * whole rhythm tree with dots on it. A dotted whole divides ## to two
     * dotted halfs, divides to two dotted quarters, divides to ## two dotted
     * eighths, and so on.
     *
     * Let's get some practice with dotted rhythm math!
     */
    {
      id: 'understandingNoteValues_video_5',
      title: 'Dotted Rhythm Math',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'understandingNoteValues5',
        rhythm: true,
      },
    },
    /**
     * Exercise: Dotted Rhythm Math
     *
     * How many dotted __ in a dotted __?
     * How many ___ in a dotted __?
     */
    {
      id: 'understandingNoteValues_practice_dotted_rhythm_math',
      title: 'Dotted Rhythm Math Practice',
      order: 11,
      interactive: {
        type: 'RhythmMath',
        skills: ['dotted_rhythm_math'],
        maxQuestions: 8,
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'understandingNoteValues_video_6',
      title: 'Conclusion',
      order: 12,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'understandingNoteValues6',
        rhythm: true,
      },
    },
  ],
};
