import type { FilterFunction } from './uniqueWithFilter';
import { uniqueWithFilter } from './uniqueWithFilter';

/**
 * Create a filter function for arrays that filters to a list of unique elements,
 * using the supplied element key.
 * ```typescript
 * [
 *   { name: 'Erin', age: 23 },
 *   { name: 'John', age: 22 },
 *   { name: 'Nancy', age: 15 },
 *   { name: 'Erin', age: 25 }
 * ].filter(uniqueByFilter('name'));
 * ```
 */
export function uniqueByFilter<T>(key: keyof T): FilterFunction<T> {
  return uniqueWithFilter((a, b) => a[key] === b[key]);
}
