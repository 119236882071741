import { action, observable, computed, makeObservable } from 'mobx';
import type { CustomCheckpointQuestionGroup } from '../../../definitions/checkpoint-definitions';
import { sum } from '../../utils/sum';
import type { CustomTestSectionStore } from './custom.test.section.store';
import type { CustomTestStore } from './custom.test.store';

export class CustomTestQuestionGroupStore {
  @observable section!: CustomTestSectionStore;

  test!: CustomTestStore;

  @computed get title(): string {
    return this.testQuestionGroup.options?.name || 'Question Group';
  }

  @computed private get questionGroupRecord(): CustomCheckpointQuestionGroup {
    return this.section.test.definition.sections[this.section.index].questionGroups[this.index];
  }

  @computed private get testQuestionGroup() {
    return this.section.test.checkpointTest.sections[this.section.index].questionGroups[this.index];
  }

  @computed get disabled(): boolean {
    return !!this.questionGroupRecord.options?.disabled;
  }

  @action setDisabled(disabled: boolean) {
    if (!this.questionGroupRecord.options) {
      this.questionGroupRecord.options = {};
    }
    this.questionGroupRecord.options.disabled = disabled;
  }

  @computed get numberOfQuestions(): number {
    return (
      this.questionGroupRecord.options?.numberOfQuestions ||
      this.testQuestionGroup.options?.numberOfQuestions ||
      this.testQuestionGroup.questionBanks.map((qb) => qb.numberOfQuestions).reduce(sum, 0)
    );
  }

  @computed get maxQuestions(): number {
    return this.testQuestionGroup.questionBanks.reduce(
      (totalQs, bank) => totalQs + (bank.maxQuestions || bank.questions.length),
      0
    );
  }

  @computed get defaultNumberOfQuestions(): number {
    return this.testQuestionGroup.questionBanks.reduce(
      (totalQs, bank) => totalQs + bank.numberOfQuestions,
      0
    );
  }

  @action setNumberOfQuestions(numberOfQuestions: number | null) {
    if (!this.questionGroupRecord.options) {
      this.questionGroupRecord.options = {};
    }
    this.questionGroupRecord.options.numberOfQuestions = numberOfQuestions;
  }

  constructor(public index: number, section: CustomTestSectionStore) {
    makeObservable(this);
    this.init(section);
    this.test = section.test;
  }

  @action init(section: CustomTestSectionStore) {
    this.section = section;
  }

  @action save() {
    this.test.save();
  }

  @action delayedSave() {
    if (!this.test.delayedSave)
      throw Error(
        'QuestionGroup.delayedSave can only be called from a ClientStore, not a UserStore'
      );
    this.test.delayedSave();
  }
}
