import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Triad Spacing and Inversion
 */
export const lesson: Lesson = {
  id: 'triad_spacing_inversion',
  areaId: 'pitch_and_harmony',
  order: 32,
  title: 'Triad Spacing & Inversion',
  skills: ['triad_spacing', 'triad_inversion', 'triad_figured_bass'],
  pages: [
    {
      id: 'triad_spacing_inversion_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Triad Spacing & Inversion</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>How to identify and write triads in open and close spacings</li>
        <li>How to invert triads,</li>
        <li>What figured bass is, and</li>
        <li>How to label inversions with figured bass.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * VIDEO: Triad Inversion (root, 1st, second)
     *
     * 1) Triad Inversion
     * - We can invert triads, just as we inverted intervals, by moving the
     *   bottom note up an octave. Because we have 3 notes instead of two, we
     *   can do this twice.
     *
     * - We call these: Root position, first inversion & 2nd inversion.
     *
     * - The notes of the chord keep their original names: root, 3rd and 5th,
     *   even when they're inverted. In this example, C is the root, regardless
     *   of inversion. E is the third, regardless of inversion, and G is the
     *   fifth, regardless of inversion.
     *
     * - The lowest note of a chord -- whether it's the root, third, or fifth --
     *   is called the bass.
     * - What part of the chord is the bass in root position? 1st inversion? 2nd
     *   inversion?
     *
     * - F# min, 1st inversion
     * - A Maj, 2nd inversion
     */
    {
      id: 'triad_spacing_inversion_video_1',
      order: 2,
      title: 'Triad Inversion',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'triadSpacingInversion1',
      },
    },
    /**
     * Exercise: Write inverted triads (root, 1st, second)
     */
    {
      id: 'triad_spacing_inversion_inversion',
      title: 'Write Inverted Triads',
      order: 3,
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: 'numbers',
        },
        questions: ['D Maj6/treble', 'C# m/bass', 'Bb M 64', 'G# d 6', 'E m 64'],
        autoadvance: true,
      },
    },
    /**
     * Video: Identifying Inversions
     * - When we look at an inverted triad, it will have a 4th in it somewhere.
     *   We can quickly spot the root, because it's always the upper note of the
     *   4th.
     * - What's the root of this chord? And which inversion is it in?
     *   - E Maj, 2nd inversion
     *   - Bb min, 1st inversion
     *
     *   Practice: Labeling root & inversion
     */
    {
      id: 'triad_spacing_inversion_video_2',
      title: 'Identifying Inversions',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triadSpacingInversion2',
      },
    },
    /**
     * Exercise: Identify inversion (root, 1st, 2nd)
     */
    {
      id: 'triad_spacing_inversion_id_inversion_number',
      title: 'Which Inversion?',
      order: 5,
      interactive: {
        type: 'ChordAndInversionID',
        quality: false,
        inversion: 'numbers',
        root: true,
        questions: ['B2 Maj 63', 'Db4 Maj 63', 'F2 dim 64', 'E4 dim', 'Eb3 min 64', 'G#3 min 64'],
      },
    },
    /**
     * Video: ID Inversion AND Quality
     *   - To figure out the quality of an inverted triad, it helps to imagine
     *     it in root position.
     *
     *   - Eb-Ab-C (Ab Maj 2nd Inversion)
     *   - E-G-C# (C# dim, 1st)
     *
     *   Practice: Labeling inversion & quality
     */
    {
      id: 'triad_spacing_inversion_video_3',
      title: 'ID Inverted Triads',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triadSpacingInversion3',
      },
    },
    /**
     * Exercise: Identify qualtiy & inversion for triads (root, 1st, 2nd)
     */
    {
      id: 'triad_spacing_inversion_id_quality_and_inversion_number',
      title: 'Inverted Triad ID',
      order: 7,
      interactive: {
        type: 'ChordAndInversionID',
        quality: true,
        inversion: 'numbers',
        root: true,
        questions: ['Bb2 Maj 63', 'B min 64', 'Eb4 Aug', 'A4 Maj 64', 'F#2 dim 6', 'C min 64'],
      },
    },
    /**
     * 4) Figured Bass:
     *
     * We can also label our inversions with what we call "figured bass" -- a
     * musical shorthand that indicates the intervals above the bass, the lowest
     * note.
     *
     * In root position, what are the intervals above the bass?
     *   - We call this 53, and draw it like this, with the larger interval
     *     number on top.
     * - First inversion? 63
     * - Second inversion? 64
     *
     * These figures -- which are already a shorthand -- have abbreviated forms,
     * that are used more commonly than the longhand versions you see now.
     *
     * If we don't write any figure, root position is assumed.
     * - 1st inversion can be abbreviated '6'
     * - 2nd inversion cannot be abbreviated -- we write out '64' every time.
     *
     */
    {
      id: 'triad_spacing_inversion_figures',
      order: 8,
      title: 'Figured Bass',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'triadSpacingInversion4',
      },
    },
    /**
     * Exercise: Match inversions, figures, and bass notes.
     */
    {
      id: 'triad_spacing_inversion_matching',
      order: 9,
      title: 'Inversion & Figures Matching',
      interactive: {
        type: 'InversionSorter',
        chordType: 'triads',
        autoadvance: true,
      },
    },
    /**
     * Video: Writing Triads in Inversion from Figures
     *
     *   Figured bass, this system of labeling the intervals above a bass, dates
     *   back to the 1500's, when composers started using it as a shorthand to
     *   make it easier for organists to accompany pieces for very large choirs.
     *   It has lasted through the centuries, such that over four hundred years
     *   later, musicians around the world are still studying it.
     *
     *     - Write triads w/ figures:
     *        D min 63
     *     - ID Triads w/ figures:
     *        B-E-G# (E Maj64)
     */
    {
      id: 'triad_spacing_inversion_figures_2',
      order: 10,
      title: 'Figured Bass',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'triadSpacingInversion5',
      },
    },
    /**
     * Exercise: Write Triads in Inversion from Figures
     */
    {
      id: 'triad_spacing_inversion_write_figures',
      title: 'Write Triads with Figures',
      order: 11,
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: 'figures',
        },
        questions: ['A Maj64/treble', 'Eb m63/bass', 'B M 64', 'F# d 6', 'C m 64'],
        autoadvance: true,
      },
    },
    /**
     * Exercise: Identify triads in inversion w/ figured bass
     */
    {
      id: 'triad_spacing_inversion_id_figures',
      title: 'Triad Figures ID',
      order: 12,
      interactive: {
        type: 'ChordAndInversionID',
        quality: true,
        inversion: 'figures',
        root: true,
        questions: ['A min 64', 'G Aug', 'Db Maj 63', 'F Maj 64', 'G# min 64', 'D#2 dim 6'],
      },
    },
    /**
     * Video: Triad Spacing
     *
     * Take a C Major triad -- as long as we keep these three notes, C E G, no
     * matter how we rearrange them, repeat them, change the octave, we'll
     * always call this some sort of C Major, as long as we stick to these three
     * notes and no others.
     *
     * - So we could write them very spread out like this: C3-G3 E4-C5
     * - Or close together with notes repeated in several octaves  C3-E3-G3-C4
     *   E4-G4-C5-E5
     * - Or with three together up high, and one alone down low C2 C5-E5-G5
     *
     * We call these choices about how to distribute the notes "Spacing"
     *
     * But how do we determine the inversion when we have different spacings?
     * We'll always look at the bass note -- remember that means the lowest
     * note.
     *
     * So in this version of C Major, we have E in the bass. What part of the
     * chord is that? (The 3rd). That means we're in 1st inversion, or 63
     * position in figured bass.
     *
     * In this next exercise, explore using different spacings of triads, as you
     * write triads in different inversions.
     */
    {
      id: 'triad_spacing_inversion_video_6',
      title: 'Triad Spacing',
      order: 13,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triadSpacingInversion6',
      },
    },
    /**
     * Exercise: Explore triad spacings as you write triads
     */
    {
      id: 'triad_spacing_inversion_write_open_spacing',
      title: 'Triad Spacing Exploration',
      order: 14,
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: 'figures',
        },
        spacing: 'open',
        questions: ['C# M 63', 'Eb m ', 'D M 64', 'B d 63', 'C M 63', 'F A ', 'Gb m 64'],
      },
    },
    /**
     * Video: ID Open Spacings
     *
     *   To identify triads with more open spacings and doublings:
     *   - Imagine the upper notes down octaves as close above the bass as
     *     possible
     *   - Then figure out the root & quality as normal.
     *
     *   Ex: G#2 - - - B3 - E4 - B4
     */
    {
      id: 'triad_spacing_inversion_video_7',
      title: 'ID Inversions in Open Spacing',
      order: 15,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triadSpacingInversion7',
      },
    },
    /**
     * Exercise: Identify triads in open spacing w/ inversions (root, 1st, 2nd)
     */
    {
      id: 'triad_spacing_inversion_open_spacing_id',
      title: 'Open Spacing ID',
      order: 16,
      interactive: {
        type: 'ChordAndInversionID',
        drill: true,
        spacing: 'open',
        root: true,
        quality: true,
        inversion: 'numbers',
        skills: [
          'major_triads',
          'minor_triads',
          'augmented_triads',
          'diminished_triads',
          'triad_inversion',
          'triad_spacing',
        ],
        maxQuestions: 10,
        autoadvance: true,
      },
    },
    /**
     * Video: Conclusion
     *
     * Learned:
     *  - How to write & identify inverted triads
     *  - The long and short versions of figured bass, for labeling inverted
     *    triads
     */
    {
      id: 'triad_spacing_inversion_video_8',
      title: 'Conclusion',
      order: 17,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triadSpacingInversion8',
      },
    },
  ],
};
