import type { Accommodation } from '../../definitions/user-object-definitions';

export function reduceAccommodations(accommodations: Pick<Accommodation, 'time' | 'accuracy'>[]) {
  return accommodations.reduce(
    (activeAccommodation, candidateAccommodation) => {
      activeAccommodation.time = Math.max(
        activeAccommodation.time!,
        candidateAccommodation.time || 1
      );
      activeAccommodation.accuracy = Math.min(
        activeAccommodation.accuracy!,
        candidateAccommodation.accuracy || 1
      );
      return activeAccommodation;
    },
    { time: 1, accuracy: 1 }
  );
}
