import { action, observable, toJS, computed, makeObservable } from 'mobx';
import type { SectionOptions as PGSectionOptions } from '@prisma/client';
import type { SectionOptions, SectionSystemsOptions } from '../../definitions/section-definitions';
import { defaultSectionSystemOptions } from '../../definitions/section-definitions';
import { Saveable } from '../user/saveable';

import { defaultsDeep } from '../utils/defaultsDeep';
import { merge } from '../utils/merge';
import type SectionStore from './section.store';

/**
 * All of the options a teacher can set that affect grading and
 * availability/visibility of skills, lessons and checkpoints
 */
export class SectionOptionsStore extends Saveable {
  constructor(sectionOptions: SectionOptions, root: SectionStore) {
    super();
    makeObservable(this);
    this.root = root;
    this.init(sectionOptions);
  }

  private root: SectionStore;

  @observable systems!: SectionSystemsOptions;

  @observable deadlines!: SectionOptions['deadlines'];

  @observable grading!: SectionOptions['grading'];

  @observable lessons!: SectionOptions['lessons'];

  @observable knowledge!: SectionOptions['knowledge'];

  @observable lms!: SectionOptions['lms'];

  @observable performanceThresholds!: SectionOptions['performanceThresholds'];

  @action init(sectionOptions: SectionOptions) {
    this.setSystemOptions(sectionOptions.systems);
    this.deadlines = sectionOptions.deadlines;
    this.grading = sectionOptions.grading;
    this.lessons = sectionOptions.lessons;
    this.knowledge = sectionOptions.knowledge;
    this.lms = sectionOptions.lms;
    this.performanceThresholds = sectionOptions.performanceThresholds;
  }

  @action update(options: Partial<SectionOptions>) {
    if (options.systems) this.setSystemOptions(options.systems);
    if (options.deadlines) this.setDeadlineOptions(options.deadlines);
    if (options.grading) this.setGradingOptions(options.grading);
    if (options.lessons) this.setLessonOptions(options.lessons);
    if (options.knowledge) this.setKnowledgeOptions(options.knowledge);
    if (options.performanceThresholds)
      this.setPerformanceThresholdOptions(options.performanceThresholds);
    if (options.lms) this.setLMSOptions(options.lms);
  }

  @action dehydrate(): SectionOptions {
    return {
      systems: toJS(this.systems),
      deadlines: toJS(this.deadlines),
      grading: toJS(this.grading),
      lessons: toJS(this.lessons),
      knowledge: toJS(this.knowledge),
      lms: toJS(this.lms),
      performanceThresholds: toJS(this.performanceThresholds),
    };
  }

  @action setSystemOptions(systems: Partial<SectionOptions['systems']>) {
    this.systems = defaultsDeep(systems, this.systems, defaultSectionSystemOptions);
  }

  @action setDeadlineOptions(deadlines: Partial<SectionOptions['deadlines']>) {
    merge(this.deadlines, deadlines);
  }

  @action setGradingOptions(grading: Partial<SectionOptions['grading']>) {
    merge(this.grading, grading);
  }

  @action setLessonOptions(lessons: Partial<SectionOptions['lessons']>) {
    merge(this.lessons, lessons);
  }

  @action setKnowledgeOptions(knowledge: Partial<SectionOptions['knowledge']>) {
    merge(this.knowledge, knowledge);
  }

  @action setLMSOptions(lms: Partial<SectionOptions['lms']>) {
    merge(this.lms, lms);
  }

  @action setPerformanceThresholdOptions(
    performanceThresholds: Partial<SectionOptions['performanceThresholds']>
  ) {
    merge(this.performanceThresholds, performanceThresholds);
  }

  @computed get requiredCheckpointPassingScore(): number | false {
    if (!this.lessons.mustPassPriorCheckpoint) return false;
    return !!this.lessons.checkpoints.passingPercent && this.lessons.checkpoints.passingPercent;
  }

  public save(): Promise<PGSectionOptions> {
    console.log('options', this);
    const clientUserStore = this.root.root;
    if (!clientUserStore) {
      return Promise.reject('Save only available when clientUserStore is at root.');
    }
    const record = this.dehydrate();
    return this.saveOrQueue<PGSectionOptions>(() =>
      clientUserStore?.api.ApiQueue.queue(
        () => clientUserStore.api.$put(`api/sections/${this.root.id}/options`, record),
        { maxRetries: 3 }
      )
    );
  }
}
