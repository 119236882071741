import type {
  CheckpointResults,
  SectionStats,
  CheckpointTest,
  LessonCheckpointTest,
} from './checkpoint-definitions';

export interface ContentArea {
  textId: string;
  id: number;
  title: string;
  lessons: (Lesson | CheckpointTest)[];
}

export interface ContentAreaWithSectionRequirements {
  textId: string;
  id: number;
  title: string;
  lessons: (LessonWithSectionRequirements | CheckpointWithSectionRequirements)[];
}

export interface ContentAreaWithProgress extends ContentAreaWithSectionRequirements {
  lessons: (LessonWithProgress | CheckpointWithProgress)[];
  /* 100-based */
  progress: number;
  completed: boolean;
}

export interface ContentWithProgress {
  rhythm: ContentAreaWithProgress;
  pitch_and_harmony: ContentAreaWithProgress;
  ear_training: ContentAreaWithProgress;
  [key: string]: ContentAreaWithProgress;
}

export interface ContentWithSectionRequirements {
  rhythm: ContentAreaWithSectionRequirements;
  pitch_and_harmony: ContentAreaWithSectionRequirements;
  ear_training: ContentAreaWithSectionRequirements;
  [key: string]: ContentAreaWithSectionRequirements;
}

export interface Lesson {
  id: string;
  areaId: 'rhythm' | 'pitch_and_harmony' | 'ear_training';
  /** Order in which the lesson should appear on the lessons page */
  order: number;
  title: LessonTitle;
  /** Array of string skillIds the lesson teaches */
  skills: string[];
  pages: LessonPage[];
}

export type LessonTitle =
  | string
  | {
      solfegeDoMinor?: string;
      solfegeLaMinor?: string;
      scaleDegrees?: string;
      american?: string;
      british?: string;
    };

export interface LessonPage {
  id: string;
  order: number;
  title: LessonTitle;
  skipForSolfegeMethods?: ('scaleDegrees' | 'solfegeDoMinor' | 'solfegeLaMinor')[];
  /** Interactive to execute */
  interactive?: {
    /** String interactive name to find in registry */
    type: string;
    /** Config properties unique to the interactive */
    [interactiveConfig: string]: any;
  };
  /** Video to play */
  video?: {
    autoadvance: boolean;
    autostart: boolean;
    url: string;
    /** If there are different videos depending on the configured solfege method */
    solfegeMethod?: boolean;
    /** If we solfegeDoMinor and solfegeLaMinor are equivalent */
    sameMinor?: boolean;
    rhythm?: boolean;
  };
  /** HTML content to display */
  content?: string;
}

export interface LessonWithSectionRequirements extends Lesson {
  order: number;
  areaId: 'rhythm' | 'pitch_and_harmony' | 'ear_training';
  isVisible: boolean;
  isDue: boolean;
  dueDate: Date | false;
  isRequired: boolean;
  isOptional: boolean;
  priorLesson?: LessonWithSectionRequirements;
  priorRequiredLesson?: LessonWithSectionRequirements;
}

export interface LessonWithProgress extends LessonWithSectionRequirements {
  isAvailable: boolean;
  isComplete: boolean;
  isInProgress: boolean;
  /** Between 0 - 1 */
  progress: number;
  /** Between 0 - 100 */
  percent: number;
  highestPageCompleted: number;
  isVisible: boolean;
  mustCompletePriorLesson: boolean;
  mustPassPriorCheckpoint: boolean;
}

export interface CheckpointWithSectionRequirements extends CheckpointTest {
  order: number;
  areaId: 'rhythm' | 'pitch_and_harmony' | 'ear_training';
  isVisible: boolean;
  isDue: boolean;
  dueDate: false | Date;
  isOptional: boolean;
  isRequired: boolean;
  priorLesson: LessonWithSectionRequirements;
  priorRequiredLesson: LessonWithSectionRequirements;
}

export interface CheckpointWithProgress extends CheckpointWithSectionRequirements {
  isComplete: boolean;
  isPassed: boolean;
  isInProgress: boolean;
  attemptsRemaining: number;
  progress: number;
  /* 100-based */
  percent: number;
  isAvailable: boolean;
  mustCompletePriorLesson: boolean;
  mustPassPriorCheckpoint: boolean;
}

export interface SectionStatsWithPassingStatus extends SectionStats {
  isPassed: boolean;
}

export interface CheckpointResultsWithPassingStatus extends CheckpointResults {
  isPassed: boolean;
  sections: SectionStatsWithPassingStatus[];
}

// Used to distinguish checkpoints & lessons in lesson list.
export function isLesson(
  lessonOrCheckpoint: Lesson | CheckpointTest | LessonCheckpointTest
): lessonOrCheckpoint is Lesson {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  if ((lessonOrCheckpoint as CheckpointTest)['checkpoint']) return false;

  // be sure it has at least id, areaId,
  return ['id', 'areaId', 'title', 'skills', 'pages'].every((key) =>
    lessonOrCheckpoint.hasOwnProperty(key)
  );
}

export function isLessonCheckpointTest(
  lessonOrCheckpoint: Lesson | CheckpointTest | LessonCheckpointTest
): lessonOrCheckpoint is LessonCheckpointTest {
  if ((lessonOrCheckpoint as LessonCheckpointTest)?.checkpoint !== true) return false;

  // And expect it to have an areaId and page order
  if (
    'areaId' in (lessonOrCheckpoint as LessonCheckpointTest) &&
    'order' in (lessonOrCheckpoint as LessonCheckpointTest)
  ) {
    return true;
  }

  return false;
}
