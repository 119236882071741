import { action, computed, observable, toJS, makeObservable } from 'mobx';
import type {
  SectionLessonSettings,
  SectionRecord,
} from '../../../definitions/section-definitions';
import type { Lesson } from '../../../definitions/content-definitions';
import { lessonsStore } from '../../lessons/lessons.store';
import {
  isLessonCheckpointDefinition,
  SectionLessonOrCheckpointStore,
} from './section.lessonOrCheckpoint.store';
import { SectionLessonAreaStore } from './section.lessonArea.store';
import type SectionStore from '../section.store';
import { uniqueFilter } from '../../utils/uniqueFilter';
import { groupByAsArrayReducer } from '../../utils/groupByReducers';
import { sortByFactory } from '../../utils/sortByFactory';

export default class SectionLessonsStore {
  root: SectionStore;

  @observable.shallow lessons!: SectionLessonOrCheckpointStore[];

  @observable.shallow areas!: SectionLessonAreaStore[];

  @computed get enabledAreas(): SectionLessonAreaStore[] {
    return this.areas.filter((area) => area.enabled);
  }

  @computed get visibleSkills(): string[] {
    return this.lessons
      .flatMap((lesson) => {
        if (!lesson.isVisible || isLessonCheckpointDefinition(lesson.definition)) return [];
        if (isLessonCheckpointDefinition(lesson.definition)) return [];
        return lesson.definition.skills;
      })
      .filter(uniqueFilter);
  }

  @computed get requiredSkills(): string[] {
    return this.lessons
      .flatMap((lesson) => {
        if (!lesson.isRequired || isLessonCheckpointDefinition(lesson.definition)) return [];
        if (isLessonCheckpointDefinition(lesson.definition)) return [];
        return lesson.definition.skills;
      })
      .filter(uniqueFilter);
  }

  constructor(
    lessons: SectionLessonSettings[],
    contentAreas: SectionRecord['areas'],
    root: SectionStore
  ) {
    makeObservable(this);
    this.root = root;
    if (lessons) this.init(lessons, contentAreas);
  }

  @action init(lessons: SectionLessonSettings[], contentAreas: SectionRecord['areas']) {
    // It is possible a lesson may exist in lessonsStore that is not in the
    // section lessons object on the database. (If, for instance, it's a new
    // lesson that was recently added.) In this case it should be marked as
    // hidden with no due date.
    //
    // It is also possible a lesson may exist in the database, but not in the
    // lessonsStore, if (for instance) a browser has an older cache. In this
    // case, we should not hold it against a student that they can't access it,
    // and should not punish their grade. So: we use the lessonsStore as the
    // source of truth.
    this.lessons = lessonsStore.lessons.map((lesson) => {
      const lessonOptions = lessons.find((potentialLesson) => potentialLesson.id === lesson.id) || {
        id: lesson.id as string,
        status: 'hidden',
        date: null,
      };
      return new SectionLessonOrCheckpointStore(lessonOptions, lesson, this, this.root);
    });
    // Set the area on each of the lessons:
    this.areas = this.lessons
      .reduce(groupByAsArrayReducer('definition.areaId'), [])
      .map((areaLessons) => {
        const areaEnabled = contentAreas.includes((areaLessons[0].definition as Lesson).areaId);
        const area = new SectionLessonAreaStore(areaLessons, areaEnabled);
        areaLessons.forEach((lesson) => {
          lesson.area = area;
        });
        return area;
      })
      .sort(sortByFactory('textId'))
      .reverse();
  }

  dehydrate(): SectionLessonSettings[] {
    return this.lessons.map((l) => {
      const settings: SectionLessonSettings = {
        id: l.id,
        status: toJS(l.status),
        date: l.dueDate || null,
      };
      return settings;
    });
  }

  @action update(lessons: SectionLessonSettings[]) {
    lessons.forEach((lessonSettings) => {
      const lesson = this.lessonsById[lessonSettings.id];
      if (!lesson) return;
      lesson.update(lessonSettings);
    });
  }

  @computed get rhythm(): SectionLessonAreaStore {
    return this.areas.find((area) => area.textId === 'rhythm') as SectionLessonAreaStore;
  }

  @computed get pitch_and_harmony(): SectionLessonAreaStore {
    return this.areas.find((area) => area.textId === 'pitch_and_harmony') as SectionLessonAreaStore;
  }

  @computed get ear_training(): SectionLessonAreaStore {
    return this.areas.find((area) => area.textId === 'ear_training') as SectionLessonAreaStore;
  }

  @computed get lessonsById(): { [id: string]: SectionLessonOrCheckpointStore } {
    return this.lessons.reduce(
      (
        dictionary: { [id: string]: SectionLessonOrCheckpointStore },
        lesson: SectionLessonOrCheckpointStore
      ) => {
        dictionary[lesson.id] = lesson;
        return dictionary;
      },
      {}
    );
  }

  getNextLesson(currentLessonId: string): SectionLessonOrCheckpointStore | undefined {
    const sectionLessonOrCheckpoint = this.lessonsById[currentLessonId];
    const area = this[sectionLessonOrCheckpoint.definition.areaId];
    const index = area.lessons.findIndex((lesson) => lesson.id === currentLessonId);
    if (index === -1) {
      throw Error(
        `Index not found for current lesson in lesson area. ${sectionLessonOrCheckpoint.id}`
      );
    }
    return area.lessons[index + 1];
  }

  getPriorLesson(currentLessonId: string): SectionLessonOrCheckpointStore | undefined {
    const sectionLessonOrCheckpoint = this.lessonsById[currentLessonId];
    const area = this[sectionLessonOrCheckpoint.definition.areaId];
    const index = area.lessons.findIndex((lesson) => lesson.id === currentLessonId);
    if (index === -1) {
      throw Error(
        `Index not found for current lesson in lesson area. ${sectionLessonOrCheckpoint.id}`
      );
    }
    return area.lessons[index - 1];
  }
}
