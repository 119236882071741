import { observable, computed, action, toJS, makeObservable } from 'mobx';
import type { UGradeColumnUserInfoHeader } from '../../section/section.store';
import type ClientUserStore from '../client.user.store';
import type {
  SavedCustomCheckpoint,
  MergedCustomCheckpoint,
} from '../../../definitions/checkpoint-definitions';
import { mergeTest, getAttemptToUse } from './tests.utils';
import type { TestAttemptStore } from './checkpoint.attempt.store';
import { CustomTestSectionStore } from './custom.test.section.store';
import type { Accommodation } from '../../../definitions/user-object-definitions';
import { SocketDispatcher } from '../../apiClient/socketDispatcher';
import { reduceAccommodations } from '../reduceAddommodation';
import { merge } from '../../utils/merge';
import { clone } from '../../utils/clone';
import type { UserStore } from '../user.store';
import { Saveable } from '../saveable';
import { clock } from '../../utils/observableClock';

interface UTestGradeDataColumnHeader {
  title: string;
  type: 'overall' | 'section';
  id: string | number;
  isPercent?: boolean;
}

type UTestGradeColumnHeader = UGradeColumnUserInfoHeader | UTestGradeDataColumnHeader;

/**
 * For custom tests. Allows editing & saving of properties,
 * as well as retrieving results.
 */
export class CustomTestStore extends Saveable {
  root?: ClientUserStore;

  @computed get id(): string {
    return this.definition.id;
  }

  @observable loading!: boolean;

  @observable saving!: boolean;

  @observable error!: false | string;

  @observable errorObject?: any;

  @observable private lastStatus = 'loading';

  @computed get status(): 'saving' | 'errored' | 'saved' | 'pristine' {
    return this.state;
  }

  @computed get statusMessage(): string {
    if (this.status === 'errored') return this.error || `Error ${this.lastStatus} test`;
    if (this.status === 'saving') return 'Saving test...';
    return this.lastStatus === 'saving' ? 'All changes saved.' : 'Loaded.';
  }

  @computed get title(): string {
    return (
      this.definition.options.name ||
      this.definition.tabTitle ||
      (this.definition as any).title ||
      'Test'
    );
  }

  @computed get lmsTitle(): string {
    return `${this.title} (Test)`;
  }

  @computed get isLandingPage(): boolean {
    if (!this.definition.landingPage) return false;
    if (this.definition.landingPage === 'never') return false;
    if (this.definition.landingPage === 'always') return true;
    if (this.definition.landingPage === 'incomplete') return !this.attempts.length;
    if (this.definition.landingPage === 'passing') return !this.attemptToUse?.isPassed;
    // this should never happen:
    return false;
  }

  @computed get isPassed(): boolean {
    return !!this.attemptToUse?.isPassed;
  }

  /**
   * We prioritize tests that have not been taken, then tests
   * that have been taken but failed, then tests that have been passed, finally
   * tests that are not landing pages. Lower numbers are higher priority.
   */
  @computed get landingPagePriority(): number {
    if (!this.isLandingPage) return 3;
    if (this.definition.landingPage === 'incomplete') return 0;
    if (this.definition.landingPage === 'passing') return 1;
    if (this.definition.landingPage === 'always') return 2;
    return 3;
  }

  @observable inviteUrl?: string;

  @observable sections!: CustomTestSectionStore[];

  @computed get ownerIds(): string[] {
    return this.definition.ownerIds || [];
  }

  @computed get teacherIds(): string[] {
    return this.definition.teacherIds || [];
  }

  @computed get teachingAssistantIds(): string[] {
    return this.definition.teachingAssistantIds || [];
  }

  @observable definition!: SavedCustomCheckpoint;

  @computed get gradeColumnInformation(): UTestGradeColumnHeader[] {
    const gradeColumns: UTestGradeColumnHeader[] = [];
    gradeColumns.push({
      title: 'ID',
      type: 'userInfo',
      id: 'userId',
    });

    gradeColumns.push({
      title: 'Refresh Key',
      type: 'userInfo',
      id: 'userIdAndLastActiveAt',
    });

    gradeColumns.push({
      title: 'Last name',
      type: 'userInfo',
      id: 'lastName',
    });

    // First name:
    gradeColumns.push({
      title: 'First name',
      type: 'userInfo',
      id: 'firstName',
    });

    gradeColumns.push({
      title: 'Name',
      type: 'userInfo',
      id: 'fullName',
    });

    gradeColumns.push({
      title: 'Overall',
      type: 'overall',
      id: 'overall',
      isPercent: true,
    });

    if (this.isPassingRequired) {
      gradeColumns.push({
        title: 'Passed',
        type: 'overall',
        id: 'passed',
      });
    }

    gradeColumns.push({
      title: 'Attempts',
      type: 'overall',
      id: 'attempts',
    });

    gradeColumns.push({
      title: 'Attempt ID',
      type: 'overall',
      id: 'attemptId',
    });

    gradeColumns.push({
      title: 'Date',
      type: 'overall',
      id: 'date',
    });

    const sections: UTestGradeColumnHeader[] = this.definition.sections
      .map((s, i): UTestGradeColumnHeader | null => {
        if (s.options.disabled) return null;
        if (s.questionGroups.every(qg => qg.options?.disabled)) return null;
        const modelSection = this.definition.model?.sections[i];
        return {
          title: modelSection?.options.name || `Section ${i + 1}`,
          type: 'section',
          id: i,
          isPercent: true,
        };
      })
      .filter((s): s is UTestGradeColumnHeader => !!s);

    return gradeColumns.concat(sections);
  }

  getEmptyGradesRow(root: UserStore): (number | string | Date | boolean | null)[] {
    if (!root) {
      throw Error('Can only get gradesRow for a custom test store attached to a user.');
    }
    const columns = this.gradeColumnInformation;
    return columns.map((column) => {
      switch (column.type) {
        case 'userInfo':
          return root.gradesRowUserInfo[column.id];
        case 'overall':
          if (column.id === 'date') return null;
          if (column.id === 'overall') return null;
          if (column.id === 'passed') return null;
          if (column.id === 'attempts') return 0;
          if (column.id === 'attemptId') return null;
          break;
        case 'section':
          return null;
      }
      return null;
    });
  }

  @computed get isEditable(): boolean {
    return !!this.definition.extendsId;
  }

  @computed get alwaysVisible(): boolean {
    return false;
    // return this.isOwner || this.isTeacher || !!this.root?.tests.find(t => t.id === this.id)
  }

  @computed get visibleFrom(): Date | null {
    // If the test is attached to the current section, use those availability dates:
    return this.sectionConnection?.visibleFromSet ?
      this.sectionConnection.visibleFrom
      : null;
  }

  @computed get visibleUntil(): Date | null {
    // If the test is attached to the current section, use those availability dates:
    return this.sectionConnection?.visibleUntilSet ?
      this.sectionConnection.visibleUntil
      : null;
  }

  @computed get dueDate(): Date | null {
    return this.sectionConnection?.dueDateSet ?
      this.sectionConnection.dueDate
      : null;
  }

  @computed get isPastDueDate(): boolean {
    return this.dueDate ? clock.time > this.dueDate : false;
  }

  @computed get isVisible(): boolean {
    const visibleInMenu = !!this.definition.hasTab;
    if (!visibleInMenu) return false;
    if (this.alwaysVisible) return true;

    if (this.visibleFrom && clock.time < this.visibleFrom) {
      return false;
    }

    if (this.visibleUntil && clock.time > this.visibleUntil) {
      return false;
    }

    return true;
  }

  @action addOwner(ownerId: string) {
    if (this.ownerIds.includes(ownerId)) return false;
    this.definition.ownerIds.push(ownerId);
    return true;
  }

  @action removeOwner(ownerId: string) {
    const index = this.ownerIds.indexOf(ownerId);
    if (index === -1) return false;
    this.definition.ownerIds.splice(index, 1);
    return true;
  }

  @computed get isOwner(): boolean {
    if (!this.root) return false;
    return this.definition.ownerIds.includes(this.root.id);
  }

  @action addTeacher(teacherId: string) {
    if (this.teacherIds.includes(teacherId)) return false;
    this.definition.teacherIds.push(teacherId);
    return true;
  }

  @action removeTeacher(teacherId: string) {
    const index = this.teacherIds.indexOf(teacherId);
    if (index === -1) return false;
    this.definition.teacherIds.splice(index, 1);
    return true;
  }

  @computed get isTeacher(): boolean {
    if (!this.root) return false;
    return this.definition.teacherIds.includes(this.root.id);
  }

  @action addTeachingAssistants(teachingAssistantId: string) {
    if (this.teachingAssistantIds.includes(teachingAssistantId)) return false;
    this.definition.teachingAssistantIds.push(teachingAssistantId);
    return true;
  }

  @action removeTeachingAssistants(teachingAssistantId: string) {
    const index = this.teachingAssistantIds.indexOf(teachingAssistantId);
    if (index === -1) return false;
    this.definition.teachingAssistantIds.splice(index, 1);
    return true;
  }

  @computed get isTeachingAssistant(): boolean {
    if (!this.root) return false;
    return this.definition.teachingAssistantIds.includes(this.root.id);
  }

  /**
   * Has the test been taken? Only available on ClientUserStore
   */
  @computed get isComplete(): boolean {
    return !!this.attempts.length;
  }

  /**
   * All of the current user's attempts for a test. Only available on
   * ClientUserStore.
   */
  @computed get attempts() {
    if (!this.root) return [];
    const attempts = this.root.testAttemptsByTestId[this.id] || [];
    return attempts.filter((attempt) => attempt.record.attemptStatus === 'complete');
  }

  /**
   * Attempt to use for the current user. Only available on ClientUserStore.
   */
  @computed get attemptToUse(): TestAttemptStore | undefined {
    return getAttemptToUse(this.attempts, this.definition.attemptToUse || false);
  }

  @computed get testTaken(): boolean {
    return !!this.attempts.length;
  }

  @computed get score(): number {
    const attempt = this.attemptToUse;
    return attempt ? attempt.record.overall.percentage : 0;
  }

  @computed get hasAttemptsRemaining(): boolean {
    return this.remainingAttempts > 0;
  }

  @computed private get sectionConnection() {
    return this.root?.currentSection.connectedTests.find(t => t.customCheckpointId === this.id) ?? null;
  }

  @computed get remainingAttempts(): number {
    const allowedRepetitions = this.definition.allowMultipleAttempts || 1;

    // unlimited:
    if (allowedRepetitions === -1) return Infinity;
    const totalAttempts = this.attempts.length;
    return Math.max(allowedRepetitions - totalAttempts, 0);
  }

  @computed get remainingAttemptsString(): string {
    switch (this.remainingAttempts) {
      case -1:
      case Infinity:
        return 'unlimited retakes';
      case 0:
        return 'no retakes';
      case 1:
        return 'one retake';
      default:
        return `${this.remainingAttempts} retakes`;
    }
  }

  /**
   * Returns a fully merged test, suitable for initializing a Tester.
   */
  @computed get checkpointTest(): MergedCustomCheckpoint {
    if (this.definition.model) {
      return mergeTest(this.definition, this.definition.model);
    }
    return this.definition as MergedCustomCheckpoint;
  }

  /**
   * Get any applicable accommodation for this custom test.
   * A section accommodation counts if:
   *   The test is attached to a section the user is in, and the user has a section accommodation.
   *   The user has a test-specific accommodation
   *   The user has a global accommodation
   */
  @computed get accomodation() {
    const defaultAccommodation: Pick<Accommodation, 'time' | 'accuracy'> = { time: 1, accuracy: 1 };
    const passing = clone(this.definition.passing);
    if (!passing) return defaultAccommodation;

    if (!this.root) return defaultAccommodation;

    // Is the test attached to a section the user is in, and the user has a section accommodation?
    // Or does the user have a test-specific accommodation?
    const sectionIdsWithTest = this.root.currentSections
      .filter((s) => s.connectedTests.map(t=>t.customCheckpointId).includes(this.id))
      .map((s) => s.id);
    const testOrSectionAccommodations: Accommodation[] = this.root.accommodations.filter(
      (a) => sectionIdsWithTest.includes(a.sectionId as string) || a.testId === this.id
    );

    // Does the user have a global accommodation?
    const globalAccommodations: Accommodation[] = this.root.accommodations.filter(
      (a) => !a.sectionId && !a.institutionId && !a.testId
    );

    // Does the user have an applicable institution accommodation?
    const institutionIdsWithTest = this.root.currentSections
      .filter((s) => s.connectedTests.map(t=>t.customCheckpointId).includes(this.id))
      .map((s) => s.institutionId)
      .filter((truthy): truthy is string => !!truthy);

    const institutionAccommodations: Accommodation[] = this.root.accommodations.filter((a) =>
      institutionIdsWithTest.includes(a.institutionId as string)
    );

    return reduceAccommodations([
      ...testOrSectionAccommodations,
      ...globalAccommodations,
      ...institutionAccommodations,
    ]);
  }

  @action setTab(visibility: boolean, text: string) {
    this.definition.hasTab = visibility;
    if (text) this.definition.tabTitle = text;
  }

  @action setAsLandingPage(useIf: 'incomplete' | 'passing' | 'always' | 'never') {
    this.definition.landingPage = useIf;
  }

  @computed get isPassingRequired(): boolean {
    return !!this.definition.passing?.type;
  }

  @action setPassingType(passingType: 'overall' | 'sections' | 'both' | null) {
    this.definition.passing!.type = passingType;
  }

  @action setOverallPassingType(overallPassingRequired: boolean) {
    const passingType = this.definition.passing?.type;
    if (overallPassingRequired) {
      if (passingType === 'sections') this.definition.passing!.type = 'both';
      if (!passingType) this.definition.passing!.type = 'overall';
    }
    if (!overallPassingRequired) {
      if (passingType === 'both') this.definition.passing!.type = 'sections';
      if (passingType === 'overall') this.definition.passing!.type = null;
    }
  }

  @action setOverallPassingScore(overallPassingScore: number) {
    this.definition.passing!.passingPercent = overallPassingScore;
  }

  @action setSectionPassingType(sectionPassingRequired: boolean) {
    const passingType = this.definition.passing?.type;
    if (sectionPassingRequired) {
      if (passingType === 'overall') this.definition.passing!.type = 'both';
      if (!passingType) this.definition.passing!.type = 'sections';
    }
    if (!sectionPassingRequired) {
      if (passingType === 'both') this.definition.passing!.type = 'overall';
      if (passingType === 'sections') this.definition.passing!.type = null;
    }
  }

  @action setSectionPassingScore(sectionPassingScore: number, sectionIndex?: number) {
    if (sectionIndex === undefined) {
      this.definition.sections.forEach((section) => {
        section.options.passingPercent = sectionPassingScore;
      });
    } else {
      const section = this.definition.sections[sectionIndex];
      if (!section) return;
      section.options.passingPercent = sectionPassingScore;
    }
  }

  @action setAllowedRepetitions(repetitions: number | false) {
    this.definition.allowMultipleAttempts = repetitions;
  }

  @action setPassword(password: string | false) {
    this.definition.password = password;
  }

  @action setInstructions(instructions: string) {
    this.definition.options.instructions = instructions;
  }

  constructor(customCheckpoint: SavedCustomCheckpoint, root?: ClientUserStore) {
    super();
    makeObservable(this);
    this.init(customCheckpoint, root);
  }

  @action init(customCheckpoint: SavedCustomCheckpoint, root?: ClientUserStore) {
    if (root) {
      this.root = root;
    }
    this.definition = customCheckpoint;
    if (customCheckpoint.inviteUrl) this.inviteUrl = customCheckpoint.inviteUrl;
    if (!this.definition.passing) {
      this.definition.passing = this.definition.passing || {
        type: null,
        passingPercent: 60,
      };
    }
    this.sections = this.definition.sections.map((__, i) => new CustomTestSectionStore(i, this));
  }

  @action update(customCheckpoint: Partial<SavedCustomCheckpoint>) {
    merge(this.definition, customCheckpoint);
    if (customCheckpoint.inviteUrl) this.inviteUrl = customCheckpoint.inviteUrl;
    // Because merge ignores empty arrays, and they matter:
    if (customCheckpoint.ownerIds) this.definition.ownerIds = customCheckpoint.ownerIds;
    if (customCheckpoint.teacherIds) this.definition.teacherIds = customCheckpoint.teacherIds;
    if (customCheckpoint.teachingAssistantIds)
      this.definition.teachingAssistantIds = customCheckpoint.teachingAssistantIds;
  }

  dehydrate(): SavedCustomCheckpoint {
    const definition = toJS(this.definition);
    if (definition.model) definition.extendsId = definition.model.id;
    delete definition.model;
    delete (definition as any).inviteUrl;
    return definition;
  }

  private socketDispatcher?: SocketDispatcher;

  private getSocket(): Promise<SocketDispatcher> {
    if (!this.socketDispatcher) {
      this.socketDispatcher = new SocketDispatcher(
        `${this.root?.api.webSocketUrl}/api/checkpoints/custom-checkpoints/${this.id}`
      );
      return this.socketDispatcher.open().then(() => this.socketDispatcher!);
    }
    return this.socketDispatcher.open().then(() => this.socketDispatcher!);
  }

  save(definition: Partial<SavedCustomCheckpoint> = this.dehydrate()) {
    if (!this.root)
      throw Error('CustomTestStore must have access to a root ClientUserStore to save.');
    if (!this.isOwner) {
      delete definition.ownerIds;
      delete definition.teacherIds;
    }
    return this.saveOrQueue<SavedCustomCheckpoint | void>(async () => {
      const socket = await this.getSocket();
      return this.root!.api.ApiQueue.queue<SavedCustomCheckpoint | void>(
        () =>
          socket.send<SavedCustomCheckpoint>('save', definition).catch((err) => {
            console.error(err);
            return Promise.reject(err);
          }),
        {
          actionText: 'saving custom test',
          triesBeforeWarning: 3,
          maxRetries: 5,
          noRetry: true,
        }
      );
    }).then((savedCustomCheckpoint) => {
      if (!savedCustomCheckpoint) return undefined;
      this.update(savedCustomCheckpoint);
      return savedCustomCheckpoint;
    });
  }

  delayedSave?: (testDefinition?: Partial<SavedCustomCheckpoint>) => Promise<void> | undefined;

  delete(): Promise<'OK'> {
    if (!this.root)
      throw Error('CustomTestStore must have access to a root ClientUserStore to delete.');
    return this.root.api.deleteCustomCheckpoint(this.id);
  }
}
