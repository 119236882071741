import type { Lesson } from '../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'et_major_scale',
  areaId: 'ear_training',
  order: 3,
  title: {
    solfegeDoMinor: 'Major Scale Solfege',
    solfegeLaMinor: 'Major Scale Solfege',
    scaleDegrees: 'Major Scale Degrees',
  },
  skills: [
    'sd_frag_stepwise_major',
    'asc_m2_et',
    'asc_M2_et',
    'dsc_m2_et',
    'dsc_M2_et',
    'major_scale_solfege',
    'major_scale_hand_signs',
    'tonic_or_not',
  ],
  pages: [
    {
      id: 'et_major_scale_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>The Major Scale</h3>
        <p>In this lesson we'll learn:
          <ul>
            <li>The words we use to sing the notes of the Major Scale</li>
            <li>About Moveable Do solfege and hand signs
            <li>To write stepwise melodies in scale degrees or solfege</li>
            <li>The kinds of steps that form the major scale, and</li>
            <li>How to hear these steps
          </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * VIDEO: Scale Degrees & Solfege
     */
    {
      id: 'et_major_scale_scale_degrees_and_solfege',
      order: 2,
      title: 'Scale Degrees & Solfege',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'et_major_scale_scale_degrees_and_solfege',
      },
    },
    /**
     * EXERCISE: Scale degree/solfege/(hand sign) matching
     */
    {
      id: 'et_major_scale_scale_degrees_and_solfege_matching',
      title: 'Solfege Match',
      order: 3,
      interactive: {
        type: 'SolfegeSort',
        mode: 'Major',
        columns: {
          scaleDegrees: true,
          solfege: true,
          handSigns: false,
        },
        autoadvance: true,
      },
    },
    /**
     * Exercise: "What scale degree is Do?"
     */
    {
      id: 'et_major_scale_scale_degrees_and_solfege_multiple_choice',
      title: 'Solfege ID',
      order: 4,
      interactive: {
        type: 'SolfegeScaleDegreeHandSignMultipleChoice',
        mode: 'Major',
        presentationSystemForQuestions: 'solfege',
        presentationSystemForAnswers: 'scaleDegrees',
        autoadvance: true,
      },
    },
    /**
     * VIDEO: Hand Signs
     */
    {
      id: 'et_major_scale_hand_signs',
      skipForSolfegeMethods: ['scaleDegrees'],
      order: 5,
      title: 'Hand Signs',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'et_major_scale_hand_signs',
      },
    },
    /**
     * EXERCISE: Hand Sign Matching
     */
    {
      id: 'et_major_scale_scale_degrees_and_hand_sign_matching',
      skipForSolfegeMethods: ['scaleDegrees'],
      title: 'Hand Sign Match',
      order: 6,
      interactive: {
        type: 'SolfegeSort',
        mode: 'Major',
        columns: {
          scaleDegrees: true,
          solfege: true,
          handSigns: true,
        },
        autoadvance: true,
      },
    },
    /**
     * Exercise: "What scale degree is `${handSign}`?"
     */
    {
      id: 'et_major_scale_scale_degrees_and_hand_sign_multiple_choice',
      title: 'Hand Sign ID',
      order: 7,
      interactive: {
        type: 'SolfegeScaleDegreeHandSignMultipleChoice',
        mode: 'Major',
        presentationSystemForQuestions: 'handSigns',
        presentationSystemForAnswers: 'solfege',
        autoadvance: true,
      },
    },
    /**
     * VIDEO: Stepwise motion
     */
    {
      id: 'et_major_scale_three_note_patterns',
      title: 'Stepwise Motion',
      order: 8,
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'et_major_scale_three_note_patterns_',
      },
    },
    /**
     * EXERCISE: Write solfege/sd for 3-5 note stepwise pattern, given the
     * starting solfege.
     */
    {
      id: 'et_major_scale_stepwise_solfege_dictation',
      order: 9,
      title: 'Stepwise Dictation',
      interactive: {
        type: 'MultipleNoteSolfegeID',
        drill: true,
        level: -4,
        maxQuestions: 16,
        notesPerQuestion: 3,
        tonicizeEvery: 20,
        autoadvance: true,
      },
    },
    /**
     * VIDEO: Tonic
     */
    {
      id: 'et_major_scale_tonic',
      order: 10,
      title: {
        solfegeDoMinor: 'Tonic (Do)',
        solfegeLaMinor: 'Tonic (Do)',
        scaleDegrees: 'Tonic (Scale Degree 1)',
      },
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'et_major_scale_tonic_',
      },
    },
    /**
     * EXERCISE: Tonic or Not
     */
    {
      id: 'et_major_scale_tonic_or_not',
      order: 11,
      title: 'Tonic or not?',
      interactive: {
        type: 'TonicOrNot',
        drill: true,
        mode: 'Major',
        autoadvance: true,
      },
    },
    /**
     * Video: Where are the half & whole steps in the scale?
     */
    {
      id: 'et_major_scale_half_and_whole_steps',
      order: 12,
      title: 'Scale Steps',
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'et_major_scale_half_and_whole_steps_',
      },
    },
    /**
     * EXERCISE: What kind of step is __ to ___ in Major?
     */
    {
      id: 'et_major_scale_half_and_whole_steps_in_scale_id',
      order: 13,
      title: 'ID Scale Steps',
      interactive: {
        type: 'ScalePatterns',
        longNames: true,
        hideAugSecond: true,
        useSolfegeSystem: true,
        skills: ['major_scales_piano'],
      },
    },
    /**
     * VIDEO: Hearning half & whole steps
     */
    {
      id: 'et_major_scale_hearing_half_and_whole_steps',
      order: 14,
      title: 'Half & Whole Steps',
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'et_major_scale_hearing_half_and_whole_steps_',
      },
    },
    /**
     * EXERCISE: Half vs Whole Step ID
     */
    {
      id: 'et_major_scale_half_and_whole_step_dictation',
      order: 15,
      title: 'Half & Whole Step ID',
      interactive: {
        type: 'IntervalsET',
        drill: true,
        buttons: ['m2', 'M2'],
        useHalfWholeNames: true,
        skills: ['asc_M2_et', 'asc_m2_et', 'dsc_M2_et', 'dsc_m2_et'],
      },
    },
    /**
     * Conclusion
     */
    {
      id: 'et_major_scale_outro',
      order: 16,
      title: 'Wrap Up',
      content: `
        <h3>Congratulations!</h3>\n<p>You've learned:
          <ul>
            <li>The words we use to sing the notes of the Major Scale</li>
            <li>About Moveable Do solfege and hand signs
            <li>To write stepwise melodies in scale degrees or solfege</li>
            <li>The kinds of steps that form the major scale, and</li>
            <li>How to hear these steps
          </ul>
        </p>
        <p>Great Work!</p>
        `,
    },
  ],
};
