import { observable, computed, makeObservable } from 'mobx';
import type { Subscription } from '../../../definitions/subscription-definitions';
import { subscriptionStringsToDates } from '../../../definitions/subscription-definitions';
import { merge } from '../../utils/merge';

export default class SubscriptionStore {
  @observable data!: Subscription;

  constructor(subscriptionData: Subscription) {
    makeObservable(this);
    this.data = subscriptionData;
    this.update(subscriptionData);
  }

  update(subscriptionData: Subscription) {
    merge(this.data, subscriptionStringsToDates(subscriptionData));
  }

  @computed get id(): string {
    return this.data.id;
  }

  @computed get planName(): string {
    return (
      this.data.items.data[0].plan.nickname ||
      this.data.items.data[0].price.nickname ||
      this.data.items.data[0].plan.id ||
      this.data.items.data[0].price.id
    );
  }

  @computed get ends(): Date {
    return (this.data.ended_at || this.data.current_period_end) as unknown as Date;
  }

  @computed get planId(): string {
    return this.data.items.data[0].plan.id || this.data.items.data[0].price.id;
  }

  @computed get nextPlanId(): string | null {
    return this.data.nextPlanId === this.planId ? null : this.data.nextPlanId || null;
  }

  @computed get isActive(): boolean {
    return this.data.status !== 'canceled';
  }

  @computed get isCanceled(): boolean {
    return this.data.status === 'canceled' || this.isRecentlyCanceled;
  }

  @computed get isRecentlyCanceled(): boolean {
    return this.data.cancel_at_period_end;
  }

  @computed get willBeSwitched(): boolean {
    const hasNextPlan = 'nextPlanName' in this.data;
    return hasNextPlan ? this.data.nextPlanName !== null : false;
  }

  @computed get canUpgrade(): boolean {
    return (
      this.isActive &&
      !this.isRecentlyCanceled &&
      (this.data?.nextPlanId || this.data?.items.data[0].plan?.id) === 'monthly'
    );
  }

  @computed get canDowngrade(): boolean {
    return (
      this.isActive &&
      !this.isRecentlyCanceled &&
      (this.data?.nextPlanId || this.data?.items.data[0].plan?.id) === 'yearly'
    );
  }

  @computed get canReactivate(): boolean {
    return this.isActive && this.isRecentlyCanceled;
  }
}
