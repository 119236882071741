import { action, observable } from 'mobx';

type SavingState = 'saving' | 'errored' | 'saved' | 'pristine';

export abstract class Saveable {
  @observable public state: SavingState = 'pristine';

  @action setState(state: SavingState) {
    this.state = state;
  }

  /**
   * Deletes itself when done.
   */
  private savingPromise: Promise<any> | null = null;

  /**
   * FIFO Queue for save requests
   */
  private pendingSaveQueue: (() => Promise<any>)[] = [];

  /**
   * Queue a save callback, resolves only when ALL save callbacks are complete,
   * rejects when the first of them rejects.
   */
  protected saveOrQueue<T>(saveCallback: () => Promise<T>): Promise<T> {
    const nextSaveFunction = () => {
      this.setState('saving');
      // Once this becomes the active save function...
      // save it:
      return saveCallback()
        .then((response: T) => {
          // Then, if there's one that's pending, execute it:
          if (this.pendingSaveQueue.length) {
            this.savingPromise = this.pendingSaveQueue.shift()?.() || null;
            return this.savingPromise || response;
          }
          this.savingPromise = null;
          this.setState('saved');
          return response;
        })
        .catch((err) => {
          this.setState('errored');
          return Promise.reject(err);
          // console.error(err);
          // if (this.pendingSave) {
          //   this.savingPromise = this.pendingSave();
          // } else {
          //   this.savingPromise = null;
          //   this.setState('errored');
          // }
        });
    };

    // If something is currently saving, pend the next save:
    if (this.savingPromise) {
      this.pendingSaveQueue.push(nextSaveFunction);
    } else {
      // Otherwise execute it:
      this.savingPromise = nextSaveFunction();
    }

    return this.savingPromise!;
  }

  abstract save(): Promise<any>;
}
