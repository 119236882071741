import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Seventh Chords
 */
export const lesson: Lesson = {
  id: 'seventh_chords',
  areaId: 'pitch_and_harmony',
  order: 33,
  title: 'Seventh Chords',
  skills: [
    'major_seventh_chords',
    'minor_seventh_chords',
    'dominant_seventh_chords',
    'diminished_seventh_chords',
    'half_diminished_seventh_chords',
  ],
  pages: [
    {
      id: 'seventh_chords_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Seventh Chords</h3>\n<p>In this lesson we'll learn:
        <ul>
        <li>The difference between triads &amp seventh chords</li>
        <li>How to spell seventh chords</li>
        <li>The five basic types of seventh chords, and</li>
        <li>How to write and identify these.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: Seventh Chord Intro
     *
     * ## 1. White note seventh chords
     *
     * - [ EX: C Dom 7]
     *
     * A seventh chord is formed by adding an additional third on top of a
     * triad. They ##are called "seventh chords" because that added note is a
     * seventh above the root ##of the chord.
     *
     * [ Happy Birthday example ]
     *
     * - Triads, which form the basis of all chords, have a lovely, clean
     *   elegance to ##them.
     * - Whereas seventh chords have a richer sound because of the built in
     *   dissonance ##of their seventh.
     * - In jazz, we even use notes beyond a seventh to give chords a luscious,
     *   complex ##fullness of sonority.
     *
     * As with triads, we refer to the notes of a seventh≠ chord by their
     * interval ##above the root of the chord. So, we have the root, the third,
     * the fifth, and the ##seventh.
     *
     * Let's spell some white note seventh chords.
     *
     * What would the letters of a seventh chord be above the note D?
     * - How about above F?
     *
     * Great, let's get some experience writing some white note seventh chords!
     */
    {
      id: 'seventh_chords_video_1',
      order: 2,
      title: 'Seventh Chords',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'seventhChords1',
      },
    },
    /**
     * Exercise: Spell white note seventh chords in letters
     */
    {
      id: 'seventh_chords_spelling',
      title: 'Spell Seventh Chords',
      order: 3,
      interactive: {
        type: 'ChordSpelling',
        triadOrSeventh: 'sevenths',
        drill: true,
        maxQuestions: 5,
      },
    },
    /**
     * Exercise: Draw white note seventh chords on the staff
     */
    {
      id: 'seventh_chords_spelling_staff',
      title: 'Draw Seventh Chords',
      order: 4,
      interactive: {
        type: 'ChordSpellingStaff',
        triadOrSeventh: 'sevenths',
        drill: true,
        maxQuestions: 5,
      },
    },
    /**
     * ## Video: Major 7ths
     *
     * Seventh chord qualities are determined by the quality of the triad and
     * the ## quality of the interval of the seventh above the root of the
     * chord.
     *
     * If the triad and the seventh have the same quality, that's what we call
     * the ## seventh chord.
     *
     * MAJOR 7TH: Ab MAJOR 7
     * - For instance, we had an Ab Major triad, and the interval of a Major 7th
     *   above Ab ## and combined them, we'd have an Ab Major 7th chord. A Major
     *   7th chord is just ## scale degrees 1-3-5-7 in a Major key.
     *
     * Let's write a D Major 7th chord. First we write our triad: DFA, and apply
     * the ## key signature of D Major. What notes will we need to change? The F
     * will become ## F#. Now let's write our interval of a Major 7th above the
     * root -- here again we ## apply the key signature of D Major. What kind of
     * C will we want? C#. And we have ## D Major 7.
     *
     * Major 7th chords have a fuzzy cheerfullness to them. They are quite
     * common in ## jazz music, but relatively rare in classical music.
     *
     * We can label Major 7ths as D Maj 7 or DM7, or in jazz contexts with a
     * triangle ## or triangle-7, either of which means a Major 7th.
     *
     * If both the triad and the seventh are minor, we have a minor 7th chord.
     */
    {
      id: 'seventh_chords_video_2',
      title: 'Major 7ths',
      order: 5,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'seventhChords2',
      },
    },
    /**
     * Exercise: Write M7 Chords on the staff
     */
    {
      id: 'seventh_chords_major_sevenths',
      title: 'Write Major 7ths',
      order: 6,
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
        },
        questions: ['E Maj7/treble', 'Bb3 Maj7', 'F#3 Maj7', 'Db3 Maj7'],
      },
    },
    /**
     * ## 3. Minor 7ths
     *
     * Let's write an F# minor 7th chord. How many sharps in the key of ## F#
     * minor? Now we write our 7th...
     *
     * We can label minor 7ths as F# min 7 or F#m7, or in jazz contexts ## with
     * a minus sign instead of the lower case m.
     *
     * Minor 7th chords have a warmth to them -- somehow the 7th seems ## to
     * mitigate a bit of the plaintiveness of the minor triad, ## perhaps that's
     * because if we look at the upper three notes of a ## minor 7th chord, we
     * see it happens to form a major triad.
     */
    {
      id: 'seventh_chords_video_3',
      title: 'Minor 7ths',
      order: 7,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'seventhChords3',
      },
    },
    /**
     * Exercise: Write m7 chords on the staff
     */
    {
      id: 'seventh_chords_minor_sevenths',
      title: 'Write minor 7ths',
      order: 8,
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
        },
        questions: ['Eb3 min7', 'B2 min7', 'C#3 min7', 'Ab3 min7'],
      },
    },
    /**
     * ## Video: Diminished 7ths
     *
     * If both the triad and seventh are diminished, we have a diminished 7th
     * chord.
     *
     * Let's write an E diminished 7th.
     *
     * We can label diminished 7th chords as E dim 7 or as Eo7. It's not enough
     * to write Eo: we could confuse that for a diminished triad!
     *
     * Diminished 7th chords (which we sometimes call "fully diminished seventh
     * chords" -- we'll see why in a moment) are tension filled and beg for
     * resolution. They're the chords we hear in Mario Brothers when we're about
     * to run out of time. What makes these chords so tension filled? They've
     * got two diminished fifths in them -- from the root to the fifth, and from
     * the third to the seventh.
     *
     * We've seen Maj 7th chord, minor 7th chords, and diminished (or
     * fully-diminished) 7th chords.
     *
     * What about an Augmented 7th chord? As it turns out, these don't exist.
     * Let's write a C Aug 7th chord and see why...
     *
     * Great: now let's get some practice writing and identify the three kinds
     * of 7th chords where both the triad and the seventh have the same quality:
     * Maj7, min7 and dim7 chords. Good luck!
     */
    {
      id: 'seventh_chords_video_4',
      order: 9,
      title: 'Diminished 7ths',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'seventhChords4',
      },
    },
    /**
     * Exercise: Write o7 (and some M7 & m7) chords
     */
    {
      id: 'seventh_chords_diminished_sevenths',
      order: 10,
      title: 'Write Maj, min, dim 7ths',
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
          quality: 'symbol',
        },
        questions: ['B4 dim7', 'F#3 dim7', 'G# dim7', 'A2 dim7', 'Ab3 Maj7', 'C#2 min7'],
      },
    },
    /**
     * ## 5. Dominant Seventh Chords
     *
     * We can also have seventh chords where the triad and the interval of the seventh have different qualities. Theoretically, lots of these are possible. But in practice, we typically only find the ones that can be made using notes from just one key. Let's look at one of these, the dominant seventh chord.
     *
     * For instance, here we have the notes from G up to F in the key of C Major. In other words, I've started on scale degree 5 (the dominant), and written all the notes going up from there in the key of C Major. If we pull out the thirds, G-B-D-F, let's see what kind of seventh chord we have.
     *
     * Is GBD a Major, minor, diminished or augmented triad? Think about G Major... are all of these notes in G Major? Yes! So we have a Major triad.
     *
     * Now how about G up to F -- is that a Major, minor, diminished or augmented seventh? Again, think abotu G Major, we would have expected F#, but we have F-natural, so, this is a minor seventh interval.
     *
     * This seventh chord has a Major triad and a minor 7th, so we can call it a Major-minor 7th chord. But: in practice, nobody actually calls it this except in beginning music theory text books. In reality, everyone calls it a dominant 7th because we find them built on the 5th scale degree, or dominant, in a key.
     *
     * We can label dominant sevenths as G Dom 7, but because dominant 7ths are the most frequently used kind of 7th chord, we can also just write G7. If we just write a 7 after a letter name for a chord, it means a dominant 7th.
     *
     * Let's write a Bb Dom 7, or just Bb7 chord -- remember, a dominant 7th chord has a Major triad and a minor 7th.
     *
     * Great, now let's get some practice writing dominant 7th chords.
     */
    {
      id: 'seventh_chords_video_5',
      order: 11,
      title: 'Dominant 7ths',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'seventhChords5',
      },
    },
    /**
     * Exercise: Write dom7 chords
     */
    {
      id: 'seventh_chords_dominant_sevenths',
      title: 'Write Dominant 7ths',
      order: 12,
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
          quality: 'symbol',
        },
        questions: ['F7', 'Eb3 Dom7', 'A3 Dom7', 'C#5 Dom7'],
      },
    },
    /**
     * ## 6. Half diminished 7th Chords:
     *
     * Let's take a look at the last of our five types of 7th chords.
     *
     * Here we have the notes from B up to A, again in C Major. Let's pull out
     * the thirds and see what kind of 7th chord we get. B-D-F-A.
     *
     * What kind of triad is B-D-F? Think about B Major...
     * - And what kind of 7th is B up to A? Again we think about B Major...
     * - So we have a diminished triad and a minor 7th, which we /could/ call a
     *   diminished-minor 7th chord. And you will see that name in some
     *   beginning music theory texts.
     *
     * But, in practice, we call this a half-diminished 7th chord, because half
     * of the chord (the triad) is diminished. The other half is minor.
     *
     * We label half-diminished 7th chords as B half-dim 7, or as Bø7 -- cutting
     * the diminished circle in half with a slash to indicate that this is a
     * half-diminished chord. In jazz contexts, you'll sometimes see these
     * labeled as Bm7 b5, because the only difference between this and minor 7th
     * chords is that the fifth is lowered a half-step.
     *
     * Let's write an A half-diminished 7th chord.
     *
     * Great, now let's get some practice writing half-diminished 7th chords.
     */
    {
      id: 'seventh_chords_video_6',
      title: 'Half-diminished 7ths',
      order: 13,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'seventhChords6',
      },
    },
    /**
     * Exercise: Write half-dim7 chords
     */
    {
      id: 'seventh_chords_write_open_spacing',
      title: 'Write Half-dim 7ths',
      order: 14,
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
          quality: 'symbol',
        },
        questions: ['G3 half-dim7/bass', 'F# half-dim7', 'D3 half-dim7', 'F half-dim7'],
      },
    },
    /**
     * ## Video: Just Five Flavors?
     *
     * We've learned about three kinds of seventh chords whose triads & sevenths
     * have the same qualities: Major, minor and diminished 7th chords.
     *
     * We've learned about two kinds of seventh chords with mixed qualities:
     * Dominant 7ths and half-diminished 7th chords.
     *
     * Of these, the Dominant 7th chord is by far the most common kind of
     * seventh chord used in music, followed by the minor 7th chord.
     *
     * There are other kinds of seventh chords, like a minor triad with a Major
     * 7th. Or an augmented triad with a minor 7th. These chords have
     * interesting, dramatic sounds, but they are so extremely rarely compared
     * to the five basic 7th chords, that we won't spend any time on them here.
     *
     * Take a moment now to review the five kinds of seventh chords by matching
     * the triad & seventh quality to the name of the seventh chord, and then
     * we'll have some practice writing the five kinds of seventh chords.
     */
    {
      id: 'seventh_chords_video_7',
      title: 'Just Five?',
      order: 15,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'seventhChords7',
      },
    },
    /**
     * Exercise: Seventh Chord Matching
     */
    {
      id: 'seventh_chords_matching',
      title: 'Seventh Chord Matching',
      order: 16,
      interactive: {
        type: 'SeventhChordSorter',
      },
    },
    /**
     * Exercise: Write seventh chords (all qualities)
     */
    {
      id: 'seventh_chords_writing',
      title: 'Write Seventh Chords',
      order: 17,
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
          quality: 'symbol',
        },
        drill: true,
        maxQuestions: 6,
        skills: [
          'major_seventh_chords',
          'minor_seventh_chords',
          'dominant_seventh_chords',
          'half_diminished_seventh_chords',
          'diminished_seventh_chords',
        ],
      },
    },
    /**
     * ## Video: Identifying Seventh Chords
     *
     * To identify a seventh chord, you'll need to look at the quality of the
     * triad and the quality of the 7th.
     *
     * Take this example: E-G#-B-D. What quality is the triad? How about the
     * 7th? And what do we call it when a triad is Major and a 7th is minor?
     *
     * Let's get some practice identifying seventh chords.
     *
     */
    {
      id: 'seventh_chords_video_8',
      title: 'Identifying 7th Chords',
      order: 18,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'seventhChords8',
      },
    },
    /**
     * Exercise: Identify all 7th chords
     */
    {
      title: 'ID 7th Chords Practice',
      order: 19,
      id: 'sevenths_id',
      interactive: {
        type: 'ChordAndInversionID',
        inversion: false,
        root: false,
        quality: true,
        drill: true,
        chords: [
          {
            type: 'sevenths',
          },
          {
            spacing: 'close',
          },
        ],
        skills: [
          'major_seventh_chords',
          'minor_seventh_chords',
          'dominant_seventh_chords',
          'half_diminished_seventh_chords',
          'diminished_seventh_chords',
        ],
        maxQuestions: 7,
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'seventh_chords_video_9',
      title: 'Conclusion',
      order: 20,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'seventhChords9',
      },
    },
  ],
};
