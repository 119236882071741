/* eslint-disable max-classes-per-file */
import { observable, action, computed, makeObservable } from 'mobx';
import type { CleanedLessonRecord } from '../../../definitions/lesson-record';

import { isCheckpoint } from '../../../definitions/checkpoint-definitions';
import { makeString } from '../../knowledge/knowledge.store';
import { lessonsStore } from '../../lessons/lessons.store';
import type { UserLessonOrCheckpointStore } from './user.lessonOrCheckpoint.store';
import { UserCheckpointStore } from './user.checkpoint.store';
import { UserLessonStore } from './user.lesson.store';

import { UserLessonAreaStore } from './user.lesson.area.store';
import type { AbstractUserStore } from '../abstract.user.store';
import { uniqueByFilter } from '../../utils/uniqueByFilter';
import { groupByAsArrayReducer } from '../../utils/groupByReducers';
import { sortByFactory } from '../../utils/sortByFactory';
import { meanReducerFactory } from '../../utils/meanReducer';
import { isLessonCheckpointDefinition } from '../../section/sectionLessons/section.lessonOrCheckpoint.store';

export class UserLessonsStore {
  lessons!: UserLessonOrCheckpointStore[];

  @observable.shallow areas!: UserLessonAreaStore[];

  @computed get isRequired(): boolean {
    return !!this.requiredLessons.length;
  }

  /**
   * Lessons are complete when every visible lesson or checkpoint has
   * been completed (or passed, per required settings)
   */
  @computed get isComplete(): boolean {
    return this.lessons.every((lesson) => lesson.isComplete);
  }

  /**
   * User's progress through required lessons & checkpoints. Does not take into account
   * any late penalty.
   */
  @computed get progress(): number {
    return this.requiredLessons.map((lesson) => lesson.progress).reduce(meanReducerFactory(), 0);
  }

  /**
   * User's score on required lessons & checkpoints. Takes into account late penalties.
   */
  @computed get score(): number {
    return this.requiredLessons.map((lesson) => lesson.score).reduce(meanReducerFactory(), 0);
  }

  @computed get lessonsById(): { [id: string]: UserLessonOrCheckpointStore } {
    return this.lessons.reduce(
      (dictionary: { [id: string]: UserLessonOrCheckpointStore }, lesson) => {
        dictionary[lesson.id] = lesson;
        return dictionary;
      },
      {}
    );
  }

  getLessonsBySkills(skills: (string | number)[]) {
    return skills
      .map(makeString)
      .flatMap((skill) =>
        this.lessons.filter(
          (lesson) =>
            !isLessonCheckpointDefinition(lesson.model.definition) &&
            lesson.model.definition.skills.includes(skill)
        )
      )
      .filter(uniqueByFilter('id'));
  }

  @computed get requiredLessonsAndCheckpoints(): UserLessonOrCheckpointStore[] {
    return this.lessons.filter((lesson) => lesson.isRequired);
  }

  @computed get requiredLessons(): UserLessonStore[] {
    return this.lessons.filter(
      (lesson): lesson is UserLessonStore => lesson.type === 'lesson' && lesson.isRequired
    );
  }

  @computed get requiredCheckpoints(): UserCheckpointStore[] {
    return this.lessons.filter(
      (lesson): lesson is UserCheckpointStore => lesson.type === 'checkpoint' && lesson.isRequired
    );
  }

  /**
   * User's progress through required lessons, does not take into account
   * any late penalty.
   */
  @computed get lessonsProgress(): number {
    // This should never happen.
    if (!this.requiredLessons.length) return 100;
    return this.requiredLessons.map((lesson) => lesson.progress).reduce(meanReducerFactory(), 0);
  }

  /**
   * User's scores on required lessons, including any late penalty.
   */
  @computed get lessonsScore(): number {
    // This should never happen.
    if (!this.requiredLessons.length) return 100;
    return this.requiredLessons.map((lesson) => lesson.score).reduce(meanReducerFactory(), 0);
  }

  @computed get checkpointsScore(): number {
    if (!this.requiredCheckpoints.length) return 100;
    return this.requiredCheckpoints
      .map((checkpoint) => checkpoint.score)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get lessonsForGradeToDateCalculation(): UserLessonStore[] {
    return this.requiredLessons.filter((lesson) => lesson.includeInGradeToDate);
  }

  @computed get includeLessonsInGradeToDate(): boolean {
    return this.lessonsForGradeToDateCalculation.length !== 0;
  }

  @computed get lessonsGradeToDate(): number {
    if (!this.includeLessonsInGradeToDate) return 100;
    return this.lessonsForGradeToDateCalculation
      .map((lesson) => lesson.score)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get checkpointsForGradeToDateCalculation(): UserCheckpointStore[] {
    return this.requiredCheckpoints.filter((checkpoint) => checkpoint.includeInGradeToDate);
  }

  @computed get includeCheckpointsInGradeToDate(): boolean {
    return this.checkpointsForGradeToDateCalculation.length !== 0;
  }

  @computed get checkpointsGradeToDate(): number {
    if (!this.includeCheckpointsInGradeToDate) return 100;
    return this.checkpointsForGradeToDateCalculation
      .map((checkpoint) => checkpoint.score)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get areasById(): { [id: string]: UserLessonAreaStore } {
    return this.areas.reduce(
      (dictionary: { [id: string]: UserLessonAreaStore }, area: UserLessonAreaStore) => {
        dictionary[area.id] = area;
        dictionary[area.textId] = area;
        return dictionary;
      },
      {}
    );
  }

  @computed get pitch_and_harmony(): UserLessonAreaStore {
    return this.areasById.pitch_and_harmony;
  }

  @computed get rhythm(): UserLessonAreaStore {
    return this.areasById.rhythm;
  }

  @computed get ear_training(): UserLessonAreaStore {
    return this.areasById.ear_training;
  }

  constructor(public root: AbstractUserStore, lessonRecords: CleanedLessonRecord[]) {
    makeObservable(this);
    this.init(lessonRecords);
  }

  @action init(lessonRecords: CleanedLessonRecord[]) {
    this.lessons = lessonsStore.lessons.map((lesson) => {
      if (isCheckpoint(lesson)) {
        return new UserCheckpointStore(this.root, lesson.id);
      }
      const lessonRecord = lessonRecords.find((record) => record.lessonId === lesson.id);
      return new UserLessonStore(this.root, lesson.id, lessonRecord);
    });
    this.areas = this.lessons
      .reduce(groupByAsArrayReducer('model.area.id'), [])
      .map((lessonOrCheckpoints) => {
        const area = new UserLessonAreaStore(lessonOrCheckpoints, this.root);
        lessonOrCheckpoints.forEach((lesson) => {
          lesson.area = area;
        });
        return area;
      })
      // This puts it in rhythm, pitch_and_harmony, ear_training order
      .sort(sortByFactory('textId'))
      .reverse();
  }
}
