import type { Lesson } from '../../../definitions/content-definitions';

/**
 * Triads
 */
export const lesson: Lesson = {
  id: 'triads',
  areaId: 'pitch_and_harmony',
  order: 31,
  title: 'Triads',
  skills: [
    'spelling_triads',
    'major_triads',
    'minor_triads',
    'augmented_triads',
    'diminished_triads',
  ],
  pages: [
    {
      id: 'triads_intro',
      order: 1,
      title: 'Triads',
      content: `
        <h3>Triads</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>How to build triads by stacking thirds,</li>
        <li>The structure of the four types of triads (Major, minor,
            Augmented and diminished), and</li>
        <li>How to write and identify triads.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video 1: Snowman
     *   - A triad is the most basic type of chord, triads form the harmony of
     *     much of the music we listen to.
     *   - Triads are made by stacking three notes in thirds, for instance: CEG.
     *   - We call these three parts the root, 3rd and 5th -- because they
     *     describe the interval from the starting note.
     *
     *   - What would the notes of a D triad be? E? F? G? A? B?
     *   - Say them in rhythm: CEG, DFA, etc...
     *
     *   - Practice spelling & writing these on the staff
     */
    {
      id: 'triads_video_1',
      order: 2,
      title: 'Stacking Thirds',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'triads1',
      },
    },
    /**
     * Exercise: Spell White Note Triads in Letters
     */
    {
      title: 'Spell White Note Triads',
      order: 3,
      id: 'triads_spelling',
      interactive: {
        type: 'ChordSpelling',
        triadOrSeventh: 'triads',
        questions: ['G', 'D5', 'B3', 'A', 'C5', 'F2', 'E'],
      },
    },
    /**
     * Exercise: Write White Note Triads on the Staff
     */
    {
      title: 'Write White Note Triads',
      order: 4,
      id: 'triads_write_white_note_triads',
      interactive: {
        type: 'ChordSpellingStaff',
        triadOrSeventh: 'triads',
        drill: false,
        questions: ['A2', 'D5', 'C', 'F2', 'E5', 'B3', 'G'],
      },
    },
    /**
     * Video 2: Major Triads
     *   - Triads have different qualities, depending on the specific intervals
     *     between their notes.
     *   - Although these qualities have the same names as interval qualities,
     *     they don't mean exactly the same thing. Let's start with Major
     *     triads.
     *
     *   - A Major triad is made up of a Major 3rd and a Perfect 5th above the
     *     root. Because of this, we can think of the key of the root, and write
     *     scale degrees 3 and 5 in that key.
     *
     *   - D Maj
     *   - Bb Maj
     *
     *   - As with intervals, we can use abbreviations: G Major, G Maj and GM
     *     (all with capital M's) all mean the same thing.
     *
     *   Let's practice writing Major triads.
     */
    {
      id: 'triads_video_2',
      title: 'Major Triads',
      order: 5,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triads2',
      },
    },
    /**
     * Exercise: Write Major Triads
     */
    {
      title: 'Write Major Triads',
      order: 6,
      id: 'triads_write_major',
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
        },
        questions: [
          'Eb Maj/treble',
          'F#3 Maj/bass',
          'A Maj',
          'Db Maj',
          'B Maj',
          'Gb Maj',
          'Bb Maj',
        ],
      },
    },
    /**
     * Video 3: Minor Triads
     *   - Minor triads are made up of a minor 3rd and a Perfect 5th above the
     *     root. - Because of this we can think of the minor key of the root,
     *     and write scale degrees 3 and 5 above it. E minor
     *   - Compare: E Major
     *   - Only difference: the 3rd is a half step lower. We can also write
     *     minor triads, by thinking of a Major triad and lowering the 3rd a
     *     half step.
     *
     *   Let's practice writing minor triads.
     */
    {
      id: 'triads_video_3',
      order: 7,
      title: 'Minor Triads',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'triads3',
      },
    },
    /**
     * Exercise: Write minor Triads
     */
    {
      title: 'Write minor Triads',
      order: 8,
      id: 'triads_write_minor',
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
        },
        questions: [
          'G min/bass',
          'F min/bass',
          'Cb min/treble',
          'A# min/treble',
          'D min',
          'G# min',
          'Ab min',
          'E min',
        ],
      },
    },
    /**
     * Video: Augmented Triads
     *   - An Augmented triad is made up of a Major 3rd and an Augmented 5th
     *     above the root.
     *   - We can think of these as being like a Major triad, with the 5th
     *     raised a half-step.
     *   - EX: Ab Aug
     */
    {
      id: 'triads_video_4',
      title: 'Augmented Triads',
      order: 9,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triads4',
      },
    },
    /**
     * Exercise: Write Augmented Triads
     */
    {
      title: 'Write Augmented Triads',
      order: 10,
      id: 'triads_augmented',
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
        },
        questions: ['C2 Aug', 'E2 Aug', 'Gb4 Aug', 'B3 Aug', 'A4 Aug', 'F3 Aug', 'Cb Aug'],
      },
    },
    /**
     * Video: Diminished Triads
     *   - A diminished triad is made up of a minor 3rd and a diminished 5th
     *     above the root.
     *   - We can think of these as being like a minor triad, with the 5th
     *     lowered a half-step.
     *   - EX: B dim
     */
    {
      id: 'triads_video_5',
      title: 'Diminished Triads',
      order: 11,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triads5',
      },
    },
    /**
     * Exercise: Write diminished triads
     */
    {
      title: 'Write diminished Triads',
      order: 12,
      id: 'triads_diminished',
      interactive: {
        type: 'ChordDraw',
        format: {
          inversion: false,
        },
        questions: [
          'G4 dim',
          'D2 dim',
          'F# dim',
          'C#4 dim',
          'E5 dim',
          'D#3 dim',
          'B3 dim',
          'Bb4 dim',
        ],
      },
    },
    /**
     * Video: Identify Triads
     *
     * Let's write all the versions of a C triad
     *   - C Maj, C min, C dim, C AUG
     *   - To identify a triad, it's often easiest to compare it to the Major
     *     version of that triad.
     *
     *   - F Ab C: What triad is this? Imagine an F Major triad, what's
     *     different?
     *   - D F# A#: What triad is this?
     *
     *   Practice: Identifying triads
     */
    {
      id: 'triads_video_6',
      title: 'Identifying Triads',
      order: 13,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triads6',
      },
    },
    /**
     * Exercise: Identify triads in root position, close spacing
     */
    {
      title: 'Identify Triads',
      order: 14,
      id: 'triads_id',
      interactive: {
        type: 'ChordAndInversionID',
        inversion: false,
        root: false,
        quality: true,
        drill: true,
        skills: ['major_triads', 'minor_triads', 'augmented_triads', 'diminished_triads'],
        maxQuestions: 12,
        autoadvance: true,
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'triads_video_7',
      title: 'Conclusion',
      order: 15,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'triads7',
      },
    },
  ],
};
