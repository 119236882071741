import type { Lesson } from '../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'treble_clef_1',
  areaId: 'pitch_and_harmony',
  order: 2,
  title: 'The Treble Clef: Intro',
  skills: ['lines_spaces', 'treble_c_g', 'treble_a_g'],
  pages: [
    {
      title: 'The Staff',
      order: 1,
      id: 'treble_clef_1_lines_and_spaces_1',
      content: `
        <h3>The Treble Clef</h3>
        <p>In this lesson you'll learn about the <strong>staff</strong>, the five
        lines we use to represent notes on paper.</p>
        <p>Click the next button below to get started!</p>
        `,
    },
    /**
     * Video: Lines and Spaces
     *
     * When we write music down, we use these five horizontal lines, which we
     * call a staff, to know how far away notes are from each other.  Notes can
     * be on a line -- meaning the line goes right through them -- or in a space
     * -- meaning they are between two lines.
     *
     * We count the lines from bottom to top: 1 2 3 4 5
     * - And the spaces: 1 2 3 4
     *
     * Let's have some practice identifying the lines and spaces.
     */
    {
      id: 'treble_clef_1_lines_and_spaces2',
      order: 2,
      title: 'Lines and Spaces',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'trebleClef1-1',
      },
    },
    /**
     * Exercise: "Draw a note on the 3rd line"
     */
    {
      title: 'Practice: Lines and Spaces',
      order: 3,
      id: 'practice_lines_and_spaces',
      interactive: {
        type: 'LinesAndSpaces',
        questionPrompt: 'Draw a note {question}.',
        clef: 'treble',
        questions: [1, 2, 5, 1.5, 3.5, 4, 4.5],
      },
    },
    /**
     * Video: Clefs Intro
     *
     * The lines and spaces by themselves don't tell us which note is which.
     * They just show us how high or low the notes are in relation to each
     * other.  Instead, we need a key to say "this line equals this note." We
     * call this a "clef" which is actually just the French word for key.  Clefs
     * started as letters drawn on a particular line, about a thousand years
     * ago.
     *
     * The modern clefs are just fancy versions of this, from the ornate
     * caligraphy of these lettters by Roman Catholic monks in the late middle
     * ages and renaissance.
     *
     * Today, we only use three letters for the clefs: G, F and C.
     *
     * We'll start with the G-clef, which we usually call the treble clef,
     * because it has the highest range of all the clefs.  The loop of the
     * treble clef identifies G (on the second line).  From this, by continuing
     * down and up the musical alphabet, we can find the names of all of the
     * lines and spaces.
     *
     * Let's focus on the first five: C to G.  The C just below the treble clef
     * is called Middle C, because it is the C closest to the middle of the
     * piano keyboard.  Use this note as a landmark to help remember where the
     * other notes are.  And let's have some practice!
     */
    {
      title: 'Clefs Intro',
      id: 'treble_clef_1_introduction_to_clefs',
      order: 4,
      video: {
        autoadvance: true,
        autostart: true,
        url: 'trebleClef1-2',
      },
    },
    /**
     * Exercise: Draw C, D, E, F, G on the treble staff
     */
    {
      title: 'Practice: C to G',
      id: 'treble_clef_1_practice_c_to_g',
      order: 5,
      interactive: {
        type: 'NoteDraw',
        questionPrompt: 'Draw the note {question} on the staff.',
        clef: 'treble',
        ignoreOctave: true,
        questions: ['C4', 'D4', 'E4', 'F4', 'G4', 'E4', 'C4', 'F4', 'D4', 'G4'],
      },
    },
    /**
     * Exercise: ID C, D, E, F, G on the treble staff
     */
    {
      title: 'Identify: C to G',
      id: 'treble_clef_1_identify_c_to_g',
      order: 6,
      interactive: {
        type: 'NoteID',
        questionPrompt: 'Click on the name of the note you see.',
        clef: 'treble',
        questions: ['G4', 'D4', 'E4', 'C4', 'G4', 'F4', 'C4', 'E4', 'D4', 'C4'],
      },
    },
    /**
     * Exercise: Play C, D, E, F, G from the treble staff on the piano
     */
    {
      title: 'Play: C to G',
      id: 'treble_clef_1_play_c_to_g',
      order: 7,
      interactive: {
        type: 'NoteReadingPiano',
        clef: 'treble',
        lowestNote: 'C4',
        highestNote: 'C#5',
        questions: ['C4/D4/E4/C4', 'G4/C4/F4/E4', 'F4/E4/D4/G4', 'E4/C4/D4/G4', 'D4/E4/C4/C4'],
      },
    },
    /**
     * Video: A4 to G5
     *
     *  Now let's look at the notes above G.  If we start on the space right
     *  above G, and continue to add notes, we simply go up the alphabet from A
     *  to G.  You might find it helpful to think of the treble clef in this
     *  way: the notes from C to G, and the notes from A up to high G.  Let's
     *  practice naming these notes.
     */
    {
      id: 'treble_clef_1_a_up_to_g',
      order: 8,
      title: 'Treble Clef A to G',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'trebleClef1-3',
      },
    },
    /**
     * Exercise: ID A4 to G5 on treble clef
     */
    {
      title: 'Identify: A to high G',
      id: 'treble_clef_1_ID_a_to_g',
      order: 9,
      interactive: {
        type: 'NoteID',
        questionPrompt: 'Click on the name of the note you see.',
        clef: 'treble',
        questions: ['A4', 'B4', 'D5', 'E5', 'G5', 'F5', 'C5', 'G5', 'B4', 'A4', 'D5'],
      },
    },
    /**
     * Exercise: Play music written in treble clef
     */
    {
      title: 'Play: Treble Clef',
      id: 'treble_clef_1_play_treble_clef',
      order: 10,
      interactive: {
        type: 'NoteReadingPiano',
        clef: 'treble',
        lowestNote: 'C4',
        highestNote: 'G5',
        questions: [
          'G4/A4/G4/F4/E4/F4/G4',
          'C5/C5/D5/B4/C5/D5',
          'E5/E5/F5/E5/D5/C5',
          'C4/C5/B4/G4/A4/B4/C5',
          'D5/B4/D5/D5/B4/D5',
          'E5/D5/C5/B4/A4/B4/C5',
          'G5/F5/E5/F5/E5',
          'D5/E5/C5/D5/B4/A4/G4',
        ],
      },
    },
    /**
     * Video: Conclusion
     */
    {
      title: 'Summary',
      id: 'treble_clef_1_summary',
      order: 11,
      content:
        "<h3>Nice Work</h3>\n<p>That's it for the treble clef.  You know the note names of the treble clef, how it is just a fancy letter G, and you've read some music written in treble clef.  Great job!</p>\n",
    },
  ],
};
