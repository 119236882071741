import { action, runInAction, toJS, makeObservable } from 'mobx';
import type { StudentUserRecord, UserWithJoins } from '../../definitions/user-object-definitions';
import type SectionStore from '../section/section.store';
import { AbstractUserStore } from './abstract.user.store';
import type { CustomTestStore } from './tests/custom.test.store';
import { TestAttemptStore } from './tests/checkpoint.attempt.store';
import { UserLessonStore } from './userLessons/user.lesson.store';
import UserKnowledgeStore from './userKnowledge/user.knowledge.store';
import { UserLessonsStore } from './userLessons/user.lessons.store';

export class UserStore extends AbstractUserStore {
  constructor(private userRecord: StudentUserRecord, currentSection: SectionStore) {
    super();

    makeObservable(this);

    this.init(userRecord, currentSection);
  }

  /** Always empty on student user store */
  readonly tests: CustomTestStore[] = [];

  /** Always empty on student user store */
  readonly ownedTests: CustomTestStore[] = [];

  /** Always empty on student user store */
  readonly testIds: string[] = [];

  /** Always empty on student user store */
  readonly testsById: { [id: string]: CustomTestStore } = {};

  @action init(userRecord: StudentUserRecord | UserWithJoins, currentSection: SectionStore) {
    super.init(userRecord, currentSection);
    // observe(userRecord, changeObject => {
    //   console.log('changeObject', changeObject);
    //   this.update(changeObject.object);
    // });
  }

  @action update(studentRecord: Partial<StudentUserRecord> | Partial<UserWithJoins>) {
    if (studentRecord.uPoints) {
      if ('dataPoints' in studentRecord.uPoints) {
        this.initializeUPoints(studentRecord.uPoints.dataPoints);
      } else {
        this.initializeUPoints(studentRecord.uPoints);
      }
    }

    if (studentRecord.firstName) this.firstName = studentRecord.firstName;
    if (studentRecord.lastName) this.lastName = studentRecord.lastName;
    if (studentRecord.email) this.email = studentRecord.email;
    if (studentRecord.accommodations) this.accommodations = studentRecord.accommodations;
    if (studentRecord.avatarUrl) this.avatarUrl = studentRecord.avatarUrl;
    if (studentRecord.credentials) this.credentials = studentRecord.credentials;
    if (studentRecord.skills)
      studentRecord.skills.forEach((skill) => {
        const studentSkill = this.knowledgeProgress.skillsById[skill.skillId];
        if (!studentSkill) {
          return;
        }
        studentSkill.update(skill);
      });
    if (studentRecord.lastActiveAt) this.lastActiveAt = new Date(studentRecord.lastActiveAt);
    if (studentRecord.lastLoginAt) this.lastLoginAt = new Date(studentRecord.lastLoginAt);
    if (studentRecord.checkpoints)
      studentRecord.checkpoints.forEach((attempt) => {
        if (attempt.attemptStatus !== 'complete') return;
        const existingAttempt = this.testAttempts.find((a) => a.id === attempt.id);
        if (existingAttempt) {
          existingAttempt.update(attempt);
        } else {
          this.addTestAttempt(new TestAttemptStore(attempt, this));
        }
      });

    if (studentRecord.lessons) {
      studentRecord.lessons.forEach((lessonRecord) => {
        // An existing lesson should exist: a record is created for every lesson in definition.
        const existingLesson = this.lessonProgress.lessonsById[lessonRecord.lessonId];
        // If it does not, we'll return
        if (!existingLesson) return;
        // And if it's not a lessonStore we'll return
        if (!(existingLesson instanceof UserLessonStore)) return;
        existingLesson.update(lessonRecord);
      });
    }
  }

  @action protected initSectionsAndRoles(
    userRecord: StudentUserRecord,
    currentSection: SectionStore
  ) {
    this.allSections = [];
    this.addSection(currentSection);
    this.sectionId = currentSection.id;
    this.roles = ['student'];
  }

  clone(newSection?: SectionStore): UserStore {
    const newUser = new UserStore(this.userRecord, newSection || this.currentSection);
    runInAction(() => {
      newUser.uPointsRecords = toJS(this.uPointsRecords);
      newUser.firstName = this.firstName;
      newUser.lastName = this.lastName;
      newUser.email = this.email;
      newUser.accommodations = this.accommodations;
      newUser.avatarUrl = this.avatarUrl;
      newUser.credentials = this.credentials;
      const skillRecords = this.knowledgeProgress.skills.map((s) => s.dehydrate());
      newUser.knowledgeProgress = new UserKnowledgeStore(newUser, skillRecords);
      const checkpointRecords = this.testAttempts.map((ta) => ta.record);
      newUser.testAttempts = checkpointRecords.map(
        (attempt) => new TestAttemptStore(attempt, newUser)
      );
      newUser.lessonProgress = new UserLessonsStore(
        newUser,
        this.lessonProgress.lessons
          .filter((l): l is UserLessonStore => l.type !== 'checkpoint')
          .map((l) => ({
            lessonId: l.id,
            highestPageCompleted: l.highestPageCompleted,
            highestPageFirstCompletedOn: l.record.highestPageFirstCompletedOn,
          }))
      );
    });
    return newUser;
  }
}
