/* eslint-disable global-require */
import type { Lesson } from '../../definitions/content-definitions';
import type { LessonCheckpointTest } from '../../definitions/checkpoint-definitions';

import { lesson as beatsQuartersEighths } from './lessonDefinitions/beatsQuartersEighths';
import { lesson as halfAndWholeNotes } from './lessonDefinitions/halfAndWholeNotes';
import { lesson as understandingNoteValues } from './lessonDefinitions/understandingNoteValues';
import { lesson as dottedNotesAndOffbeats } from './lessonDefinitions/dottedNotesAndOffbeats';
import { lesson as compoundMeters } from './lessonDefinitions/compoundMeters';
import { lesson as understandingTimeSignatures } from './lessonDefinitions/understandingTimeSignatures';
import { lesson as compoundEighthNotePatterns } from './lessonDefinitions/compoundEighthNotePatterns';
import { lesson as simpleTimeSixteenthNotes } from './lessonDefinitions/simpleTimeSixteenthNotes';
import { lesson as accidentals1 } from './lessonDefinitions/accidentals_1';
import { lesson as accidentals2 } from './lessonDefinitions/accidentals_2';
import { lesson as ascendingIntervals } from './lessonDefinitions/ascendingIntervals';
import { lesson as bassClef1 } from './lessonDefinitions/bass_clef_1';
import { lesson as bassClef2 } from './lessonDefinitions/bass_clef_2';
import { lesson as octaveNumbers } from './lessonDefinitions/octaveNumbers/octaveNumbers';
import { checkpoint as checkpoint1 } from './lessonDefinitions/checkpoint_1_piano_clefs_accidentals';
import { checkpoint as checkpoint4intervals } from './lessonDefinitions/checkpoint_4_intervals';
import { checkpoint as checkpoint5triads } from './lessonDefinitions/checkpoint_5_triads';
import { checkpoint as checkpoint3minorScalesKeys } from './lessonDefinitions/checkpoint_3_minor_scales_keys';
import { checkpoint as checkpoint2majorScalesKeys } from './lessonDefinitions/checkpoint_2_major_scales_keys';
import { lesson as descendingIntervals } from './lessonDefinitions/descendingIntervals';
import { lesson as flatMajorKeys } from './lessonDefinitions/flatMajorKeys';
import { lesson as genericIntervals } from './lessonDefinitions/genericIntervals';
import { lesson as halfStepsStaff } from './lessonDefinitions/halfStepsStaff';
import { lesson as harmonicMinorScales } from './lessonDefinitions/harmonicMinorScales';
import { lesson as intervalInversion } from './lessonDefinitions/intervalInversion';
import { lesson as layoutOfThePiano } from './lessonDefinitions/layout_of_the_piano';
import { lesson as majorScalesStaff } from './lessonDefinitions/majorScalesStaff';
import { lesson as melodicMinorScales } from './lessonDefinitions/melodicMinorScales';
import { lesson as minorKeySignatures } from './lessonDefinitions/minorKeySignatures';
import { lesson as naturalMinorScales } from './lessonDefinitions/naturalMinorScales';
import { lesson as pianoHalfAndWholeSteps } from './lessonDefinitions/piano_half_and_whole_steps';
import { lesson as pianoMajorScales } from './lessonDefinitions/piano_major_scales';
import { lesson as sharpMajorKeys } from './lessonDefinitions/sharpMajorKeys';
import { lesson as trebleClef1 } from './lessonDefinitions/treble_clef_1';
import { lesson as trebleClef2 } from './lessonDefinitions/treble_clef_2';
import { lesson as trebleClef3 } from './lessonDefinitions/treble_clef_3';
import { lesson as triadSpacingInversion } from './lessonDefinitions/triadSpacingInversion';
import { lesson as triadsIntroduction } from './lessonDefinitions/triadsIntroduction';
import { lesson as trickyIntervalsImaginaryKeys } from './lessonDefinitions/trickyIntervalsImaginaryKeys';
import { lesson as wholeStepsStaff } from './lessonDefinitions/wholeStepsStaff';
import { lesson as scaleDegrees } from './lessonDefinitions/scaleDegrees';
import { lesson as seventhChords } from './lessonDefinitions/seventhChords';
import { lesson as romanNumeralTriadsInMajor } from './lessonDefinitions/romanNumeralTriadsInMajor';
import { lesson as romanNumeralTriadsInMinor } from './lessonDefinitions/romanNumeralTriadsInMinor';
import { lesson as melodicDirectionConjunctDisjunct } from './lessonDefinitions/melodic_direction_conjunct_disjunct';
import { lesson as scaleDegrees123 } from './lessonDefinitions/scale_degrees_123';
import { lesson as earTrainingMajorScales } from './lessonDefinitions/et_major_scale';
import { lesson as solfegeAndTheStaff } from './lessonDefinitions/solfegeAndTheStaff/solfege_and_the_staff';

const lessonsAndCheckpoints: Readonly<Readonly<Lesson | LessonCheckpointTest>[]> = Object.freeze(
  [
    beatsQuartersEighths,
    halfAndWholeNotes,
    understandingNoteValues,
    dottedNotesAndOffbeats,
    compoundMeters,
    understandingTimeSignatures,
    compoundEighthNotePatterns,
    simpleTimeSixteenthNotes,
    accidentals1,
    accidentals2,
    ascendingIntervals,
    bassClef1,
    bassClef2,
    octaveNumbers,
    checkpoint1,
    checkpoint4intervals,
    checkpoint5triads,
    checkpoint3minorScalesKeys,
    checkpoint2majorScalesKeys,
    descendingIntervals,
    flatMajorKeys,
    genericIntervals,
    halfStepsStaff,
    harmonicMinorScales,
    intervalInversion,
    layoutOfThePiano,
    majorScalesStaff,
    melodicMinorScales,
    minorKeySignatures,
    naturalMinorScales,
    pianoHalfAndWholeSteps,
    pianoMajorScales,
    sharpMajorKeys,
    trebleClef1,
    trebleClef2,
    trebleClef3,
    triadSpacingInversion,
    triadsIntroduction,
    trickyIntervalsImaginaryKeys,
    wholeStepsStaff,
    scaleDegrees,
    seventhChords,
    romanNumeralTriadsInMajor,
    romanNumeralTriadsInMinor,
    melodicDirectionConjunctDisjunct,
    scaleDegrees123,
    earTrainingMajorScales,
    solfegeAndTheStaff,
  ]
    .sort((a, b) => a.order - b.order)
    .map((lesson) => Object.freeze(lesson))
);

export default lessonsAndCheckpoints;
