/* eslint-disable max-classes-per-file */
import { action, computed, makeObservable } from 'mobx';

import type { TestAttemptStore } from '../tests/checkpoint.attempt.store';
import { getAttemptToUse } from '../tests/tests.utils';
import { UserContentStore } from './user.lessonOrCheckpoint.store';
import type { AbstractUserStore } from '../abstract.user.store';
import { getGradePenalty } from './user.utils';

/**
 * Class to store checkpoints and results for a user
 * Only checkpoints that appear in lesson list -- not
 * custom tests!
 */
export class UserCheckpointStore extends UserContentStore {
  type: 'checkpoint' = 'checkpoint';

  @computed get attempts(): TestAttemptStore[] {
    const attempts = this.root.testAttemptsByTestId[this.id] || [];
    attempts.sort(
      (a, b) => new Date(b.record.createdAt).valueOf() - new Date(a.record.createdAt).valueOf()
    );
    return attempts.filter((attempt) => attempt.record.attemptStatus === 'complete');
  }

  @computed get attemptToUse(): TestAttemptStore | undefined {
    return getAttemptToUse(this.attempts);
  }

  @computed get testTaken(): boolean {
    return !!this.attempts.length;
  }

  @computed get gradesRow(): (number | string | Date | boolean | null)[] {
    const columns = this.model.gradeColumnInformation;
    return columns.map((column) => {
      switch (column.type) {
        case 'userInfo':
          return this.root.gradesRowUserInfo[column.id];
        case 'overall':
          if (column.id === 'date') return this.dateCompleted || null;
          if (column.id === 'overall') return this.score;
          if (column.id === 'passed') return this.isPassed;
          break;
        case 'section':
          return this.attemptToUse?.sections[column.id as number]?.percentage ?? null;
      }
      return null;
    });
  }

  /**
   * A checkpoint is complete when it meets the section passing requirements --
   * if there are section passing requirements. Late deductions are ignored for the
   * purposes of calcuating whether a checkpoint is complete.
   */
  @computed get isComplete(): boolean {
    if (this.attempts.some((attempt) => attempt.isComplete)) return true;

    // If they've exhausted their attempts and still haven't passed, we let them go on
    // regardless of the section settings.
    if (!this.hasAttemptsRemaining) return true;
    return false;
  }

  @computed get isPasswordRequired(): boolean {
    return !!this.root.currentSection.options.lessons.checkpoints.password.require;
  }

  @computed get isPassingRequired(): boolean {
    return !!this.root.currentSection.options.lessons.checkpoints?.passingPercent;
  }

  @computed get requiredPassingPercent(): number | false {
    if (this.isPassingRequired) {
      return this.root.currentSection.options.lessons.checkpoints.passingPercent;
    }
    return false;
  }

  @computed get isPassed(): boolean {
    return this.attempts.some((attempt) => attempt.isPassed);
  }

  /**
   * It's not currently possible to resume a checkpoint attempt, so this is false.
   */
  isInProgress = false;

  /**
   * For a checkpoint, progress is simply whether it's passed or not.
   */
  @computed get progress(): number {
    return this.isPassed ? 100 : 0;
  }

  /**
   * Score for a checkpoint, 0 - 100
   */
  @computed get score(): number {
    return Math.max(0, this.scoreWithoutGradePenalty - this.gradePenalty);
  }

  @computed get dateCompleted(): Date | undefined {
    return this.attemptToUse ? this.attemptToUse.dateCompleted : undefined;
  }

  @computed get isLate(): boolean {
    return this.attemptToUse ? this.attemptToUse.isLate : this.isDue;
  }

  @computed get gradePenalty(): number {
    if (!this.isRequired) return 0;
    return getGradePenalty(
      this.dueDate,
      this.dateCompleted,
      this.root.currentSection.options.deadlines.penalty
    );
  }

  @computed get scoreWithoutGradePenalty(): number {
    const attempt = this.attemptToUse;
    return attempt ? attempt.scoreWithoutGradePenalty : 0;
  }

  @computed get hasAttemptsRemaining(): boolean {
    return this.remainingAttempts > 0;
  }

  @computed get remainingAttempts(): number {
    const allowedRepetitions = this.root.currentSection.options.lessons.checkpoints.repetition.allow
      ? this.root.currentSection.options.lessons.checkpoints.repetition.count
      : 1;

    // unlimited:
    if (allowedRepetitions === -1) return Infinity;
    const totalAttempts = this.attempts.length;
    return Math.max(allowedRepetitions - totalAttempts, 0);
  }

  constructor(public root: AbstractUserStore, checkpointId: string) {
    super();
    makeObservable(this);
    this.init(checkpointId);
  }

  @action init(checkpointId: string) {
    this.id = checkpointId;
  }
}
