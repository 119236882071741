// Note: must be * as _. Lodash isn't es6 compat.
import type { LessonCheckpointTest } from '../../definitions/checkpoint-definitions';
import type { Lesson } from '../../definitions/content-definitions';
import { isLesson } from '../../definitions/content-definitions';
import lessons from './index';
import { makeString } from '../knowledge/knowledge.store';
import { uniqueFilter } from '../utils/uniqueFilter';
import { sortByFactory } from '../utils/sortByFactory';
import { uniqueByFilter } from '../utils/uniqueByFilter';
import { isLessonCheckpointDefinition } from '../section/sectionLessons/section.lessonOrCheckpoint.store';

export type LessonDefinition = Readonly<Lesson | LessonCheckpointTest>;

function freeze<T>(arr: T[]): Readonly<T[]> {
  return Object.freeze(arr);
}

/**
 * Lessons and checkpoints used on Lessons tab of uTheory.
 * All properties are static/frozen.
 */
export class LessonsStore {
  areaNames = Object.freeze(['rhythm', 'pitch_and_harmony', 'ear_training']);

  lessons: Readonly<LessonDefinition[]> = lessons;

  lessonsById = Object.freeze(
    lessons.reduce((dictionary: { [id: string]: LessonDefinition }, lesson: LessonDefinition) => {
      dictionary[lesson.id] = lesson;
      return dictionary;
    }, {})
  );

  rhythm: Readonly<LessonDefinition[]> = freeze(
    lessons
      .filter((l) => l.areaId === 'rhythm')
      .slice(0)
      .sort((a, b) => a.order - b.order)
  );

  pitch_and_harmony: Readonly<LessonDefinition[]> = freeze(
    lessons
      .filter((l) => l.areaId === 'pitch_and_harmony')
      .slice(0)
      .sort((a, b) => a.order - b.order)
  );

  ear_training: Readonly<LessonDefinition[]> = freeze(
    lessons
      .filter((l) => l.areaId === 'ear_training')
      .slice(0)
      .sort((a, b) => a.order - b.order)
  );

  getAreas() {
    return {
      rhythm: this.rhythm,
      pitch_and_harmony: this.pitch_and_harmony,
      ear_training: this.ear_training,
    };
  }

  /**
   * Get all the lessons that train a particular skill, from earliest
   * to latest
   * @param {string|number} skillId
   * @returns {Array.<Lesson>}
   */
  static getLessonsBySkill(skillId: string | number): Readonly<Lesson>[] {
    skillId = makeString(skillId);
    return lessons
      .filter(isLesson)
      .filter((lesson) => lesson.skills.find((lessonSkillId) => lessonSkillId === skillId));
  }

  /**
   * Get all the lessons that train a particular skill, from earliest
   * to latest
   * @param skillIds
   */
  static getLessonsBySkills(skillIds: (string | number)[]): Readonly<Lesson>[] {
    return (
      skillIds
        .flatMap((id) => this.getLessonsBySkill(id))
        // Get Uniques
        .filter(uniqueFilter)
        .sort(sortByFactory('order'))
    );
  }

  /**
   * Get the next lesson or checkpoint in an area, if it exists.
   * @param {string} currentLessonId lesson id
   * @returns {Lesson | undefined} Lesson object, or undefined if not found;
   */
  getNextLesson(currentLessonId: string): LessonDefinition | undefined {
    const lesson = this.lessonsById[currentLessonId];
    if (!lesson) throw Error(`${currentLessonId} was not found in lessons.lessonsById`);
    const areaLessons: Readonly<LessonDefinition[]> = this[lesson.areaId];
    const lessonIndex = areaLessons.indexOf(lesson);
    return areaLessons[lessonIndex + 1];
  }

  /**
   * Get the previous lesson in an area, if it exists.
   * @param {string} currentLessonId lesson id
   * @returns {Lesson | undefined} Lesson object, or undefined if not found;
   */
  getPrevLesson(currentLessonId: string): LessonDefinition | undefined {
    const lesson = this.lessonsById[currentLessonId];
    if (!lesson) throw Error(`${currentLessonId} was not found in lessons.lessonsById`);
    const areaLessons: Readonly<LessonDefinition[]> = this[lesson.areaId];
    const lessonIndex = areaLessons.indexOf(lesson);
    return areaLessons[lessonIndex - 1];
  }

  getLessonsBySkills(skills: (string | number)[]) {
    skills = skills.map(makeString);
    return skills
      .map(makeString)
      .flatMap((skill) =>
        this.lessons.filter(
          (lesson) => !isLessonCheckpointDefinition(lesson) && lesson.skills.includes(skill)
        )
      )
      .filter(uniqueByFilter('id'));
  }
}

export const lessonsStore = new LessonsStore();
