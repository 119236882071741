// Note: must be * as _. Lodash isn't es6 compat.

import type { Lambda } from 'mobx';
import {
  action,
  computed,
  observable,
  runInAction,
  toJS,
  onBecomeUnobserved,
  observe,
  onBecomeObserved,
  makeObservable,
} from 'mobx';
import type { SectionRecord, SectionRecordWithJoins } from '../../definitions/section-definitions';
import type { SavedCustomCheckpoint } from '../../definitions/checkpoint-definitions';
import SectionLessonsStore from './sectionLessons/section.lessons.store';
import SectionKnowledgeStore from './sectionKnowledge/section.knowledge.store';
import { CustomTestStore } from '../user/tests/custom.test.store';
import type { ClientUserStore } from '..';
import { SectionOptionsStore } from './section.options.store';
import type InstitutionStore from '../licenses/institution.store';
import { UserStore } from '../user/user.store';
import type { StudentUserRecord } from '../../definitions/user-object-definitions';
import type { AbstractUserStore, GradesRowUserInfo } from '../user/abstract.user.store';
import { isString } from '../utils/isString';
import { isEqual } from '../utils/isEqual';
import { debounce } from '../utils/debounce';
import knowledgeGraphNodes from '../knowledge/knowledgeGraphNodes';
import knowledgeGraphEdges from '../knowledge/knowledgeGraphEdges';

type USectionGradeColumnHeaderType =
  | 'overall'
  | 'upoints'
  | 'lesson'
  | 'checkpoint'
  | 'topic'
  | 'test';

export interface UGradeColumnUserInfoHeader {
  type: 'userInfo';
  id: keyof GradesRowUserInfo;
  title: string;
}

interface USectionGradeColumnDataHeader {
  title: string;
  type: USectionGradeColumnHeaderType;
  id: string;
  status: 'required' | 'optional';
  dueDate: Date | false;
  area: 'rhythm' | 'pitch_and_harmony' | 'ear_training' | false;
  isPercent?: boolean;
}

export type USectionGradeColumnHeader = UGradeColumnUserInfoHeader | USectionGradeColumnDataHeader;

const latestTimeObserved: { [sectionId: string]: Date } = {};

/**
 * A uTheory class Section
 */
export default class SectionStore {
  id!: string;

  root?: ClientUserStore;

  @observable options!: SectionOptionsStore;

  @observable knowledge!: SectionKnowledgeStore;

  @observable lessons!: SectionLessonsStore;

  @observable loading!: boolean;

  @observable saving!: boolean;

  @observable error!: false | string;

  @observable errorObject?: any;

  @observable updatedAt!: Date;

  @observable lmsProviderId?: null | string;

  /**
   * Only valid when root is present, and root is a teacher. Otherwise
   * will always return false.
   */
  @observable isDefaultSection: boolean = false;

  @observable private _committedRecord!: Omit<SectionRecord, 'createdAt' | 'updatedAt'>;

  @action private setError(error: string | false, err?: any) {
    this.error = error;
    if (err) this.errorObject = err;
  }

  @action private setLoadingStatus(bool: boolean) {
    this.loading = bool;
    this.lastStatus = 'loading';
  }

  @action private setSavingStatus(bool: boolean) {
    this.saving = bool;
    this.lastStatus = 'saving';
  }

  @observable private lastStatus = 'loading';

  @computed get status(): 'loading' | 'saving' | 'errored' | '' {
    // If this has errored, or any of our saveables have, we're errored:
    if (
      this.error ||
      this.options.state === 'errored' ||
      this.lessons.lessons.find((l) => l.state === 'errored') ||
      this.knowledge.skills.find((s) => s.state === 'errored')
    ) {
      return 'errored';
    }

    // If any of our saveables have errored, we're errored:
    if (
      this.saving ||
      this.options.state === 'saving' ||
      this.lessons.lessons.find((l) => l.state === 'saving') ||
      this.knowledge.skills.find((s) => s.state === 'saving')
    ) {
      return 'saving';
    }

    if (this.loading) return 'loading';
    return '';
  }

  @computed get statusMessage(): string {
    if (this.status === 'errored') return this.error || `Error ${this.lastStatus} section`;
    if (this.status === 'saving') return 'Saving section...';
    if (this.status === 'loading') return 'Loading section...';
    return this.lastStatus === 'saving' ? 'All changes saved.' : 'Loaded.';
  }

  delayedSave?: (sectionRecord?: Partial<SectionRecord>) => Promise<void> | undefined;

  @computed get gradeColumnInformation(): USectionGradeColumnHeader[] {
    // {
    //   title: string;
    //   type: 'overall' | 'lesson' | 'checkpoint' | 'topic' | 'test';
    //   id: string;
    //   status: 'required' | 'optional';
    // }
    const gradeColumns: USectionGradeColumnHeader[] = [];

    gradeColumns.push({
      title: 'ID',
      type: 'userInfo',
      id: 'userId',
    });

    gradeColumns.push({
      title: 'Refresh Key',
      type: 'userInfo',
      id: 'userIdAndLastActiveAt',
    });

    gradeColumns.push({
      title: 'Last name',
      type: 'userInfo',
      id: 'lastName',
    });

    // First name:
    gradeColumns.push({
      title: 'First name',
      type: 'userInfo',
      id: 'firstName',
    });

    gradeColumns.push({
      title: 'Name',
      type: 'userInfo',
      id: 'fullName',
    });

    // Overall grade
    gradeColumns.push({
      title: 'Overall',
      type: 'overall',
      id: 'overall',
      status: this.options.grading.visibleToStudents ? 'required' : 'optional',
      dueDate: false,
      area: false,
      isPercent: true,
    });
    // UPoints
    gradeColumns.push({
      title: 'uPoints',
      type: 'upoints',
      id: 'uPoints',
      status: 'optional',
      dueDate: false,
      area: false,
    });

    // Lessons, Checkpoints and Skills Averages
    gradeColumns.push({
      title: 'Lessons',
      type: 'lesson',
      id: 'overall',
      status: 'optional',
      dueDate: false,
      area: false,
      isPercent: true,
    });

    gradeColumns.push({
      title: 'Checkpoints',
      type: 'checkpoint',
      id: 'overall',
      status: 'optional',
      dueDate: false,
      area: false,
      isPercent: true,
    });

    gradeColumns.push({
      title: 'Skills',
      type: 'topic',
      id: 'overall',
      status: 'optional',
      dueDate: false,
      area: false,
      isPercent: true,
    });

    // Lessons grades
    gradeColumns.push(
      ...this.lessons.lessons
        .filter((l) => l.isVisible)
        .map(
          (l): USectionGradeColumnHeader => ({
            title: l.title,
            type: l.type,
            id: l.id,
            status: l.status as 'required' | 'optional',
            dueDate: l.dueDate || false,
            area: l.area.textId,
            isPercent: true,
          })
        )
    );
    // Topics grades
    gradeColumns.push(
      ...this.knowledge.topics
        .filter((t) => t.isVisible)
        .map(
          (t): USectionGradeColumnHeader => ({
            title: t.title,
            type: 'topic',
            id: t.textId,
            status: t.status as 'required' | 'optional',
            dueDate: false,
            area: t.area!.textId,
            isPercent: true,
          })
        )
    );
    // Custom test grades
    gradeColumns.push(
      ...this.connectedTests
        .map((t) => t.customCheckpointId)
        .map((testId) => this.testsById[testId])
        .map((test): USectionGradeColumnHeader => {
          if (!test) {
            throw Error(
              'To get grade columns, section.store must be initiated ' +
                'with sectionRecord.tests joined from the sectionRecord.testIds[] field.'
            );
          }
          return {
            title: test.title,
            type: 'test',
            id: test.id,
            status: 'required',
            dueDate: false,
            area: false,
            isPercent: true,
          };
        })
    );
    return gradeColumns;
  }

  /**
   * The averages row, showing average progress by all students on each column
   */
  @computed get gradeColumnAverages(): (number | null)[] {
    if (!this.studentUserRecords.length) return this.gradeColumnInformation.map(() => null);
    interface AverageCalculator {
      total: number | null;
      count: number;
    }
    const averageCalculators: AverageCalculator[] = this.gradeColumnInformation.map(() => ({
      average: null,
      total: null,
      count: 0,
    }));
    this.students.forEach((student) => {
      student.gradesRow.forEach((grade, index) => {
        if (grade === null || typeof grade === 'string') return;
        averageCalculators[index]!.count++;
        if (averageCalculators[index]!.total === null) {
          averageCalculators[index]!.total = grade;
        } else {
          averageCalculators[index]!.total! += grade;
        }
      });
    });
    return averageCalculators.map((calc) => (calc.total === null ? null : calc.total / calc.count));
  }

  /**
   * # Student loading & updating functionality
   * ---
   * These methods are only applicable for teachers
   */

  /**
   * Load all students via a websocket.
   */
  loadStudents() {
    if (!this.root) throw Error('section.root must be set to load students.');
    if (this.studentLoadingStatus === 'loading') return;
    this.root.api.fetchStudentsViaSocket(this.id);
  }

  refreshStudents() {
    if (!this.root) throw Error('section.root must be set to load students.');
    if (this.studentLoadingStatus === 'loading' || this.studentLoadingStatus === 'refreshing')
      return;
    this.root.api.refreshStudentsViaSocket(this.id);
  }

  /**
   * Reflects the current state of student loading.
   */
  @observable studentLoadingStatus!: false | 'loading' | 'refreshing' | 'errored' | 'loaded';

  @action setStudentLoadingStatus(status: this['studentLoadingStatus']) {
    this.studentLoadingStatus = status;
  }

  /**
   * Any error object/message received during loading, otherwise false.
   */
  @observable studentLoadingError!: false | any;

  @action setStudentLoadingError(status: false | any) {
    if (status) this.setStudentLoadingStatus('errored');
    this.studentLoadingError = status;
  }

  @observable private studentUserRecords!: StudentUserRecord[];

  @observable studentsUpdatedAt?: Date;

  @action setStudentsUpdatedAt(date: Date) {
    this.studentsUpdatedAt = date;
  }

  getStudentUserRecord(id: string) {
    return this.studentUserRecords.find((s) => s.id === id);
  }

  /**
   * The student UserStores for students enrolled in this section. Note: if
   * a student is enrolled in multiple sections, they will have a unique
   * UserStore for each section they are enrolled in, because of grading
   * differences between sections.
   */
  @computed get students(): UserStore[] {
    this.studentUserRecords.forEach((record) => this.makeStudent(record));
    return Object.values(this._students);
  }

  private makeStudent(studentRecord: StudentUserRecord) {
    if (this._students[studentRecord.id]) {
      // this._students[studentRecord.id].update(studentRecord);
      return;
    }
    const student = new UserStore(studentRecord, this);
    this.disposers.push(
      observe(studentRecord, () => {
        runInAction(() => {
          student.update(studentRecord);
        });
      })
    );
    this._students[studentRecord.id] = student;
  }

  private _students: { [id: string]: UserStore } = {};

  private disposers: Lambda[] = [];

  @action addStudent(userRecord: StudentUserRecord) {
    const exists = this.studentUserRecords.some((s) => s.id === userRecord.id);
    if (exists) return false;
    this.studentUserRecords.push(userRecord);
    if (this._students[userRecord.id]) {
      this._students[userRecord.id]!.update(userRecord);
    }
    return true;
  }

  /**
   * Adds or updates a student to the class section.
   */
  @action addOrUpdateStudent(userRecord: StudentUserRecord) {
    const existingStudent = this.studentUserRecords.find((s) => s.id === userRecord.id);
    if (!existingStudent) {
      this.studentUserRecords.push(userRecord);
      this.count = this.studentUserRecords.length;
    } else this.updateStudent(userRecord);
  }

  /**
   * Updates an existing students -- throws if the student doesn't exist.
   */
  @action updateStudent(userRecord: Partial<StudentUserRecord>) {
    if (!userRecord.id) throw Error('id is required to update a student record.');
    const existingStudentRecord = this.studentUserRecords.find((s) => s.id === userRecord.id);
    if (!existingStudentRecord) throw Error(`Could not find student ${userRecord.id} to update.`);
    updateUserRecord(existingStudentRecord, userRecord);
    if (this._students[userRecord.id]) {
      this._students[userRecord.id]!.update(userRecord);
    }
  }

  /**
   * Remove a student if they exist in this class. Returns boolean of whether the
   * student was removed.
   * @param studentOrId -
   */
  @action removeStudent(
    studentOrId: StudentUserRecord | AbstractUserStore | { id: string } | string
  ): boolean {
    if (!this.studentUserRecords.length) return false;
    const studentId = isString(studentOrId) ? studentOrId : studentOrId.id;
    delete this._students[studentId];
    const index = this.studentUserRecords.findIndex((s) => s.id === studentId);
    if (index === -1) return false;
    this.studentUserRecords.splice(index, 1);
    this.count = this.studentUserRecords.length;
    return true;
  }

  @observable sectionName!: string;

  @action setSectionName(name: string) {
    this.sectionName = name;
  }

  /**
   * The current enrollment of the class, as retreived by getting
   * the section & joining count.
   */
  @observable count?: number;

  /**
   * Whether the enrollment count has been loaded for this section.
   */
  @computed get hasCount(): boolean {
    return this.count !== undefined;
  }

  /**
   * If the section belongs to an institution user (i.e., a school, district, etc...)
   * this is the foreign key for that institution's id.
   */
  @observable institutionId!: string | null;

  @action setInstitutionId(id: string | null) {
    this.institutionId = id || null;
  }

  /**
   * If the section belongs to an institution user (i.e., a school, district, etc...)
   * this is the InstitutionStore for it, available only if the root user teaches
   * for that institution.
   */
  @computed get institution(): InstitutionStore | undefined {
    if (!this.root) throw Error('section.institution is only available on a ClientUserStore.');
    return this.root?.teachesForInstitutions.find((i) => i.id === this.institutionId);
  }

  /**
   * If the section is paid for via a group license, this is the foreign key
   * for the license-owner.
   */
  @observable paidById!: string | null;

  @action setPaidBy(id: string | null) {
    this.paidById = id || null;
  }

  /**
   * The URL to share with a student to invite them to join a section.
   * This is joined on the backend as apiUrl + inviteLink
   */
  @observable inviteUrl?: string;

  /**
   * The code (usually 5-digit) unique to this class that's used to make
   * the inviteUrl a student can use to enroll in this section.
   */
  @observable inviteLink?: string;

  /**
   * Teachers of this section.
   */
  @observable.shallow teacherIds!: string[];

  @action addTeacher(teacherId: string) {
    if (this.teacherIds.includes(teacherId)) return false;
    this.teacherIds.push(teacherId);
    return true;
  }

  @action removeTeacher(teacherId: string) {
    const index = this.teacherIds.indexOf(teacherId);
    if (index === -1) return false;
    this.teacherIds.splice(index, 1);
    return true;
  }

  /**
   * Is the root user a teacher of this section?
   */
  @computed get isTeacher(): boolean {
    if (!this.root) return false;
    return this.teacherIds.includes(this.root.id);
  }

  @observable.shallow ownerIds!: string[];

  @action addOwner(ownerId: string) {
    if (this.ownerIds.includes(ownerId)) return false;
    this.ownerIds.push(ownerId);
    return true;
  }

  @action removeOwner(ownerId: string) {
    const index = this.ownerIds.indexOf(ownerId);
    if (index === -1) return false;
    this.ownerIds.splice(index, 1);
    return true;
  }

  /**
   * Is the root user an owner of this section?
   */
  @computed get isOwner(): boolean {
    if (!this.root) return false;
    return this.ownerIds.includes(this.root.id);
  }

  @observable.shallow teachingAssistantIds!: string[];

  @action addTeachingAssistants(teachingAssistantId: string) {
    if (this.teachingAssistantIds.includes(teachingAssistantId)) return false;
    this.teachingAssistantIds.push(teachingAssistantId);
    return true;
  }

  @action removeTeachingAssistants(teachingAssistantId: string) {
    const index = this.teachingAssistantIds.indexOf(teachingAssistantId);
    if (index === -1) return false;
    this.teachingAssistantIds.splice(index, 1);
    return true;
  }

  /**
   * Is the root user a teaching assistant of this section?
   */
  @computed get isTeachingAssistant(): boolean {
    if (!this.root) return false;
    return this.teachingAssistantIds.includes(this.root.id);
  }

  /**
   * Any custom tests ("checkpoints") connected to this section. When
   * connected, students enrolled in this section have access to take
   * these tests during the visibility window.
   */
  @observable connectedTests!: SectionRecord['connectedTests'];

  @observable private _tests!: { [id: string]: CustomTestStore };

  @action connectOrUpdateTest(
    testConnection: SectionRecord['connectedTests'][number],
    customTest?: SavedCustomCheckpoint
  ) {
    if (customTest) {
      this.addOrUpdateTestStoreToSectionOrRoot(customTest);
    }

    if (
      !this.tests.find((t) => t.id === testConnection.customCheckpointId) &&
      !this.root?.allTests.find((t) => t.id === testConnection.customCheckpointId)
    ) {

      console.log({
        thisTests: this.tests,
        rootTests: this.root?.tests,
        id: testConnection.customCheckpointId
      })

      throw new Error('Cannot add custom test without a custom test record.');
    }

    this.connectedTests = [
      ...this.connectedTests.filter(
        (tc) => tc.customCheckpointId !== testConnection.customCheckpointId
      ),
      testConnection,
    ];
  }

  @action private addOrUpdateTestStoreToSectionOrRoot(customTest: SavedCustomCheckpoint) {
    if (this.root) {
      if (!customTest) {
        throw new Error('Cannot add custom test without a custom test record.');
      }
      this.root.addOrUpdateTest(customTest);
    } else {
      const existingTest = this._tests[customTest.id];
      if (!existingTest) {
        this._tests[customTest.id] = new CustomTestStore(customTest);
        return;
      }
      existingTest.update(customTest);
    }
  }

  @action removeTest(testOrId: SavedCustomCheckpoint | CustomTestStore | string): boolean {
    const customTestId = typeof testOrId === 'string' ? testOrId : testOrId.id;
    const testIdIndex = this.connectedTests.findIndex((t) => customTestId === t.customCheckpointId);
    if (testIdIndex !== -1) {
      this.connectedTests.splice(testIdIndex, 1);
    }
    if (this.root) {
      return testIdIndex !== -1;
    }
    return delete this._tests[customTestId];
  }

  @computed get tests(): CustomTestStore[] {
    // If we have a root, all tests are stored there for normalization.
    if (this.root) {
      return this.connectedTests
        .map((t) => t.customCheckpointId)
        .map((id) => this.root?.testsById[id])
        .filter((t) => t) as CustomTestStore[];
    }
    // Otherwise they're stored on the section store:
    return this.connectedTests
      .map((t) => t.customCheckpointId)
      .map((id) => this._tests[id])
      .filter((t) => t) as CustomTestStore[];
  }

  @computed get testsById(): { [id: string]: CustomTestStore } {
    return this.tests.reduce((dictionary: { [id: string]: CustomTestStore }, test) => {
      dictionary[test.id] = test;
      return dictionary;
    }, {});
  }

  /**
   * Used for calculation of due dates, etc...
   * Updated via call to setNow(date?: Date)
   */
  @observable now!: Date;

  @action setNow(date?: Date) {
    if (date) this.now = date;
    else this.now = new Date();
  }

  /**
   * Content/lesson areas that are enabled
   */
  @computed get areas(): ('rhythm' | 'pitch_and_harmony' | 'ear_training')[] {
    return this.lessons.areas.filter((area) => area.enabled).map((area) => area.textId);
  }

  /**
   * Skills practice areas that are visible
   */
  @computed get skillAreas(): ('rhythm' | 'pitch_and_harmony' | 'ear_training')[] {
    return this.knowledge.areas.filter((area) => area.enabled).map((area) => area.textId);
  }

  constructor(sectionRecord: SectionRecord | SectionRecordWithJoins, root?: ClientUserStore) {
    makeObservable(this);
    this.init(sectionRecord, root);
    this.commitChanges();

    // This magic keeps the student objects updated while they're being observed, and then
    // clears them from memory once they're no longer being observed.
    latestTimeObserved[this.id] = new Date();
    let updateStudentTimeout: any;
    onBecomeObserved(this, 'students', () => {
      // console.log(`became observed at ${new Date().valueOf()}`);
      latestTimeObserved[this.id] = new Date();
      if (this.root && typeof 'window' !== 'undefined' && updateStudentTimeout === undefined) {
        updateStudentTimeout = setInterval(() => this.refreshStudents(), 10 * 1000);
      }
    });

    // Keep _students in memory for 3 seconds, so we don't recalculate if we're just
    // transitioning between states where we'll need them.
    onBecomeUnobserved(this, 'students', () => {
      const unobservedTime = new Date();
      setTimeout(() => {
        // console.log(`testing if unobservable at new ${new Date().valueOf()}`);
        // console.log(`comparing to ${latestTimeObserved[this.id].valueOf()}`);
        if (unobservedTime.valueOf() > latestTimeObserved[this.id]!.valueOf()) {
          // console.log('became unobserved');
          this._students = {};
          if (this.disposers.length) this.disposers.forEach((disposer) => disposer());
          this.disposers.length = 0;
          if (this.root && typeof 'window' !== 'undefined' && updateStudentTimeout !== undefined) {
            clearInterval(updateStudentTimeout);
            updateStudentTimeout = undefined;
          }
        }
      }, 3000);
    });
  }

  @action init(sectionRecord: SectionRecord | SectionRecordWithJoins, root?: ClientUserStore) {
    this.studentUserRecords = [];
    this.setLoadingStatus(false);
    this.setStudentLoadingStatus(false);
    this.setStudentLoadingError(false);
    this.setSavingStatus(false);
    this.setError(false);
    this.setStudentLoadingStatus(false);
    this.updatedAt = new Date(sectionRecord.updatedAt);
    this.isDefaultSection = !!sectionRecord.isDefaultSection;

    this.teacherIds = sectionRecord.teacherIds;
    this.ownerIds = sectionRecord.ownerIds;
    this.teachingAssistantIds = sectionRecord.teachingAssistantIds;

    this.options = new SectionOptionsStore(sectionRecord.options, this);
    this.lessons = new SectionLessonsStore(sectionRecord.lessons, sectionRecord.areas, this);
    this.knowledge = new SectionKnowledgeStore({
      skillsSettings: sectionRecord.skills,
      skillAreas: sectionRecord.skillAreas,
      root: this,
      nodes: knowledgeGraphNodes,
      edges: knowledgeGraphEdges,
    });
    this.id = sectionRecord.id;
    this.sectionName = sectionRecord.sectionName;
    this.institutionId = sectionRecord.institutionId || null;
    this.paidById = sectionRecord.paidById || null;
    this.setNow();

    if ('inviteUrl' in sectionRecord && sectionRecord.inviteUrl) {
      this.inviteUrl = sectionRecord.inviteUrl;
    }

    if ('lmsProviderId' in sectionRecord) {
      this.lmsProviderId = sectionRecord.lmsProviderId;
    }

    if (sectionRecord.inviteLink) {
      this.inviteLink = sectionRecord.inviteLink;
    }

    if (root) {
      this.root = root;
      const delayedSaver = debounce((updates?: Partial<SectionRecord>) => this.save(updates), 1500);
      this.delayedSave = (updates?: Partial<SectionRecord>) => {
        this.setSavingStatus(true);
        this.latestSave++;
        return delayedSaver(updates);
      };
    }
    this._tests = {};
    this.connectedTests = sectionRecord.connectedTests
      ? sectionRecord.connectedTests
      : this.connectedTests || [];
    if ('tests' in sectionRecord) {
      sectionRecord.tests.forEach((test) => this.addOrUpdateTestStoreToSectionOrRoot(test));
    }
  }

  /**
   * Export store as database section record
   */
  dehydrate(): Omit<SectionRecord, 'createdAt' | 'updatedAt'> {
    const sectionRecord: Omit<SectionRecord, 'createdAt' | 'updatedAt'> = {
      id: this.id,
      options: this.options.dehydrate(),
      teacherIds: toJS(this.teacherIds),
      ownerIds: toJS(this.ownerIds),
      teachingAssistantIds: toJS(this.teachingAssistantIds),
      lessons: this.lessons.dehydrate(),
      areas: this.lessons.areas.filter((a) => a.enabled).map((a) => a.textId),
      skills: this.knowledge.dehydrate(),
      skillAreas: this.knowledge.areas.filter((a) => a.enabled).map((a) => a.textId),
      sectionName: toJS(this.sectionName),
      institutionId: toJS(this.institutionId),
      paidById: toJS(this.paidById),
      connectedTests: toJS(this.connectedTests),
    };
    if (this.inviteLink) {
      sectionRecord.inviteLink = this.inviteLink;
    }
    return sectionRecord;
  }

  commitChanges() {
    this._committedRecord = this.dehydrate();
  }

  @action rejectChanges() {
    this.update(this._committedRecord);
  }

  hasChanges(attribute?: keyof Omit<SectionRecord, 'createdAt' | 'updatedAt'>): boolean {
    const committedRecord = toJS(this._committedRecord);
    const uncommittedRecord = this.dehydrate();
    const committedAttribute = attribute ? committedRecord[attribute] : committedRecord;
    const uncommittedAttribute = attribute ? uncommittedRecord[attribute] : uncommittedRecord;
    return isEqual(committedAttribute, uncommittedAttribute);
  }

  private latestSave: number = 0;

  @action save(sectionRecord: Partial<SectionRecord> = this.dehydrate()) {
    // Some fields should not be saved:
    delete sectionRecord?.inviteLink;
    // @ts-ignore
    delete sectionRecord?.inviteUrl;

    // If paidBy has changed, we need to update usage:
    const oldPaidBy = this._committedRecord.paidById;

    const thisSave = this.latestSave;
    this.setSavingStatus(true);
    const priorState = this.dehydrate();
    if (!this.root) throw Error('You can only save a section if a root ClientStore is provided.');
    return this.root.api
      .updateSection(this.id, sectionRecord)
      .then((result) => {
        runInAction(() => {
          this.setSavingStatus(false);
          if (result && thisSave === this.latestSave) {
            this.update(result);
            this._committedRecord = this.dehydrate();
            if (result.updatedAt) this.updatedAt = new Date(result.updatedAt);
            if (oldPaidBy !== this.paidById) this.root?.api.getLicenseUsage();
          }
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.setSavingStatus(false);
          // In case the error happened in the update process:
          this.update(priorState);
          this.setError('Error saving section.', err);
          return Promise.reject(err);
        });
      });
  }

  private _hooks: { hookName: string; callback: Function }[] = [];

  addEventListener(hookName: 'update', callback: Function) {
    this._hooks.push({ hookName, callback });
  }

  removeEventListener(hookName: 'update', callback: Function) {
    const index = this._hooks.findIndex((h) => h.hookName === hookName && h.callback === callback);
    if (index === -1) return false;
    this._hooks.splice(index, 1);
    return true;
  }

  /**
   * Updates the local version of a section store. Does not persist
   * it to database. Use update(partialSectionRecord); save(), or
   * save(partialSectionRecord) to persist to database.
   */
  @action update(sectionRecord: Partial<SectionRecord> | Partial<SectionRecordWithJoins>) {
    // It is possible that the update records arrive out of order, don't
    // update if updatedAt isn't greater than the most recent update:
    if (
      sectionRecord.updatedAt &&
      // Less than, not less than or equal, is important here: when updating
      // joined records only (e.g., teacher enrollments) the updated at won't
      // be changed.
      new Date(sectionRecord.updatedAt).valueOf() < this.updatedAt.valueOf()
    ) {
      return;
    }

    if (sectionRecord.teacherIds) this.teacherIds = sectionRecord.teacherIds;
    if (sectionRecord.ownerIds) this.ownerIds = sectionRecord.ownerIds;
    if (sectionRecord.teachingAssistantIds)
      this.teachingAssistantIds = sectionRecord.teachingAssistantIds;

    if (sectionRecord.options) this.options.update(sectionRecord.options);

    if (sectionRecord.lessons) this.lessons.update(sectionRecord.lessons);

    if (sectionRecord.areas) {
      this.lessons.areas.forEach((area) => {
        const enabled = !!sectionRecord.areas?.includes(area.textId);
        area.setEnabled(enabled);
      });
    }

    if (sectionRecord.skills) {
      this.knowledge.update(sectionRecord.skills);
    }

    if (sectionRecord.skillAreas) {
      this.knowledge.areas.forEach((area) => {
        const enabled = !!sectionRecord.skillAreas?.includes(area.textId);
        area.setEnabled(enabled);
      });
    }

    if (sectionRecord.sectionName) this.setSectionName(sectionRecord.sectionName);
    if ('institutionId' in sectionRecord)
      this.setInstitutionId(sectionRecord.institutionId || null);
    if ('paidById' in sectionRecord) this.setPaidBy(sectionRecord.paidById || null);
    this.setNow();

    if ('tests' in sectionRecord)
      this.updateTests(sectionRecord.tests!, sectionRecord.connectedTests);
    this.connectedTests = sectionRecord.connectedTests || [];

    if (this.status !== 'saving') {
      this._hooks.filter((h) => h.hookName === 'update').forEach((h) => h.callback());
    }

    if ('lmsProviderId' in sectionRecord) {
      this.lmsProviderId = sectionRecord.lmsProviderId;
    }
  }

  @action public updateTests(
    tests: SavedCustomCheckpoint[],
    connectedTests: SectionRecord['connectedTests'] = this.connectedTests || []
  ) {
    tests?.forEach((test) => {
      this.addOrUpdateTestStoreToSectionOrRoot(test);
    });

    // Update the connected tests records:
    this.connectedTests = connectedTests;

    // Remove any deleted tests for GC:
    Object.keys(this._tests).forEach((key) => {
      if (!this.connectedTests.find((t) => t.customCheckpointId === key)) {
        this.removeTest(key);
      }
    });
  }

  fetchCount(): Promise<number> {
    if (!this.root)
      return Promise.reject('Can only fetch count when invoked via a ClientUserStore.');
    return this.root.api.fetchSectionWithCount(this.id).then((result) => {
      runInAction(() => {
        this.count = result.count;
      });
      return result.count;
    });
  }
}

function updateUserRecord(destination: StudentUserRecord, source: Partial<StudentUserRecord>) {
  if (source.uPoints) destination.uPoints = source.uPoints;
  if (source.firstName) destination.firstName = source.firstName;
  if (source.lastName) destination.lastName = source.lastName;
  if (source.email) destination.email = source.email;
  if (source.accommodations) destination.accommodations = source.accommodations;
  if (source.avatarUrl) destination.avatarUrl = source.avatarUrl;
  if (source.credentials) destination.credentials = source.credentials;

  if (source.skills) {
    source.skills.forEach((skill) => {
      const destinationSkillIndex = destination.skills.findIndex(
        (s) => s.skillId === skill.skillId
      );
      if (destinationSkillIndex === -1) destination.skills.push(skill);
      else destination.skills[destinationSkillIndex] = skill;
    });
  }

  if (source.checkpoints) {
    source.checkpoints.forEach((attempt) => {
      const destinationAttemptIndex = destination.checkpoints.findIndex((a) => a.id === attempt.id);
      if (destinationAttemptIndex === -1) destination.checkpoints.push(attempt);
      else destination.checkpoints[destinationAttemptIndex] = attempt;
    });
  }

  if (source.lessons) {
    source.lessons.forEach((lesson) => {
      const destinationLessonIndex = destination.lessons.findIndex(
        (l) => l.lessonId === lesson.lessonId
      );
      if (destinationLessonIndex === -1) destination.lessons.push(lesson);
      else destination.lessons[destinationLessonIndex] = lesson;
    });
  }
}
