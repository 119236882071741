import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Inversion, Compound & Enharmonic Intervals
 */
export const lesson: Lesson = {
  id: 'interval_inversion_and_compound',
  areaId: 'pitch_and_harmony',
  order: 29,
  title: 'Inversion and Enharmonic & Compound Intervals',
  skills: ['interval_inversion', 'simple_and_compound_intervals', 'enharmonic_intervals'],
  pages: [
    {
      id: 'interval_inversion_and_compound_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Interval Inversion & Compound Intervals</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>What inversion is, and how inverting an interval changes it, and</li>
        <li>How to identify and write compound intervals.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video #1:
     *
     *
     * 1) Interval Inversion
     *   To invert an interval, we switch which note is on top, by changing the
     *   octave of one note.
     *
     *   Let's see how that affects the generic name of the interval.
     *
     *   - Generic Inversion:
     *     E-G : 3rd, G-E : 6th
     *     C-D : 2nd, D-C : 7th
     *     E-A : 4th, A-E : 5th
     *   The generic interval & its inversion will always add up to 9.
     *
     *   Now let's see how interval qualities are affected by inversion:
     *   - Specific Inversion:
     *     D-F#: M3, F#-D : __6th
     *     B-C: __2, C-B : __7th
     *     "Major and minor invert onto each other."
     *
     *     B-F: __5th, F-B: __4th
     *     Eb-F#: __2, F#-Eb: __7th
     *     "Augmented and diminished invert onto each other."
     *
     *     Ab-Eb: __5th, Eb-Ab: __4th
     *     "Perfect intervals stay perfect in inversion."
     *
     *   Practice: Naming inversions of intervals.
     */
    {
      id: 'interval_inversion_and_compound_video_1',
      order: 2,
      title: 'Interval Inversion',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'intervalInversion1',
      },
    },
    {
      title: 'Invert Intervals',
      order: 3,
      id: 'interval_inversion_and_compound_cancellation_method',
      interactive: {
        type: 'IntervalInversion',
        drill: false,
        questions: ['P4', 'd5', 'm2', 'A6', 'M3', 'M7', 'A2', 'P8', 'm7', 'P5'],
        autoadvance: true,
      },
    },
    /**
     * Video 2: Compound Intervals
     *
     * 2) Compound Intervals:
     *   Remember, a compound interval is an interval that's larger than an octave. A compound 3rd is a 10th, a simple 11th is a 4th.
     *
     *   So, the compound version of a minor 3rd is a minor 10th.
     *
     *   A3 - C#5
     *
     *   Practice identifying some compound intervals.
     */
    {
      id: 'interval_inversion_and_compound_video_2',
      title: 'Compound Interval Review',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'intervalInversion2',
      },
    },
    {
      title: 'Compound Interval ID',
      order: 5,
      id: 'interval_inversion_and_compound_theoretical_keys',
      interactive: {
        type: 'IntervalsID',
        grandStaff: true,
        questions: ['D3/M10', 'Bb3/A11', 'E3/m13', 'G#2/m14', 'Fb3/P12', 'Eb3/A9', 'A#3/d14'],
      },
    },
    /**
     * Video: Enharmonic Intervals
     *
     * 3) Enharmonically Equivalent Intervals
     *
     *   CHS & DHS look the same on the piano, but are spelled differently on
     *   the staff. These intervals are enharmonically equivalent.
     *
     *   C +M3 on PIANO, Re-spell E, what interval is this?
     *
     *   Use the piano & imagine respelling a note to find an enharmonically
     *   equivalent interval.
     *
     *   Practice: Naming enharmoncially equivalent intervals.
     */
    {
      id: 'interval_inversion_and_compound_video_3',
      title: 'Enharmonic Intervals',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'intervalInversion3',
      },
    },
    {
      title: 'Enharmonic Interval Practice',
      order: 7,
      id: 'enharmonic_intervals',
      interactive: {
        type: 'EnharmonicIntervals',
        questions: ['m6', 'A4', 'A2', 'M7', 'M6', 'm2', 'P5'],
      },
    },
    /**
     * 4) Conclusion:
     *   Now we've covered all of the most important concepts with intervals. You can:
     *     - Write intervals, including very tricky ones
     *     - Convert between simple and compound intervals,
     *     - Invert intervals
     *     - And name enharmonically equivalent intervals.
     *
     *   Great work!
     */
    {
      id: 'interval_inversion_and_compound_video_4',
      order: 8,
      title: 'Conclusion',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'intervalInversion4',
      },
    },
  ],
};
