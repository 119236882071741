import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Treble Clef Ledger Lines
 */
export const lesson: Lesson = {
  id: 'treble_clef_3',
  areaId: 'pitch_and_harmony',
  order: 6,
  title: 'Treble Clef Ledger Lines',
  skills: ['treble_low_legers', 'treble_high_legers'],
  pages: [
    /**
     * Video: Treble Clef Low Ledger Lines
     *
     * We've already talked about one of the treble clef ledger lines: Middle C,
     * which sits on the first ledger line below the treble clef.  But we can
     * continue with ledger lines farther up or down.
     *
     * Let's look at the leger lines below the staff, first.  Starting with
     * middle C -- which is on the first ledger line, we continue down the
     * scale: B A G F.  We could keep going, but we won't.
     */
    {
      title: 'Low Ledger Lines',
      id: 'treble_clef_3_low_leger_video',
      order: 1,
      video: {
        url: 'trebleClef3-1',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Exercise: ID Low Ledgers on Treble Clef
     */
    {
      title: 'Identify: Low Ledgers',
      order: 2,
      id: 'treble_clef_3_low_leger_id',
      interactive: {
        type: 'NoteID',
        questionPrompt: 'Click on the name of the note you see.',
        clef: 'treble',
        questions: [
          'C4',
          'B3',
          'A3',
          'G3',
          'F3',
          'A3',
          'G3',
          'B3',
          'D4',
          'C4',
          'G3',
          'A3',
          'D4',
          'B3',
          'F3',
          'G3',
          'C4',
        ],
      },
    },
    /**
     * Video: Treble High Ledgers
     *
     * Let's talk about the high ledger lines.  Violinists and flute players
     * frequently have to read four or five ledger lines above the treble clef!
     * That's because the treble clef is the highest of all of our clefs.  In
     * fact, let's take a moment to hear one of the most famous excerpts of high
     * ledger line accrobatism: the piccolo flute solo from John Phillip Sousa's
     * Stars and Stripes Forever march, played here by the US Navy Band.
     *
     * Let's get to work learning these: we start with the fifth line of treble
     * clef, which is F, and count up F G A B C D E.  We could keep going, as
     * you saw, but we'll stop there for now.  Take a look at these notes, and
     * let's get some practice with them.
     */
    {
      title: 'Treble Clef: High Ledgers',
      order: 3,
      id: 'treble_clef_3_high_legers_video',
      video: {
        url: 'trebleClef3-2',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play High Ledgers on Treble Clef
     */
    {
      title: 'Play: High Ledgers',
      order: 4,
      id: 'treble_clef_3_play_high_ledgers',
      interactive: {
        type: 'NoteReadingPiano',
        lowestNote: 'E5',
        highestNote: 'G6',
        clef: 'treble',
        questions: [
          'F5/G5/A5/C6',
          'E6/D6/B5/G5',
          'C6/E6/A5/B5',
          'G5/E6/D6/C6',
          'F5/D6/A5/B5',
          'C6/G5/E6/C6',
        ],
      },
    },
    /**
     * Exercise: ID Treble Ledgers
     */
    {
      title: 'Identify: treble Ledgers',
      order: 5,
      id: 'treble_clef_3_id_legers',
      interactive: {
        type: 'NoteID',
        questionPrompt: 'Click on the name of the note you see.',
        clef: 'treble',
        drill: true,
        maxQuestions: 15,
        skills: ['treble_low_legers', 'treble_high_legers'],
      },
    },
    {
      id: 'treble_clef_3_conclusion',
      title: 'Summary',
      order: 6,
      content:
        "<h3>Nice Work</h3>\n<p>Those are the treble leger lines, you'll get more practice with them as we begin to learn other skills!</p>\n",
    },
  ],
};
