import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Half and Whole Steps on the Piano
 */
export const lesson: Lesson = {
  id: 'piano_half_and_whole_steps',
  areaId: 'pitch_and_harmony',
  order: 11,
  title: 'Half and Whole Steps on the Piano',
  skills: ['half_steps_piano', 'whole_steps_piano'],
  pages: [
    {
      title: 'Intro',
      id: 'piano_half_whole_1',
      order: 1,
      content: `
        <h3>Half and Whole Steps on the Piano</h3>
        <p>In this lesson we'll learn about half and whole steps, which are the building blocks of most scales -- including the major and minor scale that we are used to hearing in music.</p>
        <p>Let's get started!</p>
        `,
    },
    /**
     * Video: Half Steps
     *
     * Let's talk about half steps.  This is a way for us to measure the
     * distance between two notes.  A half step is made up of two notes on the
     * piano that have no keys between them.  So, if we look at D, the half step
     * above it would be ... D#/Eb.  If we look at Ab, the half step above that
     * would be A.  These both involved a black note-white note pair.  But, it's
     * not always that way!  Take a look at B.  What note is a half step above
     * it?  And can you find the other place on the piano that's like this? It's
     * E to F.
     *
     * On the next screen, play the note a half step above or below the
     * highlighted note.
     */
    {
      title: 'Half Steps',
      id: 'piano_half_whole_video_1',
      order: 2,
      video: {
        url: 'halfWholePiano1',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play Half Steps
     */
    {
      title: 'Play: Half steps',
      order: 3,
      id: 'piano_half_whole_play_half_steps',
      interactive: {
        type: 'PianoIntervals',
        questionPrompt: 'Play the note {question} the highlighted note.',
        clef: 'treble',
        halfWholeNames: true,
        lowestNote: 'C4',
        highestNote: 'E5',
        questions: [
          'C4/m2',
          'Gb4/m2',
          'B4/m2',
          'Eb4/m2',
          'A4/m2',
          'E4/-m2',
          'D#5/-m2',
          'F4/-m2',
          'B4/-m2',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video: Whole Steps
     *
     * You can probably guess what a whole step would be, I imagine: two half
     * steps, or you could also think of it as any two notes with one piano key
     * in between them.  So: to get a whole step above G we count up a half step
     * and one more, to get A.  White note to white note.  Or, from Db we count
     * up two half steps to get Eb.  Black note to black note.  But whole steps
     * are not always white-to-white or black-to-black.  Can you find one on the
     * piano that's not?  Any of the whole steps around our E-F and B-C will
     * have one black note and one white note.  So, if we started with Eb, a
     * whole step up skips E natural, and ends on F.  OR, if instead we started
     * from E-natural, a whole step up skips F and ends on F#.
     *
     *  Let's get some practice playing whole steps above and below a given note
     *  on the piano:
     */
    {
      title: 'Whole Steps',
      order: 4,
      id: 'piano_half_whole_video2',
      video: {
        url: 'halfWholePiano2',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Exercise: Play Whole Steps
     */
    {
      title: 'Play: Whole Steps',
      order: 5,
      id: 'piano_half_whole_play_whole_steps',
      interactive: {
        type: 'PianoIntervals',
        questionPrompt: 'Play the note {question} the highlighted note.',
        halfWholeNames: true,
        clef: 'treble',
        lowestNote: 'G3',
        highestNote: 'C5',
        questions: [
          'C4/M2',
          'Gb4/M2',
          'B3/M2',
          'Eb4/M2',
          'A4/M2',
          'E4/-M2',
          'C5/-M2',
          'F4/-M2',
          'B4/-M2',
        ],
      },
    },
    /**
     * Video: Identifying HS & WS on the Piano
     *
     * Instead of writing out the entire words Whole Step and Half Step each
     * time we want to refer to one, we typically abbreviate these as WS and HS.
     *
     * It's worth mentioning that, especially in the United Kingdom, these are
     * often referred to as "Whole Tones" and "Semi Tones" and abbreviated "WT"
     * and "ST" accordingly.  These mean the same thing, and musicians use the
     * two sets of terms interchangeably.
     *
     * Now, let's have some practice identifying whole and half steps on the
     * piano.  We'll use the abbreviations WS and HS from here on out!
     */
    {
      title: 'Identify: Half and Whole Steps',
      order: 6,
      id: 'piano_half_whole_video3',
      video: {
        url: 'halfWholePiano3',
        autostart: true,
        autoadvance: true,
      },
    },
    /**
     * Exercise: Identify HS & WS
     */
    {
      title: 'Identify: HS & WS',
      id: 'piano_half_whole_id',
      order: 7,
      interactive: {
        type: 'PianoIntervalsID',
        questionPrompt: 'What is the interval between the notes?',
        lowestNote: 'G3',
        highestNote: 'G5',
        drill: true,
        decoyInterval: 'm3',
        halfWholeNames: true,
        halfWholeOnly: true,
        skills: ['half_steps_piano', 'whole_steps_piano'],
        maxQuestions: 15,
      },
    },
    {
      title: 'Summary',
      id: 'piano_half_while_summary',
      order: 8,
      content: `
        <h3>Nice Work</h3>
        <p>These half and whole steps are the basic building blocks we use to create musical scales.  With these skills, you'll soon be able to play any Major scale on the piano.</p>
        `,
    },
  ],
};
