import type { Institution, GroupLicense, TestingLicense } from './license-definitions';
import type { Subscription } from './subscription-definitions';
import type { SectionRecord, SectionRecordWithJoins } from './section-definitions';
import type { CheckpointResults, SavedCustomCheckpoint } from './checkpoint-definitions';
import type { LessonRecord } from './lesson-record';
import type { JoinedSkillRecord, SkillRecord } from './skill-record';
import type { UPointsWithJoins } from './upoints-record';
import type { LocalLoginCredential, OAuthLoginCredential } from './login-credential-definitions';
import type { FlashRecord } from './flash-definitions';

export const userRoles = ['student', 'teacher', 'institution', 'crawler', 'superUser'] as const;
export type UserRole = typeof userRoles[number];

export const solfegeMethods = ['scaleDegrees', 'solfegeDoMinor', 'solfegeLaMinor'] as const;
export type SolfegeMethod = typeof solfegeMethods[number];

export const rhythmMethods = ['american', 'british'] as const;
export type RhythmMethod = typeof rhythmMethods[number];

export const uTheoryDisplayThemes = ['normal', 'high-contrast'] as const;
export type UTheoryDisplayTheme = typeof uTheoryDisplayThemes[number];

export interface UserPreferences {
  solfegeMethod?: SolfegeMethod;
  rhythmMethod?: RhythmMethod;
  /**
   * The display color theme
   */
  theme?: UTheoryDisplayTheme;
}

// export interface UserWithCredentials extends UserRecord {
//   credentials: (LocalLoginCredential | OAuthLoginCredential)[];
// }

export interface ClientUserWithCredentials extends Omit<UserRecord, 'password'> {
  credentials:
    | Pick<LocalLoginCredential, 'type' | 'username'>
    | Pick<OAuthLoginCredential, 'type' | 'oAuthProviderId' | 'oAuthUserId'>;
}

/**
 * A record on the 'users' table
 */
export interface UserRecord {
  id: string;
  /**
   * The section the user is currently viewing -- must be contained in either their owned
   * sections or their sectionIds list
   */
  sectionId: string;
  /**
   * The sections a user is enrolled in
   */
  sectionIds: string[];
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  emailVerified: boolean | null;
  avatarUrl: string | null;
  /**
   * Accessibility accomodations for students
   */
  accommodations: Accommodation[];
  /**
   * Dictionary of testId: externalUserId
   */
  externalUniqueIds: {
    [testId: string]: string;
  };
  preferences?: UserPreferences;
  /** 'student' 'teacher' 'institution' */
  roles: UserRole[];
  schoolName?: string | null;
  teacherUrl?: string | null;
  teacherImageUrl?: string | null;
  teacherStatusVerified?: boolean | null;
  phone?: string | null;
  /** Teachers who teach for this institutional user */
  teacherIds?: string[];
  testIds?: string[];
  stripeCustomerId?: string | null;
  /* If referred by a campaign, we track that on registration */
  referrer?: string | null;
  createdAt: Date;
  updatedAt: Date;
  isAnonymous: boolean;
  demoUser?: boolean;
  lastLoginAt: Date;
  lastActiveAt: Date;
}

export interface SkillSectionDatum {
  numQuestions: number;
  percent: number;
  recentPercent: number;
  skillId: number;
  averageTime?: number;
  recentAverageTime?: number;
}

export interface UPointsSectionDatum {
  date: string | Date;
  points: number;
  totalTime: number;
  dailyPoints: number;
}

export type CheckpointSectionDatum = Pick<
  CheckpointResults,
  | 'id'
  | 'checkpointId'
  | 'checkpointName'
  | 'overall'
  | 'sections'
  | 'passing'
  | 'attemptStatus'
  | 'createdAt'
  | 'updatedAt'
>;

export interface SectionData {
  skills: SkillSectionDatum[];
  uPoints: UPointsSectionDatum[];
  checkpoints: CheckpointSectionDatum[];
  lessons: Omit<LessonRecord, 'progressData'>[];
}

/* As sent via websocket from /api/sections/{sectionId}/users */
export interface StudentUserRecord {
  email: string | null;
  firstName: string | null;
  id: string;
  lastName: string | null;
  avatarUrl?: string | null;
  checkpoints: CheckpointSectionDatum[];
  lessons: Pick<
    LessonRecord,
    'highestPageCompleted' | 'highestPageFirstCompletedOn' | 'lessonId'
  >[];
  skills: Pick<
    SkillRecord,
    'numQuestions' | 'percent' | 'recentAverageTime' | 'recentPercent' | 'skillId'
  >[];
  credentials: UserWithJoins['credentials'];
  uPoints: {
    /* Points recorded that day */
    dailyPoints: number;
    date: string | Date;
    /* Total current points for that day */
    points: number;
    /* Seconds */
    totalTime: number;
  }[];
  accommodations: Accommodation[];
  lastLoginAt: string | Date;
  lastActiveAt: string | Date;
}

/**
 * The record with potentially joined info from other tables
 */
export interface UserWithJoins extends Omit<UserRecord, 'sectionData'> {
  pagesRemaining: PagesRemaining;
  credentials: (
    | Pick<LocalLoginCredential, 'type' | 'username' | 'displayUsername'>
    | Pick<OAuthLoginCredential, 'type' | 'oAuthProviderId' | 'isClassroomConnected'>
  )[];
  tests: SavedCustomCheckpoint[];
  ownedTests: SavedCustomCheckpoint[];
  isSubscribed: boolean;
  isCanceling: boolean;
  hasEverSubscribed: boolean;
  isPaidByGroupLicense: boolean;
  flash: FlashRecord[];
  licenses: GroupLicense[];
  testingLicenses: TestingLicense[];
  teachesForInstitutions: Institution[];
  subscriptions: Subscription[];
  /**
   * Sections a user is enrolled in
   *
   * @deprecated Use allSections instead.
   */
  currentSections: SectionRecord[];
  /**
   * Sections owned, taught, or TA'd by this user
   *
   * @deprecated Use allSections instead
   */
  sections: SectionRecordWithJoins[];
  /**
   * Sections user is enrolled in or teaches -- the union of
   * currentSections & sections.
   */
  allSections: SectionRecordWithJoins[];
  checkpoints: Omit<CheckpointResults, 'questions'>[];
  /**
   * The default section, only joined on teacher records.
   *
   * @deprecated Use allSections[number].isDefaultSection instead
   */
  defaultSection?: SectionRecord;
  lessons: Omit<LessonRecord, 'progressData'>[];
  skills: JoinedSkillRecord[];
  uPoints: UPointsWithJoins;
}

export function isUserWithJoins(user: any): user is UserWithJoins {
  const keys = Object.keys(user);
  return keys.includes('currentSections');
}

export interface PagesRemaining {
  date: Date | string;
  pages: number;
  anonymousPages: number;
}

export interface Accommodation {
  /**
   * Institution to which this accomodation applies -- if set, applies to all classes
   * within that institution. Either this or sectionId must be set for an accomodation
   * to be applied to a student
   */
  institutionId: string | null;

  /**
   * Section to which this accomodation applies -- if set, applies to just this
   * class. Either this or institutionId must be set for an accomodation
   * to be applied to a student
   */
  sectionId: string | null;

  /**
   * A custom test to which this accommodation should be applied -- useful in the
   * case of an auditioning student, for whom we only want to accommodate a specific
   * placement test within uTheory.
   */
  testId: string | null;

  /**
   * Coefficient by which to multiply time limits & expectations. Ex:
   * a 5-minute time limit for a test becomes 1-minutes when
   * accomodations.time is set to 2. Similarly, a skill that expects
   * completion in 30s now allows 60s.
   */
  time: number;

  /**
   * Coefficient by which to multiply accuracy & passing exectations for
   * tests and skills. A test that requires an 80% to pass would only
   * require a 60% to pass if accuracy is set to 0.75.
   */
  accuracy: number;
}
