import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Basic Eighth Note Patterns in Compound Time
 */
export const lesson: Lesson = {
  id: 'compoundEighthNotePatterns',
  areaId: 'rhythm',
  order: 7,
  title: 'Compound Meter Patterns',
  skills: ['C:qe', 'C:eq', 'C:eeer', 'C:qer', 'C:eqr', 'C:qre', 'C:eree', 'C:ereer', 'C:erq'],
  pages: [
    {
      id: 'compoundEighthNotePatterns_intro',
      order: 1,
      title: 'Introduction',
      content:
        "<h3>Basic Patterns in Compound Time</h3>\n<p>In this lesson we'll learn how to count and play:\n<ul>\n  <li>Patterns using eighth notes and quarter notes,</li>\n  <li>Offbeat eighth notes and quarter notes,</li>\n  <li>and pickup rhythms</li>\n</ul>\n  in compound time signatures.\n</p>\n<p>Click next to get started with the lesson!</p>\n",
    },
    /**
     *  Video 1: Skipping rhythm ( qe and e-e )
     *
     *  See: Eighth Note Patterns in 6-8 doc for details
     */
    {
      id: 'compoundEighthNotePatterns_skipping',
      order: 2,
      title: 'Skipping Rhythm',
      video: {
        autoadvance: true,
        autostart: true,
        url: '6-8-patterns-video-1',
        rhythm: false,
      },
    },
    /**
     * Interactive 1: Count Skipping Rhythms
     */
    {
      id: 'compoundEighthNotePatterns_countSkips',
      title: 'Count Skips',
      order: 3,
      interactive: {
        type: 'LabelCounts',
        manualQuestions: true,
        questions: [
          '6/8: tempo=80 e e e q e',
          '12/8: tempo=80 q e q. e e e q e',
          '9/8: tempo=60 h. q e',
        ],
        autoadvance: true,
      },
    },
    /**
     * Interactive 2: Play Skipping Rhythms
     */
    {
      id: 'compoundEighthNotePatterns_playSkips',
      title: 'Play Skips',
      order: 4,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '6/8: tempo=80 q e q e | q e q. | q e q e | h.',
          '6/8: tempo=70 e e e q e | q e q.r | e e e q e | q e q.',
          '9/8: tempo=60 h. q e | e e e h.r | q. q e e e e | q e h.',
          '12/8: q. q. e e e q e | h. q e e e e | w.r | q e q e h.',
        ],
        autoadvance: true,
      },
    },
    /**
     *  Video 2: Short-Long (e q)
     *
     *  See: Eighth Note Patterns in 6-8 doc for details
     */
    {
      id: 'compoundEighthNotePatterns_shortLong',
      order: 5,
      title: 'Short-Long',
      video: {
        autoadvance: true,
        autostart: true,
        url: '6-8-patterns-video-2',
        rhythm: false,
      },
    },
    /**
     * Interactive: Count Short-Longs
     */
    {
      id: 'compoundEighthNotePatterns_countshortLong',
      title: 'Count Short-Longs',
      order: 6,
      interactive: {
        type: 'LabelCounts',
        manualQuestions: true,
        questions: [
          '6/8: tempo=60 e e e e q',
          '12/8: tempo=80 h.r e q e q',
          '9/8: tempo=60 e q q e e q',
        ],
        autoadvance: true,
      },
    },
    /**
     * Interactive: Play Short-Longs
     */
    {
      id: 'compoundEighthNotePatterns_playShortLongs',
      title: 'Play Short-Longs',
      order: 7,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '6/8: tempo=52 e q e q | e e e q. | e q q e | e q q.',
          '9/8: tempo=62 q. e e e e q | q.r e q  q.r | q. e e e e q | q e e q q.',
          '9/8: tempo=72 q.r q. e q | e e e e q q. | q.r q e e q | e e e q e e q | h. q.r',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video 3: Rests (e e er) and (q er)
     *
     * See: Eighth Note Patterns in 6-8 doc for details
     */
    {
      id: 'compoundEighthNotePatterns_rests',
      order: 8,
      title: 'Rests',
      video: {
        autoadvance: true,
        autostart: true,
        url: '6-8-patterns-video-3',
        rhythm: false,
      },
    },
    /**
     * Interactive: Count Rests
     */
    {
      id: 'compoundEighthNotePatterns_countRests',
      title: 'Count Rests',
      order: 9,
      interactive: {
        type: 'LabelCounts',
        manualQuestions: true,
        questions: ['6/8: tempo=60 e qr e e er', '12/8: tempo=80 h.r e qr e e er'],
        autoadvance: true,
      },
    },
    /**
     * Interactive: Play Rests
     */
    {
      id: 'compoundEighthNotePatterns_playRests',
      title: 'Play Rests',
      order: 10,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '6/8: tempo=52 e e e e e er | e e e q er | e qr e qr | h.',
          '9/8: tempo=60 q e e e e e e er | q e e q e qr | q e e qr e q | e e e e e er q.',
          '12/8: tempo=72 h. e e e q er | h. e e er e qr | h. e q q er | e q q e e qr q.',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video 4: Pickups
     *
     * See: Eighth Note Patterns in 6-8 doc for details
     */
    {
      id: 'compoundEighthNotePatterns_pickups',
      order: 11,
      title: 'Pickups',
      video: {
        autoadvance: true,
        autostart: true,
        url: '6-8-patterns-video-4',
        rhythm: false,
      },
    },
    /**
     * Interactive: Play Pickups
     */
    {
      id: 'compoundEighthNotePatterns_playPickups',
      title: 'Play Pickups',
      order: 12,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '6/8: tempo=52 e:p | e e e qr e | e e e qr e | q. qr e | q.r q er',
          '9/8: tempo=60 e:p | q e q er qr e | q e q. qr e | e qr e e e qr e | e qr e qr q.',
          '12/8: tempo=72 e:p | w. | e e e q. q. qr e | w. | e e e h.r q er',
          '12/8: tempo=65 e:p | q. q. q.r qr e | e q q. q.r q e | q e qr e qr e qr e | e q q.r h.',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video 5: Two Pickups
     *
     * See: Eighth Note Patterns in 6-8 doc for details
     */
    {
      id: 'compoundEighthNotePatterns_twoPickups',
      order: 13,
      title: 'Two Pickups',
      video: {
        autoadvance: true,
        autostart: true,
        url: '6-8-patterns-video-5',
        rhythm: false,
      },
    },
    /**
     * Interactive: Play Two Pickups
     */
    {
      id: 'compoundEighthNotePatterns_playTwoPickups',
      title: 'Play Two Pickups',
      order: 14,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '9/8: tempo=60 e:p e:p | q. q.r qr e | q. q.r er e e | e q e q er e e | q er h.r',
          '6/8: tempo=72 e:p e:p | q er er e e | q. qr e | e e e er e e | q.r q.',
          '12/8: tempo=63 e:p e:p | e e er h.r er e e | e q h.r qr e | e e e h. er e e | w.',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video 6: Offbeats ( -e- -q )
     *
     * See: Eighth Note Patterns in 6-8 doc for details
     */
    {
      id: 'compoundEighthNotePatterns_offbeats',
      order: 15,
      title: 'Offbeats',
      video: {
        autoadvance: true,
        autostart: true,
        url: '6-8-patterns-video-6',
        rhythm: false,
      },
    },
    /**
     * Interactive: Count Offbeats
     */
    {
      id: 'compoundEighthNotePatterns_countOffbeats',
      title: 'Count Offbeats',
      order: 16,
      interactive: {
        type: 'LabelCounts',
        manualQuestions: true,
        questions: [
          '6/8: tempo=60 er q er e er',
          '12/8: tempo=60 qr e er e er er q qr e',
          '12/8: tempo=60 er e er h.r er q',
        ],
        autoadvance: true,
      },
    },
    /**
     * Interactive: Play offbeats
     */
    {
      id: 'compoundEighthNotePatterns_playOffbeats',
      title: 'Play Offbeats',
      order: 17,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '12/8: tempo=60 e:p e:p | q. er q er q er q | q. er e er er e er er e er | q. er e e q. er e er | e q er e er h. ',
          '9/8: tempo=70 er e er h.r | h.r er q | er e er q.r er q | e q q.r er e er',
          '6/8: tempo=60 q:p | e e er er q | e qr er q | er e er er e er | er q q.',
          '12/8: tempo=52 q.:p | q e e q e e er qr e | q e er e e q. er e e | q. q.r h.r | q.r er q er e er er e e | w.',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video 7: Summary
     */
    {
      id: 'compoundEighthNotePatterns_summary',
      order: 18,
      title: 'Summary',
      video: {
        autoadvance: true,
        autostart: true,
        url: '6-8-patterns-video-7',
        rhythm: false,
      },
    },
  ],
};
