import type { Lesson } from '../../../definitions/content-definitions';

/**
 * Melodic Countour & Scale Degrees 123 (ET)
 */
export const lesson: Lesson = {
  id: 'sd_123_lesson',
  areaId: 'ear_training',
  order: 2,
  title: {
    solfegeDoMinor: 'Do Re Mi',
    solfegeLaMinor: 'Do Re Mi',
    scaleDegrees: 'Scale Degrees 123',
  },
  skills: [
    'contour_high_medium_low_easy',
    'contour_high_medium_low_hard',
    'conjunct_disjunct_steps_vs_thirds',
    'conjunct_disjunct_2_note',
    'sd_123_major',
    'sd_frag_123_major',
  ],
  pages: [
    {
      id: 'sd_123_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Melodic Direction & Conjunct/Disjunct Intervals</h3>
        <p>In this lesson we'll learn about:
        <ul>
        <li>Major scales, scale degrees and solfege</li>
        <li>How to write down or play a melody we hear by ear</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: Melodic Shape
     *
     * Melodic Contour: three levels of pitch now, with four notes: you'll have
     * to listen closely for some repetition of the notes
     */
    {
      id: 'sd_123_video_1',
      order: 2,
      title: 'Melodic Shape',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'sd123video1',
      },
    },
    /**
     * Exercise: Melodic Contour Multiple Choice, 3 pitch levels, 4 notes
     */
    {
      id: 'sd_123_contour_mc_three_pitch_levels_four_notes',
      title: 'Shape ID',
      order: 3,
      interactive: {
        type: 'MelodicContour',
        drill: true,
        level: 3,
        maxQuestions: 8,
      },
    },
    /**
     * Video: Melodic Contour with Decoys Multiple Choice
     *
     * Now let's make it a bit harder -- we're going to give you only answers
     * that are similar to what you're hearing, so you really have to identify
     * the relationship of the notes you hear.
     */
    {
      id: 'sd_123_video_2',
      order: 4,
      title: 'Melodic Shape',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'sd123video2',
      },
    },
    /**
     * Exercise: Melodic Contour with Decoys Multiple Choice
     */
    {
      id: 'sd_123_contour_mc_with_decoys',
      title: 'Tricky Shape ID',
      order: 5,
      interactive: {
        type: 'MelodicContour',
        drill: true,
        level: 4,
        maxQuestions: 8,
      },
    },
    /**
     * Exercise: Melodic Contour Dictation, 3 pitch levels, 4 notes
     */
    {
      id: 'sd_123_contour_2',
      title: 'Contour Dictation',
      order: 6,
      interactive: {
        type: 'MelodicContourDictation',
        drill: true,
        level: 4,
        maxQuestions: 6,
      },
    },
    /**
     * Steps vs. Leaps:
     *
     * When we are learning to sight-sing and write or play melodies by ear, one
     * of the first steps is recognizing whether we're hearing steps or leaps.
     */
    {
      id: 'sd_123_video4',
      title: 'Steps & Skips',
      order: 7,
      video: {
        autoadvance: true,
        autostart: true,
        url: 'sd123video4',
      },
    },
    /**
     * Exercise: Conjunct/Disjunct: Steps vs 3rds
     */
    {
      id: 'sd_123_steps_vs_3rds',
      title: 'Practice: Steps/Leaps',
      order: 8,
      interactive: {
        type: 'ConjunctDisjunct',
        drill: true,
        skills: 'conjunct_disjunct_steps_vs_thirds',
        maxQuestions: 8,
      },
    },
    /**
     * Video: Step or Leap?
     *
     * Steps vs Leaps w/ just 2 notes:
     * - That skills can be harder when we're comparing just two notes -- as you
     *   hear these, sing them back, and try and determine if they could be two
     *   adjacent steps of a scale or not:
     * - Conjunct/Disjunct: 2-note
     */
    {
      id: 'sd_123_video5',
      order: 9,
      title: 'Step or Leap',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'sd123video5',
      },
    },
    /**
     * Exercise: Step or Leap (just 2 notes played)
     */
    {
      id: 'sd_123_step_or_leap',
      title: 'Practice: Step or Leap',
      order: 10,
      interactive: {
        type: 'ConjunctDisjunct',
        drill: true,
        skills: 'conjunct_disjunct_2_note',
        maxQuestions: 8,
      },
    },
    /**
     * Video: 1, 2, 3
     *
     * Most of the music we know is based on a scale, and to know what note
     * we're hearing at any time, we just have to figure out what degree of the
     * scale we're hearing. Each note of the scale has a different
     * characteristic feeling to it. Let's take the first 3 notes: 1 is the most
     * stable, it's the beginning and end. 3, which harmonizes well with one and
     * is the first note in the scale that confirms it's a major scale, has a
     * sense of pleasant peacefulness about it. Listen to how one and 3 sound
     * together.  2, however, is a bit unstable -- because it clashes when
     * played with tonic -- we want it to resolve to 1 or 3.
     * - 1,2,3 One note
     */
    {
      id: 'sd_123_intervals_from_tonic',
      title: {
        solfegeDoMinor: 'Do Re Mi',
        solfegeLaMinor: 'Do Re Mi',
        scaleDegrees: '1 2 3',
      },
      order: 11,
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'sd123video6',
      },
    },
    /**
     * Exercise: 123 solfege dictation
     */
    {
      id: 'sd_123_one_note_dictation',
      title: 'Practice: One-note ID',
      order: 12,
      interactive: {
        type: 'OneNoteSolfegeID',
        drill: true,
        maxQuestions: 24,
        tonicizeEvery: 6,
        skills: 'sd_123_major',
      },
    },
    /**
     * Video: Multi-Note Dictation w/ 123
     *
     * Now let's try our hand at little melodies made up of 1, 2 and 3.
     * - 1,2,3 but only 3-notes
     */
    {
      id: 'sd_123_muti_note_explanation',
      title: 'Multiple Note Dictation',
      order: 13,
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'sd123video7',
      },
    },
    /**
     * Exercise: Multi-Note Solfege Dictation w/ 123
     */
    {
      id: 'sd_123_multi_note_dictation',
      title: 'Practice: Multi-note dictation',
      order: 14,
      interactive: {
        type: 'MultipleNoteSolfegeID',
        drill: true,
        maxQuestions: 8,
        tonicizeEvery: 4,
        skills: 'sd_frag_123_major',
      },
    },
    /**
     * Video: Conclusion
     *
     * To be able to play or write music by ear we only need to have two skills
     * -- though both will need to be at a very high level:
     * 1) The ability to know what scale degree or solfege we are hearing, and
     * 2) The theory knowledge to know what note that scale degree is in the key
     *    we want to play or write it in.
     *
     * All of our exercises in dictation will be aimed at helping build and make
     * completely automatic these skills.
     *
     * When we sight sing music, we use those same two skills: we figure out
     * what scale degree we are seeing, and then we remember and sing the sound
     * of that scale degree.
     */
    {
      id: 'sd_123_conclusion',
      order: 15,
      title: 'Conclusion',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'sd123video8',
      },
    },
  ],
};
