import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Writing Whole Steps
 */
export const lesson: Lesson = {
  id: 'whole_steps_staff',
  areaId: 'pitch_and_harmony',
  order: 13,
  title: 'Writing Whole Steps',
  skills: ['ws'],
  pages: [
    {
      id: 'writing_whole_steps_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Writing Whole Steps</h3>
        <p>In this lesson we'll learn how to write whole steps in music notation. 
        Whole steps are always written with adjacent letter names; we'll learn
        why this is and get practice writing whole steps on the staff.</p>
        `,
    },
    /**
     * Video: Adjacent Letter Names
     *
     * When we look at any whole step on the piano, there are always a few ways
     * that we could spell it in letter names.  As we've learned, all of the
     * notes on the piano have multiple names.  With whole steps we follow a
     * convention: we spell the notes so that they are one-letter apart from
     * each other.
     *
     * Take a look at this whole-step (E-F#).  Let's call the first note E, for
     * now.  The second note could be spelled as Gb or F#.  But because we
     * always spell whole steps with adjacent letters in our musical alphabet,
     * we couldn't call this Gb.  That'd be skipping over the letter F.
     * Instead, we call it F#, because E and F# are adjacent letters in the
     * alphabet.
     *
     * But what if, instead of calling the first note E, we called it Fb?  How
     * would that change what we called our second note? [pause] It would have
     * to be Gb, because G is the next letter in the alphabet after F.
     *
     * We do this, largely, to make reading music easier: if we wrote whole
     * steps as the same letter name with accidentals, or skipped a letter name,
     * it would seem confusing to most musicians, who are used to seeing these
     * one note apart.
     *
     * In this next exercise, we'll ask you to play and name whole steps on the
     * piano.  Remember, they're always just one letter name apart!
     */
    {
      id: 'writing_whole_steps_video_1',
      order: 2,
      title: 'Adjacent Letter Names',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'writingWholeSteps1',
      },
    },
    /**
     * Exercise: Play whole steps on piano, then spell them in letters.
     */
    {
      id: 'writing_whole_steps_piano_first_exercise',
      title: 'Play & Spell Whole Steps',
      order: 3,
      interactive: {
        autoadvance: true,
        lowestNote: 'G3',
        highestNote: 'C5',
        halfWholeNames: true,
        questionPrompt:
          'Play the note {question} the given note, then label it by clicking the correct letter and accidental.',
        type: 'PlayAndSpellIntervals',
        questions: ['A/M2', 'C#/M2', 'Eb/M2', 'E#/M2', 'Bb/-M2', 'C#/-M2'],
      },
    },
    /**
     * Video: Whole Steps from Piano to Staff
     *
     * Once we've figured out a whole step on the piano, and how to spell it, we
     * just have to transfer these letters to the staff to write it.  For
     * instance, if we were given the note C#, and asked to write a whole step
     * above it, we'd first figure it out on the piano -- playing C#, then the
     * whole step above it, which could be D# or Eb.  Which is the correct
     * spelling?  [pause] D# -- because it's the letter next to C in the
     * alphabet.  Now that we know how to spell it, we can write it on the
     * staff.
     *
     * Notice again that when we write notes on the staff, the accidental comes
     * first.  But when we say their names, or spell them with letters, the
     * accidental comes last.  Remember this is so that when we're reading music
     * there's no danger of our playing a note before we've read its accidental.
     *
     * Let's get some practice playing whole steps and writing them on the
     * staff.
     */
    {
      id: 'writing_whole_steps_video_2',
      order: 4,
      title: 'From Piano to Staff',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'writingWholeSteps2',
      },
    },
    /**
     * Exercise: Play then Write Whole Steps
     */
    {
      id: 'writing_whole_steps_piano_and_staff',
      order: 5,
      title: 'Play & Write Whole Steps',
      interactive: {
        type: 'PlayAndWriteIntervals',
        autoadvance: true,
        highestNote: 'E5',
        lowestNote: 'C3',
        halfWholeNames: true,
        questionPrompt:
          '1. Play the given note and {question} it.<br />2. Write the second note on the staff.',
        questions: ['F/M2', 'B/M2', 'D#/M2', 'G#/-M2', 'Cb/-M2', 'E/-M2'],
      },
    },
    /**
     * Video: Our Imperfect Notation System
     *
     * In a perfect world, when two notes were one letter apart, and shared the
     * same accidental, they'd always be a whole step apart.  But as you've
     * seen, that's not how it works.  Letter names and our musical staff
     * represent the white notes only.  When we look at the letters by
     * themselves, or the staff by itself, there's nothing obvious to show us
     * which ones have a black note between them.
     *
     * The piano keyboard is a beautiful compromise: showing us the letter names
     * with its white notes, and the notes in between these with its black
     * notes.
     *
     * Because of this, even when we're writing whole steps directly on the
     * staff, without playing them, we're still going to have to imagine the
     * piano first so that we remember whether there's a black note between two
     * letter names.
     *
     * Let's try a couple together: B/M2.  F/M2
     *
     * Now give it a shot on your own.  If you need to, you should feel free to
     * sketch a piano somewhere.  But: it's even better practice to take the
     * time to imagine the piano keyboard in your head as you do these.  Even if
     * it's slower at first, it will really build some important internal music
     * theory muscles.
     */
    {
      id: 'writing_whole_steps_video_3',
      title: 'Our Imperfect Notation System',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'writingWholeSteps3',
      },
    },
    /**
     * Exercise: Write whole steps on the staff
     */
    {
      id: 'writing_whole_steps_staff_only',
      title: 'Write Whole Steps',
      order: 7,
      interactive: {
        type: 'WriteIntervals',
        questionPrompt: 'Write {question} the given note on the staff.',
        halfWholeNames: true,
        questions: ['Db/M2', 'Bb/M2', 'E/M2', 'G#/-M2', 'C/-M2', 'Fx/-M2'],
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'writing_whole_steps_summary',
      order: 8,
      title: 'Summary',
      video: {
        autostart: true,
        autoadvance: true,
        url: 'writingWholeSteps4',
      },
    },
  ],
};
