import { action, computed, observable, makeObservable } from 'mobx';
import type { JoinedSkillRecord } from '../../../definitions/skill-record';
import { UserAreaKnowledgeStore } from './user.area.knowledge.store';
import { UserTopicStore } from './user.topic.store';
import { UserSkillStore } from './user.skill.store';
import type { AbstractUserStore } from '../abstract.user.store';
import { meanReducerFactory } from '../../utils/meanReducer';
import type {
  KnowledgeGraphEdge,
  KnowledgeGraphNode,
} from '../../knowledge/knowledgeGraphDefinitions';
import { sortByFactory } from '../../utils/sortByFactory';

export default class UserKnowledgeStore {
  @observable root!: AbstractUserStore;

  @computed get areas(): UserAreaKnowledgeStore[] {
    return Object.values(this.userKnowledgeGraphNodesById)
      .filter((store): store is UserAreaKnowledgeStore => store.type === 'area')
      .sort(sortByFactory({ key: 'textId', direction: 'descending' }));
  }

  @computed get topics(): UserTopicStore[] {
    return Object.values(this.userKnowledgeGraphNodesById).filter(
      (store): store is UserTopicStore => store.type === 'topic'
    );
  }

  @computed get skills(): UserSkillStore[] {
    return Object.values(this.userKnowledgeGraphNodesById).filter(
      (store): store is UserSkillStore => store.type === 'skill'
    );
  }

  @observable private userSkillsProgressById: { [textId: string]: JoinedSkillRecord };

  @observable private _userKnowledgeGraphNodesById: {
    [textId: string]: UserSkillStore | UserTopicStore | UserAreaKnowledgeStore;
  };

  @computed get userKnowledgeGraphNodesById(): {
    [textId: string]: UserSkillStore | UserTopicStore | UserAreaKnowledgeStore;
  } {
    this.knowledgeGraphNodes.forEach((node: KnowledgeGraphNode): void => {
      const existingNode = this._userKnowledgeGraphNodesById[node.textId];
      if (existingNode) return;
      switch (node.type) {
        case 'skill': {
          this._userKnowledgeGraphNodesById[node.textId] = new UserSkillStore(
            node.textId,
            this.root,
            this.userSkillsProgressById
          );
          break;
        }
        case 'topic': {
          this._userKnowledgeGraphNodesById[node.textId] = new UserTopicStore(
            node.textId,
            this.root
          );
          break;
        }
        default:
        case 'area': {
          this._userKnowledgeGraphNodesById[node.textId] = new UserAreaKnowledgeStore(
            node.textId,
            this.root
          );
        }
      }
    });
    return this._userKnowledgeGraphNodesById;
  }

  @computed get knowledgeGraphNodes(): KnowledgeGraphNode[] {
    return this.root.currentSection.knowledge.knowledgeGraphNodes;
  }

  @computed get knowledgeGraphEdges(): KnowledgeGraphEdge[] {
    return this.root.currentSection.knowledge.knowledgeGraphEdges;
  }

  @computed get requiredAreas(): UserAreaKnowledgeStore[] {
    return this.areas.filter((area) => area.isRequired);
  }

  @computed get isRequired(): boolean {
    return this.requiredAreas.length !== 0;
  }

  /**
   * If no topic is required, we should use all topics to calculate
   * the score. Otherwise, we should just use the required areas.
   */
  @computed private get topicsForScoreCalculation(): UserTopicStore[] {
    const requiredTopics = this.topics.filter((topic) => topic.isRequired);
    if (!requiredTopics.length) return this.topics;
    return requiredTopics;
  }

  @computed private get topicsForGradeToDateCalculation(): UserTopicStore[] {
    return this.topics.filter((topic) => topic.includeInGradeToDate);
  }

  @computed get includeInGradeToDate(): boolean {
    return !!this.topicsForGradeToDateCalculation.length;
  }

  @computed get gradeToDate(): number {
    // This should never happen -- we should check includeInGradeToDate first
    if (!this.includeInGradeToDate) return 0;
    return this.topicsForGradeToDateCalculation
      .map((topic) => topic.gradeToDate)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get score(): number {
    return this.topicsForScoreCalculation
      .map((topic) => topic.score)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get accuracyScore(): number {
    return this.topicsForScoreCalculation
      .filter((topic) => topic.numQuestions > 0)
      .map((topic) => topic.accuracyScore)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get questionsScore(): number {
    return this.topicsForScoreCalculation
      .map((topic) => topic.questionsScore)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get numQuestions(): number {
    return this.topicsForScoreCalculation
      .map((topic) => topic.numQuestions)
      .reduce((sum, numQuestions) => sum + numQuestions, 0);
  }

  @computed get speed(): number | null {
    return (
      this.topicsForScoreCalculation
        .filter((area) => area.isSpeedRequired)
        .map((area) => area.speed)
        .filter((speed): speed is number => speed !== null)
        .reduce(meanReducerFactory(), 0) || null
    );
  }

  @computed get speedScore(): number | null {
    return (
      this.topicsForScoreCalculation
        .filter((area) => area.isSpeedRequired)
        .map((area) => area.speedScore)
        .filter((speedScore): speedScore is number => speedScore !== null)
        .reduce(meanReducerFactory(), 0) || null
    );
  }

  @computed get areasById(): { [id: string]: UserAreaKnowledgeStore } {
    return this.areas.reduce(
      (dictionary: { [id: string]: UserAreaKnowledgeStore }, area: UserAreaKnowledgeStore) => ({
        ...dictionary,
        [area.id]: area,
        [area.textId]: area,
      }),
      {}
    );
  }

  @computed get skillsById(): { [id: string]: UserSkillStore } {
    return this.skills.reduce(
      (dictionary: { [id: string]: UserSkillStore }, skill: UserSkillStore) => ({
        ...dictionary,
        [skill.id]: skill,
        [skill.textId]: skill,
      }),
      {}
    );
  }

  @computed get topicsById(): { [id: string]: UserTopicStore } {
    return this.topics.reduce(
      (dictionary: { [id: string]: UserTopicStore }, topic: UserTopicStore) => ({
        ...dictionary,
        [topic.id]: topic,
        [topic.textId]: topic,
      }),
      {}
    );
  }

  get pitch_and_harmony(): UserAreaKnowledgeStore {
    const areaStore = this.areas.find((area) => area.textId === 'pitch_and_harmony');
    if (!areaStore) throw Error(`No area store for user ${this.root.id}, pitch_and_harmony`);
    return areaStore;
  }

  get rhythm(): UserAreaKnowledgeStore {
    const areaStore = this.areas.find((area) => area.textId === 'rhythm');
    if (!areaStore) throw Error(`No area store for user ${this.root.id}, rhythm`);
    return areaStore;
  }

  get ear_training(): UserAreaKnowledgeStore {
    const areaStore = this.areas.find((area) => area.textId === 'ear_training');
    if (!areaStore) throw Error(`No area store for user ${this.root.id}, ear_training`);
    return areaStore;
  }

  constructor(root: AbstractUserStore, userSkills: JoinedSkillRecord[]) {
    this.root = root;
    this._userKnowledgeGraphNodesById = {};
    this.userSkillsProgressById = {};
    makeObservable(this);
    this.init(userSkills);
  }

  @action init(userSkills: JoinedSkillRecord[]) {
    userSkills.forEach((skillRecord) => {
      const skillTextId = this.knowledgeGraphNodes.find(
        (node) => node.type === 'skill' && node.textId === skillRecord.skillId
      )?.textId;

      /**
       * This can happen if a skill was saved to database for a user, but later
       * deleted from skill model. So: we just log it.
       */
      if (!skillTextId) {
        console.error(
          `Couldn't find the textId for the skill node with skillId ${skillRecord.skillId}. Skipping. @UserKnowledgeStore`
        );
        return;
      }

      this.userSkillsProgressById[skillTextId] = skillRecord;
    });
  }
}
