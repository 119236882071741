import type { LatePenalty } from '../../../definitions/late-penalty-definition';

export function getGradePenalty(
  dueDate: Date | undefined,
  dateCompleted: Date | undefined,
  penalty: LatePenalty
): number {
  const { deductionInterval, deductionPercent } = penalty;

  // If there's no due date, it's not late.
  if (!dueDate) return 0;

  // If the current section doesn't penalize, we don't have to worry.
  if (deductionInterval === 'never') return 0;

  // If the deduction percent is set to 0, we don't have to worry.
  if (deductionPercent === 0) return 0;

  // Is it late?
  const isLate = dateCompleted
    ? dueDate.valueOf() <= dateCompleted.valueOf()
    : dueDate.valueOf() <= Date.now();

  if (!isLate) return 0;

  // If the penalty is done only once, we return that:
  if (deductionInterval === 'once') return deductionPercent;

  // Otherwise we calculate the days between the due date and the completion date.
  const duration = (dateCompleted || new Date()).valueOf() - dueDate.valueOf();
  const days = Math.ceil(duration / 1000 / 60 / 60 / 24);
  const deductionIntervalCount = deductionInterval === 'week' ? Math.ceil(days / 7) : days;
  return Math.min(100, deductionIntervalCount * deductionPercent);
}
