/* eslint-disable max-classes-per-file */

import { computed, makeObservable } from 'mobx';
import type SectionKnowledgeTopicStore from '../../section/sectionKnowledge/section.knowledge.topic.store';
import { topicsById } from '../../knowledge/knowledge.store';
import type { AbstractUserStore } from '../abstract.user.store';
import type { UserSkillStore } from './user.skill.store';
import type { UserAreaKnowledgeStore } from './user.area.knowledge.store';
import { meanReducerFactory } from '../../utils/meanReducer';
import { sum } from '../../utils/sum';
import type {
  KnowledgeGraphEdge,
  KnowledgeGraphTopicNode,
} from '../../knowledge/knowledgeGraphDefinitions';

export class UserTopicStore {
  id: number;

  textId: string;

  root: AbstractUserStore;

  type: 'topic';

  @computed get title(): string {
    if (typeof this.definition.title === 'string') return this.definition.title;

    const solfegeMethod = this.root.preferences.solfegeMethod;
    if (this.definition.title[solfegeMethod]) {
      return this.definition.title[solfegeMethod] as string;
    }

    // This should never happen
    return this.definition.title.scaleDegrees;
  }

  @computed get parents(): (UserSkillStore | UserTopicStore | UserAreaKnowledgeStore)[] {
    const isEdgeToMe = (e: KnowledgeGraphEdge): boolean => e.childId === this.definition.textId;
    const parentIds = this.root.knowledgeProgress.knowledgeGraphEdges
      .filter(isEdgeToMe)
      .map((e: KnowledgeGraphEdge) => e.parentId);

    const parents = ([] as (UserSkillStore | UserTopicStore | UserAreaKnowledgeStore)[])
      .concat(this.root.knowledgeProgress.skills)
      .concat(this.root.knowledgeProgress.topics)
      .concat(this.root.knowledgeProgress.areas)
      .filter((nodeStore: UserSkillStore | UserTopicStore | UserAreaKnowledgeStore) =>
        parentIds.includes(nodeStore.textId)
      );

    return parents;
  }

  @computed get children(): UserSkillStore[] {
    const isEdgeFromMe = (e: KnowledgeGraphEdge): boolean => e.parentId === this.definition.textId;
    const childIds = this.root.knowledgeProgress.knowledgeGraphEdges
      .filter(isEdgeFromMe)
      .map((e: KnowledgeGraphEdge) => e.childId);

    return this.root.knowledgeProgress.skills.filter((skill) => childIds.includes(skill.textId));
  }

  @computed get area(): UserAreaKnowledgeStore | undefined {
    return this.parents.find((parent): parent is UserAreaKnowledgeStore => parent.type === 'area');
  }

  @computed get model(): SectionKnowledgeTopicStore {
    const model = this.root.currentSection.knowledge.topicsById[this.id];
    if (!model) throw Error(`Unable to find section topic model from ${this.id} @UserTopicStore`);
    return model;
  }

  @computed get skills(): UserSkillStore[] {
    return this.children;
  }

  @computed get requiredSkills(): UserSkillStore[] {
    return this.skills.filter((skill) => skill.isRequired);
  }

  /**
   * If this topic isn't required, we should use all visible skills to calculate
   * the score. Otherwise, we should just use the required skills.
   */
  @computed private get skillsForScoreCalculation(): UserSkillStore[] {
    if (this.isRequired) return this.requiredSkills;
    if (this.visibleSkills.length) return this.visibleSkills;
    return this.skills;
  }

  @computed get visibleSkills(): UserSkillStore[] {
    return this.skills.filter((skill) => skill.isVisible);
  }

  @computed private get skillsForGradeToDate(): UserSkillStore[] {
    return this.skills.filter((skill) => skill.includeInGradeToDate);
  }

  @computed get includeInGradeToDate(): boolean {
    return this.skillsForGradeToDate.length !== 0;
  }

  @computed get gradesRow(): (number | string | boolean | Date | null)[] {
    const columns = this.model.gradeColumnInformation;
    return columns.map((column) => {
      switch (column.type) {
        case 'userInfo':
          return this.root.gradesRowUserInfo[column.id];
        case 'overall':
          return this.score;
        case 'skill':
          return this.skills.find((skill) => skill.textId === column.id)?.score ?? null;
      }
      return null;
    });
  }

  @computed get gradeToDate(): number {
    const skills = this.skillsForGradeToDate;
    // This should never happen -- we should only ask for this if includeInGradeToDate is true.
    if (!skills.length) return 0;
    return this.skillsForGradeToDate.map((skill) => skill.score).reduce(meanReducerFactory(), 0);
  }

  @computed get score(): number {
    return this.skillsForScoreCalculation
      .map((skill) => skill.score)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get accuracyScore(): number {
    return this.skillsForScoreCalculation
      .filter((skill) => skill.numQuestions > 0)
      .map((skill) => skill.accuracyScore)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get accuracy(): number {
    return this.skillsForScoreCalculation
      .filter((skill) => skill.numQuestions > 0)
      .map((skill) => skill.accuracy)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get questionsScore(): number {
    return this.skillsForScoreCalculation
      .map((skill) => skill.questionsScore)
      .reduce(meanReducerFactory(), 0);
  }

  /**
   * The number of questions counted towards the questions score.
   */
  @computed get numQuestionsCounted(): number {
    return this.skillsForScoreCalculation
      .map((skill) => Math.min(skill.numQuestions, skill.definition.minQuestions))
      .reduce(sum, 0);
  }

  /**
   * The total number of questions required -- if a user had answered exactly the number needed in
   * each skill group
   */
  @computed get minQuestionsRequired(): number {
    return this.skillsForScoreCalculation
      .map((skill) => skill.definition.minQuestions)
      .reduce(sum, 0);
  }

  @computed get numQuestions(): number {
    return this.skillsForScoreCalculation.map((skill) => skill.numQuestions).reduce(sum, 0);
  }

  @computed get speedScore(): number | null {
    if (!this.isSpeedRequired) return null;
    return this.skillsForScoreCalculation
      .filter((skill) => skill.isSpeedRequired)
      .map((skill) => skill.speedScore)
      .filter((score): score is number => score !== null)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get isSpeedRequired(): boolean {
    return !!this.skillsForScoreCalculation.find((skill) => skill.isSpeedRequired);
  }

  @computed get speed(): number | null {
    if (!this.isSpeedRequired) return null;
    return this.skillsForScoreCalculation
      .map((skill) => skill.speed)
      .filter((speed): speed is number => speed !== null)
      .reduce(meanReducerFactory(), 0);
  }

  @computed get isVisible(): boolean {
    return !!this.skills.find((skill) => skill.isVisible);
  }

  @computed get isRequired(): boolean {
    return this.requiredSkills.length !== 0;
  }

  @computed get definition(): KnowledgeGraphTopicNode {
    const definition = topicsById[this.id];
    if (!definition) throw Error(`Unable to get topic definition for ${this.id} @UserTopicStore`);
    return definition;
  }

  constructor(textId: string, root: AbstractUserStore) {
    makeObservable(this);
    this.root = root;
    this.type = 'topic';
    this.textId = textId;
    const topic = this.root.knowledgeProgress.knowledgeGraphNodes.find(
      (n) => n.textId === this.textId
    ) as KnowledgeGraphTopicNode | undefined;
    if (!topic) throw Error(`Unable to get topic definition for ${this.textId} @UserTopicStore`);
    this.id = topic.id;
  }
}
