import type {
  CheckpointResults,
  CheckpointTest,
  SavedCustomCheckpoint,
  MergedCustomCheckpoint,
} from '../../../definitions/checkpoint-definitions';
import type { CheckpointSectionDatum } from '../../../definitions/user-object-definitions';
import type { TestAttemptStore } from './checkpoint.attempt.store';
import { lessonsStore } from '../../lessons/lessons.store';
import type { RootlessTestAttemptStore } from './rootless.test.attempt.store';
import { maxWithReducer } from '../../utils/maxByReducer';
import { cloneDeep } from '../../utils/clone';
import { mergeWith } from '../../utils/mergeWith';

export function getBestAttempt<T extends TestAttemptStore | RootlessTestAttemptStore>(
  attempts: T[]
): T {
  return attempts.reduce((highest, comparator) => {
    if (highest.isPassed && !comparator.isPassed) return highest;
    if (highest.score > comparator.score) return highest;
    return comparator;
  });
}

export function getLatestAttempt<T extends TestAttemptStore | RootlessTestAttemptStore>(
  attempts: T[]
): T | undefined {
  if (!attempts.length) return undefined;
  return attempts.reduce(maxWithReducer((attempt) => new Date(attempt.record.createdAt).valueOf()));
}

export function getAttemptToUse<T extends TestAttemptStore | RootlessTestAttemptStore>(
  attempts: T[],
  highestOrMostRecent: 'highest' | 'mostRecent' | false = false
): T | undefined {
  if (!attempts || !attempts.length) return undefined;

  // if highestOrMostRecent is specified, obey that:
  // NOTE: this might be used in Map or Reduce, in which case we'd get an int, so check and be sure it's really one or the other
  if (highestOrMostRecent === 'highest' || highestOrMostRecent === 'mostRecent') {
    return highestOrMostRecent === 'highest'
      ? getBestAttempt(attempts)
      : getLatestAttempt(attempts);
  }

  // Otherwise, assume we want the best attempt:
  return getBestAttempt(attempts);
}

export function resultsAreFromCustomCheckpoint(
  attempt: CheckpointSectionDatum | Pick<CheckpointResults, 'passing' | 'checkpointId'>
): boolean {
  // Only custom checkpoints have a passing property:
  if (attempt.passing) return true;
  // But early on in uTheory's history, some of them didn't. So check
  // the lessons list, just in case:
  if (lessonsStore.lessonsById[attempt.checkpointId]) return false;
  return true;
}

/**
 * Determines whether a checkpoint attempt has been passed. Note: any accommodations
 * have been applied for custom tests already, but need to be specified for
 * checkpoints.
 * @param results
 * @param classSectionPassingPercent
 * @param accuracyAccommodation
 */
export function isCheckpointPassed(
  results:
    | CheckpointSectionDatum
    | Pick<
        CheckpointResults,
        'attemptStatus' | 'overall' | 'passing' | 'sections' | 'checkpointId'
      >,
  classSectionPassingPercent: false | number = false,
  latePenalty: number = 0
): boolean {
  // if the test wasn't completed, it's not passed:
  if (results.attemptStatus !== 'complete') return false;

  // if it's not a customCheckpoint, look to the teacher's class settings for whether it's passed!
  if (!resultsAreFromCustomCheckpoint(results)) {
    // If no passing percent is specified, it's passed:
    if (!classSectionPassingPercent) {
      return true;
    }

    // We round the score to the nearest integer, since that's how it's
    // displayed We also round the classSectionPassingPercent, to avoid a
    // situation where a student has a score above the classPassingPercent and
    // after rounding it falls below it. For instance: classPassingPercent =
    // 60.1, student score = 60.4. Should be a pass, but will be a fail if we
    // don't round both.
    return (
      Math.round(results.overall.percentage) - latePenalty >= Math.round(classSectionPassingPercent)
    );
  }

  // It's a custom checkpoint!
  if (!results.passing) return true; // this should never happen

  // If passing isn't required, return true
  if (!results.passing.type) return true;

  // Calculate whether overall & sections are passed
  const overallPassed =
    Math.round(results.overall.percentage) - latePenalty >=
    Math.round(results.passing.passingPercent);
  const sectionsPassed = results.sections.every((section, sectionNumber) =>
    isSectionPassed(results, sectionNumber)
  );

  if (results.passing.type === 'overall') return overallPassed;
  if (results.passing.type === 'sections') return sectionsPassed;
  if (results.passing.type === 'both') return overallPassed && sectionsPassed;

  // this should never happen:
  return true;
}

export function isSectionPassed(
  results: CheckpointSectionDatum | Pick<CheckpointResults, 'sections' | 'passing'>,
  sectionNumber: number
): boolean {
  const section = results.sections[sectionNumber];
  if (!section) {
    throw new Error(`Section ${sectionNumber} does not exist in results`);
  }

  // A section is always passed if it didn't have any questions:
  if (section.totalQuestions === 0) return true;

  // A section is always passed if passing is not required on sections:
  if (!results.passing?.type) return true;
  if (results.passing?.type === 'overall') return true;

  const passingPercent = section.passingPercent || results.passing.passingPercent || 60; // 60 should never happen...
  return section.percentage >= passingPercent;
}

interface MergeTestOptions {
  /** Used in backend, since it's retrieved from DB each time */
  doNotClone?: boolean;
}

export function mergeTest(
  customCheckpoint: SavedCustomCheckpoint,
  model: CheckpointTest,
  options: MergeTestOptions = {}
): MergedCustomCheckpoint {
  const { doNotClone } = options;
  if (!doNotClone) {
    customCheckpoint = cloneDeep(customCheckpoint);
    model = cloneDeep(model);
    delete customCheckpoint.model;
  }

  // We use mergeWith to avoid propogating null values from the customCheckpoint
  // onto defined values on the model.
  const merged = mergeWith(model, customCheckpoint, (dest, source) =>
    source === null ? dest : undefined
  );
  return merged;
}
