import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Dotted Notes and Offbeats
 */
export const lesson: Lesson = {
  id: 'dottedNotesAndOffbeats',
  areaId: 'rhythm',
  order: 4,
  title: 'Dotted Notes & Offbeats',
  skills: ['S:ere', 'S:eer', 'S:h.', 'S:q.e', 'S:q.re', 'S:h.r'],
  pages: [
    {
      id: 'dottedNotesAndOffbeats_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Dotted Notes and Offbeats</h3>
        <p>In this lesson we'll learn how to count and play:
        <ul>
          <li>Dotted half notes & dotted half rests</li>
          <li>Offbeat eighth notes</li>
          <li>Dotted quarter notes & dotted quarter note rests</li>
        </ul>
          in time signatures with 4 on the bottom.
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video 1: Dotted Half Notes
     *
     * The dotted half note is like a half note tied to a quarter note. So, in
     * time signatures with 4 on the bottom, time signatures where the quarter
     * note gets the beat, it will last for three beats.
     *
     * Let's count and clap this rhythm together:
     *   - "4/4: tempo=100 h. q | h h | q h. | w",
     */
    {
      id: 'dottedNotesAndOffbeats_dottedHalf',
      order: 2,
      title: 'Dotted Half Notes',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'dottedNotesAndOffbeats1',
        rhythm: true,
      },
    },
    /**
     * Interactive: Dotted Half Notes
     */
    {
      id: 'dottedNotesAndOffbeats_playDottedHalf',
      title: 'Play Dotted Halves',
      order: 3,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=80 h. q | q qr h | q h. | qr e e q qr',
          '3/4: tempo=60 h. | q q qr | q hr | h.',
          '4/4: e e q h | h. e e | q qr hr | e e h e e | qr h.',
          '4/4: tempo=120 q h. | h. qr | e e h. | qr e e h',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video 2:
     *
     * We can also have dotted half rests, which will last for 3 beats in time
     * signatures with 4 on the bottom.
     * - "4/4: h h | h.r q | q h.r | w",
     */
    {
      id: 'dottedNotesAndOffbeats_dottedHalfRests',
      order: 4,
      title: 'Dotted Half Rests',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'dottedNotesAndOffbeats2',
        rhythm: true,
      },
    },
    /**
     * Interactive: Dotted Half Rests
     */
    {
      id: 'dottedNotesAndOffbeats_playDottedHalfRests',
      order: 5,
      title: 'Play Dotted Half Rests',
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=72 e e q h | h.r e e | e e h.r | e e h.',
          '3/4: tempo=100 e e q q | h.r | e e q qr | h. | q e e q | e e q e e | h.r | h qr',
          '5/4: tempo=60 q q h. | h h.r | e e q h. | h h.r',
        ],
      },
    },
    /**
     * VIDEO 3: Eighth Note Rests
     *
     * If we take a pair of eighth notes, we might count these "1&". But if we
     * replace the second eighth note with a rest, we'll only have a note on the
     * downbeat, and a silence on the second eighth note.
     *
     * Let's count this rhythm together:
     *   - "4/4: e er e er e er e er",
     *
     * Now this one:
     *   - "4/4: h e er qr | q e er q e er | e e e er h"
     */
    {
      id: 'dottedNotesAndOffbeats_video_3',
      title: 'Eighth Note Rests',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'dottedNotesAndOffbeats3',
        rhythm: true,
      },
    },
    /**
     * Interactive: Play Eighth Note Rests
     */
    {
      id: 'dottedNotesAndOffbeats_playEighthNoteRests',
      title: 'Play Eighth Note Rests',
      order: 7,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '2/4: tempo=60 e e e er | e e e er | q e e | e er qr',
          '4/4: tempo=90 q e e e e e e | e e e er e e e er | q e e e e e e | e e e er q e er',
          '4/4: tempo=72 h q e er | e e e e q e er | qr q q e er | e er e er h',
          '3/4: e e q e er | h. | e e q e er | qr h | h.r | e er e e q | e er e e q | h.',
        ],
      },
    },
    /**
     * VIDEO 4: Offbeats
     *
     * Take a pair of eighth notes, we might count these "1&". If we replace the
     * first eighth note with a rest, we're left with a note only on the &. We
     * call this an offbeat, because instead of having the note on the beat, it
     * happens off the beat.
     *
     * Let's count 1&2&3&4& together and clap some offbeats.
     *   - 4/4: er e er e er e er e | er e er e er e er e
     *
     * Now let's try alternating a measure of beats and a measure of offbeats:
     *
     *   - 4/4 q q q q | er e er e er e er e | q q q q | er e er e er e er e
     *
     * Now let's try alternating every two beats:
     *   - 4/4 q q er e er e | q q er e er e | q q er e er e | q q er e er e
     *
     * Now let's try reading a rhythm with some offbeats:
     *   - 4/4 q q q er e | q er e q er e | q q q qr
     */
    {
      id: 'dottedNotesAndOffbeats_video_4',
      title: 'Offbeats',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'dottedNotesAndOffbeats4',
        rhythm: true,
      },
    },
    /**
     * Interactive: Offbeats
     */
    {
      id: 'dottedNotesAndOffbeats_playOffbeats',
      title: 'Play Offbeats',
      order: 9,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '2/4: q er e | q er e | er e er e | h',
          '4/4: e e q q er e | e e q q er e | e e er e e e er e | e e q q qr',
          '3/4: q e er e er | q qr er e | q e er e er | q er e q',
          '4/4: h. er e | er e er e er e q | q h. | er e q er e e e | w',
        ],
      },
    },
    /**
     * VIDEO 5: Dotted Quarter Notes
     *
     * A dotted quarter note is like a quarter note tied to an eighth note.
     *
     * Imagine subdividing a dotted quarter into eighth notes. How many eighth
     * notes are in a dotted quarter? (3)
     *
     * So, when we count in eighth notes, as "1 & 2 & 3 & 4 &" the dotted
     * quarter will last for three of those eighth notes, 1&2 -- whatever
     * happens next will come on the and of 2.
     *
     * Let's count & clap some dotted quarters and eighth notes together.
     *
     *   - "4/4: tempo=52 q. e q. e | q. e q. e"
     */
    {
      id: 'dottedNotesAndOffbeats_video_5',
      title: 'Dotted Quarter Notes',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'dottedNotesAndOffbeats5',
        rhythm: true,
      },
    },
    /**
     * Interactive: Dotted Quarter Notes
     */
    {
      id: 'dottedNotesAndOffbeats_playQuarterNotes',
      title: 'Play Dotted Quarter Notes',
      order: 11,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '2/4: tempo=68 q e e | q et e | q. e | q. er | h',
          '4/4: tempo=80 e e e e q. e | e e q q. e | e er e er q. e | e e er e h',
          '3/4: q. e e e | h. | e e q. e | q. er e e',
          '4/4: h. er e | e e e er q. e | h. e er | q. e e e q',
        ],
      },
    },
    /**
     * VIDEO 6: Dotted Quarter Rests
     *
     * We can also have dotted quarter rests, which we count just as we do
     * dotted quarter notes. Let's count & clap a rhythm together
     *
     *   "4/4: tempo=60 h q.r e | q. e q qr | q.r e e e e er | w"
     */
    {
      id: 'dottedNotesAndOffbeats_video_6',
      title: 'Dotted Quarter Rests',
      order: 12,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'dottedNotesAndOffbeats6',
        rhythm: true,
      },
    },
    /**
     * Interactive: Dotted quarter rests
     */
    {
      id: 'dottedNotesAndOffbeats_playDottedQuarterRests',
      title: 'Play Dotted Quarter Rests',
      order: 13,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: q q q.r e | q q q.r e | e er e e q. e | q.r e e e q',
          '3/4: q q q | q. e e e | e e q. e | q q.r e | q q.r e | h.',
          '2/4: e e q | q.r e | e e er e | q.r e | e er e e | q.r e | q q | h',
        ],
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'dottedNotesAndOffbeats_video_7',
      title: 'Conclusion',
      order: 14,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'dottedNotesAndOffbeats7',
        rhythm: true,
      },
    },
  ],
};
