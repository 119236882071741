import type { Lesson } from '../../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'solfege_and_the_staff',
  areaId: 'ear_training',
  order: 4,
  title: {
    solfegeDoMinor: 'Solfege and the Staff',
    solfegeLaMinor: 'Solfege and the Staff',
    scaleDegrees: 'Scale Degrees and the Staff',
  },
  skills: [
    'identify_tonic_c_major',
    'identify_tonic_major_sharp_keys',
    'identify_tonic_major_flat_keys',
    'labeling_solfege_major',
    'writing_solfege_major',
  ],
  pages: [
    {
      id: 'solfege_and_the_staff_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Solfege/Scale Degrees and the Staff</h3>
        <p>In this lesson we'll learn:
          <ul>
            <li>To find tonic from a Major key signature</li>
            <li>To write and identify solfege/scale degrees on the staff in Major keys</li>
          </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * VIDEO: Sharp keys tonic
     */
    {
      id: 'solfege_and_the_staff_sharp_keys',
      order: 2,
      title: 'Sharp Keys',
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'solfege_and_the_staff_sharp-keys-',
      },
    },
    /**
     * Exercise: Draw tonic given sharp key signature
     */
    {
      id: 'solfege_and_the_staff_sharp_key_tonic_writing',
      order: 3,
      title: 'Write Tonic (Sharp Keys)',
      interactive: {
        type: 'ScaleDegreeToStaff',
        identifyTonicMode: true,
        questions: ['G Major', 'B Major', 'A Major', 'C Major', 'D Major', 'C# Major'],
      },
    },
    /**
     * VIDEO: Flat Keys
     */
    {
      id: 'solfege_and_the_staff_flat_keys',
      order: 4,
      title: 'Flat Keys',
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'solfege_and_the_staff_flat-keys-',
      },
    },
    /**
     * Exercise: Draw tonic given flat key signature
     */
    {
      id: 'solfege_and_the_staff_flat_key_tonic_writing',
      order: 5,
      title: 'Write Tonic (Flat Keys)',
      interactive: {
        type: 'ScaleDegreeToStaff',
        identifyTonicMode: true,
        questions: [
          'Eb Major',
          'F Major',
          'Gb Major',
          'C Major',
          'Cb Major',
          'Bb Major',
          'F Major',
        ],
      },
    },
    /**
     * Video: Write tonic in all major keys
     */
    {
      id: 'solfege_and_the_staff_all_keys',
      order: 6,
      title: 'All Keys',
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'solfege_and_the_staff_all-keys-',
      },
    },
    /**
     * Exercise: Write tonic in all major keys
     */
    {
      id: 'solfege_and_the_staff_all_key_tonic_writing',
      order: 7,
      title: 'Write Tonic (All Keys)',
      interactive: {
        type: 'ScaleDegreeToStaff',
        identifyTonicMode: true,
        questions: ['Ab Major', 'D Major', 'C Major', 'F Major', 'E Major', 'Eb Major', 'C# Major'],
      },
    },
    /**
     * Video: Writing scales within keys
     */
    {
      id: 'solfege_and_the_staff_scales',
      order: 8,
      title: 'Scales in Keys',
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'solfege_and_the_staff_scale-',
      },
    },
    /**
     * Exercise: Write scales in key sigs
     */
    {
      id: 'solfege_and_the_staff_write_scales_in_keys',
      order: 9,
      title: 'Write Scales',
      interactive: {
        type: 'ScaleWrite',
        displayKeySignature: true,
        questions: [
          'Bb/M/asc/treble',
          'D/M/asc/bass',
          'C/M/dsc/treble',
          'A/M/asc/bass',
          'Ab/M/dsc/treble',
        ],
      },
    },
    /**
     * VIDEO: Writing Notes given Scale Degrees
     */
    {
      id: 'solfege_and_the_staff_notes_from_scale_degrees',
      order: 10,
      title: {
        solfegeDoMinor: 'Writing Solfege',
        solfegeLaMinor: 'Writing Solfege',
        scaleDegrees: 'Writing Scale Degrees',
      },
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'solfege_and_the_staff_notes-from-scale-degrees-',
      },
    },
    /**
     * Exercise: Write Notes given Scale Degrees
     */
    {
      id: 'solfege_and_the_staff_write_a_note_given_its_scale_degree',
      order: 11,
      title: {
        solfegeDoMinor: 'Write Solfege',
        solfegeLaMinor: 'Write Solfege',
        scaleDegrees: 'Write Scale Degrees',
      },
      interactive: {
        type: 'ScaleDegreeToStaff',
        drill: true,
        skills: ['writing_solfege_major'],
        maxQuestions: 15,
        changeKeyEvery: 5,
      },
    },
    /**
     * VIDEO: Identifying Scale Degrees
     */
    {
      id: 'solfege_and_the_staff_staff_to_scale_degrees',
      order: 12,
      title: {
        solfegeDoMinor: 'Identifying Solfege',
        solfegeLaMinor: 'Identifying Solfege',
        scaleDegrees: 'Identifying Scale Degrees',
      },
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'solfege_and_the_staff_staff-to-scale-degrees-',
      },
    },
    /**
     * Exercise: Identify Scale Degrees
     */
    {
      id: 'solfege_and_the_staff_scale_degree_identification',
      order: 13,
      title: {
        solfegeDoMinor: 'Identify Solfege',
        solfegeLaMinor: 'Identify Solfege',
        scaleDegrees: 'Identify Scale Degrees',
      },
      interactive: {
        type: 'NoteToScaleDegreeID',
        drill: true,
        skills: ['labeling_solfege_major'],
        maxQuestions: 15,
        changeKeyEvery: 5,
      },
    },
    /**
     * Video: Stepwise Dictation
     */
    {
      id: 'solfege_and_the_staff_stepwise_dictation_video',
      order: 14,
      title: 'Dictation',
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'solfege_and_the_staff_stepwise-dictation-',
      },
    },
    /**
     * Exercise: Dictate solfege, then write it on the staff
     */
    {
      id: 'solfege_and_the_staff_stepwise_dictation_practice',
      order: 14,
      title: {
        solfegeDoMinor: 'Solfege to Staff Dictation',
        solfegeLaMinor: 'Solfege to Staff Dictation',
        scaleDegrees: 'Scale Degree to Staff Dictation',
      },
      interactive: {
        type: 'SolfegeThenStaffDictation',
        drill: true,
        skills: ['sd_frag_stepwise_major'],
        maxQuestions: 6,
        changeKeyEvery: 3,
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'solfege_and_the_staff_stepwise_conclusion',
      order: 16,
      title: 'Conclusion',
      video: {
        solfegeMethod: true,
        sameMinor: true,
        autoadvance: true,
        autostart: true,
        url: 'solfege_and_the_staff_conclusion-',
      },
    },
  ],
};
