import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Natural Minor Scales
 */
export const lesson: Lesson = {
  id: 'natural_minor_scales',
  areaId: 'pitch_and_harmony',
  order: 20,
  title: 'Natural Minor Scales',
  skills: ['natural_minor_piano', 'natural_minor'],
  pages: [
    {
      id: 'natural_minor_scales_intro',
      order: 1,
      title: 'Introduction',
      content:
        "<h3>Natural Minor Scales</h3>\n<p>In this lesson we'll learn:\n<ul>\n<li>The order of half and whole steps in natural minor scales and</li>\n<li>How to write natural minor scales.</li></ul>\n</p>\n<p>Click next to get started with the lesson!</p>\n",
    },
    /**
     * Video: Intro to Natural Minor Scales
     *
     * There are three types of minor scales -- but we'll start with just one of
     * them, "natural minor scales," or simply "minor scales."
     *
     * If we wanted to turn a C Major scale into a C natural minor scale, we
     * would need to lower three notes by a half-step:
     *    - The 3rd note (audio I-i)
     *    - The 6th (audio IV-iv)
     *    - and the 7th (audio V-v, i)
     *
     * This lowering of notes gives the minor scale and minor keys their
     * characteristic darker sound -- and this results in a different order of
     * half and whole steps from what we saw in major.
     *
     * By the way, do you notice what notes we've flatted? Bb, Eb, and Ab -- the
     * first three flats in the order of fifth. Minor scales and keys will
     * always have three more flats (or three fewer sharps) than their Major
     * counterpart.
     *
     * Where are the half steps in this scale?
     *   - By moving 3 down, we've created a half-step from 2-3,
     *   - and by moving 6 down, we've created a half-step from 5-6
     *   - all of the other steps are whole steps.
     *
     * Let's get some practice with the pattern of half & whole steps in a
     * natural minor scale.
     */
    {
      id: 'natural_minor_scales_video_1',
      order: 2,
      title: 'Order of Half and Whole Steps',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'naturalMinorScales1',
      },
    },
    /**
     * Exercise: What's the interval between ___ and ___ in a natural minor scale?
     */
    {
      title: 'HS & WS Pattern',
      order: 3,
      id: 'natural_minor_scales_hs_ws_pattern',
      interactive: {
        type: 'ScalePatterns',
        questionPrompt: 'What is the interval between the {question} notes of {question} scale?',
        hideAugSecond: true,
        skills: ['natural_minor_piano'],
        ordinalNumbers: true,
      },
    },
    /**
     * Video: Play Natural Minor Scales
     *
     * Let's play a natural minor scale on the piano, starting from F, remember
     * that our half steps will be from 2-3 and 5-6. (Work through.)
     *
     * Here's a challenge for you: can you find somewhere on the piano where we
     * can play a natural minor scale using only white notes? Feel free to pause
     * the video as you figure this out.
     *
     * Hopefully you got A. (Work through)
     * This can help you remember the order of half and whole steps in a minor
     * scale: just think of writing from A-to-A.
     *
     * Let's play some minor scales on the piano.
     */
    {
      id: 'natural_minor_scales_video_2',
      order: 4,
      title: 'Playing Minor Scales',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'naturalMinorScales2',
      },
    },
    {
      title: 'Play: Minor Scales',
      order: 5,
      id: 'natural_minor_scales_play',
      interactive: {
        type: 'ScalePlay',
        questionPrompt: 'Play {question} scale.',
        scaleDegreeHints: true,
        questions: ['B3/minor', 'Eb3/minor', 'F#4/minor', 'G4/minor/dsc'],
      },
    },
    /**
     * Video: Spelling Natural Minor Scales
     *
     * Just like major scales, when we spell minor scales, we'll use all of the
     * letter names without skipping or repeating any.
     *
     * Let's play & spell some minor scales.
     */
    {
      id: 'natural_minor_scales_video_3',
      title: 'Spelling Minor Scales',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'naturalMinorScales3',
      },
    },
    /**
     * Exercise: Play & Spell Minor Scales
     */
    {
      id: 'natural_minor_scales_play_and_spell',
      title: 'Play & Spell Minor Scales',
      order: 7,
      interactive: {
        type: 'ScalePlayAndSpell',
        scaleDegreeHints: true,
        questions: ['D/minor', 'G#/minor', 'E/minor/dsc', 'Bb3/minor/dsc'],
        autoadvance: true,
      },
    },
    /**
     * Video: Writing natural minor scales:
     *
     * If you can do that, you can write natural minor scales on the staff. Just
     * be sure to keep the order of half & whole steps in mind, and you'll be
     * fine!
     */
    {
      id: 'natural_minor_scales_video_4',
      title: 'Writing Natural Minor',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'naturalMinorScales4',
      },
    },
    /**
     * Exercise: Write natural minor scales
     */
    {
      id: 'natural_minor_scales_writing',
      title: 'Write: Natural Minor',
      order: 9,
      interactive: {
        type: 'ScaleWrite',
        questionPrompt: 'Write {question} scale.',
        questions: ['C/minor', 'A#2/minor', 'C#4/minor/', 'Ab5/minor/dsc', 'D#4/minor/dsc'],
      },
    },
    /**
     * Video: Conclusion
     *
     * Nice work -- you can now play and write ascending and descending natural
     * minor scales. This will be the basis for the other versions of minor
     * scales that we learn to write. Great work!
     */
    {
      id: 'natural_minor_scales_video_5',
      title: 'Conclusion',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'naturalMinorScales5',
      },
    },
  ],
};
