import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Minor Key Signatures
 */
export const lesson: Lesson = {
  id: 'minor_key_sigs',
  areaId: 'pitch_and_harmony',
  order: 23,
  title: 'Minor Key Signatures',
  skills: ['flat_minors', 'sharp_minors', 'parallel_keys', 'relative_keys'],
  pages: [
    {
      id: 'minor_key_sigs_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Minor Key Signatures</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>About relative keys: the major and minor keys that share the same
        key signature,</li>
        <li>How to identify minor key signatures,</li>
        <li>How to write minor key signatures, and</li>
        <li>About parallel keys: the major and minor keys that share the same
        tonic note.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: Relative Minor Keys
     *  - Write G Major
     *  - Write E minor
     *  - Compare: Both have 1 sharp.
     *  - Their key signatures are the same.
     *  - E is the 6th note of G Major
     *
     *  - Write Ab Major
     *  - Write F minor
     *  - Compare: Both have 4 flats.
     *  - Their key signatures are the same.
     *  - F is the 6th note of Ab Major.
     *  - Ab is the 3rd note of F minor.
     *
     * D Major has 2 sharps.
     * - What minor key has 2 sharps?
     *
     * How many accidentals does Eb major have? (3 flats)
     * - What minor key has the same key signature?
     *
     * What minor key has the same key signature as F# Major?
     *
     * When major & minor keys share a key signature, we call them relative keys.
     *
     * Go down to 6 of a major scale to find it's relative minor key.
     */
    {
      id: 'minor_key_sigs_video_1',
      order: 2,
      title: 'Relative Minor Keys',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'minorKeySigs1',
      },
    },
    {
      title: 'Name Relative Minors',
      order: 3,
      id: 'minor_key_sigs_name_relative_minor',
      interactive: {
        type: 'RelativeAndParallelKeys',
        questions: [
          'A/Major/relative',
          'Bb/Major/relative',
          'F#/Major/relative',
          'Db/Major/relative',
          'C/Major/relative',
          'Eb/Major/relative',
        ],
      },
    },
    /**
     * Video: Identifying Minor Key Signatures
     *
     * Identifying them on the staff is easier because we have a reference for
     * the half and whole steps -- but we can also do that in our head by
     * counting down 1-7-6 from the Major key, going down a half step and then a
     * whole step.
     *
     * Let's try one.
     */
    {
      id: 'minor_key_sigs_video_2',
      title: 'Identifying Minor Keys',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'minorKeySigs2',
      },
    },
    /**
     * Exercise: ID minor keys
     */
    {
      title: 'Identify: Minor Keys',
      order: 5,
      id: 'minor_key_sigs_id',
      interactive: {
        type: 'KeySigID',
        questions: [
          'G#/minor/treble',
          'E/minor/bass',
          'Eb/minor/bass',
          'D/minor/treble',
          'F#/minor/bass',
          'Bb/minor/treble',
        ],
      },
    },
    /**
     * Video: Relative MAJORS
     * We can do the reverse to get from minor to major: we went 8-7-6 to get to
     * minor, to get from minor to major we go 1-2-3:
     *
     * What's the third note of D minor? (F) -- and if we write both scales,
     * sure enough, they both have one flat: Bb.
     *
     * What's the relative major of F# minor? (A)
     */
    {
      id: 'minor_key_sigs_video_3',
      order: 6,
      title: 'Relative Majors',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'minorKeySigs3',
      },
    },
    /**
     * Exercise: What's the relative major of _____?
     */
    {
      title: 'Name Relative Majors',
      order: 7,
      id: 'minor_key_sigs_name_relative_major',
      interactive: {
        type: 'RelativeAndParallelKeys',
        questions: [
          'E/minor/relative',
          'C#/minor/relative',
          'D#/minor/relative',
          'F/minor/relative',
          'C/minor/relative',
          'Eb/minor/relative',
        ],
      },
    },
    /**
     * Video: Writing minor keys
     *
     * Once we can do this, we can write our minor key signatures.
     * First: Figure out its relative major.
     * Second: Write the key signature of the relative major.
     * Let's try a few...
     */
    {
      id: 'minor_key_sigs_video_4',
      title: 'Writing Minor Keys',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'minorKeySigs4',
      },
    },
    /**
     * Exercise: Write minor keys
     */
    {
      id: 'minor_key_sigs_writing',
      title: 'Write: Minor Keys',
      order: 9,
      interactive: {
        type: 'KeySigConstruction',
        questions: [
          'C#/m/treble',
          'B/m/bass',
          'F/m/bass',
          'Ab/m/treble',
          'C/m/treble',
          'F#/m/bass',
          'Eb/m/bass',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video: Circle of 5ths in minor
     */
    {
      id: 'minor_key_sigs_video_5',
      title: 'Circle of 5ths for Minors',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'minorKeySigs5',
      },
    },
    /**
     * Exercise: minor circle of 5ths sort flats
     */
    {
      id: 'minor_key_sigs_circle_fifths_sort_flats',
      title: 'Sort: Circle of Fifths Flats',
      order: 11,
      interactive: {
        type: 'CircleOfFifthsSort',
        sharpsOrFlats: 'flats',
        quality: 'both',
      },
    },
    /**
     * Exercise: minor circle of 5ths sort sharps
     */
    {
      id: 'minor_key_sigs_circle_fifths_sort_sharps',
      title: 'Sort: Circle of Fifths Sharps',
      order: 12,
      interactive: {
        type: 'CircleOfFifthsSort',
        sharpsOrFlats: 'sharps',
        quality: 'both',
      },
    },
    /**
     * Video: Parallel Keys
     *  - Write the scale of A Major
     *  - Now write A minor -- notice it has 3 fewer sharps?
     *
     *   - Now try C Major and C minor
     *     - three more flats in C minor.
     */
    {
      id: 'minor_key_sigs_video_6',
      title: 'Parallel Keys',
      order: 13,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'minorKeySigs6',
      },
    },
    /**
     * Exercise: Sort parallel keys
     */
    {
      id: 'minor_key_sigs_circle_fifths_sort_with_parallel_keys',
      title: 'Sort: Parallel Keys',
      order: 14,
      interactive: {
        type: 'ParallelKeysSort',
      },
    },
    /**
     * Video: More Practice w Minor Keys
     *
     * Getting more practice:
     * What's the last acc in ___?
     * How many ___ in ___?
     * Identify all 15 minor keys
     */
    {
      id: 'minor_key_sigs_video_7',
      title: 'Getting Practice',
      order: 15,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'minorKeySigs7',
      },
    },
    {
      id: 'minor_key_sigs_id_from_last_sharp',
      title: 'Name key from last accidental',
      order: 16,
      interactive: {
        type: 'KeyByLastSharpOrFlat',
        drill: true,
        skills: ['sharp_minors', 'flat_minors'],
        maxQuestions: 5,
      },
    },
    {
      id: 'minor_key_sigs_how_many_accidentals',
      title: 'How many accidentals?',
      order: 16,
      interactive: {
        type: 'NumberOfSharpsOrFlats',
        drill: true,
        maxQuestions: 8,
        skills: ['sharp_majors', 'sharp_minors', 'flat_majors', 'flat_minors'],
      },
    },
    {
      title: 'Identify: All Minor Keys',
      order: 17,
      id: 'minor_key_sigs_id_all',
      interactive: {
        type: 'KeySigID',
        drill: true,
        maxQuestions: 15,
        skills: ['sharp_majors', 'sharp_minors', 'flat_majors', 'flat_minors'],
      },
    },
  ],
};
