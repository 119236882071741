import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Treble Clef Lines & Spaces
 */
export const lesson: Lesson = {
  id: 'treble_clef_2',
  areaId: 'pitch_and_harmony',
  order: 3,
  title: 'Treble Clef: Lines & Spaces',
  skills: ['treble_c_g', 'treble_a_g'],
  pages: [
    /**
     * Video: Lines on the treble clef
     */
    {
      id: 'treble_clef_2_lines',
      order: 1,
      title: 'EGBDF',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'trebleClef2-1',
      },
    },
    /**
     * Exercise: ID lines on treble clef
     */
    {
      id: 'treble_clef_2_lines_id',
      order: 2,
      title: 'Identify: EGBDF',
      interactive: {
        type: 'NoteID',
        clef: 'treble',
        questionPrompt:
          'As fast as you can, name the note you see. {question_number} of {total_questions}',
        questions: [
          'E4',
          'B4',
          'F5',
          'G4',
          'D5',
          'B4',
          'E4',
          'B4',
          'F5',
          'E4',
          'F5',
          'D5',
          'G4',
          'E4',
          'D5',
          'F5',
          'B4',
          'E4',
          'D5',
          'G4',
        ],
      },
    },
    /**
     * Video: Treble Clef Spaces
     */
    {
      id: 'treble_clef_2_spaces',
      title: 'FACE',
      order: 3,
      video: {
        autoadvance: true,
        autostart: true,
        url: 'trebleClef2-2',
      },
    },
    /**
     * Exercise: ID Treble Clef Spaces
     */
    {
      id: 'treble_clef_2_spaces_id',
      order: 4,
      interactive: {
        clef: 'treble',
        questionPrompt:
          'As fast as you can, name the note you see. {question_number} of {total_questions}',
        questions: [
          'F4',
          'C5',
          'A4',
          'E5',
          'C5',
          'F4',
          'A4',
          'F4',
          'E5',
          'F4',
          'E5',
          'C5',
          'A4',
          'E5',
          'F4',
          'A4',
          'E5',
          'C5',
          'A4',
          'F4',
          'E5',
        ],
        type: 'NoteID',
      },
      title: 'Identify: FACE',
    },
    /**
     * Video: Treble Clef mixing lines & spaces
     */
    {
      id: 'treble_clef_2_lines_and_spaces',
      order: 5,
      title: 'EGBDF and FACE',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'trebleClef2-3',
      },
    },
    /**
     * Exercise: Play treble clef lines and spaces
     */
    {
      id: 'treble_clef_2_play_lines_and_spaces',
      order: 6,
      interactive: {
        type: 'NoteReadingPiano',
        clef: 'treble',
        highestNote: 'G5',
        lowestNote: 'C4',
        questionPrompt: 'As fast as you can, play the notes you see.',
        questions: [
          'F4/A4/C5/E5',
          'E4/G4/B4/D5/F5',
          'F4/E5/C5/A4',
          'G4/B4/E4/F5/D5',
          'E5/F4/E5/A4/C5',
          'F4/G4/B4/C5/E5',
          'E4/F4/A4/C5/D5/F5',
          'B4/G4/E4/C5/A4/F4',
          'E5/F4/A4/C5/D5/E4/G4/B4',
          'F5/D5/B4/E4/G4/C',
        ],
      },
      title: 'Play: EGBDF & FACE',
    },
    /**
     * Video: Treble Clef 2 Conclusion
     */
    {
      id: 'treble_clef_2_conclusion',
      order: 7,
      content:
        "<h3>Nice Work</h3>\n<p>You might have found you started to reach a point where you didn't need to think about the mnemonics to remember the names of the notes -- and if so, that's great.  Eventually, this knowledge will come to you instantly and automatically.</p>\n",
      title: 'Summary',
    },
  ],
};
