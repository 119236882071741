import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Beats, Quarters and Eighth Notes
 */
export const lesson: Lesson = {
  id: 'beatsQuartersEighths',
  areaId: 'rhythm',
  order: 1,
  title: 'Beats, Quarter and Eighth Notes',
  skills: ['S:q', 'S:qr', 'S:ee'],
  pages: [
    {
      id: 'beatsQuartersEighths_intro',
      order: 1,
      title: 'Introduction',
      content:
        "<h3>Beats, Quarter Notes and Eighth Notes</h3>\n<p>In this lesson we'll learn:\n<ul>\n<li>About beats and how they are grouped to make measures</li>\n<li>About time signatures and meters with quarter note beats,</li>\n<li>What a measure is,</li>\n<li>How to play quarter note beats,</li>\n<li>How to play quarter note rests, and</li>\n<li>How to play eighth note subdivisions.</li>\n</ul>\n</p>\n<p>Click next to get started with the lesson!</p>\n",
    },
    /**
     * Video 1: Let's talk about rhythm, the time element of music.
     *
     * - Rhythm begins with beats. Beats are the steady, underlying pulse of
     *   music. [graphic: "Beats: the steady, underlying pulse of music."]
     *
     * - Beats are joined in repeating groups of 2, 3, 4 or more beats, to form
     *   a meter. The first beat in a meter is the most strongly accented beat.
     *
     * - Duple meters have 2 beats -- as in a march Try counting "1 2, 1 2" to
     *   the Overture from Tchaikovsky's Nutcracker
     *
     * - Triple meters have 3 beats, as in a waltz
     * - Try counting "1 2 3, 1 2 3" with this Waltz by Johann Strauss, Jr.
     *   (Blue Danube)
     *
     * - And quadruple meters have 4 beats -- which is the most common of all
     * - Try counting "1 2 3 4" with the first movement of Mozart's "A little
     *   night music"
     *
     * In written music, we use a time signature to show what meter we're in.
     *
     * When we see a 4 as the lower number, that means our beat will be
     * represented by a quarter note -- which is drawn like this, either with
     * the stem (the straight line) going up or down.
     *
     * In this case, the upper number tells us how many quarter-note beats we'll
     * have in our meter. 2/4 has two quarter-note beats; 4/4? 3/4? 5/4? 11/4?
     *
     * We draw vertical lines to show when the metric pattern re-begins, and we
     * call these "barlines." The music that happens between the barlines is
     * known as a "measure" or, more informally, a "bar."
     *
     * So, in 2/4 time, we would write 2 quarter notes, and then we would draw a
     * barline, to show that the meter rebegins on the next note. We could do
     * that again, and again, and again. Let's perform this rhythm of steady
     * beats -- let's count the meter's 1 2 aloud, and clap the rhythm.
     *
     *   // RHYTHM: "2/4: q q | q q | q q | q q ||"
     *
     * Let's get some practice now playing steady beats in 2/4, 3/4, and 4/4
     * time.
     *
     */
    {
      id: 'beatsQuartersEighths_meterAndBeat',
      order: 2,
      title: 'Beats, Meter and Time Signatures',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'beatsQuartersEighths1',
        rhythm: true,
      },
    },
    {
      id: 'beatsQuartersEighths_tapMeter',
      title: 'Play Beats',
      order: 3,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '2/4: q q | q q | q q | q q',
          '4/4: q q q q | q q q q | q q q q | q q q q',
          '3/4: q q q | q q q | q q q | q q q',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video 2:
     *
     * Instead of having a note on a beat, we can also have a silence, which in
     * music we call a rest.
     *
     * A quarter rest is drawn like this -- three zigs, and a little c on the
     * bottom. A quarter rest takes the same time as a quarter note.
     *
     * Let's count the meter, and clap a rhythm with quarter notes and quarter
     *   rests together.
     *
     *  RHYTHM:
     *   - "4/4: q q q qr"
     *   - "4/4: q qr q q"
     *   - "3/4: q q
     *   - q | q qr qr | q qr q | q qr qr
     *
     * Great, now try some on your own!
     *
     */
    {
      id: 'beatsQuartersEighths_quarterRests',
      order: 4,
      title: 'Quarter Note Rests',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'beatsQuartersEighths2',
        rhythm: true,
      },
    },
    {
      id: 'beatsQuartersEighths_playQuarterRests',
      order: 5,
      title: 'Play Quarter Rests',
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: q q q qr | q qr q qr | q qr qr q | q qr qr q',
          '3/4: q q q | q qr q | q qr qr | q qr q',
          '2/4: q q | q qr | q qr | qr q | qr qr | q qr',
        ],
      },
    },
    /**
     * Video 3:
     *
     * We can also have multiple notes within a beat. If we divide a quarter
     * note into two even parts, we get eighth notes.
     *
     * An eighth note looks like a quarter note, with a squiggle added that we
     * call a "flag."
     *
     * When two eighth notes are in the same beat, instead of drawing a flag on
     * each fo them, we connect them with a beam.
     *
     * Let's count the meter, and clap some measures of all eighth notes
     * together:
     *
     *   // RHYTHM: "4/4: e e e e e e e e", "3/4 e e e e e e", "2/4 e e e e"
     *
     * Great, give it a try on your own!
     *
     */
    {
      id: 'beatsQuartersEighths_video_3',
      title: 'Pairs of Eighth Notes',
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'beatsQuartersEighths3',
        rhythm: true,
      },
    },
    {
      id: 'beatsQuartersEighths_order_on_staff',
      title: 'Play Eighth Note Groups',
      order: 7,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: e e e e e e e e | e e e e e e e e | e e e e e e e e | e e e e e e e e',
          '3/4: e e e e e e | e e e e e e | e e e e e e | e e e e e e',
          '2/4: e e e e | e e e e | e e e e | e e e e',
        ],
      },
    },
    /**
     * Video 4:
     *
     * Now let's combine what we've learned about quarter notes, quarter rests,
     * and eighth notes to perform some more interesting rhythms! Good luck!
     *
     */
    {
      id: 'beatsQuartersEighths_video_4',
      title: 'More Interesting Rhythms',
      order: 8,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'beatsQuartersEighths4',
        rhythm: true,
      },
    },
    {
      id: 'beatsQuartersEighths_playQuartersEighths',
      title: 'Play Quarters & Eighths',
      order: 9,
      interactive: {
        type: 'RhythmReading',
        manualQuestions: true,
        questions: [
          '4/4: tempo=80 q e e q qr | e e e e q qr | q qr e e qr | q qr e e q',
          '3/4: q e e e e | q q q | q qr e e | q qr qr',
          '2/4: e e qr | q qr | e e  e e | q q',
        ],
      },
    },
    /**
     * Video 5:
     *
     * Congrats!
     *
     */
    {
      id: 'beatsQuartersEighths_video_5',
      title: 'Conclusion',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'beatsQuartersEighths5',
        rhythm: true,
      },
    },
  ],
};
