import type { LessonCheckpointTest } from '../../../definitions/checkpoint-definitions';

export const checkpoint: LessonCheckpointTest = {
  id: 'checkpoint_5_triads',
  areaId: 'pitch_and_harmony',
  checkpoint: true,
  order: 33,
  title: 'Triads',
  options: {
    name: 'Triads',
    instructions: `<p>
        This checkpoint tests your mastery of triads.
      </p>
      <p>
        <strong>All of your answers are final</strong>. You will not be able to go back to 
        change answers on previous questions.</p><p>You have up to {{totalTime}} to complete this 
        checkpoint. Please be sure you have enough time before beginning the checkpoint.
      </p>
      <p>
        {{passingSentence}}
      </p>
      `,
  },
  globalQuestionBanks: {
    minorAndDiminishedTriads: ['F#', 'C#', 'G#', 'D#', 'A#', 'E#'],
    majorAndAugmentedTriads: ['Eb', 'Ab', 'Db', 'Gb', 'Cb', 'Fb'],
    anyTriad: ['Bb', 'F', 'C', 'G', 'D', 'A', 'E', 'B'],
    trickyMajors: ['G#', 'D#', 'A#', 'E#', 'B#'],
    trickyMinors: ['Db', 'Gb', 'Cb', 'Fb'],
    triadInversions: ['53', '6', '64'],
    triadInversionsNoRoot: ['6', '64'],
    diminishedInversions: ['6', '53'],
    augOrDim: ['Aug', 'dim'],
  },
  sections: [
    /**
     * ## Section 1: Write & ID Root Position Triads
     */
    {
      options: {
        instructions: `<h2>Section {{sectionNumber}}: Root Position Triads</h2>
          <p>
            In this section you will have {{sectionTime}}, or {{secondsPerQuestion}} seconds per question,
            to write & identify {{sectionQuestions}} root position triads.
          </p>
          `,
        name: 'Root Position Triads',
        timeLimit: '3:30',
        sortOrder: 'type',
        weight: 16,
        passingPercent: 70,
        completesLessons: ['triads', 'triad_spacing_inversion'],
      },
      questionGroups: [
        {
          options: {
            name: 'Root position triads.',
          },
          questionTypes: [
            {
              questionType: 'ChordAndInversionID',
              options: {
                chords: {
                  type: 'triads',
                  spacing: 'close',
                },
                root: false,
                inversion: false,
                quality: true,
              },
              questionWeighting: 1,
            },
            {
              questionType: 'ChordDraw',
              options: {
                chords: {
                  spacing: 'close',
                },
                format: {
                  inversion: false,
                },
              },
              questionWeighting: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 2,
              questions: ['{{majorAndAugmentedTriads}} Maj', '{{anyTriad}} Maj'],
            },
            {
              numberOfQuestions: 2,
              questions: ['{{minorAndDiminishedTriads}} min', '{{anyTriad}} min'],
            },
            {
              numberOfQuestions: 1,
              questions: [
                '{{majorAndAugmentedTriads}} Aug',
                '{{anyTriad}} {{augOrDim}}',
                '{{minorAndDiminishedTriads}} dim',
              ],
            },
          ],
        },
        {
          options: {
            name: 'Tricky Root position triads.',
          },
          questionTypes: [
            {
              questionType: 'ChordAndInversionID',
              options: {
                chords: {
                  type: 'triads',
                  spacing: 'close',
                },
                root: false,
                inversion: false,
                quality: true,
              },
              questionWeighting: 1,
            },
            {
              questionType: 'ChordDraw',
              options: {
                chords: {
                  spacing: 'close',
                },
                format: {
                  inversion: false,
                },
              },
              questionWeighting: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 1,
              questions: ['{{trickyMajors}} Maj'],
            },
            {
              numberOfQuestions: 1,
              questions: ['{{trickyMinors}} min'],
            },
          ],
        },
      ],
    },
    /**
     * ## Section 2: Write Inverted Triads
     */
    {
      options: {
        instructions: `<h2>Section {{sectionNumber}}: Writing inverted triads</h2>
          <p>
            In this section you will have {{sectionTime}}, or {{secondsPerQuestion}} seconds per question,
            to write {{sectionQuestions}} triads in inversion.
          </p>
          `,
        name: 'Write Inverted Triads',
        timeLimit: '4:00',
        sortOrder: 'type',
        weight: 16,
        passingPercent: 70,
        completesLessons: ['triads', 'triad_spacing_inversion'],
      },
      questionGroups: [
        {
          options: {
            name: 'Write inverted triads.',
          },
          questionTypes: [
            {
              questionType: 'ChordDraw',
              options: {
                chords: {
                  spacing: 'close',
                },
                format: {
                  inversion: 'figures',
                },
              },
              questionWeighting: 1,
            },
            {
              questionType: 'ChordDraw',
              options: {
                chords: {
                  spacing: 'close',
                },
                format: {
                  inversion: 'abbreviation',
                },
              },
              questionWeighting: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 2,
              questions: [
                '{{majorAndAugmentedTriads}} Maj {{triadInversionsNoRoot}}',
                '{{anyTriad}} Maj {{triadInversionsNoRoot}}',
                '{{majorAndAugmentedTriads}} Maj {{triadInversionsNoRoot}}',
                '{{anyTriad}} Maj {{triadInversionsNoRoot}}',
              ],
            },
            {
              numberOfQuestions: 1,
              questions: [
                '{{minorAndDiminishedTriads}} min {{triadInversionsNoRoot}}',
                '{{anyTriad}} min {{triadInversionsNoRoot}}',
                '{{minorAndDiminishedTriads}} min {{triadInversionsNoRoot}}',
                '{{anyTriad}} min {{triadInversionsNoRoot}}',
              ],
            },
            {
              numberOfQuestions: 1,
              questions: ['{{anyTriad}} dim 63', '{{minorAndDiminishedTriads}} dim 63'],
            },
            {
              numberOfQuestions: 2,
              questions: [
                '{{majorAndAugmentedTriads}} Maj {{triadInversionsNoRoot}}',
                '{{anyTriad}} Maj {{triadInversionsNoRoot}}',
                '{{minorAndDiminishedTriads}} min {{triadInversionsNoRoot}}',
                '{{anyTriad}} min {{triadInversionsNoRoot}}',
                '{{anyTriad}} {{augOrDim}} {{diminishedInversions}}',
                '{{anyTriad}} {{augOrDim}} {{diminishedInversions}}',
              ],
            },
          ],
        },
      ],
    },
    /**
     * ## Section 3: Identify Inverted Triads
     */
    {
      options: {
        instructions: `<h2>Section {{sectionNumber}}: Identifying inverted triads</h2>
          <p>
            In this section you will have {{sectionTime}}, or {{secondsPerQuestion}} seconds per question,
            to identify {{sectionQuestions}} triads in open spacings and inversions using inversion numbers or figured bass.
          </p>
          `,
        name: 'ID Inverted Triads',
        timeLimit: '4:00',
        sortOrder: 'type',
        weight: 16,
        passingPercent: 70,
        completesLessons: ['triads', 'triad_spacing_inversion'],
      },
      questionGroups: [
        {
          options: {
            name: 'ID inverted triads.',
          },
          questionTypes: [
            {
              questionType: 'ChordAndInversionID',
              options: {
                chords: {
                  type: 'triads',
                  spacing: 'open',
                },
                root: true,
                inversion: 'numbers',
                quality: true,
              },
              questionWeighting: 1,
            },
            {
              questionType: 'ChordAndInversionID',
              options: {
                chords: {
                  type: 'triads',
                  spacing: 'open',
                },
                root: true,
                inversion: 'figures',
                quality: true,
              },
              questionWeighting: 1,
            },
          ],
          questionBanks: [
            {
              numberOfQuestions: 2,
              questions: [
                '{{majorAndAugmentedTriads}} Maj {{triadInversionsNoRoot}}',
                '{{anyTriad}} Maj {{triadInversionsNoRoot}}',
                '{{majorAndAugmentedTriads}} Maj {{triadInversionsNoRoot}}',
                '{{anyTriad}} Maj {{triadInversions}}',
              ],
            },
            {
              numberOfQuestions: 2,
              questions: [
                '{{minorAndDiminishedTriads}} min {{triadInversionsNoRoot}}',
                '{{anyTriad}} min {{triadInversionsNoRoot}}',
                '{{minorAndDiminishedTriads}} min {{triadInversionsNoRoot}}',
                '{{anyTriad}} min {{triadInversionsNoRoot}}',
              ],
            },
            {
              numberOfQuestions: 1,
              questions: ['{{anyTriad}} Aug', '{{majorAndAugmentedTriads}} Aug'],
            },
            {
              numberOfQuestions: 2,
              questions: [
                '{{majorAndAugmentedTriads}} Maj {{triadInversionsNoRoot}}',
                '{{anyTriad}} Maj {{triadInversions}}',
                '{{minorAndDiminishedTriads}} min {{triadInversionsNoRoot}}',
                '{{anyTriad}} min {{triadInversions}}',
                '{{anyTriad}} {{augOrDim}} {{triadInversions}}',
                '{{anyTriad}} {{augOrDim}} {{triadInversions}}',
              ],
            },
          ],
        },
      ],
    },
  ],
};
