import type { Lesson } from '../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'melodic_direction_conjunct_disjunct',
  order: 1,
  areaId: 'ear_training',
  title: 'Melodic Direction & Motion',
  skills: [
    'contour_basic',
    'contour_high_low',
    'conjunct_disjunct_basic',
    'conjunct_disjunct_direction_changes',
  ],
  pages: [
    {
      id: 'melodic_direction_conjunct_disjunct_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Melodic Direction & Conjunct/Disjunct Intervals</h3>
        <p>In this lesson we'll learn about:
        <ul>
        <li>High and low pitches, and melodic direction</li>
        <li>Conjunct (stepwise or smooth) and disjunct (leapy) melodies</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video 1: What is ear training?
     */
    {
      id: 'melodic_direction_video_1',
      order: 2,
      title: "What's Ear Training?",
      video: {
        autoadvance: true,
        autostart: true,
        url: 'melodicDirection1',
      },
    },
    /**
     * Video: Melodic Direction
     *
     * High vs Low, going up vs going down vs repeating
     *
     * Nobody is tone deaf! It's just a matter of refining skills.
     */
    {
      id: 'melodic_direction_video_2',
      order: 3,
      title: 'Melodic Direction',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'melodicDirection2',
      },
    },
    {
      id: 'melodic_direction_contour_up_down_same_or_back_and_forth',
      title: 'Melodic Direction ID',
      order: 4,
      interactive: {
        type: 'MelodicContour',
        drill: true,
        level: 1,
        maxQuestions: 8,
      },
    },
    /**
     * Video: What is dictation, you ask???
     */
    {
      id: 'melodic_direction_dictation_video',
      order: 5,
      title: 'Dictation',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'melodicDirection3',
      },
    },
    {
      id: 'melodic_direction_contour_first_dictation',
      title: 'Melodic Direction Dictation',
      order: 6,
      interactive: {
        type: 'MelodicContourDictation',
        drill: true,
        level: 1,
        maxQuestions: 8,
      },
    },
    /**
     * Video: Conjunct & Disjunct Motion
     */
    {
      id: 'melodic_direction_conjunct_disjunct_motion',
      order: 7,
      title: 'Conjunct & Disjunct Motion',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'melodicDirection4',
      },
    },
    {
      id: 'melodic_direction_conjunct_disjunct_practice_1',
      title: 'Practice: Conjunct/Disjunct',
      order: 8,
      interactive: {
        type: 'ConjunctDisjunct',
        drill: true,
        skills: 'conjunct_disjunct_basic',
        maxQuestions: 8,
      },
    },

    /**
     * Video: Direction Changes & Repeated Notes
     */
    {
      id: 'melodic_direction_changes',
      order: 9,
      title: 'Direction Changes & Repeated Notes',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'melodicDirection5',
      },
    },
    {
      id: 'melodic_direction_contour_changes',
      title: 'Practice: Contour with direction change',
      order: 10,
      interactive: {
        type: 'MelodicContour',
        drill: true,
        level: 2,
        maxQuestions: 8,
      },
    },
    {
      id: 'melodic_direction_contour_changes_dictation',
      title: 'Practice: Contour with direction change',
      order: 11,
      interactive: {
        type: 'MelodicContourDictation',
        drill: true,
        level: 2,
        maxQuestions: 8,
      },
    },
    {
      id: 'conjunct_disjunct_w_direction_changes',
      title: 'Conjunct/disjunct with direction change',
      order: 12,
      interactive: {
        type: 'ConjunctDisjunct',
        drill: true,
        skills: 'conjunct_disjunct_direction_changes',
        maxQuestions: 8,
      },
    },
    /**
     * Video: Conclusion
     */
    {
      id: 'melodic_direction_video_6',
      order: 13,
      title: 'Conclusion',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'melodicDirection6',
      },
    },
  ],
};
