import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Writing Half Steps on the Staff
 */
export const lesson: Lesson = {
  id: 'half_steps_staff',
  areaId: 'pitch_and_harmony',
  order: 14,
  title: 'Writing Half Steps',
  skills: ['chs', 'dhs'],
  pages: [
    {
      id: 'writing_half_steps_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Writing Half Steps</h3>
        <p>In this lesson we'll learn about the two kinds of half steps: <strong>diatonic</strong> half steps and <strong>chromatic</strong> half steps.  One is written with adjacent names, the other by keeping the same letter name and changing the accidental.</p>
        <p>We'll get practice writing and identifying both kinds, as well as practice distinguishing half and whole steps.</p>
        `,
    },
    /**
     * Video: Chromatic vs Diatonic HS
     *
     * There's only one kind of whole step -- as we've learned, whole steps are
     * always written with adjacent letter names.  But, there are two ways we
     * write half steps: we can write them with adjacent letter names, like we
     * did with whole steps.  Or we can write them with the same letter name,
     * and change the accidental.
     *
     * Take this half step, will say the first note is F.  What are two ways we
     * could spell the black note a half-step above F?
     *
     * If you said F# and Gb you're absolutely right.
     *
     * You're also right if you said Ex... though when we're writing an
     * ascending interval, one that goes up on the keyboard, we'll never use a
     * lower letter name than our starting note!
     *
     * We're about to learn two terms that come from Greek music theory -- and
     * although the way we use these words in modern practice is not at all as
     * they were used in ancient Greece, knowing how the words derived from
     * Greek will help us remember their meanings.
     *
     * OK: So, the two ways we just spelled this half step, F-F# and F-Gb are
     * the two kinds of half steps: the one with two letter names we call a
     * dia-tonic half step.  "Dia" is the Greek prefix meaning "passing
     * through", and "tonic" means tone or, you can think of it as letters.  So
     * -- a diatonic half step is a half step that passes through two letter
     * names.
     *
     * The F to F# is what we call a "chromatic" half step.  "Chroma" here
     * meaning color -- we're basically saying that we color a letter with an
     * accidental.  Like F# is a different shade of F.
     *
     * If you ever have trouble remembering which is which, just think of their
     * derivations: dia-tonic from two-notes/two-letters, and chromatic from
     * "coloring" a letter.
     *
     * Let's get some practice remembering the difference: identify whether the
     * interval you see is a diatonic half step -- two letters -- or a chromatic
     * halfstep -- one letter, different accidentals.
     *
     */
    {
      id: 'writing_half_steps_video_1',
      order: 2,
      title: 'Chromatic vs Diatonic HS',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'writingHalfSteps1',
      },
    },
    {
      id: 'writing_half_steps_id_chs_dhs',
      title: 'Chromatic or Diatonic?',
      order: 3,
      interactive: {
        type: 'IntervalsID',
        questionPrompt: 'Is this a Chromatic Half Step (CHS) or Diatonic Half Step (DHS)?',
        customButtons: [
          {
            text: 'CHS',
            value: 'A1',
            hotKey: ['c', 'C'],
          },
          {
            text: 'DHS',
            value: 'm2',
            hotKey: ['d', 'D'],
          },
        ],
        drill: true,
        skills: ['chs', 'dhs'],
        maxQuestions: 10,
        autoadvance: true,
        halfWholeOnly: true,
      },
    },
    /**
     * Video: Writing CHS's
     *
     * Whenever we write a chromatic half step -- or CHS, we'll always have the
     * same letter.  Remember: the chroma part of that is about "coloring" a
     * note with an accidental, not changing the letter itself.
     *
     * Let's get some practice playing and writing chromatic half steps.
     */
    {
      id: 'writing_half_steps_video_2',
      order: 4,
      title: "Writing CHS's",
      video: {
        autoadvance: true,
        autostart: true,
        url: 'writingHalfSteps2',
      },
    },
    {
      id: 'writing_chs_piano_and_staff',
      order: 5,
      title: "Play & Write CHS's",
      interactive: {
        type: 'PlayAndWriteIntervals',
        autoadvance: true,
        highestNote: 'E5',
        lowestNote: 'C3',
        questionPrompt:
          '1. Play the given note and {question} it.<br />2. Write the second note on the staff.',
        halfWholeNames: true,
        questions: ['F4/A1', 'Bb3/A1', 'F#3/A1', 'Db4/-A1', 'G#4/-A1', 'C5/-A1'],
      },
    },
    /**
     * Video: Writing DHS's
     *
     * Remember "diatonic" means "passing through notes," or through letters.
     * So, writing a diatonic halfstep is a bit like writing a whole step: we'll
     * use adjacent letter names.  So, if we had the half step starting from G,
     * and wanted to write a diatonic halfstep below it, would we write F# or
     * Gb? ... We'd write F#, so that we had two adjacent letter names.  Let's
     * get some practice writing diatonic half steps!
     */
    {
      id: 'writing_half_steps_video_3',
      title: "Writing DHS's",
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'writingHalfSteps3',
      },
    },
    {
      id: 'writing_dhs_piano_and_staff',
      title: "Play & Write DHS's",
      order: 7,
      interactive: {
        type: 'PlayAndWriteIntervals',
        halfWholeNames: true,
        autoadvance: true,
        highestNote: 'E5',
        lowestNote: 'C3',
        questionPrompt:
          '1. Play the given note and {question} it.<br />2. Write the second note on the staff.',
        questions: ['D4/m2', 'C#5/m2', 'Bb3/m2', 'F3/-m2', 'Eb4/-m2', 'G#3/-m2'],
      },
    },
    /**
     * Video: Mixing Up CHS/DHS
     *
     * Now let's mix them up.  In the next two exercises, you'll get practice
     * writing & identifying chromatic and diatonic half steps, as well as whole
     * steps.  We're taking the training wheels off again: you won't see a
     * piano, so you'll have to imagine one in your mind so that you remember
     * where there are and are not black notes between the notes of the staff.
     *
     * If you make a mistake -- double check what you've done by drawing out a
     * piano, and seeing where the notes are on the piano.  About eight times
     * out of ten, you'll discover what went wrong!
     *
     * For half and whole step exercises the keyboard is "key" to success!  I
     * know... sorry... but it's a really key principle!  Alright... good luck!
     */
    {
      id: 'writing_half_steps_video_4',
      order: 8,
      title: 'Mixing It Up',
      video: {
        autostart: true,
        autoadvance: true,
        url: 'writingHalfSteps4',
      },
    },
    {
      id: 'writing_half_and_whole_steps',
      title: 'Write CHS, DHS, WS',
      order: 9,
      interactive: {
        type: 'WriteIntervals',
        questionPrompt: 'Write {question} the given note.',
        drill: true,
        skills: ['ws', 'chs', 'dhs'],
        maxQuestions: 10,
        halfWholeNames: true,
      },
    },
    {
      id: 'writing_half_steps_id_chs_dhs_or_ws',
      title: 'Identify CHS, DHS, WS',
      order: 10,
      interactive: {
        type: 'IntervalsID',
        questionPrompt: 'Identify the interval you see.',
        drill: true,
        decoyInterval: 'A2',
        halfWholeNames: true,
        skills: ['chs', 'dhs', 'ws'],
        maxQuestions: 10,
        autoadvance: true,
        halfWholeOnly: true,
      },
    },
    /**
     * Conslusion
     */
    {
      id: 'writing_half_steps_summary',
      order: 11,
      title: 'Summary',
      content: `
        <h3>Nice Work</h3>
        <p>You've got a good foundation now on the two types of half steps: Chromatic Half Steps and Diatonic Half Steps. As we learn to write scales on the staff, these skills will be very useful. Recognizing whole steps and half steps is also crucial in sight singing.  As we'll learn, the half steps in scales help orient us to our location in a scale.</p>
        <p>As always, we'll get plenty more practice on this!  Keep up the good work!</p>
        `,
    },
  ],
};
