/* eslint-disable-next-line import/no-cycle */
import type { AuthFlowParameters } from 'definitions/auth-object-definitions';
import type { UserWithJoins } from '../../../definitions/user-object-definitions';
import type {
  UserLoginBody,
  UserRegistrationBody,
} from '../../../definitions/local-registration-obj-definition';
import { defaultsDeep } from '../defaultsDeep';
import { JSONParse, JSONStringify } from '../JSONUtils';

export interface ApiServiceConfig {
  baseUrl: string;
  webSocketUrl: string;
}

export function toURLString(
  baseUrl: string,
  relativeUrl: string,
  searchParams?: Record<string, any>
): string {
  const slash = relativeUrl[0] !== '/' ? '/' : '';
  let url = baseUrl + slash + relativeUrl;
  // const url = new URL(relativeUrl, baseUrl);
  if (searchParams) {
    // Fliter any undefined from search params
    const searchParamsWithoutUndefined = { ...searchParams };
    Object.entries(searchParamsWithoutUndefined).forEach(([key, value]) => {
      if (value === undefined) delete searchParamsWithoutUndefined[key];
    });

    url += `?${new URLSearchParams(searchParamsWithoutUndefined).toString()}`;
  }
  return url.toString();
}

type ErrorResponse<T = any> = {
  status: number;
  statusText: string;
  url: string;
  headers: Headers;
  response: Response;
} & T;

export async function extractData<ExpectedResponseType>(
  response: Response
): Promise<ExpectedResponseType> {
  if (!response.ok) {
    const errData = await handleFetchError(response);
    return Promise.reject(errData);
  }

  const text = await response.text();
  const data = await parseResponseText<ExpectedResponseType>(text);
  return data;
}

export async function parseResponseText<T>(text: string): Promise<T> {
  try {
    return JSONParse(text) as T;
  } catch (err) {
    return text as unknown as T;
  }
}

export async function handleFetchError(response: Response): Promise<ErrorResponse> {
  const text = await response.text();
  const data = await parseResponseText(text);

  return {
    status: response.status,
    statusText: response.statusText,
    url: response.url,
    response,
    headers: response.headers,
    ...(typeof data !== 'object' ? { message: data } : (data as object)),
  };
}

export const defaultFetchOptions: RequestInit = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
};

export class API {
  protected baseUrl: string;

  public webSocketUrl: string;

  /**
   * The svelte server will pass its own version of fetch, which preserves
   * cookies during SSR. This allows us to use it.
   */
  private fetch: typeof fetch;

  constructor({ baseUrl, webSocketUrl }: ApiServiceConfig, cookiedFetch?: typeof fetch) {
    this.fetch = cookiedFetch || (typeof window !== 'undefined' ? fetch.bind(window) : fetch);

    this.baseUrl = baseUrl;
    this.webSocketUrl = webSocketUrl;
  }

  $get<T, U extends Record<string, any> = {}>(path: string, params?: U, options: RequestInit = {}) {
    const url = toURLString(this.baseUrl, path, params);

    return this.fetch(url, defaultsDeep(options, { method: 'GET' }, defaultFetchOptions)).then(
      (response) => extractData<T>(response)
    );
  }

  $post<T, U extends Object = {}>(path: string, body?: U, options: RequestInit = {}) {
    const url = toURLString(this.baseUrl, path);

    const defaultPostOptions: RequestInit = {
      method: 'POST',
    };
    if (body) {
      defaultPostOptions.body = JSONStringify(body);
    }
    return this.fetch(url, defaultsDeep(options, defaultPostOptions, defaultFetchOptions)).then(
      (response) => extractData<T>(response)
    );
  }

  $put<T, U extends Object = {}>(path: string, body?: U, options: RequestInit = {}) {
    return this.$post<T, U>(path, body, { ...options, method: 'PUT' });
  }

  $delete<T, U extends Record<string, any> = {}>(
    path: string,
    params?: U,
    options: RequestInit = {}
  ) {
    return this.$get<T, U>(path, params, { ...options, method: 'DELETE' });
  }

  $upload(fileName: string, fileData: string | Blob): Promise<{ url: string }> {
    const url = toURLString(this.baseUrl, 'api/upload');
    const fd = new window.FormData();
    fd.append('file', fileData as Blob, fileName);

    return this.fetch(url, {
      method: 'POST',
      body: fd,
      credentials: 'include',
    }).then((response) => extractData<{ url: string }>(response));
  }

  login(username: string, password: string, parameters: AuthFlowParameters = {}) {
    const loginBody: UserLoginBody = {
      username,
      password,
      ...parameters,
    };

    return this.$post<UserWithJoins>('auth/login?joinAll=true', loginBody);
  }

  connectLocalLoginToOAuth(
    username: string,
    password: string,
    parameters: AuthFlowParameters = {}
  ) {
    const loginBody: UserLoginBody = {
      username,
      password,
      ...parameters,
    };
    return this.$post<UserWithJoins>('auth/connect?joinAll=true', loginBody);
  }

  loginGoogle(params: AuthFlowParameters = {}, url = 'auth/login/google') {
    const urlWithParams = toURLString(this.baseUrl, url, params);

    window.location.href = urlWithParams;
  }

  loginClever(params: AuthFlowParameters = {}, url = 'auth/login/clever') {
    const urlWithParams = toURLString(this.baseUrl, url, params);

    window.location.href = urlWithParams;
  }


  loginFB(params: AuthFlowParameters = {}, url = 'auth/login/facebook') {
    const urlWithParams = toURLString(this.baseUrl, url, params);
    window.location.href = urlWithParams;
  }

  logout(): Promise<void> {
    return this.$post('auth/logout');
  }

  register(data: UserRegistrationBody) {
    return this.$post<UserWithJoins>('auth/register?joinAll=true', data);
  }
}
