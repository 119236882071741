import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Roman numeral triads in major
 */
export const lesson: Lesson = {
  id: 'roman_numeral_triads_in_major',
  areaId: 'pitch_and_harmony',
  order: 36,
  title: 'Roman Numeral Triads in Major',
  skills: ['triad_rn_qualities_major', 'triad_rn_major', 'rn_inversion', 'rn_open_spacing'],
  pages: [
    {
      id: 'roman_numeral_triads_in_major_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Roman Numerals</h3>
        <p>In this lesson we'll learn:
        <ul>
          <li>How roman numerals are used in music,</li>
          <li>The qualities of roman numerals in major keys, and</li>
          <li>How to write and identify roman numerals in major keys.</li>
        </ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video 1: What are Roman Numerals?
     */
    {
      id: 'roman_numeral_triads_in_major_video_1',
      order: 2,
      title: 'What are Roman Numerals?',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'romanNumeralsInMajor1',
      },
    },
    /**
     * Exercise: Figuring out the root:
     */
    {
      id: 'roman_numeral_triads_in_major_roots',
      title: "What's the root?",
      order: 3,
      interactive: {
        type: 'ScaleDegreeToNoteID',
        degreeSystem: 'romans',
        questions: ['G: 6', '5', '1', 'Bb: 4', '2', '7', '1'],
      },
    },
    /**
     * Video: RN Qualities in Major
     */
    {
      id: 'roman_numeral_triads_in_major_video_2',
      title: 'RN Qualities',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMajor2',
      },
    },
    /**
     * Exercise: RN Quality ID
     *
     * "What's the quality of the chord built on..."
     */
    {
      id: 'roman_numeral_triads_in_major_number_to_note',
      title: 'ID RN Qualities',
      order: 5,
      interactive: {
        type: 'RomanQualityLabel',
        chordTypes: 'triads',
        questions: [
          'IV/Major',
          'ii/Major',
          'V/Major',
          'vi/Major',
          'iii/Major',
          'viio/Major',
          'I/Major',
        ],
      },
    },
    /**
     * Video: Writing RN's in Major
     */
    {
      id: 'roman_numeral_triads_in_major_video_3',
      title: "Writing RN's",
      order: 6,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMajor3',
      },
    },
    /**
     * Exercise: Write RN's
     */
    {
      id: 'roman_numeral_triads_in_major_write_rns',
      title: "Write RN's",
      order: 7,
      interactive: {
        type: 'RomanNumeralDraw',
        spacing: 'close',
        questions: [
          'A Maj: vi',
          'A Maj: ii',
          'A Maj: V',
          'A Maj: I',
          'Db Maj: IV',
          'Db Maj: viio',
          'Db Maj: I',
        ],
      },
    },
    /**
     * Video: Writing RN's with Key Signatures
     */
    {
      id: 'roman_numeral_triads_in_major_video_4',
      order: 8,
      title: "Writing RN's with Key Signatures",
      video: {
        autoadvance: true,
        autostart: true,
        url: 'romanNumeralsInMajor4',
      },
    },
    /**
     * Exercise: Write Key, then RN
     */
    {
      id: 'roman_numeral_triads_in_major_write_rns_with_key',
      order: 9,
      title: "Write RN's with Keys",
      interactive: {
        type: 'RomanNumeralDraw',
        drawKey: true,
        questions: [
          'Eb: IV/treble',
          'Eb: viio/treble',
          'Eb: I/treble',
          'F#: iii/bass',
          'F#: ii/bass',
          'F#: V/bass',
          'F#: I/bass',
        ],
      },
    },
    /**
     * Video: Identifying RN's
     */
    {
      id: 'roman_numeral_triads_in_major_video_5',
      order: 10,
      title: "Identifying RN's",
      video: {
        autoadvance: true,
        autostart: true,
        url: 'romanNumeralsInMajor5',
      },
    },
    /**
     * Exercise: Identify RN's
     */
    {
      id: 'roman_numeral_triads_in_major_id_rns',
      order: 11,
      title: "ID RN's",
      interactive: {
        type: 'RomanNumeralID',
        spacing: 'close',
        inversion: false,
        chordTypes: 'triads',
        questions: [
          'F Maj: IV',
          'D Maj: viio',
          'E Maj: iii',
          'G Maj: V',
          'C Maj: vi',
          'Gb Maj: ii',
        ],
      },
    },
    /**
     * Video: RN Inversions & Figured Bass
     */
    {
      id: 'roman_numeral_triads_in_major_video_6',
      title: "Inverting RN's",
      order: 12,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMajor6',
      },
    },
    /**
     * Exercise: Write inverted RN's
     */
    {
      id: 'roman_numeral_triads_in_major_write_rns_in_inversion',
      order: 13,
      title: "Write inverted RN's",
      interactive: {
        type: 'RomanNumeralDraw',
        drawKey: true,
        questions: [
          'E: vi6/treble',
          'E: IV64/treble',
          'E: viio6/treble',
          'E: I6/treble',
          'Bb: ii6/bass',
          'Bb: viio64/bass',
          'Bb: I6/bass',
        ],
      },
    },
    /**
     * Video: ID Inverted RN's
     */
    {
      id: 'roman_numeral_triads_in_major_video_7',
      title: "Identifying Inverted RN's",
      order: 14,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMajor7',
      },
    },
    /**
     * Exercise: ID Inverted Rn's
     */
    {
      id: 'roman_numeral_triads_in_major_id_inverted_rns',
      order: 15,
      title: "ID Inverted RN's",
      interactive: {
        type: 'RomanNumeralID',
        spacing: 'close',
        inversion: true,
        chordTypes: 'triads',
        questions: [
          'Ab Maj: ii6/bass',
          'Ab Maj: I64/bass',
          'Ab Maj: V/bass',
          'Ab Maj: I6/bass',
          'D Maj: vi6/treble',
          'D Maj: V64/treble',
          'D Maj: I6/treble',
        ],
      },
    },
    /**
     * Video: Open Spacing RN ID
     */
    {
      id: 'roman_numeral_triads_in_major_video_8',
      title: "Identifying Open Spacing RN's",
      order: 16,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMajor8',
      },
    },
    /**
     * Exercise: Open Spacing RN ID
     */
    {
      id: 'roman_numeral_triads_in_major_id_open_spacings',
      order: 17,
      title: "ID Open Spacing RN's",
      interactive: {
        type: 'RomanNumeralID',
        spacing: 'open',
        inversion: true,
        chordTypes: 'triads',
        questions: [
          'F Maj: I6',
          'A Maj: IV64',
          'B Maj: V6',
          'Bb Maj: ii6',
          'Db Maj: ii64',
          'D Maj: V',
          'G Maj: I6',
        ],
      },
    },
    /**
     * Video: Register & Spacing
     */
    {
      id: 'roman_numeral_triads_in_major_video_9',
      title: 'Register & Spacing',
      order: 18,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMajor9',
      },
    },
    /**
     * Exercise: Writing RN Open Spacing
     */
    {
      id: 'roman_numeral_triads_in_major_write_open_spacings',
      order: 19,
      title: "Write Open Spacing's",
      interactive: {
        type: 'RomanNumeralDraw',
        spacing: 'open',
        chordTypes: 'triads',
        questions: [
          'A Maj: I6',
          'Db Maj: IV6',
          'G Maj: IV64',
          'Eb Maj: V6',
          'Bb Maj: viio6',
          'Bb Maj: I',
        ],
      },
    },
    {
      id: 'roman_numeral_triads_in_major_video_10',
      title: 'Conclusion',
      order: 20,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'romanNumeralsInMajor10',
      },
    },
  ],
};
