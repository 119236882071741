import { action, computed, observable, makeObservable } from 'mobx';
import { areas } from '../../knowledge/knowledgeGraphNodes';
import type { SectionLessonOrCheckpointStore } from './section.lessonOrCheckpoint.store';

export class SectionLessonAreaStore {
  @observable lessons!: SectionLessonOrCheckpointStore[];

  @observable enabled!: boolean;

  id!: number;

  textId!: 'pitch_and_harmony' | 'rhythm' | 'ear_training';

  title!: string;

  @computed get isRequired(): boolean {
    return this.enabled && !!this.requiredLessons.length;
  }

  @computed get isVisible(): boolean {
    return this.enabled && !!this.visibleLessons.length;
  }

  @computed get isOptional(): boolean {
    return this.isVisible && !this.isRequired;
  }

  @computed get visibleLessons(): SectionLessonOrCheckpointStore[] {
    return this.lessons.filter((l) => l.isVisible);
  }

  @computed get requiredLessons(): SectionLessonOrCheckpointStore[] {
    return this.lessons.filter((l) => l.isRequired);
  }

  constructor(lessonStores: SectionLessonOrCheckpointStore[], areaEnabled: boolean) {
    makeObservable(this);
    this.init(lessonStores, areaEnabled);
  }

  @action init(lessonStores: SectionLessonOrCheckpointStore[], areaEnabled: boolean) {
    this.enabled = areaEnabled;
    this.lessons = lessonStores;
    this.textId = lessonStores[0].definition.areaId;
    const area = areas.find((potentialArea) => potentialArea.textId === this.textId);
    if (!area) throw Error(`Unable to find area for ${this.textId}`);
    this.id = area.id;
    this.title = area.title;
  }

  @action setEnabled(enabled: boolean) {
    this.enabled = enabled;
  }
}
