import type { Lesson } from '../../../definitions/content-definitions';

/**
 * # Harmonic Minor Scales
 */
export const lesson: Lesson = {
  id: 'harmonic_minor_scales',
  areaId: 'pitch_and_harmony',
  order: 21,
  title: 'Harmonic Minor Scales',
  skills: ['harmonic_minor_piano', 'harmonic_minor'],
  pages: [
    {
      id: 'harmonic_minor_scales_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Harmonic Minor Scales</h3>
        <p>In this lesson we'll learn:
        <ul>
        <li>The interval pattern of harmonic minor scales,</li>
        <li>What an Augmented 2nd is, and how to write it,</li>
        <li>How to write a harmonic minor scale.</li></ul>
        </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: About Harmonic Minor Scales
     *
     * Natural minor is lovely, but it has one major flaw: it removes the
     * half-step that's between scale degrees 7 and 1. That half step is
     * a big part of what gives the tonic note its magnetic pull.
     *
     * Listen to that difference -- if I play 5-6-7 in Major, there's a huge
     * pull, our ear wants, the resolution up to 1.
     *
     * But if I play the same thing in natural minor -- it doesn't have the same
     * effect at all. Although it still has some dissonance, it doesn't have
     * strong pull anymore. It even weakens our sense of tonic -- instead of
     * pulling up to 1, it seems to pull down into a different key.
     *
     * That's a cool effect -- unless you wanted to come back to the key you
     * started in. To make that possible, composers tend to borrow back the
     * higher scale degree 7 from Major near cadence points, places where they
     * want to return to tonic. We call this "Harmonic Minor" because it
     * restores the sense of harmonic pull towards tonic.
     *
     * Let's look at what would happen if we did that in a minor scale: here's
     * C natural minor, if we raise 7 up to B-natural, what's our pattern of
     * half and whole steps? (work through)
     *
     * ..and from 6 to 7, we have a step which is larger than a whole step,
     * it's 1 & 1/2 steps! We call this an Augmented 2nd. Have a listen to this
     * interval in the scale, it has a bit of a spicy character that jumps out
     * at us! Classical composers tend to avoid this interval -- unless they're
     * making a reference to "exotic" cultures, typically arab ones.
     *
     * Consider, for instance, "Arabian Nights" from Alladin -- where Alan
     * Menken also raises the 4th scale degree in minor, so you get two
     * augmented seconds!
     *
     * But -- I digress -- harmonic minor has half steps between 2-3, 5-6,
     * just like natural minor, plus an augmented 2nd from 6-7, and a
     * half-step from 7 back to tonic.
     *
     * Let's get some practice remembering this pattern.
     */
    {
      id: 'harmonic_minor_scales_video_1',
      order: 2,
      title: 'Harmonic Minor Interval Pattern',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'harmonicMinorScales1',
      },
    },
    /**
     * Exercise: What's the interval between ___ and ___ in a harmonic minor
     * scale?
     */
    {
      title: 'Interval Pattern Practice',
      order: 3,
      id: 'harmonic_minor_scales_hs_ws_pattern',
      interactive: {
        type: 'ScalePatterns',
        questionPrompt: 'What is the interval between the {question} notes of {question} scale?',
        hideAugSecond: false,
        skills: ['harmonic_minor_piano'],
        ordinalNumbers: true,
      },
    },
    /**
     * Video: Augmented 2nds
     *
     * Let's take a few moments to practice playing & writing Augmented 2nds.
     *
     * Remember: Aug 2nd's have three half-steps. So, if we wanted to play an A2
     * above G-natural, we'd count up three notes, to this black note.
     *
     * We'll spell it with adjacent-letter names. So, what sort of A would we
     * call this note? (A#)
     *
     * Let's get some practice playing, spelling, and writing Augmented 2nds.
     */
    {
      id: 'harmonic_minor_scales_video_2',
      order: 4,
      title: 'Augmented Seconds',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'harmonicMinorScales2',
      },
    },
    {
      title: 'Play & Spell A2',
      order: 5,
      id: 'harmonic_minor_scales_play_spell_A2',
      interactive: {
        type: 'PlayAndSpellIntervals',
        highestNote: 'C5',
        lowestNote: 'G3',
        halfWholeNames: true,
        questions: ['G/A2', 'Eb/A2', 'C4/A2', 'Gb4/A2', 'B3/A2'],
      },
    },
    {
      title: 'Write: Aug 2nd',
      order: 6,
      id: 'harmonic_minor_scales_write_A2',
      interactive: {
        type: 'WriteIntervals',
        questions: ['Ab3/A2', 'E4/A2', 'Bb4/A2', 'D3/A2', 'F#3/A2'],
      },
    },
    /**
     * Video: Spelling Harmonic Minor Scales
     *
     * With this in mind, we can play & spell harmonic minor scales. Just
     * remember the arrangement of intervals: half steps from 2-3 and 5-6,
     * just as in natural minor, plus an Aug 2 from 6-7, which gives us a
     * half step from 7-8.
     *
     * Let's play & spell some harmonic minor scales.
     */
    {
      id: 'harmonic_minor_scales_video_3',
      title: 'Spelling Minor Scales',
      order: 7,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'harmonicMinorScales3',
      },
    },
    /**
     * Exercise: Play & Spell Harmonic Minor Scales:
     */
    {
      id: 'harmonic_minor_scales_play_and_spell',
      title: 'Play & Spell Harmonic Minor',
      order: 8,
      interactive: {
        type: 'ScalePlayAndSpell',
        scaleDegreeHints: true,
        questions: [
          'G/harmonic',
          'C#4/harmonic',
          'D/harmonic',
          'Ab5/harmonic/dsc',
          'G#/harmonic/dsc',
        ],
        autoadvance: true,
      },
    },
    /**
     * Video: Writing harmonic minor scales
     *
     * If you can do that, you can write harmonic minor scales on the staff.
     * Let's do it -- and mix in some natural minor scales, so that we keep
     * our memory sharp on those!
     */
    {
      id: 'harmonic_minor_scales_video_4',
      title: 'Writing Harmonic Minor',
      order: 9,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'harmonicMinorScales4',
      },
    },
    /**
     * Exercise: Write harmonic & natural minor scales:
     */
    {
      id: 'harmonic_minor_scales_writing',
      title: 'Write: Harmonic Minor',
      order: 9,
      interactive: {
        type: 'ScaleWrite',
        questionPrompt: 'Write {question} scale.',
        questions: [
          'C/harmonic',
          'A#2/harmonic',
          'B/minor',
          'D#4/harmonic/dsc',
          'E/harmonic/dsc',
          'Ab/minor/dsc',
          'Bb3/harmonic/dsc',
        ],
      },
    },
    /**
     * Conclusion Video:
     *
     * Nice work -- you can now play and write ascending and descending
     * harmonic minor & natural minor scales.
     */
    {
      id: 'harmonic_minor_scales_video_5',
      title: 'Conclusion',
      order: 10,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'harmonicMinorScales5',
      },
    },
  ],
};
