import type { Lesson } from '../../../definitions/content-definitions';

export const lesson: Lesson = {
  id: 'major_scales_staff',
  areaId: 'pitch_and_harmony',
  order: 15,
  title: 'Writing Major Scales',
  skills: ['major_scales_staff'],
  pages: [
    {
      id: 'writing_major_scales_intro',
      order: 1,
      title: 'Introduction',
      content: `
        <h3>Writing Major Scales</h3>
        <p>In this lesson we'll use our ability to write half and whole steps to write major scales on the staff.  The two half steps in major scales are always written with diatonic half steps, so that we never skip or repeat a letter name. </p>
        <p>Click next to get started with the lesson!</p>
        `,
    },
    /**
     * Video: Spelling Major Scales
     *
     * Remember the pattern of whole and half steps in a Major scale?  All whole
     * steps except between scale degrees 3-4 and 7-1.
     *
     * When we write Major scales, we always use diatonic half-steps -- in other
     * words, we'll use all of the letters of the scale, without repeating any.
     * (Except, of course, when we get back to our starting note -- which we
     * call our tonic.)
     *
     * Let's start with a major scale on the piano: Eb Major.  Now let's spell
     * it with letter names.  First note is Eb -- now remember, our next note
     * will always be just one letter away.  So, how should we spell scale
     * degree 2? ... F.  (etc...)
     *
     * Let's get some practice playing & spelling Major Scales on the piano.
     */
    {
      id: 'writing_major_scales_video_1',
      order: 2,
      title: 'Spelling Major Scales',
      video: {
        autoadvance: true,
        autostart: true,
        url: 'writingMajorScales1',
      },
    },
    {
      id: 'writing_major_scales_play_and_spell',
      title: 'Play & Spell Major Scales',
      order: 3,
      interactive: {
        type: 'ScalePlayAndSpell',
        questionPrompt: 'Play {question} scale on the piano.',
        questions: ['Ab/M', 'D/M', 'Gb/M', 'E/M/dsc', 'Bb/M/dsc'],
        autoadvance: true,
      },
    },
    /**
     * Video: Writing Major Scales
     *
     * GCR: Note, originally you'd envisioned a video & exercise of converting
     * the spelled scale on the piano to the staff... but that got cut. This,
     * however is why the numbers increment higher in the video.
     *
     * The piano is always a good tool to think about when writing scales.  And,
     * like with half and whole steps, it will make us much stronger at theory
     * to be able to do these exercises by just imagining the piano in our mind.
     * It's like being able to do subtraction in your mind without having to
     * write it down on paper.  And the more we practice this way, the more
     * complex musical concepts we'll be able to hold in our mind!
     *
     * So, let's get practice writing scales on the staff directly.  Imagine the
     * piano in your mind as you're doing it -- try not to draw one out, unless
     * you absolutely have to, as you're working through these.
     */
    {
      id: 'writing_major_scales_video_3',
      title: 'Writing Major Scales',
      order: 4,
      video: {
        autostart: true,
        autoadvance: true,
        url: 'writingMajorScales3',
      },
    },
    {
      id: 'writing_major_scales_staff_only',
      title: 'Write Major Scales',
      order: 5,
      interactive: {
        type: 'ScaleWrite',
        questionPrompt: 'Write {question} scale.',
        questions: ['A/M', 'C#/M', 'F#/M/dsc', 'Cb/M/dsc'],
      },
    },
    {
      id: 'writing_major_scales_summary',
      order: 6,
      title: 'Summary',
      content:
        "<h3>Nice Work</h3>\n<p>That's all there is to writing Major scales.  We'll get plenty of practice on this, and you'll soon find you can call to mind all of the notes of a Major scale almost immediately.  Good work!</p>\n",
    },
  ],
};
