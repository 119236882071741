import { action, observable, computed, makeObservable } from 'mobx';
import type {
  CustomCheckpointQuestionGroup,
  CustomCheckpointSection,
} from 'definitions/checkpoint-definitions';
import type { CustomTestStore } from './custom.test.store';
import { CustomTestQuestionGroupStore } from './custom.test.question.group.store';
import { sum } from '../../utils/sum';

export class CustomTestSectionStore {
  @observable test!: CustomTestStore;

  @observable questionGroups!: CustomTestQuestionGroupStore[];

  constructor(public index: number, test: CustomTestStore) {
    makeObservable(this);
    this.init(test);
  }

  @computed get status(): 'all' | 'some' | 'none' {
    if (this.disabled) return 'none';
    if (this.questionGroups.every((g) => !g.disabled && g.numberOfQuestions)) return 'all';
    if (this.questionGroups.find((g) => !g.disabled && g.numberOfQuestions)) return 'some';
    return 'none';
  }

  @action init(test: CustomTestStore) {
    this.test = test;
    this.questionGroups = this.checkpointSectionRecord.questionGroups.map(
      (__, i) => new CustomTestQuestionGroupStore(i, this)
    );
  }

  @computed private get checkpointSectionRecord() {
    const sectionRecord = this.test.definition.sections[this.index];
    if (!sectionRecord) {
      throw Error(`Section ${this.index} not found in test definition`);
    }
    return sectionRecord;
  }

  @computed private get checkpointModelSectionRecord() {
    const modelSectionRecord = this.test.checkpointTest.sections[this.index];
    if (!modelSectionRecord) {
      throw Error(`Section ${this.index} not found in test model`);
    }
    return modelSectionRecord;
  }

  @computed get numberOfQuestions(): number {
    return this.questionGroups
      .filter((qg) => !qg.disabled)
      .map((qg) => qg.numberOfQuestions)
      .reduce(sum, 0);
  }

  @computed get disabled(): boolean {
    return !!this.checkpointSectionRecord.options.disabled;
  }

  @action setDisabled(disabled: boolean) {
    this.checkpointSectionRecord.options.disabled = disabled;
  }

  @computed get timeLimit(): string | null {
    return this.checkpointSectionRecord.options.timeLimit || null;
  }

  @action setTimeLimit(timeLimit: string | null) {
    this.checkpointSectionRecord.options.timeLimit = timeLimit;
  }

  @computed get weight(): number {
    return (
      this.checkpointSectionRecord.options.weight ??
      this.checkpointModelSectionRecord.options.weight
    );
  }

  @action setWeight(weight: number | null) {
    this.checkpointSectionRecord.options.weight = weight;
  }

  @computed get passingPercent(): number | null {
    return this.checkpointSectionRecord.options.passingPercent || null;
  }

  @action setPassingPercent(passingPercent: number | null) {
    this.checkpointSectionRecord.options.passingPercent = passingPercent;
  }

  @computed get title(): string {
    return this.checkpointModelSectionRecord.options.name;
  }

  dehydrate(): CustomCheckpointSection {
    return {
      options: {
        disabled: this.disabled,
        timeLimit: this.timeLimit,
        passingPercent: this.passingPercent,
        weight: this.weight,
      },
      questionGroups: this.questionGroups.map(
        (qg): CustomCheckpointQuestionGroup => ({
          options: {
            disabled: qg.disabled,
            numberOfQuestions: qg.numberOfQuestions,
          },
        })
      ),
    };
  }

  @action save() {
    this.test.save();
  }

  @action delayedSave() {
    if (!this.test.delayedSave)
      throw Error(
        'QuestionGroup.delayedSave can only be called from a ClientStore, not a UserStore'
      );
    this.test.delayedSave();
  }
}
