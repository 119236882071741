import type Stripe from 'stripe';
import { cloneDeep } from '../src/utils/clone';

// Used in shared to convert JSON data from the uTheory server which has the
// dates as date strings.
export function subscriptionStringsToDates(subscription: Subscription): Subscription {
  subscription = cloneDeep(subscription);
  [
    'canceled_at',
    'created',
    'current_period_end',
    'current_period_start',
    'start',
    'trial_end',
    'trial_start',
  ].forEach((key) => {
    if (key in subscription && (subscription as any)[key]) {
      (subscription as any)[key] = new Date((subscription as any)[key]);
    }
  });
  return subscription;
}

export interface Subscription extends Stripe.Subscription {
  userId: string | null;
  nextPlanId: string | null;
  nextPlanName: string | null;
  switchToBookstoreSubscription?: boolean | null;
}

// export interface Subscription {
//   application_fee_percent: null;
//   billing: string;
//   billing_cycle_anchor: number;
//   billing_thresholds: null;
//   cancel_at: number;
//   cancel_at_period_end: boolean;
//   canceled_at: Date;
//   collection_method: string;
//   created: Date;
//   current_period_end: Date;
//   current_period_start: Date;
//   customer: string;
//   days_until_due: null | number;
//   default_payment_method: null | any;
//   default_source: null | any;
//   default_tax_rates: DefaultTaxRate[];
//   discount: null | any;
//   ended_at: null | Date;
//   id: string;
//   items: Items;
//   latest_invoice: string;
//   livemode: boolean;
//   metadata: StripeMetadata;
//   nextPlanName: null | string;
//   object: 'subscription';
//   pending_setup_intent: null | any;
//   plan: Plan;
//   quantity: number;
//   schedule: null | any;
//   start: Date;
//   start_date: number;
//   status:
//     | 'incomplete'
//     | 'incomplete_expired'
//     | 'trialing'
//     | 'active'
//     | 'past_due'
//     | 'canceled'
//     | 'unpaid';
//   tax_percent: number;
//   trial_end: null | Date;
//   trial_start: null | Date;
//   userId: string;
//   /** Switch to a monthly or yearly plan as indicated */
//   nextPlanId?: string;
//   /** User has paid for already, should be applied at end of current subscription period */
//   switchToBookstoreSubscription?: boolean;
// }

export interface DefaultTaxRate {
  active: boolean;
  created: number;
  description: null | string;
  display_name: string;
  id: string;
  inclusive: boolean;
  jurisdiction: null | any;
  livemode: boolean;
  metadata: object;
  object: string;
  percentage: number;
}

export interface DefaultTaxRateMetadata {
  [key: string]: string;
}

export interface Items {
  data: Datum[];
  has_more: boolean;
  object: string;
  total_count: number;
  url: string;
}

export interface Datum {
  billing_thresholds: null;
  created: Date;
  id: string;
  metadata: DefaultTaxRateMetadata;
  object: string;
  plan: Plan;
  quantity: number;
  subscription: string;
  tax_rates: any[];
}

export interface Plan {
  active: boolean;
  aggregate_usage: null;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: Date;
  currency: string;
  id: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: DefaultTaxRateMetadata;
  name: string;
  nickname: null | string;
  object: string;
  product: string;
  statement_descriptor: null | string;
  tiers: null | any;
  tiers_mode: null | any;
  transform_usage: null | any;
  trial_period_days: null | number;
  usage_type: string;
}

export interface StripeMetadata {
  email: string;
  newCustomer: string;
  userId: string;
  [key: string]: string;
}
