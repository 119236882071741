/* eslint-disable max-classes-per-file */
import { observable, action, computed, makeObservable } from 'mobx';
import type { CleanedLessonRecord } from '../../../definitions/lesson-record';
import type { Lesson, LessonPage } from '../../../definitions/content-definitions';

import { UserContentStore } from './user.lessonOrCheckpoint.store';
import type { AbstractUserStore } from '../abstract.user.store';
import { getGradePenalty } from './user.utils';
import { merge } from '../../utils/merge';
import { isLessonCheckpointDefinition } from '../../section/sectionLessons/section.lessonOrCheckpoint.store';

export class UserLessonStore extends UserContentStore {
  type: 'lesson' = 'lesson';

  @computed get numberOfPages(): number {
    return (
      (!isLessonCheckpointDefinition(this.model.definition) &&
        this.model.definition.pages.length) ||
      1
    );
  }

  record!: CleanedLessonRecord;

  @observable highestPageCompleted!: number;

  @computed get isComplete(): boolean {
    return this.numberOfPages <= this.highestPageCompleted;
  }

  @computed get isInProgress(): boolean {
    return this.highestPageCompleted > 0 && !this.isComplete;
  }

  @computed get gradesRow(): (number | string | boolean | Date | null)[] {
    const columns = this.model.gradeColumnInformation;
    return columns.map((column) => {
      switch (column.type) {
        case 'userInfo':
          return this.root.gradesRowUserInfo[column.id];
        case 'overall':
          if (column.id === 'date') return this.dateCompleted || null;
          return this.progress;
        case 'page':
          return this.pages.find((page) => page.id === column.id)?.isComplete ?? false;
      }
      return null;
    });
  }

  /**
   * Lesson pages with title determined based on section & user prefs
   */
  @computed get pages(): (Omit<LessonPage, 'title'> & {
    isAvailable: boolean;
    isComplete: boolean;
    nextPageIsAvailable: boolean;
    type: ReturnType<typeof getPageType>;
    title: string;
  })[] {
    const isTeacherInDefaultSection =
      this.root.isTeacher && this.root.currentSection.isDefaultSection;

    return (this.model.definition as Readonly<Lesson>).pages.map((page, index) => {
      const isComplete = this.highestPageCompleted > index;
      const nextPageIsAvailable = isComplete || isTeacherInDefaultSection;

      return {
        ...page,
        title: this.getPageTitle(page),
        isAvailable: isTeacherInDefaultSection || this.highestPageCompleted >= index,
        isComplete,
        nextPageIsAvailable,
        type: getPageType(page),
      };
    });
  }

  /**
   * Returns the page the user should view when opening the lesson.
   */
  @computed get startingPage(): LessonPage {
    // This is 1-indexed
    const currentPage = this.highestPageCompleted;
    // If there's a page after it, that's the one we go to:
    const page = this.pages[currentPage];
    // Otherwise, we go to the first page:
    return page || this.pages[0]!;
  }

  /**
   * Returns the next page
   */
  getNextPage(currentPageId: string) {
    const pages = this.pages;
    const index = pages.findIndex((page) => page.id === currentPageId);
    return pages[index + 1];
  }

  /**
   * Returns the previous page
   */
  getPreviousPage(currentPageId: string) {
    const pages = this.pages;
    const index = pages.findIndex((page) => page.id === currentPageId);
    return pages[index - 1];
  }

  /**
   * In some solfege methods, certain pages should be skipped.
   */
  shouldLessonPageBeSkipped(page: Lesson['pages'][number]): boolean {
    const currentSolfegeMethod = this.root.preferences.solfegeMethod;
    return !page.skipForSolfegeMethods?.includes(currentSolfegeMethod);
  }

  getPageTitle(page: LessonPage): string {
    const { solfegeMethod, rhythmMethod } = this.root.preferences;

    if (typeof page.title === 'object') {
      if (page.title[solfegeMethod]) {
        return page.title[solfegeMethod] as string;
      }
      if (page.title[rhythmMethod]) {
        return page.title[rhythmMethod] as string;
      }
    } else if ('title' in page) {
      return page.title;
    }
    return 'Page';
  }

  /**
   * Percentage of a lesson finished, 0 - 100. In the future, it is intended that
   * this will reflect a user's accuracy in a lesson as well.
   */
  @computed get score(): number {
    return Math.max(0, this.progress - this.gradePenalty);
  }

  @computed get scoreWithoutGradePenalty(): number {
    return this.progress;
  }

  /**
   * Percentage of pages finished, 0 - 100
   */
  @computed get progress(): number {
    const pages =
      (!isLessonCheckpointDefinition(this.model.definition) &&
        this.model.definition.pages.length) ||
      1;
    return Math.min(100, (100 * this.highestPageCompleted) / pages);
  }

  @computed get isLate(): boolean {
    // If it's not required, we don't have to worry.
    if (!this.isRequired) return false;

    // If there's no due date, we don't have to worry.
    if (!this.dueDate) return false;
    const dateCompleted = this.dateCompleted || new Date();
    return this.dueDate.valueOf() <= dateCompleted.valueOf();
  }

  /** The date a lesson was first completed */
  @computed get dateCompleted(): Date | undefined {
    if (!this.isComplete) return undefined;
    if (this.record.highestPageFirstCompletedOn) {
      return new Date(this.record.highestPageFirstCompletedOn);
    }

    // This should never happen -- but if we can't determine the date a lesson
    // was completed, return the earlier of its due date, the earliest of the
    // highest page completed, or now.
    const dueDate = this.dueDate || new Date();
    const now = new Date();

    return dueDate.valueOf() < now.valueOf() ? dueDate : now;
  }

  @computed get gradePenalty(): number {
    // If it's not late, no penalty.
    if (!this.isLate) return 0;

    // This is an extra check -- isLate includes this -- but makes type checking later easier
    if (!this.dueDate) return 0;

    // If the current section doesn't penalize, we don't have to worry.
    if (this.root.currentSection.options.deadlines.penalty.deductionInterval === 'never') return 0;

    // If the deduction percent is set to 0, we don't have to worry.
    if (this.root.currentSection.options.deadlines.penalty.deductionPercent === 0) return 0;

    // If it hasn't yet been completed, we use now for calculating the penalty
    const dateCompleted = this.dateCompleted || new Date();

    return getGradePenalty(
      this.dueDate,
      dateCompleted,
      this.root.currentSection.options.deadlines.penalty
    );
  }

  constructor(
    public root: AbstractUserStore,
    lessonId: string,
    lessonRecord?: CleanedLessonRecord
  ) {
    super();
    makeObservable(this);
    this.init(lessonId, lessonRecord);
  }

  @action init(lessonId: string, lessonRecord?: CleanedLessonRecord) {
    this.id = lessonId;
    this.update(lessonRecord);
  }

  @action update(lessonRecord: Partial<CleanedLessonRecord> = { highestPageCompleted: 0 }) {
    this.record = merge(this.record, lessonRecord);
    if (lessonRecord.highestPageCompleted !== undefined) {
      this.highestPageCompleted = lessonRecord.highestPageCompleted;
    }
  }
}

function getPageType(page: LessonPage) {
  if ('content' in page) {
    return 'Info';
  }

  if (page.video) {
    return 'Video';
  }

  if (page.interactive) {
    return 'Practice';
  }
  // This should never happen...
  return '';
}
